import swal from "sweetalert";
import { Modal, Button } from "react-bootstrap";
import React, { useState, useEffect, useRef } from "react";
import SimpleReactValidator from "simple-react-validator";
import { TextInput } from "../../components/FormInputs";

const DesignationModel = (props: any) => {

  const { show, pageType, data } = props;

  const validator = useRef(new SimpleReactValidator());

  const [disableButton, setDisableButton] = useState<boolean>(false);
  const [count, forceUpdate] = useState<number>(0);
  const [designation, setDesignation] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [label, setLabel] = useState<string>("");
  const [isDisabled, setIsDisabled] = useState<boolean>(false);

  useEffect(() => {
    if (pageType == "add") {
      setLabel("Add Designation")
    }
    if (pageType == "edit") {
      setLabel("Edit Designation")
      setDesignation(data.designation);
      setDescription(data.description);
    }
    if (pageType == "view") {
      setLabel("View Designation")
      setDesignation(data.designation);
      setDescription(data.description);
      setIsDisabled(true)
    }
  }, []);

  const isDesignationSubmit = () => {
    const payload = {
      designation: designation,
      description: description
    }
    if (pageType == "add") {
      swal("Designation added succesfully!", '', 'success')
        .then(() => {
          props.handleClose()
        })
    } else {
      swal("Designation updated succesfully!", '', 'success')
        .then(() => {
          props.handleClose()
        })
    }
  }

  const onSubmit = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (disableButton) return;
    setDisableButton(true);
    if (validator.current.allValid()) {
      isDesignationSubmit()
    } else {
      setDisableButton(false);
      validator.current.showMessages()
      forceUpdate(count + 1)
    }
  }

  return (
    <>
      <Modal
        show={show}
        onHide={props.handleClose}
        // size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <>
              <h5>{label}</h5>
            </>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <TextInput
            onInputChange={(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
              setDesignation(e.target.value);
            }}
            label="Designation"
            asterisk="*"
            containerClass="form-group"
            type="text"
            name="designation"
            disabled={isDisabled}
            value={designation}
            placeholder="Designation"
            errorMessage={validator.current.message(
              "designation",
              designation,
              "required"
            )}
            autoComplete="new-designation"
          />
          <TextInput
            onInputChange={(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
              setDescription(e.target.value);
            }}
            label="Description"
            asterisk="*"
            containerClass="form-group"
            type="text"
            as="textarea"
            name="description"
            disabled={isDisabled}
            value={description}
            placeholder="Enter Description"
            errorMessage={validator.current.message(
              "description",
              description,
              "required"
            )}
            autoComplete="new-designation"
          />
          <div className="d-flex justify-content-end">
            {
              pageType !== "view" && (
                <>
                  <Button onClick={(e) => {
                    onSubmit(e)
                  }}>Submit</Button>
                  <Button className="ml-10" variant="secondary" onClick={() => {
                    validator.current.hideMessages();
                    props.handleClose()
                  }}>Cancel</Button>
                </>
              )
            }
            {
              pageType == "view" && (
                <Button onClick={() => {
                  validator.current.hideMessages();
                  props.handleClose()
                }}>Back</Button>
              )
            }
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}
export default DesignationModel;