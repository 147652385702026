/* eslint-disable */
import httpService from "../services/HttpService";
import httpResponse from "../services/HttpResponse";

class AuthApi {
  getDataFromServer = async (endPoint: string,config?:any) =>
    httpService
      .get(endPoint,config) // eslint-disable-line
      .then(
        (response: any): any => {
          if (response.data.code === 200 || response.data.code === 201) {
            httpResponse.data = response.data;
            return httpResponse;
          }
          httpResponse.data = response.data;
          httpResponse.success = false;
          httpResponse.message = response.data.error;
          return httpResponse;
        },
        (err) => {
          httpResponse.data = null;
          httpResponse.success = false;
          httpResponse.message =
            err && err.status == 500 ? "Something went wrong! Please try again later" :
              err && err.data && err.data.message
                ? err.data.message
                : err &&
                  err.response &&
                  err.response.data &&
                  err.response.data.message
                  ? err.response.data.message
                  : "Something went wrong! Please try again later"; // eslint-disable-line
          return httpResponse;
        }
      );

  postDataToServer = async (endPoint: string, payload: any) =>

    httpService
      .post(endPoint, payload) // eslint-disable-line
      .then(
        (response: any): any => {
          if ([200, 201, 202].includes(response.data.code)) {
            httpResponse.data = response.data;            
            return httpResponse;
          }
          httpResponse.success = false;
          httpResponse.message = response.data.message;
          return httpResponse;
        },
        (err) => {

          httpResponse.success = false;
          httpResponse.data = null;
          httpResponse.message =
            err && err.status == 500 ? "Something went wrong! Please try again later" :
              err && err.data && err.data.message
                ? err.data.message
                : err &&
                  err.response &&
                  err.response.data &&
                  err.response.data.message
                  ? err.response.data.message
                  : err.message
                  // : "Something went wrong! Please try again later"; // eslint-disable-line
          return httpResponse;
        }

      );

  putDataToServer = async (endPoint: string, payload: any) =>
    httpService
      .put(endPoint, payload) // eslint-disable-line
      .then(
        (response: any): any => {
          if (response.data.code === 200 || response.data.code === 202) {
            httpResponse.data = response.data;
            return httpResponse;
          }
          httpResponse.success = false;
          httpResponse.message = response.data.message;
          return httpResponse;
        },
        (err) => {
          httpResponse.success = false;
          httpResponse.data = null;
          httpResponse.message =
            err && err.status == 500 ? "Something went wrong! Please try again later" :
              err && err.data && err.data.message
                ? err?.data?.message
                : err &&
                  err.response &&
                  err.response.data &&
                  err.response.data?.message
                  ? err.response.data?.message
                  : "Something went wrong! Please try again later"; // eslint-disable-line
          return httpResponse;
        }
      );

  patchDataToServer = async (endPoint: string, payload: any) =>
    httpService
      .patch(endPoint, payload) // eslint-disable-line
      .then(
        (response: any): any => {
          if (response.data.code === 200 || response.data.code === 202) {
            httpResponse.data = response.data;
            return httpResponse;
          }
          httpResponse.success = false;
          httpResponse.message = response.data.message;
          return httpResponse;
        },
        (err) => {
          httpResponse.success = false;
          httpResponse.data = null;
          httpResponse.message =
            err && err.data && err.data.message
              ? err.data.message
              : err &&
                err.response &&
                err.response.data &&
                err.response.data.message
                ? err.response.data.message
                : "Something went wrong! Please try again later"; // eslint-disable-line
          return httpResponse;
        }
      );

  deleteDataFromServer = async (endPoint: string, payload: any) =>
    httpService
      .delete(endPoint)
      .then(
        (response: any): any => {
          if ([200, 201, 202].includes(response.data.code)) {
            httpResponse.data = response.data;
            return httpResponse;
          }
          httpResponse.success = false;
          httpResponse.message = response.data.message;
          return httpResponse;
        },
        (err) => {
          httpResponse.success = false;
          httpResponse.data = null;
          httpResponse.status = err.data && err.data.code ? err.data.code : 400;
          httpResponse.message =
            err && err.data && err.data.message // eslint-disable-line
              ? typeof err.data.message == "object" // eslint-disable-line
                ? err.data.message
                : err.data.message || err.data.message
              : "Something went wrong! Please try again later"; // eslint-disable-line
          return httpResponse;
        }
      );
}

export default new AuthApi();
