import React, { useEffect, useRef, useState } from "react";
import SimpleReactValidator from "simple-react-validator";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Card, Col, Row, Image } from "react-bootstrap";
import {
  TextInput,
  Datepicker,
  Helmet,
  DropdownInput,
  PasswordInput,
} from "../../components/FormInputs";
import Breadcrumb from "../../components/Breadcrumb/breadcrumb.component";
import swal from "sweetalert";
import Api from "../../helper/api";
import AuthApi from "../../helper/authApi";
import customFunctions from "../../helper/customFunctions";
import Loader from "../../components/Loader";
import AddDocument from "./addDocument_model";

const AddEmployee = () => {
  const validator = useRef(new SimpleReactValidator());

  const navigate = useNavigate();
  const state = useLocation();
  const currentDate = new Date();
  const minDate = new Date(currentDate);

  const [count, forceUpdate] = useState<number>(0);
  const [pageType, setPageType] = useState<string>("add");
  const [title, setTitle] = useState<string>("Create Employee");
  const [label, setLabel] = useState<string>("Create Employee");
  const [updateShow, setUpdateShow] = useState<boolean>(false);
  const [disableButton, setDisableButton] = useState<boolean>(false);
  const [isShowLoader, setShowLoader] = useState<boolean>(false);
  const [userId, setUserId] = useState<any>();

  const [basicDetails, setBasicDetails] = useState<any>({
    firstName: "",
    lastName: "",
    email: "",
    contactNumber: "",
    panCardNumber: "",
    aadharCardNumber: "",
    address: "",
    password: "",
  });

  const [joiningDetails, setJoiningDetails] = useState<any>({
    joiningDate: "",
    designation: "",
    dateOfBirth: "",
    employeeId: "",
    isAdmin: false,
  });

  const [previousJoiningDate, setPreviousJoiningDate] = useState<any>("");
  const [designationValues, setDesignationValues] = useState<any>([]);
  const [changeDocumentLabel, setChangeDocumentLabel] = useState<any>();

  const [documents, setDocuments] = useState<any>([]);
  const [documentType, setDocumentType] = useState([
    {
      doc_id: null,
      files: "",
      description: "",
      documentsArr: customFunctions.uniqueArray(documents, "value"),
      file_id: null,
      id: null,
      title: "",
    },
  ]);

  const items: any = [
    { to: "/", label: "Dashboard" },
    { to: "/employee-list", label: "Employees" },
    { label: label },
  ];

  useEffect(() => {
    if (state && state?.state?.pageType === "add") return;

    if (state && state?.state) {
      setUserId(state?.state?.id);
      if (state.state.pageType === "edit") {
        setPageType("edit");
        setTitle("Edit Employee Details");
        setLabel("Edit Employee");
      }
      if (state.state.pageType === "view") {
        setPageType("view");
        setTitle("View Employee Details");
        setLabel("View Employee");
      }
    }
  }, [state]);

  useEffect(() => {
    if (!userId) return;
    getEmployee();
  }, [userId]);

  useEffect(() => {
    getDesignationData();
    getDocumentType();
  }, []);

  const getDesignationData = async () => {
    setShowLoader(true);
    const { data, message } = await AuthApi.getDataFromServer(
      `${Api.designationUrl}`
    );

    if (data && data.success === true) {
      setShowLoader(false);
      if (data && data.data && data.data.length > 0) {
        let designationArr: any = [];
        data &&
          data.data.length > 0 &&
          data.data.map((item: any) => {
            if ([1, 2].includes(item.id)) return;
            designationArr.push({ label: item.designation, value: item.id });
          });
        setDesignationValues(designationArr);
      } else {
        setDesignationValues([]);
      }
    } else {
      setShowLoader(false);
      swal(
        typeof message == "string"
          ? message
          : message?.length && message[0]
          ? message[0]
          : "Something went wrong!",
        "",
        "error"
      );
      return;
    }
  };

  const getEmployee = async () => {
    setShowLoader(true);
    const { data, message } = await AuthApi.getDataFromServer(
      `${Api.employeeUrl}${userId}/`
    );

    if (data && data.success === true) {
      setShowLoader(false);
      if (data && data.data) {
        let { employee, user, address, documents } = data.data;
        const roleType: any = {
          label: user.role_name,
          value: user.role,
        };

        const documentsDetailsArr: any = [];
        documents &&
          documents.length &&
          documents.map((doc: any, index: any) => {
            documentsDetailsArr.push({
              doc_id: {
                label: doc.doc_type_name,
                value: doc.doc_type_id,
              },
              file_id: doc.Documents.id,
              description: doc.description,
              files: doc.Documents.file_name,
              documentsArr: customFunctions.uniqueArray(documents, "value"),
              title: doc.Documents.f_name,
            });
            // if (joiningDetails.employeeId)
            if (userId) {
              documentsDetailsArr[index].id = doc.id;
            }
          });

        setDocumentType(documentsDetailsArr);

        setBasicDetails({
          firstName: user?.first_name,
          lastName: user?.last_name,
          email: user?.email,
          contactNumber: user?.mobile,
          panCardNumber: employee?.pan_number,
          aadharCardNumber: employee?.aadhar_card,
          address: address?.area,
          // password: employeeData?.user?.password,
        });
        setJoiningDetails({
          joiningDate: new Date(employee?.joining_date),
          designation: roleType,
          dateOfBirth: new Date(employee?.date_of_birth),
          employeeId: employee?.emp_id,
          isAdmin: user?.admin_access,
        });
        setPreviousJoiningDate(new Date(employee?.joining_date));
      }
    } else {
      setShowLoader(false);
      swal(
        typeof message == "string"
          ? message
          : message?.length && message[0]
          ? message[0]
          : "Something went wrong!",
        "",
        "error"
      );
    }
  };

  const addEmployee = async () => {
    setShowLoader(true);

    const documentsDetailsArr: any = [];
    documentType &&
      documentType.length &&
      documentType.map((doc: any) => {
        documentsDetailsArr.push({
          doc_id: doc.doc_id ? doc.doc_id.value : "",
          file_id: doc.file_id ? doc.file_id : "",
          description: doc.description,
        });
        // if (!employeeId) delete doc.id;
      });

    const postData: any = {
      user: {
        first_name: basicDetails?.firstName,
        last_name: basicDetails?.lastName,
        email: basicDetails?.email?.trim(),
        password: basicDetails?.password,
        role: joiningDetails?.designation?.value,
        mobile: basicDetails?.contactNumber,
        admin_access: joiningDetails?.isAdmin,
      },
      pan_number: basicDetails?.panCardNumber,
      aadhar_card: basicDetails?.aadharCardNumber,
      date_of_birth: customFunctions.getDate(joiningDetails.dateOfBirth),
      emp_id: joiningDetails.employeeId,
      address: { area: basicDetails?.address },
      documents: documentsDetailsArr,
    };

    if (
      customFunctions.getDate(previousJoiningDate) !=
      customFunctions.getDate(joiningDetails.joiningDate)
    ) {
      postData.joining_date = customFunctions.getDate(
        joiningDetails.joiningDate
      );
    }

    if (userId) {
      postData.id = userId;
    } else {
      postData.user.password = basicDetails?.password;
    }

    const payload: any = customFunctions.cleanObject(postData);

    const endPoint: any = userId
      ? `${Api.employeeUrl}${userId}/`
      : `${Api.employeeUrl}`;

    const callback: any = userId
      ? AuthApi.putDataToServer
      : AuthApi.postDataToServer;

    const { data, message } = await callback(endPoint, payload);

    if (data && data.success === true) {
      setShowLoader(false);
      setDisableButton(false);
      swal(
        userId
          ? "Employee updated successfully!"
          : "Employee created successfully!",
        "",
        "success"
      ).then(() => {
        navigate("/employee-list");
      });
    } else {
      setDisableButton(false);
      setShowLoader(false);
      swal(
        typeof message == "string"
          ? message
          : message?.length && message[0]
          ? message[0]
          : "Something went wrong!",
        "",
        "error"
      );
    }
  };

  const addField = () => {
    const payload = {
      doc_id: null,
      files: "",
      description: "",
      documentsArr: documents,
      file_id: null,
      id: null,
      title: "",
    };
    const newObj: any = [...documentType, payload];
    setDocumentType([...newObj]);
  };

  const deleteField = (index: any) => {
    const del: any = [...documentType];
    del.splice(index, 1);
    setDocumentType([...del]);
    forceUpdate(count + 1);
  };
  validator.current.purgeFields();

  const getDocumentType = async () => {
    setShowLoader(true);
    const { data, message } = await AuthApi.getDataFromServer(
      `${Api.documentTypeUrl}`
    );

    if (data && data.success === true) {
      setShowLoader(false);
      if (data && data.data) {
        let documenttype: any = [];
        data &&
          data.data &&
          data.data.map((item: any) => {
            documenttype.push({ label: item?.name, value: item?.id });
          });
        documenttype.push({
          value: "Add_more",
          label: <a className="btn-link">Add More</a>,
          id: "1",
        });
        setDocuments(documenttype);
      } else {
        setDocuments([]);
      }
    } else {
      setShowLoader(false);
      swal(
        typeof message == "string"
          ? message
          : message?.length && message[0]
          ? message[0]
          : "Something went wrong!",
        "",
        "error"
      );
    }
  };

  const uploadDocument = async (pIndex: any) => {
    const formData = new FormData();
    formData.append("file_name", documentType[pIndex].files[0]);
    setShowLoader(true);

    const endPoint: any = `${Api.documentUploadUrl1}`;

    const callback: any = AuthApi.postDataToServer;
    const { data, message } = await callback(endPoint, formData);

    if (data && data.success === true) {
      let documentArr: any = [...documentType];
      documentArr[pIndex].file_id = data?.data?.id;
      documentArr[pIndex].files = data?.data?.file_name;
      documentArr[pIndex].title = data?.data?.f_name;

      setDocumentType([...documentArr]);
      setShowLoader(false);
      setDisableButton(false);
      swal(
        state.state && state.state.clientId
          ? "Document updated successfully!"
          : "Document uploaded successfully!",
        "",
        "success"
      );
    } else {
      setDisableButton(false);
      setShowLoader(false);
      swal(
        typeof message == "string"
          ? message
          : message?.length && message[0]
          ? message[0]
          : "Something went wrong!",
        "",
        "error"
      );
    }
  };

  const getDocumentData = (pIndex: any) => {
    const documentsDetailsArr: any = [...documentType];
    if (documentsDetailsArr && documentsDetailsArr.length) {
      let filterDocmentsArr: any = documents.filter((sr: any) => {
        return documentsDetailsArr.findIndex(
          (serv: any, i: any) =>
            sr?.value === serv?.doc_id?.value && i !== pIndex
        ) === -1
          ? true
          : false;
      });

      documentsDetailsArr[pIndex].documentsArr = filterDocmentsArr?.length
        ? customFunctions.uniqueArray(filterDocmentsArr, "value")
        : [];
    }
    setDocumentType(documentsDetailsArr);
    forceUpdate(count + 1);
  };

  const checklistChangeImage = (itm: any) => {
    const newImage: any = documentType.some((item2: any) => {
      if (item2.doc_id && item2.file_id !== null) {
        return Number(itm.value) === Number(item2.doc_id.value);
      }
    })
      ? "/images/complete-task-green.svg"
      : "/images/complete-task.svg";
    return newImage;
  };

  const isChangeLabel = (it: any) => {
    let changeLabel: any =
      documentType &&
      documentType.filter((item: any) => {
        if (item?.doc_id?.value === it?.value) {
          return window.open(item?.files);
        } else {
          return;
        }
      });
    setChangeDocumentLabel(changeLabel);
  };

  const onSubmit = (e: any) => {
    e.preventDefault();
    if (validator.current.allValid()) {
      addEmployee();
    } else {
      let errFieldsArr: any = Object.entries(validator?.current?.fields).find(
        (item) => item[1] == false
      );
      let erName = errFieldsArr[0].replace(/ /g, "");
      document.getElementById(`${erName}`)?.focus();
      validator.current.showMessages();
      forceUpdate(count + 1);
    }
  };

  return (
    <>
      <Helmet title={title} />
      <div style={{ float: "right" }} className="form-group mt-4">
        <Button
          type="button"
          variant="primary"
          onClick={(e) => {
            e.preventDefault();
            navigate(-1);
          }}
        >
          Back
        </Button>
      </div>

      <div className="page-header">
        <div className="title-breadcrumb-section">
          <h2 className="main-content-title tx-24 mg-b-5">{title}</h2>
          <Breadcrumb items={items} />
        </div>
      </div>
      <Loader showLoader={isShowLoader} needFullPage={false} />
      <section className="content-area"></section>
      <Card>
        <Card.Body>
          <Row>
            <h6 className="section-title">Basic Details</h6>
            <hr />
            <Col sm={6}>
              <TextInput
                onInputChange={(
                  e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
                ) => {
                  const re = /^[a-zA-Z]*$/;
                  if (!e.target.value || re.test(e.target.value)) {
                    setBasicDetails({
                      ...basicDetails,
                      firstName: e.target.value,
                    });
                  }
                }}
                id="FirstName"
                label="First Name"
                asterisk="*"
                containerClass="form-group"
                type="text"
                name="firstName"
                value={basicDetails.firstName}
                disabled={pageType === "view"}
                placeholder="Enter first name"
                errorMessage={validator.current.message(
                  "First Name",
                  basicDetails.firstName,
                  "required"
                )}
                autoComplete="new-firstName"
              />
            </Col>

            <Col sm={6}>
              <TextInput
                onInputChange={(
                  e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
                ) => {
                  const re = /^[a-zA-Z]*$/;
                  if (!e.target.value || re.test(e.target.value)) {
                    setBasicDetails({
                      ...basicDetails,
                      lastName: e.target.value,
                    });
                  }
                }}
                id="LastName"
                label="Last Name"
                asterisk="*"
                containerClass="form-group"
                type="text"
                name="lastName"
                value={basicDetails.lastName}
                disabled={pageType === "view"}
                placeholder="Enter last name"
                errorMessage={validator.current.message(
                  "Last Name",
                  basicDetails.lastName,
                  "required"
                )}
                autoComplete="new-lastName"
              />
            </Col>
            <Col sm={6}>
              <TextInput
                onInputChange={(
                  e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
                ) => {
                  setBasicDetails({ ...basicDetails, email: e.target.value });
                }}
                id="EmailAddress"
                label="Email Address"
                asterisk="*"
                containerClass="form-group"
                type="text"
                name="email"
                value={basicDetails.email}
                disabled={pageType === "view"}
                placeholder="Enter email address"
                errorMessage={validator.current.message(
                  "Email Address",
                  basicDetails.email,
                  "required|email"
                )}
                autoComplete="new-email"
              />
            </Col>
            <Col sm={6}>
              <TextInput
                onInputChange={(e: any) => {
                  const re = /^[0-9]*$/;
                  if (!e.target.value || re.test(e.target.value)) {
                    setBasicDetails({
                      ...basicDetails,
                      contactNumber: e.target.value,
                    });
                  }
                }}
                id="contactNumber"
                label="Contact Number"
                asterisk="*"
                inputVarible={"+91"}
                containerClass="form-group"
                type="tel"
                name="contactNumber"
                value={basicDetails.contactNumber}
                disabled={pageType === "view"}
                placeholder="Enter contact number"
                maxlength={10}
                minlength={10}
                errorMessage={validator.current.message(
                  "contactNumber",
                  basicDetails.contactNumber,
                  "required|regex:^([6-9]){1}([0-9]){9}$",
                  {
                    messages: {
                      required: "The contact number field is required",
                      regex: "Invalid contact number",
                    },
                  }
                )}
                autoComplete="new-contactNumber"
              />
            </Col>

            <Col sm={6}>
              <TextInput
                onInputChange={(
                  e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
                ) => {
                  const re = /^[ 0-9 a-z A-Z ]*$/;
                  if (!e.target.value || re.test(e.target.value)) {
                    setBasicDetails({
                      ...basicDetails,
                      panCardNumber: e.target.value.toUpperCase(),
                    });
                  }
                }}
                id="PanNumber"
                label="PAN Number"
                asterisk="*"
                containerClass="form-group"
                type="text"
                name="panCardNumber"
                value={basicDetails.panCardNumber}
                disabled={pageType === "view"}
                placeholder="Enter pan number"
                errorMessage={validator.current.message(
                  "Pan Number",
                  basicDetails.panCardNumber,
                  "required|regex:^([A-Z]){5}([0-9]){4}([A-Z]){1}$",
                  {
                    messages: {
                      required: "The pan number field is required",
                      regex: "Invalid pan number",
                    },
                  }
                )}
                autoComplete="new-panCardNumber"
                maxlength={10}
                minlength={10}
              />
            </Col>

            <Col sm={6}>
              <TextInput
                onInputChange={(e: any) => {
                  const re = /^[0-9]*$/;
                  if (!e.target.value || re.test(e.target.value)) {
                    setBasicDetails({
                      ...basicDetails,
                      aadharCardNumber: e.target.value,
                    });
                  }
                }}
                id="AadharCardNumber"
                label="Aadhar Number"
                asterisk="*"
                containerClass="form-group"
                type="text"
                name="aadharCardNumber"
                value={basicDetails.aadharCardNumber}
                disabled={pageType === "view"}
                placeholder="Enter aadhar number"
                errorMessage={validator.current.message(
                  "Aadhar Card Number",
                  basicDetails.aadharCardNumber,
                  "required|regex:^([0-9]){12}$",
                  {
                    messages: {
                      required: "The aadhar number field is required",
                      regex: "Invalid aadhar number",
                    },
                  }
                )}
                autoComplete="new-aadharCardNumber"
                maxlength={12}
                minlength={12}
              />
            </Col>
            <Col sm={6}>
              <TextInput
                onInputChange={(
                  e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
                ) => {
                  setBasicDetails({ ...basicDetails, address: e.target.value });
                }}
                label="Address"
                asterisk="*"
                containerClass="form-group"
                type="text"
                as="textarea"
                name="address"
                value={basicDetails.address}
                disabled={pageType === "view"}
                placeholder="Enter address"
                errorMessage={validator.current.message(
                  "Address",
                  basicDetails.address,
                  "required"
                )}
                autoComplete="new-address"
                id="Address"
              />
            </Col>

            {!userId && (
              <Col sm={6}>
                <PasswordInput
                  onInputChange={(
                    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
                  ) => {
                    setBasicDetails({
                      ...basicDetails,
                      password: e.target.value,
                    });
                  }}
                  id="password"
                  name="password"
                  containerClass="form-group"
                  inputClass="form-control"
                  label="Password"
                  asterisk="*"
                  value={basicDetails.password}
                  type="password"
                  minlength="6"
                  placeholder="Please enter password"
                  errorMessage={validator.current.message(
                    "password",
                    basicDetails.password,
                    "required",
                    {
                      className: "text-danger",
                    }
                  )}
                  autoComplete="new-password"
                  switchElement="true"
                />
              </Col>
            )}
            <br />
          </Row>
          <Row>
            <h6 className="section-title">Joining Details</h6>
            <hr />
            <Col sm={6}>
              <Datepicker
                peekNextMonth={true}
                showMonthDropdown={true}
                showYearDropdown={true}
                dropdownMode="select"
                label="DOB"
                className="form-control"
                selected={joiningDetails.dateOfBirth}
                asterisk="*"
                disabled={pageType === "view"}
                placeholder="dd-mm-yyyy"
                dateFormat="dd-MM-yyyy"
                maxDate={new Date()}
                // maxDate={minDate.setFullYear(minDate.getFullYear() - 18)}
                onChange={(e: any) => {
                  setJoiningDetails({ ...joiningDetails, dateOfBirth: e });
                }}
                errorMessage={validator.current.message(
                  "Date Of Birth",
                  joiningDetails.dateOfBirth,
                  "required"
                )}
                id="DateOfBirth"
              />
            </Col>

            <Col sm={6}>
              <Datepicker
                peekNextMonth={true}
                showMonthDropdown={true}
                showYearDropdown={true}
                dropdownMode="select"
                label="Joining Date"
                className="form-control"
                selected={joiningDetails.joiningDate}
                asterisk="*"
                disabled={pageType === "view"}
                placeholder="dd-mm-yyyy"
                dateFormat="dd-MM-yyyy"
                // minDate={new Date()}
                onChange={(e: any) => {
                  setJoiningDetails({ ...joiningDetails, joiningDate: e });
                }}
                errorMessage={validator.current.message(
                  "Joining Date",
                  joiningDetails.joiningDate,
                  "required"
                )}
                id="JoiningDate"
              />
            </Col>
            <Col sm={6}>
              <DropdownInput
                id="Designation"
                label="Designation"
                asterisk="*"
                placeholder="Select designation"
                options={designationValues}
                defaultValue={joiningDetails.designation}
                disabled={pageType === "view"}
                onSelectHandler={(select: any) => {
                  setJoiningDetails({ ...joiningDetails, designation: select });
                }}
                errorMessage={validator.current.message(
                  "Designation",
                  joiningDetails.designation,
                  "required"
                )}
              />
            </Col>

            <Col sm={6}>
              <TextInput
                onInputChange={(
                  e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
                ) => {
                  setJoiningDetails({
                    ...joiningDetails,
                    employeeId: e.target.value,
                  });
                }}
                label="Employee Id"
                asterisk="*"
                containerClass="form-group"
                type="text"
                name="employeeId"
                value={joiningDetails.employeeId}
                disabled={pageType === "view"}
                placeholder="Enter employee id"
                errorMessage={validator.current.message(
                  "Employee Id",
                  joiningDetails.employeeId,
                  "required"
                )}
                autoComplete="new-employeeId"
                maxlength={8}
                id="EmployeeId"
              />
            </Col>
            <Col className="d-flex justify-content-end">
              <div className="form-check mt-2">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value={joiningDetails.isAdmin}
                  checked={joiningDetails.isAdmin}
                  disabled={pageType === "view"}
                  id="flexCheckDefault"
                  onChange={(e) =>
                    setJoiningDetails({
                      ...joiningDetails,
                      isAdmin: e.target.checked,
                    })
                  }
                />
                <div>
                  <label
                    className="form-check-label"
                    htmlFor="flexCheckDefault"
                  >
                    Admin Access
                  </label>
                </div>
              </div>
            </Col>
          </Row>

          <h6 className="section-title">Document Details</h6>
          <hr />
          <Row>
            <Col sm={9}>
              <div className="mb-4">
                <h6 style={{ fontSize: "smaller" }}>
                  <b>Note:</b>
                </h6>
                <h6 style={{ fontSize: "smaller" }}>
                  1. Maximum upload file size limit is 1 MB.
                </h6>

                <h6 style={{ fontSize: "smaller" }}>
                  2. File supported extension is jpg , png , svg , jpeg, pdf
                </h6>
              </div>
              {documentType &&
                documentType.length >= 0 &&
                documentType.map((item: any, index: any) => (
                  <div key={index}>
                    <Row className="mt-2">
                      <Col sm={4}>
                        <DropdownInput
                          label={index === 0 ? "Document Type" : ""}
                          asterisk="*"
                          placeholder="Select type"
                          options={item.documentsArr}
                          defaultValue={item.doc_id}
                          disabled={pageType === "view"}
                          onSelectHandler={(select: any) => {
                            if (select.value === "Add_more") {
                              setUpdateShow(true);
                              return;
                            }
                            let Arr = [...documentType];
                            Arr[index].doc_id = select;
                            setDocumentType([...Arr]);
                          }}
                          onMenuOpen={() => {
                            getDocumentData(index);
                          }}
                          errorMessage={validator.current.message(
                            `documenttype${index}`,
                            documentType[index].doc_id,
                            "required",
                            {
                              messages: {
                                required: "The document type field is required.",
                              },
                            }
                          )}
                          id={`documenttype${index}`}
                        />
                        {/* <Row>
                          <TextInput
                            onInputChange={(e: any) => {
                              documentType[index].description = e.target.value;
                              setDocumentType([...documentType]);
                            }}
                            label="Description"
                            containerClass="form-group"
                            type="text"
                            as="textarea"
                            name="description"
                            value={item.description}
                            placeholder="Enter description"
                            disabled={pageType == "view"}
                          />
                        </Row> */}
                      </Col>
                      <Col sm={4}>
                        <TextInput
                          onInputChange={(e: any) => {
                            let Arr = [...documentType];
                            Arr[index].files = e.target.files;
                            setDocumentType([...Arr]);
                            if (e.target.value == "") return;
                            if (
                              e.target.value !== "" &&
                              ["png", "jpeg", "jpg", "svg", "pdf"].includes(
                                typeof e.target.files[0].name === "string" &&
                                  e.target.files[0].name.split(".").pop()
                              )
                            ) {
                              if (e.target.files[0].size >= 1000000) {
                                setShowLoader(false);
                                swal("File size is too large", " ", "error");
                                Arr[index].files = "";
                                item.title = "";
                                setDocumentType([...Arr]);
                                return;
                              } else {
                                uploadDocument(index);
                              }
                            } else {
                              swal(
                                "File extension is not supported",
                                "",
                                "error"
                              );
                              return;
                            }
                          }}
                          label={index === 0 ? "Upload File" : ""}
                          placeholder="hidden"
                          title={item.title}
                          asterisk="*"
                          type="file"
                          name="file"
                          accept="image/png, image/jpeg, image/jpg, image/svg, application/pdf"
                          disabled={pageType === "view"}
                          style={{
                            opacity: 0,
                            overflow: "hidden",
                            color: "ffffff",
                          }}
                        />

                        <Button
                          variant="secondary"
                          style={{ marginTop: "-63px", width: "230px" }}
                        >
                          {item.title
                            ? item.title && item.title.length > 20
                              ? item.title.slice(0, 20) + "..."
                              : item.title
                            : "Choose File"}
                        </Button>
                        {
                          <p style={{ color: "red", marginTop: "-20px" }}>
                            {" "}
                            {validator.current.message(
                              `UploadFile${index}`,
                              documentType[index].files,
                              "required",
                              {
                                messages: {
                                  required: "The upload file field is required.",
                                },
                              }
                            )}
                          </p>
                        }
                        {item.files ? (
                          <div className={"justify-content-between mb-3"}>
                            {/* <div className={"mt-1 remove-upload-img"}> */}
                            <div className={""}>
                              {/* <img
                                    className="remove-img ml-5"
                                    src={"/images/cross-circle-.svg"}
                                    alt="close icon"
                                    onClick={() => {
                                      if(pageType === "view") return;
                                      let documentArr:any = [...documentType];
                                      documentArr[index].file_id = "";
                                      documentArr[index].files = "";
                                      documentArr[index].title = "";

                                      setDocumentType([...documentArr]);
                                    }}
                                  ></img> */}
                              <Image
                                className=""
                                width={50}
                                height={50}
                                title={" Click here to view document"}
                                src={
                                  ["png", "jpeg", "jpg", "svg"].includes(
                                    typeof item?.files === "string" &&
                                      item?.files.split(".").pop()
                                  )
                                    ? item?.files
                                    : "images/attachment.png"
                                }
                                alt="image"
                                style={{
                                  cursor: "pointer",
                                  marginTop: "-15px",
                                }}
                                onClick={() => {
                                  window.open(item.files);
                                }}
                              />
                            </div>
                          </div>
                        ) : null}
                      </Col>

                      <Col sm={4}>
                        <div className={index === 0 ? "form-group mt-4" : ""}>
                          {documentType.length > 1 && pageType !== "view" && (
                            // <button
                            //   style={{
                            //     height: "30px",
                            //     width: "30px",
                            //     color: "white",
                            //     backgroundColor: "#24959D",
                            //     border: "#24959D",
                            //   }}
                            //   onClick={(e) => {
                            //     getDocumentData(index);
                            //     deleteField(index);
                            //   }}
                            // >
                            //   <h3>-</h3>
                            // </button>

                            <a
                              href="javascript:void(0)"
                              title="Remove Document"
                              className="underline ml-3"
                              onClick={(e) => {
                                getDocumentData(index);
                                deleteField(index);
                              }}
                            >
                              Remove
                            </a>
                          )}

                          {documentType &&
                            documentType.length - 1 === index &&
                            documents.length - 1 !== documentType.length &&
                            pageType !== "view" && (
                              <>
                                {/* <button
                                  style={{
                                    height: "30px",
                                    width: "30px",
                                    color: "white",
                                    backgroundColor: "#24959D",
                                    border: "#24959D",
                                  }}
                                  className="ml-10"
                                  onClick={(e) => {
                                    getDocumentData(index);
                                    addField();
                                  }}
                                >
                                  <h3>+</h3>
                                </button> */}

                                <a
                              href="javascript:void(0)"
                              title="Add More Document"
                              className="underline ml-3"
                              onClick={(e) => {
                                getDocumentData(index);
                                addField();
                              }}
                            >
                              Add More
                            </a>
                              </>
                            )}
                        </div>
                      </Col>
                    </Row>
                  </div>
                ))}
            </Col>
            <Col sm={3}>
              <Card>
                <Card.Header>Document Checklist</Card.Header>
                <Card.Body>
                  {documents.map((item: any, index: any) => (
                    <tr>
                      {item.value !== "Add_more" ? (
                        <>
                          <Row>
                            <Col sm={2}>
                              <td>
                                <img
                                  style={{ height: "15px", width: "15px" }}
                                  src={checklistChangeImage(item)}
                                  alt=""
                                />
                              </td>
                            </Col>

                            <Col sm={9}>
                              <td className={"text-break"}>
                                <span
                                  // target="_blank"
                                  className={
                                    checklistChangeImage(item) ==
                                    "/images/complete-task-green.svg"
                                      ? "active"
                                      : ""
                                  }
                                  title={
                                    checklistChangeImage(item) ==
                                    "/images/complete-task-green.svg"
                                      ? " Click here to view document"
                                      : ""
                                  }
                                  onClick={(e: any) => {
                                    e.preventDefault();
                                    isChangeLabel(item);
                                  }}
                                >
                                  {item?.label}
                                </span>
                              </td>
                            </Col>
                          </Row>
                        </>
                      ) : null}
                    </tr>
                  ))}
                </Card.Body>
              </Card>
            </Col>
          </Row>

          {pageType !== "view" && (
            <Col>
              <div style={{ float: "left" }} className="form-group mt-2">
                <Button
                  id="submitBtn"
                  type="submit"
                  variant="primary"
                  onClick={(e) => {
                    e.preventDefault();
                    onSubmit(e);
                  }}
                  disabled={disableButton}
                >
                  Submit
                </Button>
                <Button
                  onClick={(e) => {
                    e.preventDefault();
                    navigate("/employee-list");
                  }}
                  variant="secondary"
                  className="ms-2"
                >
                  Cancel
                </Button>
              </div>
              <br />
            </Col>
          )}
        </Card.Body>
      </Card>

      {updateShow && (
        <AddDocument
          updateShow={updateShow}
          handleClose={() => setUpdateShow(!updateShow)}
          data={documents}
          callback={() => getDocumentType()}
        />
      )}
    </>
  );
};

export default AddEmployee;
