import React from 'react';
import Spinner from "react-bootstrap/Spinner";

function Loader(props:any) {
  const { showLoader, isLoaderText } = props;

  return showLoader || isLoaderText ? (
    // <div className="text-center preloader ">
    //   <Spinner animation="border" className="center" />
    // </div>


    isLoaderText ? (
      <div
        className="text-center preloader"
        style={{
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <Spinner
          as="span"
          animation="border"
          size="sm"
          role="status"
          aria-hidden="true"
          className="center"
        />
        <p
          className="mt-2"
          style={{
            position: "fixed",
            top: "50%",
            left: "48%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <h5 style={{ color: "#3A4C58" }}>
            {" "}
            {"Your request is send and Report is preparing..."}{" "}
          </h5>
        </p>
      </div>
    ) : (
      <div className="text-center preloader">
        <Spinner animation="border" className="center" />
      </div>
    )
  ) : null;
}

export default Loader;