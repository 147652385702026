import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";
import {
  Card,
  Form,
  Col,
  Row,
  Button,
  Accordion,
  Image,
} from "react-bootstrap";
import customFunctions from "../../helper/customFunctions";
import AuthApi from "../../helper/authApi";
import Api from "../../helper/api";
import Breadcrumb from "../../components/Breadcrumb/breadcrumb.component";
import Loader from "../../components/Loader/index";
import { Helmet, DropdownInput } from "../../components/FormInputs";
import Table from "../../components/Table/simpletable.component";
import SweetAlert from "react-bootstrap-sweetalert";

const SuperAdminTaskList = () => {
  const navigate = useNavigate();
  const [isShowLoader, setShowLoader] = useState<any>(false);
  const [rows, setRows] = useState<any>([]);
  const [title, setTitle] = useState<any>("Tasks");
  const [count, forceUpdate] = useState<any>(0);
  const [filterErr, setFilterErr] = useState<any>("");
  const [searchKeyword, setSearchKeyword] = useState<any>("");
  const [selectedStatus, setSelectedStatus] = useState<any>("");
  const [applyToggle, setApplyToggle] = useState<any>(false);
  const [deleteToggle, setDeleteToggle] = useState<any>(false);
  const [roleId, setRoleId] = useState<number>(0);
  const [currentUserId, setCurrentUserId] = useState<number>(0);
  const [taskData, setTaskData] = useState<any>([]);
  const [taskId, setTaskId] = useState<any>("");
  const [serviceArray, setServiceArray] = useState<any>();
  // const [Due_date,setDueDate] = useState<any>(new Date())

  const items = [{ to: "/", label: "Dashboard" }, { label: "Tasks" }];
  const initialPagination = {
    currentPage: 1,
    itemsPerPage: 10,
    totalItems: 1,
  };
  const [pagination, setPagination] = useState(initialPagination);

  const columns: any = [
    {
      label: "Sr. No.",
      id: "index",
    },
    {
      label: "Task Name",
      id: "task_name",
      // roleAccess:[2]
    },
    {
      label: "Service Name",
      id: "services_name",
    },
    {
      label: "Action",
      id: "action",
    },
  ];

  useEffect(() => {
    getUserRole();
    serviceList();
  }, []);

  const getUserRole = () => {
    const role = customFunctions.getLocalStorage("role_id");
    setRoleId(Number(role));
    const currentUserId = customFunctions.getLocalStorage("id");
    setCurrentUserId(Number(currentUserId));
  };

  useEffect(() => {
    getTaskListData();
  }, [currentUserId]);

  const serviceList = async () => {
    setShowLoader(true);

    const params = {
      limit: 100,
      sort_by: "name",
    };

    const url = customFunctions.generateUrl(`${Api.servicesUrl}`, params);

    const { data, message } = await AuthApi.getDataFromServer(url);

    if (data && data.success === true) {
      setShowLoader(false);
      let serviceData: any = [];
      data &&
        data.data.length > 0 &&
        data.data.map((item: any) => {
          serviceData.push({
            label: item.services,
            value: item.id,
          });
        });
      setServiceArray(serviceData);
    } else {
      setShowLoader(false);
      swal(typeof message === "string" ? message : message[0], "", "error");
    }
  };

  const getTaskListData = async (pageNo?: any, clearFilter = false) => {
    if (!currentUserId || (!applyToggle && clearFilter && !searchKeyword))
      return;
    const page = pageNo || 1;
    setShowLoader(true);
    setApplyToggle(false);
    const endPoint = Api.taskUrl;
    const params = {
      page: page,
      limit: pagination.itemsPerPage,
      sort_direction: "descending",
      keyword: !clearFilter && searchKeyword ? searchKeyword.label : "",
      status: !clearFilter && selectedStatus ? selectedStatus.value : "",
    };

    const url = customFunctions.generateUrl(endPoint, params);

    const { data, message } = await AuthApi.getDataFromServer(url);

    if (data && data.success === true) {
      setShowLoader(false);
      if (data && data.data && data.data.length > 0) {
        setShowLoader(false);
        setPagination({
          currentPage: data.paginator.current_page,
          itemsPerPage: Number(10),
          totalItems: data.paginator.total_count,
        });
        setTaskData(data.data);
        forceUpdate(count + 1);
      } else {
        setTaskData([]);
      }
    } else {
      setShowLoader(false);
      setTaskData([]);
      swal(message, "", "error");
    }
  };

  useEffect(() => {
    setTableData();
  }, [taskData]);

  const setTableData = () => {
    const rowsData: any = [];

    taskData.map((dt: any, i: any) => {
      rowsData.push({
        id: dt.id,
        index: (pagination.currentPage - 1) * pagination.itemsPerPage + (i + 1),
        services_name: <>{dt && dt?.services.services_name}</>,
        task_name: <>{dt && dt?.task_name}</>,
        client: dt.Client_name,
        file: dt.File,
        note: dt.Note,
        action: (
          <>
            <a
              href="/"
              className="pl-2 ms-2 underline"
              title={"Edit Task"}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                navigate("/edit-task", {
                  state: {
                    data: dt,
                    pageType: "edit",
                    // clientId: dt?.id,
                  },
                });
              }}
            >
              {/* <Image
                src="images/edit-button.svg"
                alt="Edit task"
                height={"12px"}
              /> */}
              Edit
            </a>
            <br />
            <a
              href="/"
              className="pl-2 ms-2 underline"
              title={"View Task"}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                navigate("/edit-task", {
                  state: {
                    data: dt,
                    pageType: "view",
                    // clientId: dt?.id,
                  },
                });
              }}
            >
              {/* <Image src="images/eye.svg" alt="view" height={"12px"} /> */}
              View
            </a>
            &nbsp;
            {/* <a
              href="/"
              className="pl-2 ms-1"
              title={"Delete Task"}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                
                setTaskId(dt?.id);
                setDeleteToggle(true);
              }}
            >
              <Image src="images/delete.png" alt="view" />
            </a> */}
            {/* <i
              title="Delete Task"
              className="fa-regular fa-trash-can fa-xl ml-2"
              style={{ color: "#e65b5b", cursor: "pointer" }}
              onClick={() => {
                setTaskId(dt?.id);
                setDeleteToggle(true);
              }}
            ></i> */}
          </>
        ),
      });
      return dt;
    });
    setRows([...rowsData]);
  };

  const filterSearch = (e: any) => {
    e.preventDefault();
    let filterSearchErr = "";
    let isValid = true;
    if (customFunctions.checkIfEmpty(searchKeyword)) {
      filterSearchErr = "Please select service name ";
      setFilterErr(filterSearchErr);
      isValid = false;
    }
    if (isValid) {
      setFilterErr("");
      getTaskListData(1);
    }
  };

  const onClear = (e: any) => {
    setFilterErr("");
    setSearchKeyword("");
    setSelectedStatus("");
    getTaskListData(1, true);
    setApplyToggle(false);
  };

  const deleteTask = async () => {
    const postData: any = {
      id: Number(taskId),
    };

    if (!taskId) return;
    setShowLoader(true);

    const callback: any = AuthApi.deleteDataFromServer;
    let url = `${Api.taskUrl}${taskId}/`;
    const { data, message } = await callback(url);

    if (data && data.success == true) {
      setShowLoader(false);
      setDeleteToggle(false);
      swal("Task deleted successfully!", "", "success", {
        closeOnClickOutside: false,
      }).then(() => {
        setTaskId(null);
        getTaskListData(1, true);
      });
    } else {
      setShowLoader(false);
      swal(message || "Something went wrong", "", "error").then(() => {
        setTaskId(null);
        getTaskListData(1, true);
      });
    }
  };

  return (
    <>
      <Helmet title={title} />
      <div className="page-header">
        <div className="title-breadcrumb-section">
          <h2 className="main-content-title tx-24 mg-b-5">{title}</h2>
          {/* <Breadcrumb items={items} /> */}
        </div>
      </div>
      <Loader showLoader={isShowLoader} needFullPage={false} />
      {deleteToggle && (
        <div className="sweetalert">
          <SweetAlert
            title={"Are you sure? do you want to delete the task?"}
            warning
            showCancel
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            onConfirm={() => {
              setDeleteToggle(false);
              deleteTask();
            }}
            onCancel={() => {
              setDeleteToggle(false);
            }}
            reverseButtons={false}
          />
        </div>
      )}
      <section className="content-area">
        <Accordion defaultActiveKey="1">
          <Accordion.Item eventKey="1">
            <Accordion.Header>
              <h5 className="card-title mb-0">Filters</h5>
            </Accordion.Header>
            <Accordion.Body>
              <Form onSubmit={(e) => filterSearch(e)}>
                {filterErr && (
                  <div className="p-3  bg-danger text-white bg-danger-main table-red-bg mb-3">
                    <div className="img-text">
                      <img
                        style={{ cursor: "pointer" }}
                        onClick={(e) => onClear(e)}
                        src="/images/fail.svg"
                        alt="Success"
                      />{" "}
                      {filterErr}
                    </div>
                  </div>
                )}
                <Row className="mt-3">
                  {/* <Col sm={3}>
                    <TextInput
                      value={searchKeyword}
                      placeholder="Search by service name"
                      onInputChange={(e: any) => {
                        setSearchKeyword(e.target.value);
                      }}
                    />
                  </Col> */}

                  <Col sm={3}>
                    <DropdownInput
                      placeholder="Select service name"
                      options={serviceArray}
                      defaultValue={searchKeyword}
                      onSelectHandler={(select: any) => {
                        setSearchKeyword(select);
                      }}
                    />
                  </Col>

                  <Col sm={3}>
                    <div className="filter-btn pull-right filter-btn-report">
                      <Button
                        variant="primary"
                        type="submit"
                        className="ms-4"
                        onClick={() => setApplyToggle(true)}
                      >
                        Apply
                      </Button>
                      <Button
                        variant="secondary"
                        type="button"
                        className="ms-4"
                        onClick={(e) => onClear(e)}
                      >
                        Clear
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Form>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        <Card className="mt-3">
          <Card.Header>
            <div className="d-flex align-items-center justify-content-end">
              <a
                href="#"
                className="underline"
                onClick={(e) => {
                  e.preventDefault();
                  navigate("/create-task", {
                    state: { pageType: "add" },
                  });
                }}
              >
                Create Task
              </a>
            </div>
          </Card.Header>
          <Card.Body>
            <Table
              columns={columns}
              showCheckbox={false}
              rows={rows}
              showPagination
              role={roleId}
              paginationProps={{
                ...pagination,
                currentPage: Number(pagination.currentPage),
                onPageChange: (pageNo: any) => {
                  getTaskListData(pageNo);
                },
              }}
              dropdownContainerClass="mb-3"
              emptyRender={() => (
                <p className="text-center mb-0">No record found</p>
              )}
            />
          </Card.Body>
        </Card>
      </section>
    </>
  );
};

export default SuperAdminTaskList;
