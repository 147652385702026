import { useRef, useState } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import SelectAsyncPaginate from "../../components/ReactSelectPagination/SelectAsyncPaginate";
import AuthApi from "../../helper/authApi";
import Api from "../../helper/api";
import SimpleReactValidator from "simple-react-validator";
import Loader from "../../components/Loader";
import customFunctions from "../../helper/customFunctions";
import swal from "sweetalert";
import { useNavigate, useLocation } from "react-router-dom";

export const DisplayModel = ({
  employee,
  popupClose,
  employeeLitigation,
  callBack,
}: any) => {
  const validator = useRef(new SimpleReactValidator());
  const navigate: any = useNavigate();

  const [selectedEmployees, setSelectedEmployees] = useState<any>("");
  const [count, forceUpdate] = useState<any>(0);
  const [isShowLoader, setShowLoader] = useState<boolean>(false);

  const assignEmployee = async () => {
    // setShowLoader(true);
    const postData: any = {
      client: employeeLitigation?.client_id
        ? employeeLitigation?.client_id
        : null,
      services: employeeLitigation?.service
        ? employeeLitigation?.service
        : null,
      title: employeeLitigation?.title ? employeeLitigation?.title : null,
      date_of_receipt: employeeLitigation?.date_of_receipt
        ? customFunctions.getDate(new Date(employeeLitigation?.date_of_receipt))
        : null,
      due_date_of_filing: employeeLitigation?.due_date_of_filing
        ? customFunctions.getDate(
            new Date(employeeLitigation?.due_date_of_filing)
          )
        : null,
      date_of_filing: employeeLitigation?.date_of_filing
        ? customFunctions.getDate(new Date(employeeLitigation?.date_of_filing))
        : null,
      hearing_date: employeeLitigation?.hearing_date
        ? customFunctions.getDate(new Date(employeeLitigation?.hearing_date))
        : null,
      order_date: employeeLitigation?.order_date
        ? customFunctions.getDate(new Date(employeeLitigation?.order_date))
        : null,
      notice_details: employeeLitigation?.notice_details
        ? employeeLitigation?.notice_details
        : null,
      status: 1,
      employee: selectedEmployees?.length
        ? selectedEmployees?.map((element: any) => element?.id)
        : null,
    };
    const payload = customFunctions.cleanObject(postData);
    const endPoint = `${Api.litigationUrl}${employeeLitigation?.id}/`;

    const callback = AuthApi.putDataToServer;
    const { data, message } = await callback(endPoint, postData);
    if (data && data.success === true) {
      setShowLoader(false);
      popupClose();
      swal("Employee assigned successfully!", "", "success").then(() => {
        callBack();
        navigate("/litigation-list");
      });
    } else {
      setShowLoader(false);
      swal(typeof message == "string" ? message : message[0], "", "error");
    }
  };

  const onsubmit = () => {
    if (validator.current.allValid()) {
      assignEmployee();
    } else {
      validator.current.showMessages();
      forceUpdate(count + 1);
    }
  };

  return (
    <>
      <Loader showLoader={isShowLoader} needFullPage={false} />
      <Modal
        className={""}
        // size="sm"
        centered
        onHide={() => popupClose()}
        show={true}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <h5>{employee?.length ? "Assigned Employees" : "Assign Employees"}</h5>
        </Modal.Header>
        <Modal.Body>
          {employee?.length ? (
            <ul>
              {employee?.length
                ? employee?.map((item: any) => {
                    return <li>{item}</li>;
                  })
                : ""}
            </ul>
          ) : (
            <>
              <Row>
                <Col sm={9}>
                  <SelectAsyncPaginate
                    selectPlaceholder={"Select employee"}
                    value={selectedEmployees}
                    onSelectChange={(select: any) => {
                      setSelectedEmployees(select);
                    }}
                    apiname={"employeeUrl"}
                    endpoint={Api.employeeUrl}
                    isClearable={true}
                    isMulti={true}
                    fromComponent={"litigation"}
                    errorMessage={validator.current.message(
                      "employee",
                      selectedEmployees,
                      "required"
                    )}
                  />
                </Col>
              </Row>
              <Row className="mt-3">
                <Col>
                  <Button
                    className="mr-3"
                    onClick={() => {
                      onsubmit();
                    }}
                  >
                    Submit
                  </Button>
                  <Button
                    variant="secondary"
                    onClick={() => {
                      popupClose();
                    }}
                  >
                    Cancel
                  </Button>
                </Col>
              </Row>
            </>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};
