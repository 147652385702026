export const Status = [
  { value: "Not started", label: "Not started" },
  { value: "Work in progress", label: "Work in progress" },
  { value: "Complete", label: "Complete" },
];

export const employee = [
  { value: "Akshay", label: "Akshay" },
  { value: "Nilesh", label: "Nilesh" },
  { value: "Omkar", label: "Omkar" },
  { value: "Laxi", label: "Laxi" },
  { value: "Santosh", label: "Santosh" },
];
