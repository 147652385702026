import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import SimpleReactValidator from "simple-react-validator";
import {
  TextInput,
  Helmet,
  DropdownInput,
  Datepicker,
} from "../../components/FormInputs";
import Breadcrumb from "../../components/Breadcrumb/breadcrumb.component";
import Loader from "../../components/Loader/index";
import { Button, Card, Col, Row, Image } from "react-bootstrap";
import swal from "sweetalert";
import AuthApi from "../../helper/authApi";
import Api from "../../helper/api";
import customFunctions from "../../helper/customFunctions";

interface profile {
  id?: any;
  address?: any;
  statee?: any;
  city?: any;
  zipcode?: string;
  contact_number?: string;
  website?: string;
  aboutCompany?: string;
  services?: any;
}
const CompanyProfile = () => {
  const file: any = useRef();

  const [firstName, setFirstName] = useState<any>();
  const [email, setEmail] = useState<any>();
  const [lastName, setLastName] = useState<any>();
  const [address, setAddress] = useState<string>();
  const [addressId, setAddressId] = useState<any>("");
  const [zipcode, setZipcode] = useState<string>();
  const [contactNumber, setContactNumber] = useState<any>();
  const [website, setWebsite] = useState<string>("");
  const [aboutCompany, setAboutCompany] = useState<string>("");
  const [companyName, setcompanyName] = useState<any>();
  const [companyPanNumber, setCompanyPanNumber] = useState<any>();
  const [companyId, setCompanyId] = useState<any>();
  const [cinNumber, setCinNumber] = useState<any>();
  const [gstNumber, setGstNumber] = useState<any>();
  const [panNumber, setPanNumber] = useState<any>();
  const [newDocument, setNewDocument] = useState<any>([]);
  const [incorporationDate, setIncorporationDate] = useState<any>("");
  const employeeName = localStorage.getItem("userName");
  const [count, forceUpdate] = useState(0);
  const [fileId, setFileId] = useState<any>();
  const [newDocumentTitle, setNewDocumentTitle] = useState<any>("");
  const navigate = useNavigate();
  const [isShowLoader, setShowLoader] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const [imageFile, setImageFile] = useState(null);
  const [imageUrl, setImageUrl] = useState("");
  const [fileName, setFileName] = useState("");
  const [profiletToggle, setProfiletToggle] = useState(false);
  const [sacNumber, setSacNumber] = useState("");
  const [companyLogo, setCompanyLogo] = useState<any>({
    url: "",
    file: "",
    title: "",
  });
  const [city, setCity] = useState<any>("");
  const [state, setState] = useState<any>("");
  const [pincode, setPincode] = useState<any>("");
  const [stateArr, setStateArr] = useState<any>([]);
  const [cityArr, setCityArr] = useState<any>([]);
  const validator = useRef(new SimpleReactValidator());
  const title = "Company Profile";
  const items = [
    { to: "/", label: "Dashboard" },
    { label: " Company Profile" },
  ];

  useEffect(() => {
    getCompanyProfile();
  }, []);

  const getCompanyProfile = async () => {
    setShowLoader(true);
    const { data, message } = await AuthApi.getDataFromServer(
      `${Api.profileUrl}`
    );
    if (data && data.success === true) {
      setShowLoader(false);
      if (data && data.data) {
        let { company, employee, user, company_logo } = data.data;
        employee &&
          employee.length > 0 &&
          employee.map((item: any) => {
            setFirstName(user.first_name);
            setLastName(user.last_name);
            setContactNumber(user.mobile);
            setPanNumber(item.pan_number);
            setEmail(user.email);

            setImageUrl(user?.file?.file_name);
            setFileId(user?.file?.id);
          });
        company &&
          company.length > 0 &&
          company.map((company: any) => {
            setCompanyId(company.company);
            setcompanyName(company.firm_name);
            setCinNumber(company.cin_no);
            setGstNumber(company?.gst_no);
            setCompanyPanNumber(company.pan_number);
            setWebsite(company.website_url);
            if (company.incorporation_date) {
              setIncorporationDate(new Date(company.incorporation_date));
            }
            setAboutCompany(company.description);
            // setSacNumber(company?.sac_code?company?.sac_code : "");
            setAddressId(company?.address?.address);
            setAddress(company?.address?.area);
            setPincode(
              company?.address?.zipcode ? company?.address?.zipcode : ""
            );
            setState(
              company?.address?.state_id
                ? {
                    label: company?.address?.state_name,
                    value: company?.address?.state_id,
                  }
                : ""
            );
            setCity(
              company?.address?.city_id
                ? {
                    label: company?.address?.city_name,
                    value: company?.address?.city_id,
                  }
                : ""
            );

            setAboutCompany(
              company?.invoice_terms_conditions
                ? company?.invoice_terms_conditions
                : ""
            );
          });

        setCompanyLogo({
          ...company_logo[0],
          url: company_logo[0]?.file_name,
          file: "",
          title: company_logo[0]?.f_name,
        });

        localStorage.setItem(
          "sac_code",
          company?.sac_code ? company?.sac_code : ""
        );
      }
    } else {
      setShowLoader(false);
    }
  };

  useEffect(() => {
    getState();
  }, []);

  useEffect(() => {
    if (state && state?.value) getCity();
  }, [state]);

  const getState = async () => {
    setShowLoader(true);
    const endPoint: any = Api.stateUrl;
    const params: any = {
      limit: 50,
      sort_by:"name"
    };

    const url: any = customFunctions.generateUrl(endPoint, params);
    const { data, message } = await AuthApi.getDataFromServer(url);

    if (data && data.success === true) {
      setShowLoader(false);
      if (data && data?.data?.length) {
        const stateData: any = [];
        data?.data?.map((item: any) => {
          stateData.push({
            label: item?.name,
            value: item?.id,
          });
        });
        setStateArr(stateData);
      }
    } else {
      setShowLoader(false);
      swal(typeof message == "string" ? message : message[0], "", "error");
    }
  };

  const getCity = async () => {
    setShowLoader(true);
    const endPoint: any = Api.cityUrl;
    const params: any = {
      limit: 800,
      state_id: state?.value,
      sort_by:"name"
    };

    const url: any = customFunctions.generateUrl(endPoint, params);
    const { data, message } = await AuthApi.getDataFromServer(url);

    if (data && data.success === true) {
      setShowLoader(false);
      if (data && data?.data?.length) {
        const cityData: any = [];
        data?.data?.map((item: any) => {
          cityData.push({
            label: item?.name,
            value: item?.id,
          });
        });
        setCityArr(cityData);
      }
    } else {
      setShowLoader(false);
      swal(typeof message == "string" ? message : message[0], "", "error");
    }
  };

  const uploadDocument = async (file: any) => {
    setShowLoader(true);
    const formData = new FormData();
    formData.append("file_name", file[0]);

    const { data, message } = await AuthApi.postDataToServer(
      Api.documentUploadUrl,
      formData
    );

    if (data && data.success === true) {
      setShowLoader(false);
      setFileId(data?.data?.id);
      setNewDocumentTitle(data?.data?.f_name);
      setImageUrl(data?.data?.file_name);
      swal("File uploaded successfully!", "", "success");
    } else {
      setShowLoader(false);
      swal(message, "", "error");
    }
  };

  const uploadCompanyLogo = async (file: any) => {
    setShowLoader(true);
    const formData = new FormData();
    formData.append("file_name", file[0]);

    const { data, message } = await AuthApi.postDataToServer(
      Api.documentUploadUrl,
      formData
    );

    if (data && data.success === true) {
      setShowLoader(false);
      setCompanyLogo({
        ...data.data,
        url: data?.data?.file_name,
        file: "",
        title: data?.data?.f_name,
      });
      swal("File uploaded successfully!", "", "success");
    } else {
      setShowLoader(false);
      swal(message, "", "error");
    }
  };

  const updateProfile = async (file_id?: any) => {
    setShowLoader(true);
    const postData: any = {
      first_name: firstName,
      last_name: lastName,
      mobile: contactNumber,
      profile_image: file_id ? file_id : fileId,
      address: {
        address_id: addressId,
        area: address,
        zipcode: pincode,
        state: state?.value,
        city: city?.value,
      },
      company: {
        company_id: companyId,
        firm_name: companyName,
        company_logo: companyLogo && companyLogo?.id ? companyLogo?.id : null,
        gst_no: gstNumber,
        pan_number: companyPanNumber,
        cin_no: cinNumber,
        website_url: website,
        // description: aboutCompany,
        incorporation_date: incorporationDate
          ? customFunctions.getDate(incorporationDate)
          : null,
        // sac_code: sacNumber ? sacNumber : null,
        invoice_terms_conditions: aboutCompany ? aboutCompany : null,
      },
    };
    const payload = customFunctions.cleanObject(postData);
    const endPoint = `${Api.profileUrl}`;
    const callback: any = AuthApi.putDataToServer;

    const { data, message } = await callback(endPoint, payload);

    if (data && data.success === true) {
      setShowLoader(false);
      setDisableButton(false);
      if (data.data?.profile_image_file_name) {
        customFunctions.setLocalStorage(
          "profileImage",
          data.data?.profile_image_file_name
        );
      }
      customFunctions.setLocalStorage(
        "userName",
        `${
          data.data.first_name
            ? customFunctions.capitalizeFirstLetter(data.data.first_name)
            : ""
        } ${
          data.data.last_name
            ? customFunctions.capitalizeFirstLetter(data.data.last_name)
            : ""
        }`
      );
      customFunctions.setLocalStorage("sac_code", sacNumber);
      swal("Profile Updated successfully!", "", "success").then(() => {
        navigate(-1);
      });
    } else {
      setDisableButton(false);
      setShowLoader(false);
      swal(typeof message == "string" ? message : message[0], "", "error");
    }
  };

  const onSubmit = (e: any) => {
    e.preventDefault();
    if (validator.current.allValid()) {
      updateProfile();
    } else {
      validator.current.showMessages();
      forceUpdate(count + 1);
      setDisableButton(false);
    }
  };

  return (
    <>
      <Helmet title={title} />
      <div className="page-header">
        <div className="title-breadcrumb-section">
          <h2 className="main-content-title tx-24 mg-b-5">{title}</h2>
          <Breadcrumb items={items} />
        </div>
      </div>
      <Loader showLoader={isShowLoader} needFullPage={false} />

      <section className="content-area">
        <Card>
          <Card.Body>
            <div className="d-flex justify-content-between mb-3">
              <div>
                <h5>Contact Information </h5>
              </div>
              <div className="float-end">
                {!profiletToggle && (
                  <Button
                    onClick={() => {
                      setProfiletToggle(true);
                    }}
                  >
                    Edit Profile
                  </Button>
                )}
              </div>
            </div>
            <Row>
              <Col sm={2}>
                <div className="text-center m-b-10">
                  <div className="position-relative">
                    <input
                      onChange={(e: any) => {
                        e.preventDefault();
                        if (e.target.value == "") return;
                        if (
                          e.target.value !== "" &&
                          ["png", "jpeg", "jpg"].includes(
                            typeof e.target.files[0].name === "string" &&
                              e.target.files[0].name.split(".").pop()
                          )
                        ) {
                          if (e.target.files[0].size >= 3000000) {
                            setShowLoader(false);
                            swal("File size is too large", " ", "error");
                            return;
                          } else {
                            setNewDocument(e.target.files);
                            uploadDocument(e.target.files);
                          }
                        } else {
                          swal("File extension is not supported", "", "error");
                          return;
                        }
                      }}
                      title={
                        profiletToggle
                          ? `Edit Profile Picture`
                          : newDocumentTitle
                      }
                      className="profile-file"
                      type="file"
                      accept="image/png, image/jpeg,image/jpg, "
                      disabled={!profiletToggle}
                    />
                    {/* <img
                      className={"static-profilepic"}
                      // className={
                      //   "progilepic"
                      // }
                      src={imageUrl ? imageUrl : "/images/user.svg"}
                      alt=""
                      width={90}
                      height={90}
                    /> */}
                    <Image
                      width={100}
                      height={100}
                      src={imageUrl ? imageUrl : "/images/user.svg"}
                      roundedCircle
                    />
                    {profiletToggle && (
                      <span className={"profile-pen"} style={{ left: "80%" }}>
                        <img
                          className={"static-profilepic"}
                          src="images/edit-pen.svg"
                          height={"15px"}
                          width={"14px"}
                        />
                      </span>
                    )}
                  </div>
                </div>
              </Col>
              <Col sm={10}>
                <Row>
                  <Col sm={6}>
                    <TextInput
                      onInputChange={(
                        e: React.ChangeEvent<
                          HTMLTextAreaElement | HTMLInputElement
                        >
                      ) => {
                        const re = /^[a-zA-Z ]*$/;
                        if (!e.target.value || re.test(e.target.value)) {
                          setFirstName(e.target.value);
                        }
                      }}
                      disabled={!profiletToggle}
                      label="First Name"
                      asterisk="*"
                      containerClass="form-group"
                      type="text"
                      name="firstName"
                      value={firstName}
                      placeholder="Enter first name"
                      errorMessage={validator.current.message(
                        "firstName",
                        firstName,
                        "required"
                      )}
                      autocomplete="new-firstName"
                    />
                  </Col>
                  <Col sm={6}>
                    <TextInput
                      onInputChange={(e: any) => {
                        const re = /^[a-zA-Z ]*$/;
                        if (!e.target.value || re.test(e.target.value)) {
                          setLastName(e.target.value);
                        }
                      }}
                      disabled={!profiletToggle}
                      label="Last Name"
                      asterisk="*"
                      containerClass="form-group"
                      type="text"
                      name="lastname"
                      value={lastName}
                      placeholder="Enter last name"
                      errorMessage={validator.current.message(
                        "lastName",
                        lastName,
                        "required"
                      )}
                      autocomplete="new-lastName"
                    />
                  </Col>
                  <Col sm={6}>
                    <TextInput
                      onInputChange={(
                        e: React.ChangeEvent<
                          HTMLTextAreaElement | HTMLInputElement
                        >
                      ) => {
                        const re = /^[a-zA-Z ]*$/;
                        if (!e.target.value || re.test(e.target.value)) {
                          setEmail(e.target.value);
                        }
                      }}
                      disabled
                      label="Email"
                      asterisk="*"
                      containerClass="form-group"
                      type="text"
                      name="email"
                      value={email}
                      placeholder="Enter email"
                      errorMessage={validator.current.message(
                        "email",
                        email,
                        "required"
                      )}
                      autocomplete="new-firstName"
                    />
                  </Col>
                  <Col sm={6}>
                    <TextInput
                      onInputChange={(
                        e: React.ChangeEvent<
                          HTMLTextAreaElement | HTMLInputElement
                        >
                      ) => {
                        const re = /^[0-9]*$/;
                        if (!e.target.value || re.test(e.target.value)) {
                          setContactNumber(e.target.value);
                        }
                      }}
                      disabled={!profiletToggle}
                      label="Contact Number"
                      asterisk="*"
                      containerClass="form-group"
                      type="text"
                      value={contactNumber}
                      minlength={10}
                      maxlength={10}
                      name="ContactNumber"
                      placeholder="Enter contact number"
                      errorMessage={validator.current.message(
                        "Contact Number",
                        contactNumber,
                        "required|regex:^([6-9]){1}([0-9]){9}$",
                        {
                          messages: {
                            required: "The contact number field is required",
                            regex: "Invalid contact number ",
                          },
                        }
                      )}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>

            <hr />
            <h5 className="">Company Profile</h5>
            <Row className="mt-4">
              <Col sm={4} className="">
                <TextInput
                  onInputChange={(e: any) => {
                    const re = /^[a-zA-Z.& ]*$/;
                    if (!e.target.value || re.test(e.target.value)) {
                      setcompanyName(e.target.value);
                    }
                  }}
                  disabled={!profiletToggle}
                  label="Company Name"
                  asterisk="*"
                  containerClass="form-group"
                  type="text"
                  name="firstName"
                  value={companyName}
                  placeholder="Enter company name"
                  errorMessage={validator.current.message(
                    "companyName",
                    companyName,
                    "required"
                  )}
                  autocomplete="new-gstNumber"
                />
              </Col>

              <Col sm={4} className="">
                <TextInput
                  onInputChange={(
                    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
                  ) => {
                    const re = /^[a-zA-Z0-9 ]*$/;
                    if (!e.target.value || re.test(e.target.value)) {
                      setGstNumber(e.target.value.toUpperCase());
                    }
                  }}
                  label="GST Number"
                  asterisk="*"
                  maxlength={15}
                  disabled={!profiletToggle}
                  containerClass="form-group"
                  type="text"
                  name="lastName"
                  value={gstNumber}
                  placeholder="Enter gst number"
                  errorMessage={validator.current.message(
                    "gst Number",
                    gstNumber,
                    "required"
                  )}
                  autocomplete="new-cinNumber"
                />
              </Col>
              <Col sm={4} className="">
                <TextInput
                  onInputChange={(e: any) => {
                    const re = /^[a-zA-Z0-9]*$/;
                    if (!e.target.value || re.test(e.target.value)) {
                      setCompanyPanNumber(e.target.value.toUpperCase());
                    }
                  }}
                  disabled={!profiletToggle}
                  label="PAN Number"
                  asterisk="*"
                  containerClass="form-group"
                  type="text"
                  maxlength={10}
                  name="panNumber"
                  value={companyPanNumber}
                  placeholder="Enter pan number"
                  errorMessage={validator.current.message(
                    "pan Number",
                    companyPanNumber,
                    "required|regex:^([A-Z]){5}([0-9]){4}([A-Z]){1}$"
                  )}
                  autocomplete="new-panNumber"
                />
              </Col>
              <Col sm={4}>
                <TextInput
                  onInputChange={(
                    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
                  ) => {
                    const re = /^[a-zA-Z ]*$/;
                    setAddress(e.target.value);
                  }}
                  disabled={!profiletToggle}
                  label="Address"
                  containerClass="form-group"
                  asterisk="*"
                  as="textarea"
                  type="text"
                  name="firstName"
                  maxlength={255}
                  value={address}
                  placeholder="Enter address"
                  errorMessage={validator.current.message(
                    "address",
                    address,
                    "required"
                  )}
                  autocomplete="new-firstName"
                />
              </Col>
              <Col sm={4}>
                <DropdownInput
                  label="State"
                  asterisk="*"
                  placeholder="Select state"
                  defaultValue={state}
                  onSelectHandler={(select: any) => {
                    setState(select);
                    setCity("");
                    setPincode("");
                    setCityArr([]);
                  }}
                  options={stateArr}
                  disabled={!profiletToggle}
                  errorMessage={validator.current.message(
                    "state",
                    state,
                    "required"
                  )}
                />
              </Col>
              <Col sm={4}>
                <DropdownInput
                  placeholder="Select city"
                  label="City"
                  asterisk="*"
                  defaultValue={city}
                  onSelectHandler={(select: any) => {
                    setCity(select);
                    setPincode("");
                  }}
                  options={cityArr}
                  disabled={!profiletToggle || !state}
                  errorMessage={validator.current.message(
                    "city",
                    city,
                    "required"
                  )}
                />
              </Col>
              <Col sm={4}>
                <TextInput
                  onInputChange={(e: any) => {
                    const re = /^[0-9]*$/;
                    if (!e.target.value || re.test(e.target.value)) {
                      setPincode(e.target.value);
                    }
                  }}
                  disabled={!profiletToggle}
                  asterisk="*"
                  maxlength={6}
                  label="Pincode"
                  containerClass="form-group"
                  type="text"
                  value={pincode}
                  placeholder="Enter pincode"
                  errorMessage={validator.current.message(
                    "pincode",
                    pincode,
                    "required"
                  )}
                />
              </Col>
              <Col sm={4}>
                <TextInput
                  onInputChange={(e: any) => {
                    const re = /^[a-zA-Z ]*$/;
                    setWebsite(e.target.value);
                  }}
                  disabled={!profiletToggle}
                  label="Website"
                  containerClass="form-group"
                  type="text"
                  value={website}
                  autocomplete="new-firstName"
                  placeholder="http://www.website.com"
                  errorMessage={validator.current.message(
                    "website",
                    website,
                    "url"
                  )}
                />
              </Col>
              <Col sm={4} className="">
                <Datepicker
                  disabled={!profiletToggle}
                  peekNextMonth={true}
                  showMonthDropdown={true}
                  showYearDropdown={true}
                  dropdownMode="select"
                  label="Incorporation Date"
                  className="form-control"
                  selected={incorporationDate}
                  placeholder="Select incorporation date"
                  dateFormat="dd/MM/yyyy"
                  onChange={(e: any) => {
                    setIncorporationDate(e);
                  }}
                  // class
                  maxDate={new Date()}
                  onKeyDown={true}
                  // showYearDropdown={true}
                  // isClearable={true}
                  // errorMessage={validator.current.message(
                  //   "incorporation Date",
                  //   incorporationDate,
                  //   "required"
                  // )}
                />
              </Col>
              {/* <Col sm={4}>
                <TextInput
                  onInputChange={(e: any) => {
                    const re = /^[a-zA-Z ]*$/;
                    setSacNumber(e.target.value);
                  }}
                  disabled={!profiletToggle}
                  label="SAC Number"
                  containerClass="form-group"
                  type="text"
                  value={sacNumber}
                  autocomplete="new-firstName"
                  placeholder="Enter SAC number"
                />
              </Col> */}
              {/* <Col sm={4}>
                      <TextInput
                        onInputChange={(
                          e: React.ChangeEvent<
                            HTMLTextAreaElement | HTMLInputElement
                          >
                        ) => {
                          setAboutCompany(e.target.value);
                        }}
                        disabled={!profiletToggle}
                        label="Terms"
                        containerClass="form-group"
                        type="text"
                        as="textarea"
                        style={{ height: "4rem" }}
                        value={aboutCompany}
                        placeholder="Enter invoice terms"
                      />
                    </Col> */}

              <Col sm={4}>
                <TextInput
                  onInputChange={(e: any) => {
                    setCompanyLogo({
                      ...companyLogo,
                      file: e.target.files,
                    });
                    if (e.target.value == "") return;

                    if (
                      e.target.value !== "" &&
                      ["png", "jpeg", "jpg", "svg", "pdf"].includes(
                        typeof e.target.files[0].name === "string" &&
                          e.target.files[0].name.split(".").pop()
                      )
                    ) {
                      if (e.target.files[0].size >= 1000000) {
                        setShowLoader(false);
                        swal("File size is too large", " ", "error");
                        setCompanyLogo({
                          ...companyLogo,
                          file: "",
                          title: "",
                        });
                        return;
                      } else {
                        uploadCompanyLogo(e.target.files);
                      }
                    } else {
                      swal("File extension is not supported.", "", "error");
                      return;
                    }
                    forceUpdate(count + 1);
                  }}
                  label={"Upload Company Logo"}
                  type="file"
                  name="file"
                  title={companyLogo?.title}
                  style={{
                    opacity: 0,
                    color: "transparent",
                    width: "70px",
                  }}
                  accept={"image/png, image/jpeg, image/jpg, image/svg"}
                  disabled={!profiletToggle}
                />
                <Button
                  ref={file}
                  variant="secondary"
                  style={{ marginTop: "-63px", width: "230px" }}
                  disabled={!profiletToggle}
                >
                  {companyLogo?.title
                    ? companyLogo?.title && companyLogo?.title?.length > 20
                      ? companyLogo?.title.slice(0, 20) + "..."
                      : companyLogo?.title
                    : "Choose File"}
                </Button>
              </Col>
            </Row>

            <Row>
              {profiletToggle && (
                <Col>
                  <div style={{ float: "right" }} className="form-group mt-3">
                    <Button
                      type="submit"
                      variant="primary"
                      disabled={disableButton}
                      onClick={(e) => {
                        onSubmit(e);
                        setProfiletToggle(true);
                      }}
                    >
                      Submit
                    </Button>
                    <Button
                      onClick={(e) => {
                        e.preventDefault();
                        setProfiletToggle(false);
                        navigate("/");
                      }}
                      variant="secondary"
                      className="ms-2"
                    >
                      Cancel
                    </Button>
                  </div>
                </Col>
              )}
            </Row>
          </Card.Body>
        </Card>
      </section>
    </>
  );
};

export default CompanyProfile;
