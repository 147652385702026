import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";
import SweetAlert from "react-bootstrap-sweetalert";
import { Card, Form, Col, Row } from "react-bootstrap";
import customFunctions from "../../helper/customFunctions";
import AuthApi from "../../helper/authApi";
import Api from "../../helper/api";
// import Breadcrumb from "../../components/Breadcrumb/breadcrumb.component";
import Loader from "../../components/Loader/index";
import { Helmet, TextInput, DropdownInput } from "../../components/FormInputs";
import TableNew from "../../components/Table/simpletable.component";
import PieChartComponent from "../dashboard/Dashboard/PieGraphComponent";
import Table from "../../components/Table/simpletable.component";
import EmployeeBarComponent from "./employeeChart";
import RejectReasonModel from "../ManagerTaskApprovalDetails/rejectReasonModel";
import RejectTaskList from "../ManagerTaskApprovalDetails/rejectTaskList";
import EmployeeTaskReport from "../ManagerTaskApprovalDetails/mangerTaskReport";
import EmployeeTimesheet from "./employeeTimesheet";
const EmployeeDashboard = () => {
  const navigate = useNavigate();
  const [isShowLoader, setShowLoader] = useState<any>(false);
  const [rows, setRows] = useState<any>([]);
  const [title, setTitle] = useState<any>(" Employee Dashboard");
  const [count, forceUpdate] = useState<any>(0);
  const [applyToggle, setApplyToggle] = useState<any>(false);
  const [roleId, setRoleId] = useState<number>(0);
  const [currentEmpId, setCurrentUserId] = useState<number>(0);
  const [empTaskList, setEmployeeTaskList] = useState<any>([]);
  const [updateShow, setUpdateShow] = useState<boolean>(false);
  const [modalData, setModalData] = useState<any>(false);
  const userName = customFunctions.getLocalStorage("userName");

  const items = [
    { to: "/", label: "Dashboard" },
    { label: " Employee Dashboard" },
  ];

  const columns = [
    {
      label: "Sr.No",
      id: "index",
    },
    {
      label: "Client Name",
      id: "client_name",
    },
    {
      label: "Task",
      id: "task_name",
    },
    {
      label: "Note",
      id: "note",
    },
  ];

  useEffect(() => {
    getTaskData();
  }, []);

  const getTaskData = () => {
    const role = customFunctions.getLocalStorage("role_id");
    setRoleId(Number(role));
    const currentUserId = customFunctions.getLocalStorage("id");
    setCurrentUserId(Number(currentUserId));
  };

  return (
    <>
      <Helmet title={title} />
      <div className="page-header">
        <div className="title-breadcrumb-section">
          <h2 className="main-content-title tx-24 mg-b-5">Dashboard</h2>
          Welcome To Dashboard
        </div>
      </div>
      <Loader showLoader={isShowLoader} needFullPage={false} />
      <Row className="mt-3">
        
        <Col sm={12}>
          <RejectTaskList
            limit={4}
            fromComponent="dashboard"
            pagetype="tabfile"
          />
        </Col>
      </Row>
    </>
  );
};

export default EmployeeDashboard;
