import React, { useEffect, useRef, useState } from "react";
import { Modal, Button, Card, Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { TextInput } from "../../components/FormInputs";
import SimpleReactValidator from "simple-react-validator";

const ClientPanValidationComponent = (props: any) => {
  const { show, fromComponent } = props;
  const navigate = useNavigate();
  const validator = useRef(new SimpleReactValidator());

  const [panCardNumber, setPanCardNumber] = useState<any>('');
  const [count, forceUpdate] = useState<number>(0);

  const [modalShow, setModalShow] = useState<boolean>(false);
  const handleShow = () => setModalShow(true)
  const handleClose = () => {
    validator.current.hideMessages(); setModalShow(false); if (props) {
      props.handleClose()
    }
  }

  const panValidation = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    const validKey: string = panCardNumber.charAt(3)

    if (!validator.current.allValid()) {
      validator.current.showMessages();
      forceUpdate(count + 1);
      return;
    }

    switch (validKey) {
      case 'P':
        return navigate('/individual-client-onboard', {
          state: {
            pageType: 'add', panCardNumber: panCardNumber, fromComponent: fromComponent
          }
        })
        break;

      case 'C':
        return navigate('/corporate-client-onboard', {
          state: {
            pageType: 'add', panCardNumber: panCardNumber, fromComponent: fromComponent
          }
        })
        break;

      default:
        return navigate('/non-corporate-client-onboard', {
          state: {
            pageType: 'add', panCardNumber: panCardNumber, fromComponent: fromComponent
          }
        })
        break;
    }
  }

  return (
    <>
      <div style={{ float: "right" }} className="form-group">
        <Button
          type="submit"
          variant="primary"
          onClick={(e) => {
            handleShow();
          }}
        >
          Add Client
        </Button>
      </div>

      <Modal show={modalShow || show}
        onHide={handleClose}
        // size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <h5>
              Pan Information
            </h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <TextInput
            onInputChange={(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
              const re = /^[ 0-9 a-z A-Z ]*$/
              if (!e.target.value || re.test(e.target.value)) {
                setPanCardNumber(e.target.value.toUpperCase());
              }
            }}
            label="Pan Card Number"
            asterisk="*"
            containerClass="form-group"
            type="text"
            name="panCardNumber"
            value={panCardNumber}
            placeholder="Enter pan card number"
            errorMessage={validator.current.message(
              "Pan Card Number",
              panCardNumber,
              "required|regex:^([A-Z]){5}([0-9]){4}([A-Z]){1}$",
              {
                messages: {
                  required: "The pan card number field is required",
                  regex: "Invalid pan card number",
                },
              }
            )}
            autoComplete="new-panCardNumber"
            maxlength={10}
            minlength={10}
          />
          <div className="d-flex justify-content-end">
            <Button onClick={(e) => { panValidation(e) }}>Next</Button>
            <Button className="ml-10" variant="secondary" onClick={() => { validator.current.hideMessages(); handleClose() }}>Cancel</Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default ClientPanValidationComponent;