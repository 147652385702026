
import React, { Component } from 'react';
import PaginationComponent from 'react-js-pagination';
import './style.css';
// require("bootstrap/less/bootstrap.less");

export default class Pagination extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activePage: 1,
    };
  }

  static getDerivedStateFromProps(props) { // eslint-disable-line

    return { activePage: props.currentPage };
  }

  handlePageChange = async (pageNumber) => {
    const { onPageChange } = this.props;
    const { activePage } = this.state;
    if (activePage !== pageNumber) {
      await this.setState({ activePage: pageNumber });
      if (onPageChange) onPageChange(pageNumber);
    }
  };

  render() {
    // const { totalItems, itemsPerPage, activePage } = this.state;
    const { totalItems, itemsPerPage, currentPage, showPagination } = this.props; //eslint-disable-line

    return (
      <div className={showPagination}>
        <PaginationComponent
          activePage={currentPage} //eslint-disable-line
          itemsCountPerPage={itemsPerPage}
          totalItemsCount={totalItems}
          pageRangeDisplayed={5}
          onChange={this.handlePageChange}
          hideFirstLastPages
          hideDisabled={true}
          // activeClass
          itemClass="page-item"
          linkClass="page-link"
        />
      </div>
    );
  }
}
