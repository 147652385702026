import React, { useState, useRef, useEffect } from "react";
import { Card, Row, Col, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import SimpleReactValidator from "simple-react-validator";
import swal from "sweetalert";
import {
  TextInput,
  Helmet,
  PasswordInput,
  DropdownInput,
} from "../../components/FormInputs";
import customFunctions from "../../helper/customFunctions";
import Api from "../../helper/api";
import AuthApi from "../../helper/authApi";
import Loader from "../../components/Loader/index";

interface postDataInterface {
  id?: any;
  first_name?: string;
  last_name?: string;
  firm_name?: string;
  email?: string;
  pan_number?: string;
  mobile?: string;
  password?: string;
  confirm_password?: string;
  website_url?: string;
  gst_no?: string;
}

const CompanyRegistrationComponent = () => {
  const title = "Company Registration";

  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [firmName, setFirmName] = useState<string>("");
  const [email, setEmail] = useState<any>();
  const [contactNumber, setContactNumber] = useState<string>("");
  const [panCardNumber, setPanCardNumber] = useState<string>("");
  const [websiteUrl, setWebsiteUrl] = useState<any>();
  const [password, setPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [gstNumber, setGstNumber] = useState<any>("");
  const [address, setAddress] = useState<string>();
  const [sacNumber, setSacNumber] = useState<any>("");
  const [city, setCity] = useState<any>("");
  const [state, setState] = useState<any>("");
  const [pincode, setPincode] = useState<any>("");
  const [stateArr, setStateArr] = useState<any>([]);
  const [cityArr, setCityArr] = useState<any>([]);

  const [disableButton, setDisableButton] = useState<boolean>(false);
  const [count, forceUpdate] = useState<number>(0);
  const [isShowLoader, setShowLoader] = useState(false);

  const navigate = useNavigate();

  const validator = useRef(new SimpleReactValidator());

  useEffect(() => {
    getState();
  }, []);

  useEffect(() => {
    if (state && state?.value) getCity();
  }, [state]);

  const getState = async () => {
    setShowLoader(true);
    const endPoint: any = Api.stateUrl;
    const params: any = {
      limit: 50,
      sort_by: "name",
    };

    const url: any = customFunctions.generateUrl(endPoint, params);
    const { data, message } = await AuthApi.getDataFromServer(url);

    if (data && data.success === true) {
      setShowLoader(false);
      if (data && data?.data?.length) {
        const stateData: any = [];
        data?.data?.map((item: any) => {
          stateData.push({
            label: item?.name,
            value: item?.id,
          });
        });
        setStateArr(stateData);
      }
    } else {
      setShowLoader(false);
      swal(typeof message == "string" ? message : message[0], "", "error");
    }
  };

  const getCity = async () => {
    setShowLoader(true);
    const endPoint: any = Api.cityUrl;
    const params: any = {
      limit: 800,
      state_id: state?.value,
      sort_by: "name",
    };

    const url: any = customFunctions.generateUrl(endPoint, params);
    const { data, message } = await AuthApi.getDataFromServer(url);

    if (data && data.success === true) {
      setShowLoader(false);
      if (data && data?.data?.length) {
        const cityData: any = [];
        data?.data?.map((item: any) => {
          cityData.push({
            label: item?.name,
            value: item?.id,
          });
        });
        setCityArr(cityData);
      }
    } else {
      setShowLoader(false);
      swal(typeof message == "string" ? message : message[0], "", "error");
    }
  };

  const onProceed = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    if (disableButton) return;
    setDisableButton(true);
    if (validator.current.allValid()) {
      addCompany();
    } else {
      setDisableButton(false);
      validator.current.showMessages();
      forceUpdate(count + 1);
    }
  };

  const addCompany = async () => {
    // setShowLoader(true);
    const postData: any = {
      first_name: firstName.trim(),
      last_name: lastName.trim(),
      firm_name: firmName.trim(),
      email: email,
      pan_number: panCardNumber,
      mobile: contactNumber,
      password: password,
      confirm_password: confirmPassword,
      website_url: websiteUrl,
      gst_no: gstNumber,
      address: {
        area: address,
        zipcode: pincode,
        state: state?.value,
        city: city?.value,
      },
      // sac_code:sacNumber
    };

    const payload = customFunctions.cleanObject(postData);
    const endPoint = `${Api.registrationUrl}`;
    const callback = AuthApi.postDataToServer;
    const { data, message } = await callback(endPoint, payload);

    if (data && data.success === true) {
      setShowLoader(false);
      setDisableButton(false);
      swal(data.message[0], "", "success").then(() => {
        navigate(-1);
      });
    } else {
      setDisableButton(false);
      setShowLoader(false);
      swal(typeof message == "string" ? message : message[0], "", "error");
    }
  };

  return (
    <>
      <Helmet title={title} />
      <div className="client-main">
        <div className="page-header">
          <div className="title-breadcrumb-section">
            <h2 className="main-content-title tx-24 mg-b-5">{title}</h2>
          </div>
        </div>
        <Loader showLoader={isShowLoader} needFullPage={false} />

        <section className="content-area">
          <Card>
            <Card.Body>
              <h6 className="section-title mb-0">Company Details</h6>
              <hr />
              <Row>
                <Col sm="6">
                  <TextInput
                    onInputChange={(
                      e: React.ChangeEvent<
                        HTMLTextAreaElement | HTMLInputElement
                      >
                    ) => {
                      let re = /^[A-Z a-z & .]*$/;
                      if (!e.target.value || re.test(e.target.value)) {
                        setFirmName(e.target.value);
                      }
                    }}
                    id="companyName"
                    label="Company Name"
                    asterisk="*"
                    containerClass="form-group"
                    type="text"
                    name="firmName"
                    value={firmName}
                    placeholder="Enter company name"
                    errorMessage={validator.current.message(
                      "company Name",
                      firmName,
                      "required"
                    )}
                    autoComplete="new-firmName"
                  />
                </Col>
                <Col sm="6">
                  <TextInput
                    onInputChange={(
                      e: React.ChangeEvent<
                        HTMLTextAreaElement | HTMLInputElement
                      >
                    ) => {
                      let re = /^[A-Z a-z]*$/;
                      if (!e.target.value || re.test(e.target.value)) {
                        setFirstName(e.target.value);
                      }
                    }}
                    id="firstName"
                    label="First Name"
                    asterisk="*"
                    containerClass="form-group"
                    type="text"
                    name="firstName"
                    value={firstName}
                    placeholder="Enter first name"
                    errorMessage={validator.current.message(
                      "firstName",
                      firstName,
                      "required|alpha_space"
                    )}
                    autoComplete="new-firstName"
                  />
                </Col>

                <Col>
                  <TextInput
                    onInputChange={(
                      e: React.ChangeEvent<
                        HTMLTextAreaElement | HTMLInputElement
                      >
                    ) => {
                      let re = /^[A-Z a-z]*$/;
                      if (!e.target.value || re.test(e.target.value)) {
                        setLastName(e.target.value);
                      }
                    }}
                    id="lastName"
                    label="Last Name"
                    asterisk="*"
                    containerClass="form-group"
                    type="text"
                    name="lastName"
                    value={lastName}
                    placeholder="Enter last name"
                    errorMessage={validator.current.message(
                      "lastName",
                      lastName,
                      "required|alpha_space"
                    )}
                    autoComplete="new-lastName"
                  />
                </Col>

                <Col sm="6">
                  <TextInput
                    onInputChange={(
                      e: React.ChangeEvent<
                        HTMLTextAreaElement | HTMLInputElement
                      >
                    ) => {
                      const re = /^[0-9]*$/;
                      if (!e.target.value || re.test(e.target.value)) {
                        setContactNumber(e.target.value);
                      }
                    }}
                    id="contactNumber"
                    inputVarible={"+91"}
                    label="Contact Number"
                    asterisk="*"
                    containerClass="form-group"
                    type="text"
                    name="contactNumber"
                    placeholder="Enter contact number"
                    autoComplete="new-contact"
                    maxlength={10}
                    minlength={10}
                    value={contactNumber}
                    errorMessage={validator.current.message(
                      "contact Number",
                      contactNumber,
                      "required|regex:^(([0-9]){10}$)",
                      {
                        messages: {
                          required: "The contact number field is required",
                          regex: "Invalid contact number",
                        },
                      }
                    )}
                  />
                </Col>

                <Col sm="6">
                  <TextInput
                    onInputChange={(e: any) => {
                      setEmail(e.target.value);
                    }}
                    id="email"
                    label="Email Address"
                    asterisk="*"
                    containerClass="form-group"
                    type="text"
                    name="email"
                    value={email}
                    placeholder="Enter email address"
                    errorMessage={validator.current.message(
                      "email address",
                      email,
                      "required|email"
                    )}
                    autoComplete="new-email"
                  />
                </Col>

                <Col sm="6">
                  <TextInput
                    onInputChange={(e: any) => {
                      const re = /^[ 0-9 a-z A-Z]*$/;
                      if (!e.target.value || re.test(e.target.value)) {
                        setPanCardNumber(e.target.value.toUpperCase());
                      }
                    }}
                    id="panCardNumber"
                    label="Pan Card Number"
                    asterisk="*"
                    containerClass="form-group"
                    type="text"
                    name="panCardNumber"
                    value={panCardNumber}
                    placeholder="Enter pan card number e.g. AAAAA0000A"
                    maxlength={10}
                    minlength={10}
                    errorMessage={validator.current.message(
                      "panCardNumber",
                      panCardNumber,
                      "required|regex:^([A-Z]){5}([0-9]){4}([A-Z]){1}$",
                      {
                        messages: {
                          required: "The pan card number field is required",
                          regex: "Invalid pan card number",
                        },
                      }
                    )}
                    autoComplete="new-panCardNumber"
                  />
                </Col>
                <Col sm={6} className="">
                  <TextInput
                    onInputChange={(
                      e: React.ChangeEvent<
                        HTMLTextAreaElement | HTMLInputElement
                      >
                    ) => {
                      const re = /^[a-zA-Z0-9]*$/;
                      if (!e.target.value || re.test(e.target.value)) {
                        setGstNumber(e.target.value.toUpperCase());
                      }
                    }}
                    label="GST Number"
                    asterisk="*"
                    maxlength={15}
                    containerClass="form-group"
                    type="text"
                    name="lastName"
                    value={gstNumber}
                    placeholder="Enter GST number e.g. 22AAAAA0000A1Z5"
                    errorMessage={validator.current.message(
                      "GST Number",
                      gstNumber,
                      "required|regex:^([0-9]){2}([A-Z]){5}([0-9]){4}([A-Z]){1}([A-Z0-9]){1}Z([A-Z0-9]){1}$",
                      {
                        messages: {
                          regex: "Invalid GST number",
                          required: "The GST number field is required."
                        },
                      }
                    )}
                    autocomplete="new-cinNumber"
                  />
                </Col>
                {/* <Col sm={6} className="">
                  <TextInput
                    onInputChange={(
                      e: React.ChangeEvent<
                        HTMLTextAreaElement | HTMLInputElement
                      >
                    ) => {
                      const re = /^[a-zA-Z0-9]*$/;
                      if (!e.target.value || re.test(e.target.value)) {
                        setSacNumber(e.target.value);
                      }
                    }}
                    label="SAC Number"
                    asterisk="*"
                    containerClass="form-group"
                    type="text"
                    value={sacNumber}
                    placeholder="Enter gst number"
                    errorMessage={validator.current.message(
                      "sac Number",
                      sacNumber,
                      "required"
                    )}
                  />
                </Col> */}

                <Col sm={6}>
                  <PasswordInput
                    onInputChange={(
                      e: React.ChangeEvent<
                        HTMLTextAreaElement | HTMLInputElement
                      >
                    ) => {
                      setPassword(e.target.value);
                    }}
                    id="password"
                    name="password"
                    containerClass="form-group"
                    inputClass="form-control"
                    label="New Password"
                    asterisk="*"
                    value={password}
                    type="password"
                    minlength="6"
                    placeholder="Enter new password"
                    errorMessage={validator.current.message(
                      "password",
                      password,
                      "required",
                      {
                        className: "text-danger",
                      }
                    )}
                    autoComplete="new-password"
                    switchElement="true"
                  />
                </Col>
                <Col sm={6}>
                  <PasswordInput
                    onInputChange={(
                      e: React.ChangeEvent<
                        HTMLTextAreaElement | HTMLInputElement
                      >
                    ) => {
                      setConfirmPassword(e.target.value);
                    }}
                    id="confirmPassword"
                    name="confirmPassword"
                    containerClass="form-group"
                    inputClass="form-control"
                    label="Confirm Password"
                    asterisk="*"
                    value={confirmPassword}
                    type="password"
                    minlength="6"
                    placeholder="Enter confirm password"
                    errorMessage={validator.current.message(
                      "confirmPassword",
                      confirmPassword,
                      // `required|in:${password}`,
                      `required`,
                      {
                        className: "text-danger",
                      }
                    )}
                    autoComplete="new-confirmPassword"
                    switchElement="true"
                  />
                </Col>
              </Row>

              <h6 className="section-title mb-0">Address Details</h6>
              <hr />
              <Row>
                <Col sm={6}>
                  <TextInput
                    onInputChange={(
                      e: React.ChangeEvent<
                        HTMLTextAreaElement | HTMLInputElement
                      >
                    ) => {
                      setAddress(e.target.value);
                    }}
                    label="Address"
                    containerClass="form-group"
                    asterisk="*"
                    type="text"
                    name="firstName"
                    value={address}
                    placeholder="Enter address"
                    errorMessage={validator.current.message(
                      "address",
                      address,
                      "required"
                    )}
                    autocomplete="new-firstName"
                  />
                </Col>
                <Col sm={6}>
                  <DropdownInput
                    label="State"
                    asterisk="*"
                    placeholder="Select state"
                    defaultValue={state}
                    onSelectHandler={(select: any) => {
                      setState(select);
                      setCity("");
                      setPincode("");
                      setCityArr([]);
                    }}
                    options={stateArr}
                    errorMessage={validator.current.message(
                      "state",
                      state,
                      "required"
                    )}
                  />
                </Col>
                <Col sm={6}>
                  <DropdownInput
                    placeholder="Select city"
                    label="City"
                    asterisk="*"
                    defaultValue={city}
                    onSelectHandler={(select: any) => {
                      setCity(select);
                      setPincode("");
                    }}
                    options={cityArr}
                    errorMessage={validator.current.message(
                      "city",
                      city,
                      "required"
                    )}
                  />
                </Col>
                <Col sm={6}>
                  <TextInput
                    onInputChange={(e: any) => {
                      const re = /^[0-9]*$/;
                      setPincode(e.target.value);
                    }}
                    maxlength={6}
                    asterisk="*"
                    label="Pincode"
                    containerClass="form-group"
                    type="text"
                    value={pincode}
                    placeholder="Enter pincode"
                    errorMessage={validator.current.message(
                      "pincode",
                      pincode,
                      "required"
                    )}
                  />
                </Col>
                <Col sm="6">
                  <TextInput
                    onInputChange={(
                      e: React.ChangeEvent<
                        HTMLTextAreaElement | HTMLInputElement
                      >
                    ) => {
                      setWebsiteUrl(e.target.value);
                    }}
                    label="Website URL"
                    // asterisk="*"
                    containerClass="form-group"
                    type="text"
                    value={websiteUrl}
                    placeholder="https://www.website.com"
                    errorMessage={validator.current.message(
                      "websiteUrl",
                      websiteUrl,
                      "url",
                      {
                        messages: {
                          url: "The website URL must be a valid URL.",
                        },
                      }
                    )}
                  />
                </Col>
              </Row>

              <Row>
                <Col>
                  <div style={{ float: "left" }} className="form-group mt-3">
                    <Button
                      id="submitBtn"
                      type="submit"
                      variant="primary"
                      onClick={(e) => onProceed(e)}
                    >
                      Submit
                    </Button>
                    <Button
                      onClick={(e) => {
                        e.preventDefault();
                        navigate("/login");
                      }}
                      id="cancelBtn"
                      variant="secondary"
                      className="ms-2"
                    >
                      Cancel
                    </Button>
                  </div>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </section>
      </div>
    </>
  );
};
export default CompanyRegistrationComponent;
