export const Data = [
  {
    index: '1',
    // clientId:'C1',
    client_type: 'Individual',
    first_name: 'Samir',
    last_name: 'Sawant',
    // name: 'Samir Sawant',
    email: 'samir@gmail.com',
    contact_number: 9982212168,
    address: 'Pune',
    pan_card_number: 'AWEPE6377L',
    financial_year: '2022-23',
    financial_statement: 'document.docx',
    engagement: 'One-time',
    company_identification_number: 745448458954,
    gst_number: 'WES745h484',
    import_export_code: 'D8G445',
    business_description:'GST filing',
    parent_company_name: 'TATA motors',
    service1: 'GST filing',
    service2: 'Audit',
  },
  {
    index: '2',
    // clientId:'C2',
    client_type: 'Corporate',
    first_name: 'Akshay',
    last_name: 'Chavan',
    name:'Akshay Chavan',
    email: 'akshay@gmail.com',
    contact_number: 9934552178,
    address: 'Mumbai',
    pan_card_number: 'DWFPW6377O',
    financial_year: '2022-23',
    financial_statement: 'document.docx',
    engagement: 'Recurring',
    company_identification_number: 745448458954,
    gst_number: 'WES745h484',
    import_export_code: 'D8V445',
    business_description:'Tax filing',
    parent_company_name: 'Infosys',
    service1: 'Income tax filling',
    service2: 'Audit',
  },
  {
    index: '3',
    // clientId:'C3',
    client_type: 'Non-Corporate',
    first_name: 'Nilesh',
    last_name: 'Gatala',
    name:'Nilesh Gatala',
    email: 'nilesh@gmail.com',
    contact_number: 9644431218,
    address: 'Pune',
    pan_card_number: 'CWEPT6377C',
    financial_year: '2022-23',
    financial_statement:'document.docx',
    engagement: 'On-going',
    company_identification_number: 745448458954,
    gst_number: 'WES745h484',
    import_export_code: 'D8D445',
    business_description:'Auditing',
    parent_company_name: 'Mahindra',
    service1: 'Audit',
    service2: 'Audit',
  },
  {
    index: '4',
    // clientId:'C4',
    client_type: 'Corporate',
    first_name: 'Santosh',
    last_name: 'Shinde',
    name: 'Santosh Shinde',
    email: 'santosh@gmail.com',
    contact_number: 8844436618,
    address: 'Mumbai',
    pan_card_number: 'CWECE6377P',
    financial_year: '2022-23',
    financial_statement: 'document.docx',
    engagement: 'One-time',
    company_identification_number: 745448458954,
    gst_number: 'WES745h484',
    import_export_code: 'D8E445',
    business_description:'Auditing',
    parent_company_name: 'TATA motors',
    service1: 'ITR filling',
    service2: 'Audit',
  },
  {
    index: '5',
    // clientId:'C5',
    client_type: 'Individual',
    first_name: 'Omkar',
    last_name: 'Malpote',
    name: 'Omkar Malpote',
    email: 'omkar@gmail.com',
    contact_number: 8754734938,
    address: 'Delhi',
    pan_card_number: 'EWJJC6377U',
    financial_year: '2022-23',
    financial_statement: 'document.docx',
    engagement: 'One-time',
    company_identification_number: 745448458954,
    gst_number: 'WES745h484',
    import_export_code: 'D8h445',
    business_description:'Auditing',
    parent_company_name: 'Coca cola',
    service1: 'GST filing',
    service2: 'Audit',
  },
  {
    index: '6',
    // clientId:'C6',
    client_type: 'Non-Corporate',
    first_name: 'Akshay',
    last_name: 'Sing',
    name:'Akshay Sing',
    email: 'akshay@gmail.com',
    contact_number: 7876785544,
    address: 'Pune',
    pan_card_number: 'WEDCE6377C',
    financial_year: '2022-23',
    financial_statement:'document.docx',
    engagement: 'Recurring',
    company_identification_number: 745448458954,
    gst_number: 'WES745h484',
    import_export_code: 'D8D445',
    business_description:'Auditing',
    parent_company_name: 'Relience',
    service1: 'Audit',
    service2: 'GST filing',
  },
];


export const columns = [
  // {
  //   label: "Sr.No",
  //   id: "index",
  // },
  // {
  //   label: "Client Id",
  //   id: "clientId",
  // },
  {
    label: "Name",
    id: "name",
  },
  {
    label: "Email",
    id: "email",
  },
  {
    label: "Contact Number",
    id: "contact_number",
  },
  // {
  //   label: "Address",
  //   id: "address",
  // },
  {
    label: "Pan Card",
    id: "pan_card_number",
  },
    // {
    //   label: "Financial Year",
    //   id: "financial_year",
    // },
  {
    label: "Enagagement",
    id: "engagement",
  },
  {
    label: "Document",
    id: "financial_statement",
  },
  {
    label: "Status",
    id: "status",
  },
  {
    label: "Action",
    id: "action",
  },
];
