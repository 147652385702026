import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";
import SweetAlert from "react-bootstrap-sweetalert";
import { Card, Form, Col, Row, Button, Accordion } from "react-bootstrap";
import customFunctions from "../../helper/customFunctions";
import AuthApi from "../../helper/authApi";
import Api from "../../helper/api";
import Breadcrumb from "../../components/Breadcrumb/breadcrumb.component";
import Loader from "../../components/Loader/index";
import { Helmet, TextInput, DropdownInput } from "../../components/FormInputs";
import Table from "../../components/Table/simpletable.component";

const PartnerComponent = () => {
  const navigate = useNavigate();
  const [isShowLoader, setShowLoader] = useState<any>(false);
  const [rows, setRows] = useState<any>([]);
  const [title, setTitle] = useState<any>("Partners");
  const [statusToggle, setStatusToggle] = useState<any>(false);
  const [statusId, setStatusId] = useState<any>("");
  const [count, forceUpdate] = useState<any>(0);
  const [filterErr, setFilterErr] = useState<any>("");
  const [searchKeyword, setSearchKeyword] = useState<any>("");
  const [selectedStatus, setSelectedStatus] = useState<any>("");
  const [applyToggle, setApplyToggle] = useState<any>(false);
  const [roleId, setRoleId] = useState<number>(0);
  const [partnerId, setPartnerId] = useState<any>(null);
  const [currentUserId, setCurrentUserId] = useState<number>(0);
  const [partnerData, setPartnerData] = useState<any>([]);

  const items = [{ to: "/", label: "Dashboard" }, { label: "Partners" }];

  const initialPagination = {
    currentPage: 1,
    itemsPerPage: 2,
    totalItems: 1,
  };
  const [pagination, setPagination] = useState(initialPagination);

  const columns = [
    {
      label: "Sr.no",
      id: "index",
    },
    {
      label: "Name",
      id: "name",
    },
    {
      label: "Email",
      id: "email",
    },
    {
      label: "contact Number",
      id: "contact_number",
    },
    {
      label: "Designation",
      id: "designation",
    },
    {
      label: "Action",
      id: "action",
    },
  ];

  const statusArray = [
    {
      label: "Active",
      value: "1",
    },
    {
      label: "Inactive",
      value: "2",
    },
  ];

  useEffect(() => {
    getUserRole();
  }, []);

  const getUserRole = () => {
    const role = customFunctions.getLocalStorage("role_id");
    setRoleId(Number(role));
    const currentUserId = customFunctions.getLocalStorage("id");
    setCurrentUserId(Number(currentUserId));
  };

  useEffect(() => {
    getPartnerData();
  }, [currentUserId]);

  const getPartnerData = async (pageNo?: any, clearFilter?: boolean) => {
    // if (
    //   !currentUserId ||
    //   (!applyToggle && clearFilter && !searchKeyword && !selectedStatus)
    // )
    //   return;
    // const page = pageNo || 1;
    // setShowLoader(true);
    // setApplyToggle(false);
    // const endPoint = Api.doctorUrl;

    // const params = {
    //   page: page,
    //   limit: pagination.itemsPerPage,
    //   search: !clearFilter && searchKeyword ? searchKeyword.trim() : "",
    //   status: !clearFilter && selectedStatus ? selectedStatus.value : "",
    // };

    // const url = customFunctions.generateUrl(endPoint, params);

    // const { data, message } = await AuthApi.getDataFromServer(url);
    // if (data && data.success == true) {
    //   setShowLoader(false);
    //   if (
    //     data &&
    //     data.data &&
    //     data.data.response &&
    //     data.data.response.length > 0
    //   ) {
    //     setShowLoader(false);
    //     setPagination({
    //       currentPage: data.data.page,
    //       itemsPerPage: 2,
    //       totalItems: data.data.count,
    //     });
    //     setPartnerData(data.data.response);
    //     forceUpdate(count + 1);
    //   } else {
    //     setPartnerData([]);
    //   }
    // } else {
    //   setShowLoader(false);
    //   setPartnerData([]);
    //   swal(message, "", "error");
    // }
  };

  useEffect(() => {
    setTableData();
  }, [partnerData]);

  const setTableData = () => {
    const rowsData: any = [];
    partnerData.map((dt: any, i: any) => {
      rowsData.push({
        id: dt.id,
        index: (pagination.currentPage - 1) * pagination.itemsPerPage + (i + 1),
        email: dt.email,
        contact_number: dt.contact_number,
        statuss: dt.statuss,
        designation: dt.designation,
        name: (
          <>
            <a
              href="/"
              className="pl-2 ms-1"
              title="Edit Partner"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                navigate("/edit-partner", {
                  state: { data: dt, pageType: "edit" },
                });
              }}
            >
              {`${
                dt && dt.first_name
                  ? customFunctions.capitalizeFirstLetter(dt.first_name)
                  : null
              }
               ${
                 dt && dt.last_name
                   ? customFunctions.capitalizeFirstLetter(dt.last_name)
                   : null
               }`}
            </a>
          </>
        ),

        status: (
          <>
            <div className="switch-check">
              <div
                className="form-check form-switch"
                style={{ marginBottom: "0" }}
              >
                <input
                  className="form-check-input"
                  type="checkbox"
                  role="switch"
                  // disabled={[3, 4].includes(Number(roleId)) ? true : false}
                  id="flexSwitchCheckDefault"
                  checked={dt?.status == "1" ? true : false}
                  onClick={() => {
                    setStatusToggle(true);
                    setStatusId(dt?.status == "2" ? "1" : "2");
                    setPartnerId(dt?.id);
                  }}
                  title={dt?.status == "1" ? "Active" : "Inactive"}
                />
              </div>
            </div>
          </>
        ),
        action: (
          <>
            <a
              href="#"
              className="pl-2 ms-1"
              title={"View task"}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                navigate("/add-partner", {
                  state: {
                    data: dt,
                    pageType: "view",
                  },
                });
              }}
            >
              <img src="images/eye.svg" alt="view" />
            </a>
            &nbsp;&nbsp;
            <a
              href="/"
              className="pl-2 ms-1"
              title={"Edit Partner"}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                navigate("/add-partner", {
                  state: { data: dt, pageType: "edit" },
                });
              }}
            >
              <img src="images/edit-button.svg" alt="Edit" />
            </a>
            
          </>
        ),
      });
      return dt;
    });
    setRows([...rowsData]);
  };

  const changeStatus = async () => {
    const postData = {
      id: Number(partnerId),
      status: statusId,
    };

    if (!partnerId) return;
    setShowLoader(true);
    const endpoint = `${Api.doctorUrl}/change-doctor-status`;
    const { data, message } = await AuthApi.putDataToServer(endpoint, postData);
    if (data && data.success == true) {
      setStatusToggle(false);
      swal("Doctor status changed successfully!", "", "success", {
        closeOnClickOutside: false,
      }).then(() => {
        setPartnerId(null);
        setStatusId("");
        getPartnerData(1);
      });
    } else {
      setShowLoader(false);
      swal(message || "Something went wrong", "", "error").then(() => {
        setPartnerId(null);
        setStatusId("");
        getPartnerData(1);
      });
    }
  };

  const filterSearch = (e: any) => {
    e.preventDefault();
    let filterSearchErr = "";
    let isValid = true;
    if (
      customFunctions.checkIfEmpty(searchKeyword) &&
      customFunctions.checkIfEmpty(selectedStatus)
    ) {
      filterSearchErr = "Please enter name, email or select status";
      setFilterErr(filterSearchErr);
      isValid = false;
    }
    if (isValid) {
      setFilterErr("");
      getPartnerData(1);
    }
  };

  const onClear = (e: any) => {
    setFilterErr("");
    setSearchKeyword("");
    setSelectedStatus("");
    getPartnerData(1, true);
    setApplyToggle(false);
  };

  return (
    <>
      <>
        <Helmet title={title} />
        <div className="page-header">
          <div className="title-breadcrumb-section">
            <h2 className="main-content-title tx-24 mg-b-5">{title}</h2>
            <Breadcrumb items={items} />
          </div>
        </div>
        {/* <Loader showLoader={isShowLoader} needFullPage={false} /> */}

        {statusToggle && (
          <div className="sweetalert">
            <SweetAlert
              title={"Are you sure, do you want to change the status ?"}
              warning
              showCancel
              confirmBtnBsStyle="success"
              cancelBtnBsStyle="danger"
              onConfirm={() => {
                setStatusToggle(false);
                changeStatus();
              }}
              onCancel={() => {
                setStatusToggle(false);
              }}
              reverseButtons={true}
            />
          </div>
        )}
        <section className="content-area">
          <Accordion defaultActiveKey="1">
            <Accordion.Item eventKey="1">
              <Accordion.Header>
                <h5 className="card-title mb-0">Filters</h5>
              </Accordion.Header>
              <Accordion.Body>
                <Form onSubmit={(e) => filterSearch(e)}>
                  {filterErr && (
                    <div className="p-3  bg-danger text-white bg-danger-main table-red-bg mb-3">
                      <div className="img-text">
                        <img
                          style={{ cursor: "pointer" }}
                          onClick={(e) => onClear(e)}
                          src="/images/fail.svg"
                          alt="Success"
                        />{" "}
                        {filterErr}
                      </div>
                    </div>
                  )}
                  <Row className="mt-3">
                    <Col sm={3}>
                      <TextInput
                        value={searchKeyword}
                        placeholder="Search by name, email"
                        onInputChange={(e: any) => {
                          setSearchKeyword(e.target.value);
                        }}
                      />
                    </Col>

                    <Col sm={3}>
                      <DropdownInput
                        placeholder="Select status"
                        options={statusArray}
                        defaultValue={selectedStatus}
                        onSelectHandler={(e: any) => {
                          setSelectedStatus(e);
                        }}
                      />
                    </Col>

                    <Col sm={3}>
                      <div className="filter-btn pull-right filter-btn-report">
                        <Button
                          variant="primary"
                          type="submit"
                          className="ms-4"
                          onClick={() => setApplyToggle(true)}
                        >
                          Apply
                        </Button>
                        <Button
                          variant="secondary"
                          type="button"
                          className="ms-4"
                          onClick={(e) => onClear(e)}
                        >
                          Clear
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
          <Card className="mt-3">
            <Card.Header>
              <div className="d-flex align-items-center justify-content-end">
                <a
                  href="#"
                  className="underline"
                  onClick={(e) => {
                    e.preventDefault();
                    navigate("/add-partner", { state: { pageType: "add" } });
                  }}
                >
                  Add Partner
                </a>
              </div>
            </Card.Header>
            <Card.Body>
              <Table
                columns={columns}
                showCheckbox={false}
                rows={rows}
                showPagination
                role={roleId}
                paginationProps={{
                  ...pagination,
                  activePage: pagination.currentPage,
                  onPageChange: (pageNo: any) => {
                    getPartnerData(pageNo);
                  },
                }}
                dropdownContainerClass="mb-3"
                emptyRender={() => (
                  <p className="text-center mb-0">No record found</p>
                )}
              />
            </Card.Body>
          </Card>
        </section>
      </>
    </>
  );
};

export default PartnerComponent;
