import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import menu from "../../helper/menu";
import UserRestrictions from "../UserRestrictions/userrestrictions.component";
import customFunctions from "../../helper/customFunctions";

function Sidebar() {
  const navigate = useNavigate();

  const [activeMenu, setActiveMenu] = useState<any>(0);
  const [activeSubPath, setActiveSubPath] = useState<any>(0);
  const [submenuToggle, setSubmenuToggle] = useState<boolean>(false);

  const [userRole, setUserRole] = useState<any>("");

  useEffect(() => {
    const uRole = localStorage.getItem("role_id");
    setUserRole(Number(uRole));
  }, []);

  const onSubActiveClass = () => {
    if (activeSubPath !== window.location.pathname)
      setActiveSubPath(window.location.pathname);
  };

  useEffect(() => {
    document.addEventListener("MenuChange", () => {
      onSubActiveClass();
    });

    document.addEventListener("ViewBtnChange", () => {
      onSubActiveClass();
    });

    document.addEventListener("ViewButtonChange", () => {
      onSubActiveClass();
      setActiveMenu(0);
    });

    document.addEventListener("ViewEventButtonChange", () => {
      onSubActiveClass();
      setActiveMenu(0);
    });
  }, []);

  const dispatch = useDispatch();
  interface eventProps {
    isLogin?: boolean;
  }

  // const event = new Event("Login", { isLogin: false });

  return (
    <>
      <div className="main-sidebar main-sidebar-sticky side-menu ps">
        <div className="sidemenu-logo">
          <a
            className="main-logo"
            href={"/"}
          >
            <img
              src="/images/CA-logo_12.png"
              className="header-brand-img desktop-logo svg"
              alt="logo"
              height="100px"
            />
          </a>
        </div>
        <div className="main-sidebar-body">
          <ul className="nav">
            {menu.map((menuItem: any, menuIndex: any) => {
              const hasSubMenu =
                menuItem.submenu && menuItem.submenu.length > 0;
              const parentLiCls = `nav-item ${
                menuIndex === 0 || window.location.pathname === menuItem.path
                  ? "show"
                  : ""
              }`;
              const demo = menuItem.path.split("-")[0].slice(1);
              const parentActiveCls =
                (activeMenu === menuIndex &&
                  window.location.pathname === menuItem.path) ||
                window.location.pathname === menuItem.path ||
                (window.location.pathname.includes(demo) &&
                  !submenuToggle &&
                  menuItem.path.length > 1)
                  ? "show active"
                  : "";
              const parentLinkCls = `nav-link ${hasSubMenu ? "with-sub" : ""}`;
              return (
                <UserRestrictions
                  fromComponent="sidebar"
                  permittedUsers={menuItem.roleaccess}
                  roleId={userRole}
                >
                  <>
                    <li className={`${parentLiCls} ${parentActiveCls}`}>
                      <Link
                        className={parentLinkCls}
                        to={
                          menuItem?.redirect !== "undefined" &&
                          menuItem?.redirect === false
                            ? false
                            : menuItem.path
                        }
                        onClick={(e) => {
                          if (hasSubMenu) {
                            setSubmenuToggle(true);
                            e.preventDefault();
                            e.stopPropagation();
                          } else {
                            setSubmenuToggle(false);
                          }
                          if (window.location.pathname == "/client-navbar") {
                            customFunctions.setLocalStorage(
                              "activeTab",
                              "individualClient"
                            );
                          }
                          if (menuItem.title === "Log Out") {
                            e.preventDefault();
                            e.stopPropagation();
                            customFunctions.clearLocalStorage();
                            customFunctions.onLogout();
                            navigate("/login");
                            // document.dispatchEvent(event);
                            dispatch({ type: "LOGIN", value: false });
                          }
                        }}
                      >
                        <span>
                          <img src={menuItem.icon} />
                          <span className="sidemenu-label">
                            {menuItem.title}
                          </span>
                        </span>
                        {hasSubMenu && (
                          <span className="pal">
                            <img
                              className="justify-content-end"
                              src="/images/up-arrow-svgrepo-com.png"
                              height={"10px"}
                              width="10px"
                            />
                          </span>
                        )}
                      </Link>

                      {hasSubMenu && (
                        <ul className="nav-sub list-unstyled">
                          {menuItem.submenu.map((submenuItem: any, i: any) => {
                            const subDemo = submenuItem.path
                              .split("-")[0]
                              .slice(1);

                            return (
                              <UserRestrictions
                                fromComponent="sidebar"
                                permittedUsers={submenuItem.roleaccess}
                              >
                                <>
                                  <li
                                    key={i}
                                    onClick={() => {
                                      // setSubmenuToggle(true);
                                      onSubActiveClass();
                                    }} // eslint-disable-line
                                    className={`nav-sub-item ${
                                      submenuItem.path ===
                                      window.location.pathname
                                        ? // || (window.location.pathname.includes(subDemo) &&
                                          // submenuItem.path.length > 1)
                                          "show active"
                                        : ""
                                    }`}
                                  >
                                    <Link
                                      className="nav-sub-link"
                                      to={submenuItem.path}
                                    >
                                      {submenuItem.title}
                                    </Link>
                                  </li>
                                </>
                              </UserRestrictions>
                            );
                          })}
                        </ul>
                      )}
                    </li>
                  </>
                </UserRestrictions>
              );
            })}
          </ul>
        </div>
      </div>
    </>
  );
}

export default Sidebar;
