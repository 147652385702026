/* eslint-disable-line */
import React, { useState, useRef, useEffect } from "react";
import { Row, Col, Card, Form, Button } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";
import swal from "sweetalert";
import {
  Datepicker,
  DropdownInput,
  Helmet,
  TextInput,
} from "../../components/FormInputs";
import SimpleReactValidator from "simple-react-validator";
import customFunctions from "../../helper/customFunctions";
import AuthApi from "../../helper/authApi";
import Api from "../../helper/api";
import Loader from "../../components/Loader";
import Breadcrumb from "../../components/Breadcrumb/breadcrumb.component";
import SelectAsyncPaginate from "../../components/ReactSelectPagination/SelectAsyncPaginate";
import {
  serviceStatus,
  dateOptions,
  monthsOptions,
} from "../superAdmin/constant";

const AddLitigationComponent = () => {
  const navigate: any = useNavigate();
  const { state } = useLocation();

  const validator = useRef(new SimpleReactValidator());

  const [count, forceUpdate] = useState<any>(0);
  const [roleId, setRoleId] = useState<number>(0);
  const [pageType, setPageType] = useState<string>("add");
  const [title, setTitle] = useState("Create Litigation");
  const [isShowLoader, setShowLoader] = useState<boolean>(false);
  const [litigationId, setLitigationId] = useState<boolean>(false);
  const [serviceOptions, setServiceOptions] = useState<any>([]);
  const [litigationDetails, setLitigationDetails] = useState<any>({
    client: "",
    service: "",
    title: "",
    orderDate: "",
    hearingDate: "",
    dateOfFiling: "",
    fillingDueDate: "",
    recievedDate: "",
    noticeDetails: "",
    employee: "",
  });

  useEffect(() => {
    getUserRole();
  }, []);

  const getUserRole = () => {
    const role = customFunctions.getLocalStorage("role_id");
    setRoleId(Number(role));
  };

  useEffect(() => {
    if (state && state?.id) {
      setTitle("Edit Litigation");
      setLitigationId(state?.id);
    }
  }, [state]);

  const items = [
    {
      to: "/",
      label: "Dashboard",
    },
    {
      to: "/litigation-list",
      label: "Litigations",
    },
    { label: state?.id ? "Edit Litigation Details" : "Create Litigation" },
  ];

  useEffect(() => {
    if (litigationDetails?.client && litigationDetails?.client?.id) {
      getClientServices(litigationDetails?.client?.id);
    }
  }, [litigationDetails?.client]);

  useEffect(() => {
    if (litigationId) {
      getLitgation(litigationId);
    }
  }, [litigationId]);

  const getLitgation = async (id: any) => {
    setShowLoader(true);
    const { data, message } = await AuthApi.getDataFromServer(
      `${Api.litigationUrl}${id}/`
    );

    if (data && data.success === true) {
      setShowLoader(false);
      if (data && data.data) {
        const employeeData: any = [];
        data?.data?.employee?.length &&
          data?.data?.employee?.map((element: any) => {
            employeeData.push({
              name: element?.employee_name ? element?.employee_name : "",
              id: element?.id ? element?.id : "",
            });
          });
        setLitigationDetails({
          client: data?.data?.client_id
            ? { name: data?.data?.client_name, id: data?.data?.client_id }
            : "",
          service: data?.data?.service
            ? { label: data?.data?.service_name, value: data?.data?.service }
            : "",
          title: data?.data?.title ? data?.data?.title : "",
          orderDate: data?.data?.order_date
            ? new Date(data?.data?.order_date)
            : "",
          hearingDate: data?.data?.hearing_date
            ? new Date(data?.data?.hearing_date)
            : "",
          dateOfFiling: data?.data?.date_of_filing
            ? new Date(data?.data?.date_of_filing)
            : "",
          fillingDueDate: data?.data?.due_date_of_filing
            ? new Date(data?.data?.due_date_of_filing)
            : "",
          recievedDate: data?.data?.date_of_receipt
            ? new Date(data?.data?.date_of_receipt)
            : "",
          noticeDetails: data?.data?.notice_details
            ? data?.data?.notice_details
            : "",
          employee: employeeData?.length ? employeeData : "",
        });
      }
    } else {
      setShowLoader(false);
      swal(typeof message == "string" ? message : message[0], "", "error");
    }
  };

  const getClientServices = async (id: any) => {
    setShowLoader(true);
    const { data, message } = await AuthApi.getDataFromServer(
      `${Api.clientUrl}${id}/`
    );

    if (data && data.success === true) {
      setShowLoader(false);
      let serviceArr: any = [];
      data &&
        data.data &&
        data.data.services &&
        data.data.services.length > 0 &&
        data.data.services.map((item: any) => {
          serviceArr.push({
            label: item?.service_name,
            value: item?.service_id,
          });
        });

      const sortedData: any =
        serviceArr?.length &&
        serviceArr?.sort?.((acu: any, cur: any) =>
          acu?.label?.toLowerCase() > cur?.label?.toLowerCase() ? 1 : -1
        );

      setServiceOptions(sortedData?.length ? sortedData : []);
    } else {
      setShowLoader(false);
      setServiceOptions("");
      swal(typeof message === "string" ? message : message[0], "", "error");
    }
  };

  const addLitigation = async () => {
    setShowLoader(true);
    const postData: any = {
      client: litigationDetails?.client ? litigationDetails?.client?.id : null,
      services: litigationDetails?.service
        ? litigationDetails?.service?.value
        : null,
      title: litigationDetails?.title ? litigationDetails?.title : null,
      date_of_receipt: litigationDetails?.recievedDate
        ? customFunctions.getDate(litigationDetails?.recievedDate)
        : null,
      due_date_of_filing: litigationDetails?.fillingDueDate
        ? customFunctions.getDate(litigationDetails?.fillingDueDate)
        : null,
      date_of_filing: litigationDetails?.dateOfFiling
        ? customFunctions.getDate(litigationDetails?.dateOfFiling)
        : null,
      hearing_date: litigationDetails?.hearingDate
        ? customFunctions.getDate(litigationDetails?.hearingDate)
        : null,
      order_date: litigationDetails?.orderDate
        ? customFunctions.getDate(litigationDetails?.orderDate)
        : null,
      notice_details: litigationDetails?.noticeDetails
        ? litigationDetails?.noticeDetails
        : null,
      status: 1,
      employee: litigationDetails?.employee?.length
        ? litigationDetails?.employee?.map((element: any) => element?.id)
        : null,
    };

    const payload = customFunctions.cleanObject(postData);
    const endPoint = litigationId
      ? `${Api.litigationUrl}${litigationId}/`
      : `${Api.litigationUrl}`;

    const callback = litigationId
      ? AuthApi.putDataToServer
      : AuthApi.postDataToServer;
    const { data, message } = await callback(endPoint, postData);
    if (data && data.success === true) {
      setShowLoader(false);
      swal(
        litigationId
          ? "Litigation updated successfully!"
          : "Litigation added successfully!",
        "",
        "success"
      ).then(() => {
        navigate(-1);
      });
    } else {
      setShowLoader(false);
      swal(typeof message == "string" ? message : message[0], "", "error");
    }
  };

  const onSubmit = (e: any) => {
    e.preventDefault();

    if (validator.current.allValid()) {
      addLitigation();
    } else {
      let errFieldsArr: any = Object.entries(validator?.current?.fields).find(
        (item) => item[1] == false
      );
      let erName = errFieldsArr[0].replace(/ /g, "");
      document.getElementById(`${erName}`)?.focus();
      validator.current.showMessages();
      forceUpdate(count + 1);
    }
  };

  return (
    <>
      <Helmet title={title} />
      <Button
        style={{ float: "right" }}
        className="form-group mt-4"
        type="submit"
        variant="primary"
        onClick={() => {
          navigate(-1);
        }}
      >
        Back
      </Button>
      <div className="page-header">
        <div className="title-breadcrumb-section">
          <h2 className="main-content-title tx-24 mg-b-5">{title}</h2>
          <Breadcrumb items={items} />
        </div>
      </div>
      <Loader showLoader={isShowLoader} needFullPage={false} />
      <section className="content-area">
        <Card>
          <Card.Body>
            <Form
              autoComplete="off"
              onSubmit={(e) => {
                onSubmit(e);
              }}
            >
              <Row>
                <Col sm={4}>
                  <SelectAsyncPaginate
                    id="client"
                    label={"Client"}
                    asterisk={"*"}
                    selectPlaceholder={"Select client"}
                    value={litigationDetails?.client}
                    onSelectChange={(select: any) => {
                      setLitigationDetails({
                        ...litigationDetails,
                        client: select,
                        service: "",
                      });
                      setServiceOptions([]);
                    }}
                    disabled={state?.pageType == "view"}
                    apiname={"clientUrl"}
                    endpoint={Api.clientUrl}
                    errorMessage={validator.current.message(
                      "client",
                      litigationDetails?.client,
                      "required"
                    )}
                  />
                </Col>
                <Col sm={4}>
                  <DropdownInput
                    id="service"
                    label={"Service"}
                    asterisk={"*"}
                    placeholder="Select service"
                    options={serviceOptions}
                    defaultValue={litigationDetails?.service}
                    onSelectHandler={(select: any) => {
                      setLitigationDetails({
                        ...litigationDetails,
                        service: select,
                      });
                    }}
                    disabled={
                      !litigationDetails?.client || state?.pageType == "view"
                    }
                    errorMessage={validator.current.message(
                      "service",
                      litigationDetails?.service,
                      "required"
                    )}
                  />
                </Col>
                <Col sm={4}>
                  <TextInput
                    id="title"
                    onInputChange={(e: any) => {
                      setLitigationDetails({
                        ...litigationDetails,
                        title: e.target.value,
                      });
                    }}
                    label="Title"
                    asterisk="*"
                    containerClass="form-group"
                    type="text"
                    maxlength={50}
                    value={litigationDetails?.title}
                    disabled={state?.pageType == "view"}
                    placeholder="Enter title"
                    errorMessage={validator.current.message(
                      "title",
                      litigationDetails?.title,
                      "required"
                    )}
                  />
                </Col>
                <Col sm={4}>
                  <Datepicker
                    id="OrderDate"
                    peekNextMonth={true}
                    showMonthDropdown={true}
                    showYearDropdown={true}
                    dropdownMode="select"
                    label="Order Date"
                    className="form-control"
                    selected={litigationDetails?.orderDate}
                    asterisk="*"
                    placeholder="dd-mm-yyyy"
                    dateFormat="dd-MM-yyyy"
                    maxDate={new Date()}
                    onChange={(e: any) => {
                      setLitigationDetails({
                        ...litigationDetails,
                        orderDate: e,
                      });
                    }}
                    disabled={state?.pageType == "view"}
                    errorMessage={validator.current.message(
                      "Order Date",
                      litigationDetails?.orderDate,
                      "required"
                    )}
                  />
                </Col>
                <Col sm={4}>
                  <Datepicker
                    id="HearingDate"
                    peekNextMonth={true}
                    showMonthDropdown={true}
                    showYearDropdown={true}
                    dropdownMode="select"
                    label="Hearing Date"
                    className="form-control"
                    selected={litigationDetails?.hearingDate}
                    asterisk="*"
                    placeholder="dd-mm-yyyy"
                    dateFormat="dd-MM-yyyy"
                    minDate={new Date()}
                    onChange={(e: any) => {
                      setLitigationDetails({
                        ...litigationDetails,
                        hearingDate: e,
                      });
                    }}
                    disabled={state?.pageType == "view"}
                    errorMessage={validator.current.message(
                      "Hearing Date",
                      litigationDetails?.hearingDate,
                      "required"
                    )}
                  />
                </Col>
                <Col sm={4}>
                  <Datepicker
                    id="DateOfFiling"
                    peekNextMonth={true}
                    showMonthDropdown={true}
                    showYearDropdown={true}
                    dropdownMode="select"
                    label="Date Of Filing"
                    className="form-control"
                    selected={litigationDetails?.dateOfFiling}
                    asterisk="*"
                    placeholder="dd-mm-yyyy"
                    dateFormat="dd-MM-yyyy"
                    minDate={new Date()}
                    onChange={(e: any) => {
                      setLitigationDetails({
                        ...litigationDetails,
                        dateOfFiling: e,
                        fillingDueDate: "",
                      });
                    }}
                    disabled={state?.pageType == "view"}
                    errorMessage={validator.current.message(
                      "Date Of Filing",
                      litigationDetails?.dateOfFiling,
                      "required"
                    )}
                  />
                </Col>
                <Col sm={4}>
                  <Datepicker
                    id="DueDateOfFiling"
                    peekNextMonth={true}
                    showMonthDropdown={true}
                    showYearDropdown={true}
                    dropdownMode="select"
                    label="Due Date Of Filing"
                    className="form-control"
                    selected={litigationDetails?.fillingDueDate}
                    asterisk="*"
                    placeholder="dd-mm-yyyy"
                    dateFormat="dd-MM-yyyy"
                    minDate={new Date(litigationDetails?.dateOfFiling)}
                    onChange={(e: any) => {
                      setLitigationDetails({
                        ...litigationDetails,
                        fillingDueDate: e,
                      });
                    }}
                    disabled={state?.pageType == "view"}
                    errorMessage={validator.current.message(
                      "Due Date Of Filing",
                      litigationDetails?.fillingDueDate,
                      "required"
                    )}
                  />
                </Col>
                <Col sm={4}>
                  <Datepicker
                    id="ReceiptDate"
                    peekNextMonth={true}
                    showMonthDropdown={true}
                    showYearDropdown={true}
                    dropdownMode="select"
                    label="Receipt Date"
                    className="form-control"
                    selected={litigationDetails?.recievedDate}
                    asterisk="*"
                    placeholder="dd-mm-yyyy"
                    dateFormat="dd-MM-yyyy"
                    maxDate={new Date()}
                    onChange={(e: any) => {
                      setLitigationDetails({
                        ...litigationDetails,
                        recievedDate: e,
                      });
                    }}
                    disabled={state?.pageType == "view"}
                    errorMessage={validator.current.message(
                      "Receipt Date",
                      litigationDetails?.recievedDate,
                      "required"
                    )}
                  />
                </Col>
                <Col sm={4}>
                  <SelectAsyncPaginate
                    id="employee"
                    label={"Assign Employee"}
                    selectPlaceholder={"Select employee"}
                    value={litigationDetails?.employee}
                    onSelectChange={(select: any) => {
                      setLitigationDetails({
                        ...litigationDetails,
                        employee: select,
                      });
                    }}
                    disabled={state?.pageType == "view"}
                    apiname={"employeeUrl"}
                    endpoint={Api.employeeUrl}
                    isClearable={true}
                    isMulti={true}
                    fromComponent={"litigation"}
                  />
                </Col>
                <Col sm={4}>
                  <TextInput
                    id="notice-details"
                    onInputChange={(e: any) => {
                      setLitigationDetails({
                        ...litigationDetails,
                        noticeDetails: e.target.value,
                      });
                    }}
                    disabled={state?.pageType == "view"}
                    label="Notice Details"
                    as="textarea"
                    containerClass="form-group"
                    type="text"
                    maxlength={255}
                    value={litigationDetails?.noticeDetails}
                    placeholder="Enter details"
                  />
                </Col>
              </Row>
              {state?.pageType != "view" && (
                <Row>
                  <Col>
                    <div style={{ float: "left" }} className="form-group mt-2">
                      <Button type="submit" variant="primary">
                        Submit
                      </Button>
                      <Button
                        onClick={(e) => {
                          e.preventDefault();
                          navigate(-1);
                        }}
                        variant="secondary"
                        className="ms-2"
                      >
                        Cancel
                      </Button>
                    </div>
                  </Col>
                </Row>
              )}
            </Form>
          </Card.Body>
        </Card>
      </section>
    </>
  );
};

export default AddLitigationComponent;
