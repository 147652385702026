import React, { useEffect, useRef, useState } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import Breadcrumb from "../../components/Breadcrumb/breadcrumb.component";
import { Datepicker, DropdownInput, TextInput, Helmet, } from "../../components/FormInputs";
import SimpleReactValidator from "simple-react-validator";
import { useNavigate, useLocation } from "react-router-dom";
import swal from "sweetalert";
import Api from "../../helper/api";
import AuthApi from "../../helper/authApi";
import customFunctions from "../../helper/customFunctions";

const CorporateClientOnboardComponent = () => {
  customFunctions.setLocalStorage('activeTab', 'corporateClient');

  const validator = useRef(new SimpleReactValidator());
  const navigate = useNavigate();
  const location = useLocation();

  const [clientType, setClientType] = useState<string>('Corporate Client');
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [panCardNumber, setPanCardNumber] = useState<any>('');
  const [businessDescription, setBusinessDescription] = useState<any>('');
  const [financialYear, setFinancialYear] = useState<any>(new Date());
  const [financialDocument, setFinancialDocument] = useState<any>('');
  const [engagement, setEngagement] = useState<string>('');
  const [contactNumber, setContactNumber] = useState<string>('');
  const [email, setEmail] = useState<any>('');
  const [address, setAddress] = useState<any>('');
  const [companyIdentificationNumber, setCompanyIdentificationNumber] = useState<any>('');
  const [GSTNumber, setGSTNumber] = useState<any>('');
  const [importExportCode, setImportExportCode] = useState<any>('');
  const [title, setTitle] = useState<string>('Add Corporate Client');

  const [isShowLoader, setShowLoader] = useState<boolean>(false);
  const [disableButton, setDisableButton] = useState<boolean>(false);
  const [count, forceUpdate] = useState<number>(0);
  const [label, setLabel] = useState<string>("Add Corporate Client");
  const [pageType, setPageType] = useState<string>('add');
  const [roleId, setRoleId] = useState<any>("");
  const [currentUserId, setCurrentUserId] = useState<any>(null);
  const [selectedClientId, setSelectedClientId] = useState<any>(null);
  const [parentCompanyName, setParentCompanyName] = useState<string>('');

  const items: any = [{ to: "/", label: "Dashboard" },
  { to: '/client-master-list', label: 'Client List' },
  { label: label }
  ];

  // const items: any = [{ to: "/", label: "Dashboard" },
  // { to: "/client-navbar", label: "Corporate Client List" },
  // { label: location?.state?.clientId ? "Edit Corporate Client" : "Add Corporate Client" }
  // ];

  const engagementValues: any = [
    { value: 'Recurring', label: 'Recurring' },
    { value: 'One-Time', label: 'One-Time' },
    { value: 'On-Going', label: 'On-Going' }]

  useEffect(() => {

    if (location && location?.state?.pageType == 'add') {
      setClientType('Corporate Client');
      setPanCardNumber(location?.state?.panCardNumber);
      return;
    }

    if (location && location?.state) {

      const engagement: any = {
        label: location.state?.data?.engagement,
        value: location.state?.data?.engagement
      }
      setEngagement(engagement)

      if (location.state.pageType == 'edit') {
        setPageType('edit');
        setTitle("Edit Corporate Client");
        setLabel("Edit Corporate Client");
      }
      else {
        setPageType('view');
        setTitle("View Corporate Client");
        setLabel("View Corporate Client");
      }

      setSelectedClientId(location?.state?.data?.index);
      setClientType(location?.state?.data?.client_type)
      setFirstName(location.state?.data?.first_name)
      setLastName(location.state?.data?.last_name)
      setPanCardNumber(location.state?.data?.pan_card_number)
      setBusinessDescription(location.state?.data?.business_description)
      // setFinancialYear(location.state?.data?.financial_year)
      // setFinancialDocument(location.state?.data?.financial_statement)
      setContactNumber(location.state?.data?.contact_number)
      setEmail(location.state?.data?.email)
      setAddress(location.state?.data?.address)
      setCompanyIdentificationNumber(location.state?.data?.company_identification_number)
      setGSTNumber(location.state?.data?.gst_number)
      setImportExportCode(location.state?.data?.import_export_code)
      setParentCompanyName(location.state?.data?.parent_company_name)
    }
  }, [location])

  // useEffect(() => {
  //     const role: any = customFunctions.getLocalStorage("role_id");
  //     setRoleId(Number(role));
  //     const currentUserId: any = customFunctions.getLocalStorage("id");
  //     setCurrentUserId(currentUserId);
  // }, []);

  // useEffect(() => {
  //     if (location.state && location.state.pageType == "edit") {
  //         setTitle("Edit Client");
  //         getClient();
  //     }
  // }, [location.state])

  // const getClient = async () => {
  //     setShowLoader(true)
  //     const { data, message } = await AuthApi.getDataFromServer(
  //         `${Api.clientUrl}?id=${location.state.clientId}`
  //     );

  //     if (data && data.success == true) {
  //         setShowLoader(false);
  //         if (
  //             data &&
  //             data.data &&
  //             data.data.response &&
  //             data.data.response.length > 0
  //         ) {
              // let client: any = data.data.response[0];

  //             setClientType(client.client_type)
  //             setFirstName(client.first_name);
  //             setLastName(client.last_name);
  //             setPanCardNumber(client.pan_card_number)
  //             setBusinessDescription(client.business_description)
  //             // setFinancialYear(client.financial_year)
  //             setFinancialDocument(client.financial_statement)
              // setContactNumber(client.contact_number);
  //             setEmail(client.email);
  //             setAddress(client.address)
  //             setCompanyIdentificationNumber(client.company_identification_number)
  //             setGSTNumber(client.gst_number)
  //             setImportExportCode(client.import_export_code)
  //             setEngagement(client.engagement)
  //         }
  //     } else {
  //         setShowLoader(false);
  //         swal(message, "", "error");
  //     }
  // };

  const onSubmit = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (disableButton) return;
    setDisableButton(true);
    if (validator.current.allValid()) {
      addClient();
    }
    else {
      validator.current.showMessages();
      forceUpdate(count + 1);
      setDisableButton(false);
    }
  }

  const addClient = async () => {
    // setShowLoader(true);
    const postData = {
      client_type: clientType,
      first_name: firstName,
      last_name: lastName,
      email: email.trim(),
      contact_number: contactNumber,
      address: address,
      pan_card_number: panCardNumber,
      company_identification_number: companyIdentificationNumber,
      gst_number: GSTNumber,
      import_export_code: importExportCode,
      business_description: businessDescription,
      // financial_year: financialYear,
      financial_statement: financialDocument,
      engagement: engagement,
      parent_company_name: parentCompanyName
    };

    customFunctions.setLocalStorage('activeTab', 'corporateClient');
    swal(selectedClientId ? "Client updated successfully!" : "Client added successfully!", '', 'success')
      .then(() => {
        // navigate('/client-navbar');
        navigate('/client-master-list');
      })

    // if (location.state) {
    //     postData.id = location.state.clientId;
    // }
    // const payload: any = customFunctions.cleanObject(postData);
    // const endPoint: any = `${Api.clientUrl}`;
    // const callback: any =
    //     location.state && location.state.clientId
    //         ? AuthApi.putDataToServer
    //         : AuthApi.postDataToServer;
    // const { data, message } = await callback(endPoint, payload);
    // if (data && data.success === true) {
    //     setShowLoader(false);
    //     setDisableButton(false);
    //     swal(
    //         location.state && location.state.clientId
    //             ? "Client updated successfully!"
    //             : "Client created successfully!",
    //         "",
    //         "success"
    //     ).then(() => {
    //         navigate(-1);
    //     });
    // } else {
    //     setDisableButton(false);
    //     setShowLoader(false);
    //     swal(message, "", "error");
    // }
  }

  return (
    <>
      <Helmet title={title} />
      {pageType == 'view' &&
        <div style={{ float: "right" }} className="form-group mt-4">
          <Button
            type="button"
            variant="primary"
            onClick={(e) => {
              e.preventDefault();
              navigate('/client-navbar')
            }}
          >
            Back
          </Button>
        </div>
      }

      <div className="page-header">
        <div className="title-breadcrumb-section">
          <h2 className="main-content-title tx-24 mg-b-5">{title}</h2>
          <Breadcrumb items={items} />
        </div>
      </div>
      {/* <Loader showLoader={isShowLoader} needFullPage={false} /> */}
      <section className="content-area"></section>
      <Card>
        <Card.Body>
          <h6 className="section-title">General Information</h6>
          <hr />

          {/* <Col sm={6}>
            <TextInput
              onInputChange={(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                const re = /^[a-zA-Z ]*$/;
                if (!e.target.value || re.test(e.target.value)) {
                  setClientType(e.target.value);
                }
              }}
              label="Client Type"
              asterisk="*"
              containerClass="form-group"
              type="text"
              name="clientType"
              value={clientType}
              disabled={selectedClientId || pageType == 'add'}
              placeholder="Choose Client Type"
              errorMessage={validator.current.message(
                "Client Type",
                clientType,
                "required|alpha_space"
              )}
              autoComplete="new-clientType"
            />
          </Col> */}

          <Row>
            <Col sm={6}>
              <TextInput
                onInputChange={(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                  const re = /^[a-zA-Z ]*$/;
                  if (!e.target.value || re.test(e.target.value)) {
                    setFirstName(e.target.value);
                  }
                }}
                label="First Name"
                asterisk="*"
                containerClass="form-group"
                type="text"
                name="firstName"
                value={firstName}
                disabled={pageType == 'view'}
                placeholder="Enter first name"
                errorMessage={validator.current.message(
                  "First Name",
                  firstName,
                  "required|alpha_space"
                )}
                autoComplete="new-firstName"
              />
            </Col>

            <Col sm={6}>
              <TextInput
                onInputChange={(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                  const re = /^[a-zA-Z ]*$/;
                  if (!e.target.value || re.test(e.target.value)) {
                    setLastName(e.target.value);
                  }
                }}
                label="Last Name"
                asterisk="*"
                containerClass="form-group"
                type="text"
                name="lastName"
                value={lastName}
                disabled={pageType == 'view'}
                placeholder="Enter last name"
                errorMessage={validator.current.message(
                  "last Name",
                  lastName,
                  "required|alpha_space"
                )}
                autoComplete="new-lastName"
              />
            </Col>

            <Col sm={6}>
              <TextInput
                onInputChange={(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                  setEmail(e.target.value);
                }}
                label="Email Address"
                asterisk="*"
                containerClass="form-group"
                type="text"
                name="email"
                value={email}
                disabled={pageType == 'view'}
                placeholder="Enter email address"
                errorMessage={validator.current.message(
                  "Email Address",
                  email,
                  "required|email"
                )}
                autoComplete="new-email"
              />
            </Col>

            <Col sm={6}>
              <TextInput
                onInputChange={(e: any) => {
                  const re = /^[0-9]*$/;
                  if (!e.target.value || re.test(e.target.value)) {
                    setContactNumber(e.target.value)
                  }
                }
                }
                label="Contact Number"
                asterisk="*"
                inputVarible={'+91'}
                containerClass="form-group"
                type="tel"
                name="contactNumber"
                value={contactNumber}
                disabled={pageType == 'view'}
                placeholder="Enter contact number"
                errorMessage={validator.current.message(
                  "Contact Number",
                  contactNumber,
                  "required|regex:^([0-9]){10}$",
                  {
                    messages: {
                      required: "The contact number field is required",
                      regex: "Invalid contact number",
                    },
                  }
                )}
                autoComplete="new-contactNumber"
                maxlength={10}
                minlength={10}
              />
            </Col>

            <Col sm={6}>
              <TextInput
                onInputChange={(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                  setAddress(e.target.value)
                }}
                label="Address"
                asterisk="*"
                containerClass="form-group"
                type="text"
                as="textarea"
                name="address"
                value={address}
                disabled={pageType == 'view'}
                placeholder="Enter address"
                errorMessage={validator.current.message(
                  "Address",
                  address,
                  "required"
                )}
                autoComplete="new-address"
              />
            </Col>
            <Col sm={6}>
              <TextInput
                onInputChange={(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                  // const re = /^[a-zA-Z ]*$/;
                  // if (!e.target.value || re.test(e.target.value)) {
                  setParentCompanyName(e.target.value);
                  // }
                }}
                label="Parent Company Name"
                asterisk="*"
                containerClass="form-group"
                type="text"
                name="parentName"
                value={parentCompanyName}
                disabled={pageType == 'view'}
                placeholder="Enter parent company name"
                errorMessage={validator.current.message(
                  "parent company name",
                  parentCompanyName,
                  "required"
                )}
                autoComplete="new-parentName"
              />
            </Col>
            </Row>
            <h6 className="section-title">Engagement Information</h6>
            <hr />
            <Row>
            <Col sm={6}>
              <DropdownInput
                label="Engagement Type"
                asterisk="*"
                placeholder="Select engagement type"
                options={engagementValues}
                defaultValue={engagement}
                disabled={pageType == 'view'}
                onSelectHandler={(select: any) => {
                  setEngagement(select);
                }}
                isClearable={true}
                errorMessage={validator.current.message(
                  "Engagement Type",
                  engagement,
                  "required"
                )}
                id="engagement"
              />
            </Col>
            </Row>
            <h6 className="section-title">Regulatory Information</h6>
            <hr />
            <Row>
            <Col sm={6}>
              <TextInput
                onInputChange={(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                  const re = /^[ 0-9 a-z A-Z ]*$/
                  if (!e.target.value || re.test(e.target.value)) {
                    setPanCardNumber(e.target.value.toUpperCase());
                  }
                }}
                label="Pan Card Number"
                asterisk="*"
                containerClass="form-group"
                type="text"
                name="panCardNumber"
                value={panCardNumber}
                disabled={selectedClientId || pageType == 'add'}
                placeholder="Enter pan card number"
                errorMessage={validator.current.message(
                  "Pan Card Number",
                  panCardNumber,
                  "required|regex:^([A-Z]){5}([0-9]){4}([A-Z]){1}$",
                  {
                    messages: {
                      required: "The pan card number field is required",
                      regex: "Invalid pan card number",
                    },
                  }
                )}
                autoComplete="new-panCardNumber"
                maxlength={10}
                minlength={10}
              />
            </Col>

            <Col sm={6}>
              <TextInput
                onInputChange={(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                  const re = /^[a-z A-Z 0-9]*$/;
                  if (!e.target.value || re.test(e.target.value)) {
                    setCompanyIdentificationNumber(e.target.value)
                  }
                }}
                label="Company Identification Number"
                containerClass="form-group"
                type="text"
                name="companyIdentificationNumber"
                value={companyIdentificationNumber}
                disabled={pageType == 'view'}
                placeholder="Enter company identification number"
                autoComplete="new-companyIdentificationNumber"
              />
            </Col>

            <Col sm={6}>
              <TextInput
                onInputChange={(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                  const re = /^[a-z A-Z 0-9]*$/;
                  if (!e.target.value || re.test(e.target.value)) {
                    setGSTNumber(e.target.value)
                  }
                }}
                label="GST Registration Number"
                containerClass="form-group"
                type="text"
                name="GSTNumber"
                value={GSTNumber}
                disabled={pageType == 'view'}
                placeholder="Enter GST number"
                autoComplete="new-GSTNumber"
              />
            </Col>

            <Col sm={6}>
              <TextInput
                onInputChange={(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                  const re = /^[a-z A-Z 0-9]*$/;
                  if (!e.target.value || re.test(e.target.value)) {
                    setImportExportCode(e.target.value)
                  }
                }}
                label="Import Export Code(IEC)"
                containerClass="form-group"
                type="text"
                name="importExportCode"
                value={importExportCode}
                disabled={pageType == 'view'}
                placeholder="Enter import export code"
                autoComplete="new-importExportCode"
              />
            </Col>

            {/* <Col sm={6}>
              <TextInput
                onInputChange={(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                  setBusinessDescription(e.target.value)
                }}
                label="Business Description"
                asterisk="*"
                containerClass="form-group"
                type="text"
                as="textarea"
                name="businessDescription"
                value={businessDescription}
                disabled={pageType == 'view'}
                placeholder="Enter business description"
                errorMessage={validator.current.message(
                  "Business Description",
                  businessDescription,
                  "required"
                )}
                autoComplete="new-businessDescription"
              />
            </Col> */}

            {/* <Col sm={6}>
              <Datepicker
                peekNextMonth={true}
                showMonthDropdown={true}
                showYearDropdown={true}
                dropdownMode="select"
                label="Financial Year"
                className="form-control"
                selected={financialYear}
                asterisk="*"
                disabled={pageType == 'view'}
                placeholder="Select financial year"
                dateFormat="yyyy"
                onChange={(e: any) => {
                  setFinancialYear(e);
                }}
                maxDate={new Date()}
                onKeyDown={true}
                // showYearDropdown={true}
                isClearable={financialYear && true}
                errorMessage={validator.current.message(
                  "Financial Year",
                  financialYear,
                  "required"
                )}
              />
            </Col> */}

            {/* <Col sm={6}>
              <TextInput
                onInputChange={(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                  setFinancialDocument(e.target.value)
                }}
                label="Last Audited Finacial Statement"
                asterisk="*"
                containerClass="form-group"
                type="file"
                name="financialDocument"
                value={financialDocument}
                disabled={pageType == 'view'}
                errorMessage={validator.current.message(
                  "Financial Document",
                  financialDocument,
                  "required"
                )}
                autoComplete="new-financialDocument"
              />
            </Col> */}
          </Row>
          {pageType !== 'view' &&
            <Col>
              <div style={{ float: "right" }} className="form-group mt-3">
                <Button
                  type="button"
                  variant="primary"
                  onClick={(e) => {
                    e.preventDefault();
                    onSubmit(e);
                  }}
                  disabled={disableButton}
                >
                  Submit
                </Button>
                <Button
                  onClick={(e) => {
                    e.preventDefault();
                    navigate(-1);
                  }}
                  variant="secondary"
                  className="ms-2"
                >
                  Cancel
                </Button>
              </div>
            </Col>
          }
        </Card.Body>
      </Card>
    </>
  )
}

export default CorporateClientOnboardComponent;