import React, { useState, useEffect } from "react";
import swal from "sweetalert";
import SweetAlert from "react-bootstrap-sweetalert";
import { useNavigate } from "react-router-dom";
import { Card, Col, Row, Button, Accordion, Image } from "react-bootstrap";
import Api from "../../helper/api";
import AuthApi from "../../helper/authApi";
import Loader from "../../components/Loader/index";
import customFunctions from "../../helper/customFunctions";
import Table from "../../components/Table/simpletable.component";
import Breadcrumb from "../../components/Breadcrumb/breadcrumb.component";
import { Helmet, TextInput, DropdownInput } from "../../components/FormInputs";

const ClientMasterListComponent = () => {
  const navigate = useNavigate();

  const [title, setTitle] = useState<any>("Clients");
  const [isShowLoader, setShowLoader] = useState<any>(false);
  const [rows, setRows] = useState<any>([]);
  const [statusToggle, setStatusToggle] = useState<any>(false);
  const [statusId, setStatusId] = useState<any>("");
  const [count, forceUpdate] = useState<any>(0);
  const [filterErr, setFilterErr] = useState<any>("");
  const [searchKeyword, setSearchKeyword] = useState<any>("");
  const [selectedStatus, setSelectedStatus] = useState<any>("");
  const [selectedClientType, setSelectedClientType] = useState<any>("");
  const [applyToggle, setApplyToggle] = useState<any>(false);
  const [roleId, setRoleId] = useState<number>(0);
  const [clientId, setClientId] = useState<any>(null);
  const [currentUserId, setCurrentUserId] = useState<number>(0);
  const [clientData, setClientData] = useState<any>([]);
  const [isShowFilter, setisShowFilter] = useState<boolean>(true);

  const initialPagination: any = {
    currentPage: 1,
    itemsPerPage: 10,
    totalItems: 1,
  };

  const [pagination, setPagination] = useState<any>(initialPagination);

  const items: any = [{ to: "/", label: "Dashboard" }, { label: title }];

  const statusArray: any = [
    {
      label: "Active",
      value: "1",
    },
    {
      label: "Inactive",
      value: "2",
    },
  ];

  const clientTypeArray: any = [
    {
      label: "Proprietor",
      value: 1,
    },
    {
      label: "Corporate",
      value: 2,
    },
    {
      label: "Non-Corporate",
      value: 3,
    },
  ];

  const columns = [
    {
      label: "Sr. No.",
      id: "index",
    },
    {
      label: "Client Name",
      id: "name",
    },
    {
      label: "Email",
      id: "email",
    },
    {
      label: "Client Type",
      id: "client_type",
    },
    {
      label: "PAN Number",
      id: "pan_card_number",
    },
    {
      label: "Contact Number",
      id: "contact_number",
    },
    // {
    //   label: "Document",
    //   id: "financial_statement",
    // },
    {
      label: "Status",
      id: "status",
    },
    {
      label: "Action",
      id: "action",
    },
  ];

  useEffect(() => {
    getUserRole();
  }, []);

  useEffect(() => {
    getClientList(1, true);
  }, [currentUserId]);

  useEffect(() => {
    setTableData();
  }, [clientData]);

  const getUserRole = () => {
    const role: any = customFunctions.getLocalStorage("role_id");
    const admin_access: any = customFunctions.getLocalStorage("admin_access");

    setRoleId(Number(role));
    if (![1, 2].includes(Number(role)) && admin_access == "false") {
      navigate("/login");
    }
    const currentUserId: any = customFunctions.getLocalStorage("id");
    setCurrentUserId(Number(currentUserId));
  };

  const getClientList = async (pageNo?: any, clearFilter?: boolean) => {
    const page: any = pageNo || 1;
    setShowLoader(true);
    setApplyToggle(false);
    const endPoint: any = Api.clientUrl;

    const params: any = {
      page: page,
      limit: pagination.itemsPerPage,
      keyword: !clearFilter && searchKeyword ? searchKeyword.trim() : "",
      client_type:
        !clearFilter && selectedClientType.value
          ? selectedClientType.value
          : "",
      status: !clearFilter && selectedStatus ? selectedStatus.value : "",
      sort_direction: "descending",
    };

    const url: any = customFunctions.generateUrl(endPoint, params);

    const { data, message } = await AuthApi.getDataFromServer(url);
    if (data && data.success === true) {
      setShowLoader(false);
      if (data?.data && !data?.data?.length && clearFilter) {
        setisShowFilter(false);
      }
      if (data && data.data) {
        setPagination({
          itemsPerPage: Number(10),
          currentPage: data.paginator.current_page,
          totalItems: data.paginator.total_count,
        });

        setClientData(data.data);
        forceUpdate(count + 1);
      } else {
        setClientData([]);
      }
    } else {
      setShowLoader(false);
      setClientData([]);
      swal(message, "", "error");
    }
  };

  const setTableData = () => {
    const rowsData: any = [];
    clientData.map((dt: any, i: any) => {
      rowsData.push({
        id: dt.id,
        index: (pagination.currentPage - 1) * pagination.itemsPerPage + (i + 1),
        client_type: customFunctions.capitalizeFirstLetter(
          dt?.client_type_name
        ),
        name: (
          <>
            <a
              href="/"
              className="pl-2 ms-1"
              title="View Dashboard"
              onClick={(e: any) => {
                e.preventDefault();
                setClientId(dt?.id);
                navigate("/client-dashboard", {
                  state: {
                    fromComponent: "clientList",
                    clientId: dt?.id,
                    data: dt,
                  },
                });
              }}
            >
              {`${
                dt && dt.client_name
                  ? customFunctions.capitalizeFirstLetter(dt?.client_name)
                  : null
              } `}
            </a>
          </>
        ),
        services: dt?.service1,
        parent_company_name: dt?.company_name,
        email: dt?.email,
        contact_number: dt?.contact_no_m,
        address: dt?.address,
        pan_card_number: dt?.pan_no,
        financial_statement: (
          <>
            <Image src="/images/notes.svg" title="Download Document" />
          </>
        ),
        engagement: dt?.billing_type_name,
        company_identification_number: dt?.cin_no,
        gst_number: dt?.gst_no,
        import_export_code: dt?.iec_no,
        status: (
          <>
            <div className="switch-check">
              <div
                className="form-check form-switch"
                style={{ marginBottom: "0" }}
              >
                <input
                  className="form-check-input"
                  type="checkbox"
                  role="switch"
                  id="flexSwitchCheckDefault"
                  checked={dt?.client_status == "1" ? true : false}
                  onClick={() => {
                    setStatusToggle(true);
                    setStatusId(dt?.client_status == "2" ? "1" : "2");
                    setClientId(dt?.id);
                  }}
                  title={dt?.client_status == "1" ? "Active" : "Inactive"}
                />
              </div>
            </div>
          </>
        ),
        action: (
          <>
            <a
              href="/"
              className="pl-2 ms-1 underline"
              title={"Edit Client"}
              onClick={(e) => {
                e.preventDefault();
                setClientId(dt?.id);
                navigate("/edit-client", {
                  state: { data: dt, pageType: "edit", clientId: dt?.id },
                });
              }}
            >
              {/* <Image src="images/edit-button.svg" alt="view" /> */}
              Edit
            </a>

            {/* <a
              href="/"
              className="pl-2 ms-1 underline"
              title={"View Dashboard"}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                navigate("/client-dashboard", {
                  state: {
                    fromComponent:"clientList",
                    clientId: dt?.id,
                    data: dt
                  },
                });
              }}
            >
              View
            </a> */}
          </>
        ),
      });
      return dt;
    });
    setRows([...rowsData]);
  };

  const changeStatus = async () => {
    const postData: any = {
      id: Number(clientId),
      client_status: statusId,
    };

    if (!clientId) return;
    setShowLoader(true);
    const endpoint: any = `${Api.clientUrl}${clientId}/`;
    const { data, message } = await AuthApi.putDataToServer(endpoint, postData);

    if (data && data.success === true) {
      setStatusToggle(false);
      swal("Client status changed successfully!", "", "success", {
        closeOnClickOutside: false,
      }).then(() => {
        setClientId(null);
        setStatusId("");
        getClientList(1, true);
      });
    } else {
      setShowLoader(false);
      swal(typeof message == "string" ? message : message[0], "", "error").then(
        () => {
          setClientId(null);
          setStatusId("");
          getClientList(1, true);
        }
      );
    }
  };

  const filterSearch = (e: any) => {
    e.preventDefault();
    let filterSearchErr: string = "";
    let isValid: boolean = true;
    if (
      customFunctions.checkIfEmpty(searchKeyword) &&
      customFunctions.checkIfEmpty(selectedStatus) &&
      customFunctions.checkIfEmpty(selectedClientType)
    ) {
      filterSearchErr =
        "Please enter client name or select client type or select status";
      setFilterErr(filterSearchErr);
      isValid = false;
    }
    if (isValid) {
      setFilterErr("");
      getClientList(1, false);
    }
  };

  const onClear = (e: any) => {
    setFilterErr("");
    setSearchKeyword("");
    setSelectedStatus("");
    setSelectedClientType("");
    setApplyToggle(false);
    
    if (searchKeyword || selectedStatus || selectedClientType) {
      getClientList(1, true);
    }
  };

  return (
    <>
      <>
        <Helmet title={title} />
        <div className="page-header">
          <div className="title-breadcrumb-section">
            <h2 className="main-content-title tx-24 mg-b-5">{title}</h2>
            <Breadcrumb items={items} />
          </div>
        </div>
        <Loader showLoader={isShowLoader} needFullPage={false} />
        {statusToggle && (
          <div className="sweetalert">
            <SweetAlert
              title={"Are you sure? do you want to change the status?"}
              warning
              showCancel
              confirmBtnBsStyle="success"
              cancelBtnBsStyle="danger"
              onConfirm={() => {
                setStatusToggle(false);
                changeStatus();
              }}
              onCancel={() => {
                setStatusToggle(false);
              }}
              reverseButtons={false}
            />
          </div>
        )}
        <section className="content-area">
          {isShowFilter && (
            <Accordion defaultActiveKey="1">
              <Accordion.Item eventKey="1">
                <Accordion.Header>
                  <h5 className="card-title mb-0">Filters</h5>
                </Accordion.Header>
                <Accordion.Body>
                  {filterErr && (
                    <div className="p-3  bg-danger text-white bg-danger-main table-red-bg mb-3">
                      <div className="img-text">
                        <img
                          style={{ cursor: "pointer" }}
                          onClick={(e) => {
                            onClear(e);
                          }}
                          src="/images/fail.svg"
                          alt="Success"
                        />{" "}
                        {filterErr}
                      </div>
                    </div>
                  )}
                  <Row className="mt-3">
                    <Col sm={3}>
                      <TextInput
                        value={searchKeyword}
                        placeholder="Search by client name"
                        onInputChange={(e: any) => {
                          setSearchKeyword(e.target.value);
                        }}
                      />
                    </Col>

                    <Col sm={3}>
                      <DropdownInput
                        placeholder="Select client type"
                        options={clientTypeArray}
                        defaultValue={selectedClientType}
                        onSelectHandler={(e: any) => {
                          setSelectedClientType(e);
                        }}
                      />
                    </Col>

                    <Col sm={3}>
                      <DropdownInput
                        placeholder="Select status"
                        options={statusArray}
                        defaultValue={selectedStatus}
                        onSelectHandler={(e: any) => {
                          setSelectedStatus(e);
                        }}
                      />
                    </Col>

                    <Col sm={3}>
                      <div className="filter-btn pull-right filter-btn-report">
                        <Button
                          id="submit-btn"
                          variant="primary"
                          type="submit"
                          className="ms-4"
                          onClick={(e) => {
                            e.preventDefault();
                            filterSearch(e);
                            setApplyToggle(true);
                          }}
                        >
                          Apply
                        </Button>
                        <Button
                          id="clear-btn"
                          variant="secondary"
                          type="button"
                          className="ms-4"
                          onClick={(e) => {
                            // if (
                            //   searchKeyword ||
                            //   selectedStatus ||
                            //   selectedClientType
                            // ) {
                            onClear(e);
                            // }
                          }}
                        >
                          Clear
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          )}
          <Card className="mt-3">
            <Card.Header>
              <div className="d-flex align-items-center justify-content-end">
                {isShowFilter && (
                  <a
                    style={{ marginRight: "20px" }}
                    id="add-client-btn-link"
                    href="#"
                    className="underline"
                    onClick={(e) => {
                      e.preventDefault();
                      navigate("/client-dashboard", {
                        state: {
                          fromComponent: "",
                        },
                      });
                    }}
                  >
                    Client Dashboard
                  </a>
                )}
                <a
                  id="add-client-btn-link"
                  href="#"
                  className="underline"
                  onClick={(e) => {
                    e.preventDefault();
                    navigate("/create-client", {
                      state: { pageType: "add" },
                    });
                  }}
                >
                  Create Client
                </a>
              </div>
            </Card.Header>
            <Card.Body>
              <Table
                columns={columns}
                // showCheckbox={true}
                rows={rows}
                showPagination
                role={roleId}
                paginationProps={{
                  ...pagination,
                  currentPage: Number(pagination.currentPage),
                  onPageChange: (pageNo: any) => {
                    getClientList(pageNo, false);
                  },
                }}
                dropdownContainerClass="mb-3"
                emptyRender={() => (
                  <p className="text-center mb-0">No record found</p>
                )}
              />
            </Card.Body>
          </Card>
        </section>
      </>
    </>
  );
};

export default ClientMasterListComponent;
