/* eslint no-unneeded-ternary: "error" */
import React from "react";
import { Form, InputGroup, Image } from "react-bootstrap";

export default function InputComponent(props:any) {
  const {
    label,
    placeholder,
    id,
    value,
    onInputChange,
    name,
    inputClass,
    inputIcon,
    type,
    containerClass,
    inputVarible,
    errorMessage,
    disabled,
    multiple,
    rows,
    as,
    asterisk,
    maxlength,
    minlength,
    accept,
    optionalLabel,
    style,
    autoComplete,
    inputEndVarible,
    title,
  } = props;

  return (
    <>
      {label && (
        <Form.Label>
          {" "}
          {label}
          <small>{optionalLabel}</small>{" "}
          <span style={{ color: "red" }}>{asterisk}</span>{" "}
        </Form.Label>
      )}
      <Form.Group className={containerClass}>
        <InputGroup>
          {inputVarible && <InputGroup.Text>{inputVarible}</InputGroup.Text>}
          {inputIcon && <Image src={inputIcon} rounded />}
          <Form.Control
            id={id}
            value={value}
            onChange={(e) => {
              if (onInputChange) onInputChange(e);
            }}
            name={name}
            type={type}
            placeholder={placeholder}
            maxLength={maxlength}
            className={inputClass}
            disabled={disabled}
            multiple={multiple}
            accept={accept}
            rows={rows}
            as={as}
            style={style}
            minLength={minlength}
            autoComplete={autoComplete}
            title={title}
          />
          {inputEndVarible && (
            <InputGroup.Text>{inputEndVarible}</InputGroup.Text>
          )}
        </InputGroup>
        {errorMessage && <p style={{ color: "red" }}> {errorMessage} </p>}
      </Form.Group>
    </>
  );
}
