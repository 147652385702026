/* eslint-disable-line */
import React, { useState, useRef, useEffect } from "react";
import { Row, Col, Card, Form, Button } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";
import swal from "sweetalert";
import { DropdownInput, Helmet, TextInput } from "../../components/FormInputs";
import SimpleReactValidator from "simple-react-validator";
import customFunctions from "../../helper/customFunctions";
import AuthApi from "../../helper/authApi";
import Api from "../../helper/api";
import Loader from "../../components/Loader";
import Breadcrumb from "../../components/Breadcrumb/breadcrumb.component";
import { serviceStatus } from "./constant";

const CreateServiceComponent = () => {
  const navigate: any = useNavigate();
  const { state } = useLocation();

  const validator = useRef(new SimpleReactValidator());

  const [count, forceUpdate] = useState<any>(0);
  const [roleId, setRoleId] = useState<number>(0);
  const [currentUserId, setCurrentUserId] = useState<number>(0);
  const [pageType, setPageType] = useState<string>("add");
  const [title, setTitle] = useState("Create Service");
  const [disableButton, setDisableButton] = useState<boolean>(false);
  const [isShowLoader, setShowLoader] = useState<boolean>(false);
  const [serviceId, setServiceId] = useState<string>("");
  const [service, setService] = useState<string>("");
  const [repeatitionStatus, setRepeatitionStatus] = useState<any>("");
  const [sacNumber, setSacNumber] = useState<string>("");

  useEffect(() => {
    getUserRole();
  }, []);

  const getUserRole = () => {
    const role = customFunctions.getLocalStorage("role_id");
    setRoleId(Number(role));
    const currentUserId = customFunctions.getLocalStorage("id");
    setCurrentUserId(Number(currentUserId));
  };

  const items = [
    {
      to: "/",
      label: "Dashboard",
    },
    {
      to: "/services",
      label: "Services",
    },
    { label: title },
  ];

  useEffect(() => {
    if (state && state.pageType === "add") return;

    if (state?.pageType === "edit") {
      setPageType("edit");
      setTitle("Edit Service");
      if (state?.id && state?.data) {
        setServiceId(state?.id);
        setService(state?.data?.label ? state?.data?.label : "");
        setRepeatitionStatus(
          state?.data?.repeatative_status
            ? {
                label: state?.data?.repeatative_status,
                value: state?.data?.repeatative_status_id,
              }
            : ""
        );
        setSacNumber(state?.data?.sac_code ? state?.data?.sac_code : "");
      }
    }
  }, [state]);

    const addTask = async () => {
      setShowLoader(true);
      const postData: any = {
        name: service ? service :null,
        sac_code: sacNumber ? sacNumber: null,
        status: 1,
        // repetative_status: repeatitionStatus?.value ? repeatitionStatus?.value : null,
      };

      // if (serviceId) {
      //   postData.id = serviceId;
      // }
      const payload = customFunctions.cleanObject(postData);
      const endPoint = serviceId ? `${Api.servicesUrl}${serviceId}/` : `${Api.servicesUrl}`;

      const callback = serviceId
        ? AuthApi.putDataToServer
        : AuthApi.postDataToServer;
      const { data, message } = await callback(endPoint, payload);
      if (data && data.success === true) {
        setShowLoader(false);
        setDisableButton(false);
        swal(
          serviceId ? "Service updated successfully!" : "Service created successfully!",
          "",
          "success"
        ).then(() => {
          navigate(-1);
        });
      } else {
        setDisableButton(false);
        setShowLoader(false);
        swal(typeof message == "string" ? message : message[0], "", "error");
      }
    };

  const onSubmit = (e: any) => {
    e.preventDefault();
    if (validator.current.allValid()) {
        addTask();
    } else {
      validator.current.showMessages();
      forceUpdate(count + 1);
      setDisableButton(false);
    }
  };

  return (
    <>
      <Helmet title={title} />
      <Button
        style={{ float: "right" }}
        className="form-group mt-4"
        type="submit"
        variant="primary"
        onClick={() => {
          navigate(-1);
        }}
      >
        Back
      </Button>
      <div className="page-header">
        <div className="title-breadcrumb-section">
          <h2 className="main-content-title tx-24 mg-b-5">{title}</h2>
          <Breadcrumb items={items} />
        </div>
      </div>
      <Loader showLoader={isShowLoader} needFullPage={false} />
      <section className="content-area">
        <Card>
          <Card.Body>
            <Form
              autoComplete="off"
              onSubmit={(e) => {
                onSubmit(e);
              }}
            >
              <Row>
                <Col sm={4}>
                  <TextInput
                    onInputChange={(e: any) => {
                      const re = /^[a-z A-Z ]*$/;
                      if (!e.target.value || re.test(e.target.value)) {
                        setService(e.target.value);
                      }
                    }}
                    label="Service Name"
                    asterisk="*"
                    containerClass="form-group"
                    type="text"
                    name="Service name"
                    value={service}
                    placeholder="Enter service name"
                    errorMessage={validator.current.message(
                      "Service",
                      service,
                      "required"
                    )}
                  />
                </Col>

                <Col sm={4}>
                  <TextInput
                    onInputChange={(e: any) => {
                      const re = /^[a-z A-Z 0-9 ]*$/;
                      if (!e.target.value || re.test(e.target.value)) {
                        setSacNumber(e.target.value);
                      }
                    }}
                    label="SAC Number"
                    asterisk="*"
                    containerClass="form-group"
                    type="text"
                    maxlength={6}
                    value={sacNumber}
                    placeholder="Enter sac number"
                    errorMessage={validator.current.message(
                      "sac number",
                      sacNumber,
                      "required"
                    )}
                  />
                </Col>

                {/* <Col sm={4}>
                  <DropdownInput
                    label="Repetition Status"
                    asterisk="*"
                    placeholder="Select status"
                    options={serviceStatus}
                    defaultValue={repeatitionStatus}
                    onSelectHandler={(select: any) => {
                      setRepeatitionStatus(select);
                    }}
                    errorMessage={validator.current.message(
                      "status",
                      repeatitionStatus,
                      "required"
                    )}
                    id="selectOption"
                  />
                </Col> */}
              </Row>
              <Row>
                <Col>
                  <div style={{ float: "right" }} className="form-group mt-3">
                    <Button
                      type="submit"
                      variant="primary"
                      disabled={disableButton}
                    >
                      Submit
                    </Button>
                    <Button
                      onClick={(e) => {
                        e.preventDefault();
                        navigate(-1);
                      }}
                      variant="secondary"
                      className="ms-2"
                    >
                      Cancel
                    </Button>
                  </div>
                </Col>
              </Row>
            </Form>
          </Card.Body>
        </Card>
      </section>
    </>
  );
};

export default CreateServiceComponent;
