import React, { useEffect, useState } from "react";
import { Route, useNavigate, Routes } from "react-router-dom";
import { useDispatch } from "react-redux";
import RouterFile from "./routes";
// import CustomFunctions from "./helper/customFunctions";
import Login from "./containers/authentication/Login/login.component";
import ForgetPassword from "./containers/authentication/Forget/forgetPassword.component";
import ResetPassword from "./containers/authentication/Forget/resetPassword.component";
// import { onMessageListener } from "./firebase";
// import ShowNotification from "./components/Notification/ShowNotification";
import TokenVerificationComponent from "./containers/authentication/Forget/tokenVerification.component";
// import OuterClientComponent from "./containers/client/outerClinet.component";
import CompanyRegistrationComponent from "./containers/company/companyRegistration";

function App() {
  const token = localStorage.getItem("access_token");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const path = window.location.pathname.split("/");
  const extractedPath = path.length > 1 ? path[1].toString() : "";
  const checkLoggedIn = () => {
    if (!token || token === null) {
      if (
        extractedPath !== "login" &&
        extractedPath !== "forget-password" &&
        extractedPath !== "company-registration" &&
        extractedPath !== "update-password" &&
        extractedPath !== "reset-password"
      ) {
        navigate("/login");
      }
    }
  };

  useEffect(() => {
    if (!token || token === null) {
      setIsLoggedIn(false);
      // navigate("/login");
      dispatch({ type: "LOGIN", value: false });
      return;
    } else {
      setIsLoggedIn(true);
      dispatch({ type: "LOGIN", value: true });
      return;
    }
  }, [token]);

  useEffect(() => {
    checkLoggedIn();
  });

  return (
    <>
      {isLoggedIn ? (
        <Routes>
          <Route path="*" element={<RouterFile />} />
        </Routes>
      ) : (
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/forget-password" element={<ForgetPassword />} />
          <Route path="/company-registration" element={<CompanyRegistrationComponent />} />
          <Route path="/update-password" element={<ResetPassword />} />
          <Route path="/reset-password" element={<TokenVerificationComponent />} />
        </Routes>
      )}
    </>
  );
}

export default App;
