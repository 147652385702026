import React, { useState, useEffect } from "react";
import customFunctions from "../../helper/customFunctions";
import AuthApi from "../../helper/authApi";
import Api from "../../helper/api";
import swal from "sweetalert";
import { Card, Form, Col, Row, Accordion, Button } from "react-bootstrap";
import Table from "../../components/Table/simpletable.component";
import { DropdownInput, Helmet } from "../../components/FormInputs";
import Breadcrumb from "../../components/Breadcrumb/breadcrumb.component";
import Loader from "../../components/Loader/index";
import SelectAsyncPaginate from "../../components/ReactSelectPagination/SelectAsyncPaginate";

const ApproveTaskList = (props:any) => {
  const [roleId, setRoleId] = useState<number>(0);
  const [currentEmpId, setCurrentUserId] = useState<number>(0);
  const [isShowLoader, setShowLoader] = useState<any>(false);
  const [isApiCall, setIsApiCall] = useState<any>(false);
  const [count, forceUpdate] = useState<any>(0);
  const [approvedTask, setApprovedTask] = useState<any>([]);
  const [rows, setRows] = useState<any>([]);
  const [filterErr, setFilterErr] = useState<any>("");
  const [searchKeyword, setSearchKeyword] = useState<any>("");
  const [selectedEmployee, setSelectedEmployee] = useState<any>("");
  const [clientDataArray, setClientDataArray] = useState<any>([]);
  const [applyToggle, setApplyToggle] = useState<any>(false);
  const [selectedClient, setSelectedClient] = useState<any>("");
  const [employeeDataArray, setEmployeeDataArray] = useState<any>([]);
  const [isShowFilter, setisShowFilter] = useState<boolean>(true);

  const [title, setTitle] = useState<any>("Approved Tasks");
  const items = [{ to: "/", label: "Dashboard" }, { label: "Approved Tasks" }];
  const initialPagination = {
    currentPage: 1,
    itemsPerPage: 10,
    totalItems: 1,
  };
  const [pagination, setPagination] = useState(initialPagination);
  const columns = [
    {
      label: "Sr. No",
      id: "index",
    },
    {
      label: "Client Name",
      id: "client_name",
    },
    {
      label: "Task Name",
      id: "task_name",
    },
    {
      label: "Submitted By",
      id: "name",
      roleAccess: [2, 10],
    },
    {
      label: "Due Date",
      id: "due_date",
    },
    {
      label: "Approved Date",
      id: "date",
    },

    // {
    //   label: "Reason",
    //   id: "note",
    // },
  ];

  useEffect(() => {
    getUserRole();
  }, []);

  const getUserRole = () => {
    const role = customFunctions.getLocalStorage("role_id");
    setRoleId(Number(role));
    const currentUserId = customFunctions.getLocalStorage("id");
    setCurrentUserId(Number(currentUserId));
  };

  useEffect(() => {
    if (roleId == 2 || roleId == 10) {
      // getEmployeeList();
    }
    // getClientList();
  }, [roleId]);

  const getClientList = async () => {
    setShowLoader(true);
    const { data, message } = await AuthApi.getDataFromServer(
      `${Api.clientUrl}?limit=1000`
    );
    if (data && data.success === true) {
      setShowLoader(false);
      let clientData: any = [];
      data &&
        data.data &&
        data.data.length > 0 &&
        data.data.map((item: any) => {
          if (item.client_status == 2) return;
          clientData.push({ label: item?.client_name, value: item.id });
        });
      setClientDataArray(clientData);
    } else {
      setShowLoader(false);
      swal(typeof message === "string" ? message : message[0], "", "error");
    }
  };

  const getEmployeeList = async () => {
    setShowLoader(true);
    const { data, message } = await AuthApi.getDataFromServer(
      `${Api.employeeUrl}?limit=1000`
    );

    if (data && data.success === true) {
      setShowLoader(false);
      let employeeData: any = [];
      data &&
        data.data &&
        data.data.length > 0 &&
        data.data.map((item: any) => {
          if (
            item.user.role == 2 ||
            Number(localStorage.getItem("id")) == item?.user?.user
          )
            return;
          employeeData.push({
            label: `${item?.user.first_name} ${item?.user.last_name}`,
            value: item?.employee?.id,
          });
        });
      setEmployeeDataArray(employeeData);
    } else {
      setShowLoader(false);
      swal(typeof message === "string" ? message : message[0], "", "error");
    }
  };

  useEffect(() => {
    getApprovedTaskData(1, true);
  }, [props,currentEmpId]);

  const getApprovedTaskData = async (pageNo?: any, clearFilter?: boolean) => {
    const page = pageNo || 1;
    setShowLoader(true);
    // setApplyToggle(false);
    const endPoint = Api.taskApprovalUrl;
    const params = {
      page: page,
      sort_direction: "descending",
      limit: pagination.itemsPerPage,
      approved_status: 1,
      employee_id:
        !clearFilter && selectedEmployee?.id ? selectedEmployee?.id : "",
      client_id: !clearFilter && selectedClient?.id ? selectedClient?.id : "",
    };
    const url = customFunctions.generateUrl(endPoint, params);
    const { data, message } = await AuthApi.getDataFromServer(url);

    if (data && data.success == true) {
      setShowLoader(false);
      if (data && data?.data && !data?.data?.length && clearFilter) {
        setisShowFilter(false);
      }

      if (data && data.data && data.data.length > 0) {
        setShowLoader(false);
        setPagination({
          currentPage: data.paginator.current_page,
          itemsPerPage: Number(10),
          totalItems: data.paginator.total_count,
        });
        setApprovedTask(data.data);
        forceUpdate(count + 1);
      } else {
        setApprovedTask([]);
      }
    } else {
      setShowLoader(false);
      setApprovedTask([]);
      swal(message, "", "error");
    }
  };
  useEffect(() => {
    setTableData();
  }, [approvedTask]);

  const setTableData = () => {
    const rowsData: any = [];
    approvedTask.map((dt: any, index: any) => {
      // if(dt.approved_status == 3) return
      rowsData.push({
        index:
          (pagination.currentPage - 1) * pagination.itemsPerPage + (index + 1),
        client_name: dt?.client_name,
        task_name: dt?.task?.task_name
          ? dt?.task?.task_name
          : dt?.litigation?.title,
        date: customFunctions.getDMYDate(dt?.updated_at, "dmy"),
        due_date: dt?.task_due_date
          ? customFunctions.getDMYDate(dt?.task_due_date, "dmy")
          : "-",
        name: dt?.name,
        // note: (
        //   <>
        //     <a
        //       href=""
        //       title="View Note"
        //       onClick={(e: any) => {
        //         e.preventDefault();
        //         setModalData(dt);
        //         setUpdateShow(true);
        //       }}
        //     >
        //       <img src="/images/eye.svg" alt="" />
        //     </a>
        //   </>
        // ),
      });
    });
    setRows([...rowsData]);
  };

  const filterSearch = (e: any) => {
    e.preventDefault();
    let filterSearchErr = "";
    let isValid = true;
    if (
      customFunctions.checkIfEmpty(selectedEmployee) &&
      customFunctions.checkIfEmpty(selectedClient)
    ) {
      filterSearchErr = `Please select client ${
        roleId == 2 || roleId == 10 ? "or employee" : ""
      }`;
      setFilterErr(filterSearchErr);
      isValid = false;
    }
    if (isValid) {
      setFilterErr("");
      getApprovedTaskData(1, false);
    }
  };

  const onClear = (e: any) => {
    setFilterErr("");
    setSearchKeyword("");
    setSelectedEmployee("");
    setSelectedClient("");
    setApplyToggle(false);

    if (selectedClient || selectedEmployee) {
      getApprovedTaskData(1, true);
    }
  };
  return (
    <>
      {roleId == 2 ? (
        <>
          <Helmet title={title} />
          <div className="page-header">
            <div className="title-breadcrumb-section"></div>
          </div>
        </>
      ) : null}
      <Loader showLoader={isShowLoader} needFullPage={false} />
      <section className="content-area mt-3">
        {isShowFilter && (
          <Accordion defaultActiveKey="1">
            <Accordion.Item eventKey="1">
              <Accordion.Header>
                <h5 className="card-title mb-0">Filters</h5>
              </Accordion.Header>
              <Accordion.Body>
                <Form onSubmit={(e) => filterSearch(e)}>
                  {filterErr && (
                    <div className="p-3  bg-danger text-white bg-danger-main table-red-bg mb-3">
                      <div className="img-text">
                        <img
                          style={{ cursor: "pointer" }}
                          onClick={(e) => onClear(e)}
                          src="/images/fail.svg"
                          alt="Success"
                        />{" "}
                        {filterErr}
                      </div>
                    </div>
                  )}
                  <Row className="mt-3">
                    <Col sm={3}>
                      <SelectAsyncPaginate
                        id={"Client"}
                        selectPlaceholder={"Select client"}
                        apiname={"clientUrl"}
                        endpoint={Api.clientUrl}
                        value={selectedClient}
                        onSelectChange={(select: any) => {
                          setSelectedClient(select);
                        }}
                      />
                    </Col>
                    {roleId == 2 || roleId == 10 ? (
                      <Col sm={3}>
                        <SelectAsyncPaginate
                          selectPlaceholder={"Select employee"}
                          value={selectedEmployee}
                          onSelectChange={(select: any) => {
                            setSelectedEmployee(select);
                          }}
                          apiname={"employeeUrl"}
                          endpoint={Api.employeeUrl}
                        />
                      </Col>
                    ) : null}
                    <Col sm={3}>
                      <div className="filter-btn pull-right filter-btn-report">
                        <Button
                          variant="primary"
                          type="submit"
                          className="ms-4"
                          onClick={() => setApplyToggle(true)}
                        >
                          Apply
                        </Button>
                        <Button
                          variant="secondary"
                          type="button"
                          className="ms-4"
                          onClick={(e) => onClear(e)}
                        >
                          Clear
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        )}
      </section>
      <Card className="mt-3">
        <Card.Body>
          <Row>
            <Col>
              {
                <Table
                  columns={columns}
                  showCheckbox={false}
                  rows={rows}
                  showPagination
                  role={roleId}
                  paginationProps={{
                    ...pagination,
                    currentPage: Number(pagination.currentPage),
                    onPageChange: (pageNo: any) => {
                      getApprovedTaskData(pageNo, true);
                    },
                  }}
                  dropdownContainerClass="mb-3"
                  emptyRender={() => (
                    <p className="text-center mb-0">No record found</p>
                  )}
                />
              }
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};

export default ApproveTaskList;
