/* eslint-disable-line */
import React, { useState, useRef, useEffect } from "react";
import { Row, Col, Card, Form, Button } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";
import swal from "sweetalert";
import { DropdownInput, Helmet, TextInput } from "../../components/FormInputs";
import SimpleReactValidator from "simple-react-validator";
import customFunctions from "../../helper/customFunctions";
import AuthApi from "../../helper/authApi";
import Api from "../../helper/api";
import Loader from "../../components/Loader";
import Breadcrumb from "../../components/Breadcrumb/breadcrumb.component";

const CreateTaskComponent = () => {
  const navigate: any = useNavigate();
  const { state } = useLocation();

  const validator = useRef(new SimpleReactValidator());

  const [count, forceUpdate] = useState<any>(0);
  const [roleId, setRoleId] = useState<number>(0);
  const [currentUserId, setCurrentUserId] = useState<number>(0);
  const [pageType, setPageType] = useState<string>("add");
  const [title, setTitle] = useState("Create Task");
  const [disableButton, setDisableButton] = useState<boolean>(false);
  const [isShowLoader, setShowLoader] = useState<boolean>(false);

  const [selectTaskId, setSelectTaskId] = useState<any>();
  const [selectService, setSelectService] = useState<any>();
  const [selectTask, setSelectTask] = useState<any>();
  const [selectOption, setSelectOption] = useState<any>();
  const [servicesArray, setServicesArray] = useState<any>([]);

  const [checkFile, setCheckFile] = useState<any>(false);
  const [checkNote, setCheckNote] = useState<any>(false);
  const [checkOther, setCheckOther] = useState<any>(false);
  const [taskId, setTaskId] = useState<any>();
  const [optionError, setOptionError] = useState<any>("");

  useEffect(() => {
    getUserRole();
  }, []);

  const getUserRole = () => {
    const role = customFunctions.getLocalStorage("role_id");
    setRoleId(Number(role));
    const currentUserId = customFunctions.getLocalStorage("id");
    setCurrentUserId(Number(currentUserId));
  };

  const items = [
    {
      to: "/",
      label: "Dashboard",
    },
    {
      to: "/super-admin-task-list",
      label: "Task List",
    },
    { label: title },
  ];

  useEffect(() => {
    if (state && state.pageType === "add") return;

    setTaskId(state?.data?.id);
    if (state && state) {
      const sName: any = {
        label: state?.data?.services?.services_name,
        value: state?.data?.services?.services,
      };
      const isComplete: any = {
        label: state?.data?.on_complition_doc === true ? "Yes" : "No",
        value: state?.data?.on_complition_doc === true ? "yes" : "no",
      };

      if (state.pageType === "edit") {
        setPageType("edit");
        setTitle("Edit Task");
        setSelectTaskId(state?.data?.index);
      } else {
        setPageType("view");
        setTitle("View Task");
      }
      setSelectService(sName);
      setSelectOption(isComplete);
      setSelectTask(state.data?.task_name);
      setCheckFile(state?.data?.is_file);
      setCheckNote(state?.data?.is_note);
      setCheckOther(state?.data?.is_other);
    }
  }, [state]);

  useEffect(() => {
    getServices();
  }, []);

  const getServices = async () => {
    setShowLoader(true);
    const { data, message } = await AuthApi.getDataFromServer(
      `${Api.servicesUrl}?limit=100`
    );

    if (data && data.success === true) {
      setShowLoader(false);
      let serviceArr: any = [];
      data &&
        data.data.map((item: any) => {
          serviceArr.push({
            label: item?.services,
            value: item?.id,
          });
        });
      setServicesArray(serviceArr);
    } else {
      setShowLoader(false);
      setServicesArray("");
      swal(typeof message === "string" ? message : message[0], "", "error");
    }
  };



  const addTask = async () => {
    // setShowLoader(true);
    const postData: any = {
      task_name: selectTask,
      services: selectService.value,
      on_complition_doc: selectOption.value === "yes" ? true : false,
      is_note: checkNote,
      is_file: checkFile,
      is_other: checkOther,
    };

    if (taskId) {
      postData.id = taskId;
    }
    const payload = customFunctions.cleanObject(postData);
    const endPoint = taskId ? `${Api.taskUrl}${taskId}/` : `${Api.taskUrl}`;

    const callback = taskId
      ? AuthApi.putDataToServer
      : AuthApi.postDataToServer;
    const { data, message } = await callback(endPoint, payload);
    if (data && data.success === true) {
      setShowLoader(false);
      setDisableButton(false);
      swal(
        taskId ? "Task updated successfully!" : "Task created successfully!",
        "",
        "success"
      ).then(() => {
        navigate(-1);
      });
    } else {
      setDisableButton(false);
      setShowLoader(false);
      swal(typeof message == "string" ? message : message[0], "", "error");
    }
  };

  const onSubmit = (e: any) => {
    e.preventDefault();
    if (disableButton) return;
    let isValid = true;
    if (selectOption?.value === "yes" && (!checkFile && !checkNote && !checkOther)) {
      setOptionError('Please select option')
      isValid = false;
    }
    setDisableButton(true);
    if (validator.current.allValid() && isValid) {      
      addTask();
    } else {
      validator.current.showMessages();
      forceUpdate(count + 1);
      setDisableButton(false);
    }
  };

  return (
    <>
      <Helmet title={title} />
        <Button
          style={{ float: "right" }}
          className="form-group mt-4"
          type="submit"
          variant="primary"
          onClick={() => {
            navigate(-1);
          }}
        >
          Back
        </Button>
      <div className="page-header">
        <div className="title-breadcrumb-section">
          <h2 className="main-content-title tx-24 mg-b-5">{title}</h2>
          <Breadcrumb items={items} />
        </div>
      </div>
      <Loader showLoader={isShowLoader} needFullPage={false} />
      <section className="content-area">
        <Card>
          <Card.Body>
            <Form
              autoComplete="off"
              onSubmit={(e) => {
                onSubmit(e);
              }}
            >
              <Row>
                <Col sm={4}>
                  <DropdownInput
                    label="Service Name"
                    asterisk="*"
                    placeholder="Select service"
                    disabled={pageType === "view"}
                    options={servicesArray}
                    defaultValue={selectService}
                    onSelectHandler={(select: any) => {
                      setSelectService(select);
                      // getClientTask(select.value);
                    }}
                    isClearable={true}
                    errorMessage={validator.current.message(
                      "service name",
                      selectService,
                      "required"
                    )}
                    id="selectService"
                  />
                </Col>
                
                <Col sm={4}>
                  <TextInput
                    onInputChange={(
                      e: React.ChangeEvent<
                        HTMLTextAreaElement | HTMLInputElement
                      >
                    ) => {
                      const re = /^[a-z A-Z : 0-9]*$/;
                      if (!e.target.value || re.test(e.target.value)) {
                        setSelectTask(e.target.value);
                      }
                    }}
                    label="Task Name"
                    asterisk="*"
                    containerClass="form-group"
                    type="text"
                    name="task name"
                    disabled={pageType === "view"}
                    value={selectTask}
                    // maxlength={12}
                    placeholder="Enter task name"
                    errorMessage={validator.current.message(
                      "task name",
                      selectTask,
                      "required"
                    )}
                    autoComplete="new-selectTask"
                  />
                </Col>

                <Col sm={4}>
                  <DropdownInput
                    label="Complete Options"
                    asterisk="*"
                    placeholder="Select options"
                    disabled={pageType === "view"}
                    // isMulti
                    options={[
                      {
                        label: "Yes",
                        value: "yes",
                      },
                      {
                        label: "No",
                        value: "no",
                      },
                    ]}
                    defaultValue={selectOption}
                    onSelectHandler={(select: any) => {
                      if(select && select?.value == "no"){
                        setCheckFile(false);
                        setCheckNote(false);
                      }
                      setSelectOption(select);
                    }}
                    isClearable={true}
                    errorMessage={validator.current.message(
                      "option",
                      selectOption,
                      "required"
                    )}
                    id="selectOption"
                  />
                </Col>
              </Row>
              {selectOption?.value == "yes" && (
                <Row>
                  <Col sm={4}>
                    <div className="d-flex justify-content-between">
                      <div className="form-check mt-2">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value={checkFile}
                          checked={checkFile}
                          disabled={pageType === "view"}
                          id="flexCheckDefault"
                          onChange={(e) => {
                            if (e.target.checked == true) {
                              setOptionError('')
                            }
                            setCheckFile(e.target.checked)
                          }}
                        />
                        <div>
                          <label
                            className="form-check-label"
                            htmlFor="flexCheckDefault"
                          >
                            File
                          </label>
                        </div>
                      </div>

                      <div className="form-check mt-2">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value={checkNote}
                          checked={checkNote}
                          disabled={pageType === "view"}
                          id="flexCheckDefault1"
                          onChange={(e) => {
                            if (e.target.checked === true) {
                              setOptionError('')
                            }
                            setCheckNote(e.target.checked)
                          }}
                        />
                        <div>
                          <label
                            className="form-check-label"
                            htmlFor="flexCheckDefault1"
                          >
                            Note
                          </label>
                        </div>
                      </div>
                    </div>
                    <span className="text-danger" >{optionError}</span>
                  </Col>
                </Row>
              )}
              {pageType !== "view" && (
                <Row>
                  <Col>
                    <div style={{ float: "right" }} className="form-group mt-3">
                      <Button
                        type="submit"
                        variant="primary"
                        disabled={disableButton}
                      >
                        Submit
                      </Button>
                      <Button
                        onClick={(e) => {
                          e.preventDefault();
                          navigate(-1);
                        }}
                        variant="secondary"
                        className="ms-2"
                      >
                        Cancel
                      </Button>
                    </div>
                  </Col>
                </Row>
              )}
            </Form>
          </Card.Body>
        </Card>
      </section>
    </>
  );
};

export default CreateTaskComponent;
