import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Form, Button, Row, Col, Card } from "react-bootstrap";
import SimpleReactValidator from "simple-react-validator";
import swal from "sweetalert";
import AuthApi from "../../helper/authApi";
import Api from "../../helper/api";
import { PasswordInput, Helmet } from "../../components/FormInputs";
import Breadcrumb from "../../components/Breadcrumb/breadcrumb.component";
import customFunctions from "../../helper/customFunctions";
import Loader from "../../components/Loader/index";

const ChangePassword = () => {
  const validator = useRef(
    new SimpleReactValidator({
      messages: {
        in: "New password and confirm password is not matching",
      },
    })
  );
  const [password, setPassword] = useState<any>("");
  const [oldPassword, setOldPassword] = useState<any>("");
  const [confirmPassword, setConfirmPassword] = useState<any>("");
  const [count, forceUpdate] = useState<any>(0);
  const [disableButton, setDisableButton] = useState<boolean>(false);
  const [isShowLoader, setShowLoader] = useState<boolean>(false);
  const [roleId, setRoleId] = useState<any>(0);
  const [currentUserId, setCurrentUserId] = useState<any>(0);
  const navigate = useNavigate();

  useEffect(() => {
    getUserRole();
  }, []);

  const getUserRole = () => {
    const role = customFunctions.getLocalStorage("role_id");
    setRoleId(Number(role));
    const currentUserId = customFunctions.getLocalStorage("id");
    setCurrentUserId(Number(currentUserId));
    // const emailId = customFunctions.getLocalStorage("email");
    // setEmail(emailId);
  };

  const items = [{
    to: '/', label: 'Dashboard'
  }, { label: "Change Password" }];


  const setNewPassword = async () => {
    setShowLoader(true);
    const payload = {
      old_password: oldPassword,
      password: password,
      confirm_password: confirmPassword,
    };

    const postData = customFunctions.cleanObject(payload);

    const { data, message } = await AuthApi.postDataToServer(
      `${Api.changePasswordUrl}`,
      postData
    );

    if (data) {
      setShowLoader(false);
      swal(data?.message[0], "", "success"
      ).then((result) => {
        if (result) {
          setDisableButton(false);
          validator.current.hideMessages();
          navigate("/");
        }
      });
    } else {
      setShowLoader(false);
      swal(typeof message === "string" ? message : message[0], "", "error");
      setDisableButton(false);
    }
  };

  const validateForm = (e: any) => {
    e.preventDefault();

    if (validator.current.allValid()) {
      setNewPassword();
    } else {
      validator.current.showMessages();
      forceUpdate(count + 1);
      setDisableButton(false);
    }
  };

  return (
    <>
      <Helmet title={"Change Password"} />
      <div className="page-header">
        <div className="title-breadcrumb-section">
          <h2 className="main-content-title tx-24 mg-b-5">Change Password</h2>
          <Breadcrumb items={items} />
        </div>
      </div>
      <Loader showLoader={isShowLoader} needFullPage={false} />
      <section className="content-area">
        <div className="">
          <Card>
            <Card.Header>
              <h6 className="section-title mt-1 mb-1">Change Password</h6>
            </Card.Header>
            <Card.Body>
              <Form autoComplete="off" onSubmit={(e) => validateForm(e)}>
                <Row>
                  <Col className="col-md-4">
                    <PasswordInput
                      onInputChange={(e: any) => setOldPassword(e.target.value)}
                      name="oldPassword"
                      containerClass="form-group"
                      label="Old Password"
                      minlength="6"
                      value={oldPassword}
                      type="password"
                      asterisk="*"
                      placeholder="Please enter old password"
                      errorMessage={validator.current.message(
                        "oldPassword",
                        oldPassword,
                        "required",
                        {
                          className: "text-danger",
                        }
                      )}
                      switchElement="true"
                    />
                  </Col>
                  <Col className="col-md-4">
                    <PasswordInput
                      onInputChange={(e: any) => setPassword(e.target.value)}
                      name="password"
                      containerClass="form-group"
                      inputClass="form-control"
                      label="New Password"
                      asterisk="*"
                      value={password}
                      type="password"
                      minlength="6"
                      placeholder="Please enter new password"
                      errorMessage={validator.current.message(
                        "New Password",
                        password,
                        "required",
                        {
                          className: "text-danger",
                        }
                      )}
                      switchElement="true"
                    />
                  </Col>
                  <Col className="col-md-4">
                    <PasswordInput
                      onInputChange={(e: any) => setConfirmPassword(e.target.value)}
                      name="confirmPassword"
                      containerClass="form-group"
                      inputClass="form-control"
                      label="Confirm Password"
                      asterisk="*"
                      value={confirmPassword}
                      type="password"
                      minlength="6"
                      placeholder="Please enter confirm password"
                      errorMessage={validator.current.message(
                        "confirmPassword",
                        confirmPassword,
                        `required|in:${password}`,
                        {
                          className: "text-danger",
                        }
                      )}
                      switchElement="true"
                    />
                  </Col>
                </Row>
                <div className="d-flex align-items-center justify-content-end">
                  <Button
                    variant="primary"
                    type="submit"
                    disabled={disableButton}
                  >
                    Submit
                  </Button>
                  <Button
                    variant="secondary"
                    className="ms-4"
                    onClick={(e) => navigate(-1)}
                  >
                    Cancel
                  </Button>
                </div>
              </Form>
            </Card.Body>
          </Card>
        </div>
      </section>
    </>
  );
};
export default ChangePassword;
