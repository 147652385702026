import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";
import {
  Col,
  Row,
  Button,
  Card,
  DropdownButton,
  Dropdown,
  NavDropdown,
  Form,
} from "react-bootstrap";
import { Helmet, DropdownInput } from "../../../components/FormInputs";
import Loader from "../../../components/Loader/index";
import AuthApi from "../../../helper/authApi";
import Api from "../../../helper/api";
import InvoiceListComponent from "./invoiceListComponent";
import RevenueChartComponent from "./revenueGraphComponent";
import InvoicesPerTypeComponent from "./totalInvoicePerTypeComponent";
import customFunctions from "../../../helper/customFunctions";
import RecentlyTaskApprovalComponent from "./recentlyApprovalTask";
import { yearMonths } from "../../../helper/yearMonths";

const NewAdminDashboardComponent = () => {
  const navigate = useNavigate();
  const currentMonth = new Date().getMonth() + 1;
  const currentSixMonths = yearMonths.slice(currentMonth + 3, currentMonth + 9);
  const title = "Dashboard";

  const [show, updateShow] = useState<boolean>(false);
  const [isShowLoader, setShowLoader] = useState<any>(false);
  const [clientData, setClientData] = useState<any>("");
  const [invoiceData, setInvoiceData] = useState<any>("");
  const [serviceData, setServiceData] = useState<any>("");
  const [revenueData, setRevenueData] = useState<any>("");
  const [isHideDropdown, setIsHideDropdown] = useState<any>(false);
  const [roleId, setRoleId] = useState<number>(0);
  const [isHideRevenueGraph, setIsHideRevenueGraph] = useState<any>(false);
  const [selectedFinancialMonth, setSelectedFinancialMonth] = useState<any>("");
  const [financialSixMonths, setFinancialSixMonths] = useState<any>([]);
  const [indexes, setIndexes] = useState<any>({
    first: `${currentMonth - 9}`,
    last: `${currentMonth - 3}`,
    // first: `${currentMonth + 3}`,
    // last: `${currentMonth + 9}`,
  });

  const [selectedFinancialYear, setSelectedFinancialYear] = useState<any>({
    label: `${new Date().getFullYear()}-${new Date().getFullYear() + 1}`,
    value: `${new Date().getFullYear()}-${new Date().getFullYear() + 1}`,
    fromYear: `${new Date().getFullYear()}`,
    toYear: `${new Date().getFullYear() + 1}`,
  });

  const [count, setCount] = useState<any>({
    clients: 0,
    employees: 0,
    services: 0,
    approvalTask: 0,
    earning: 0,
  });

  let startYear = 2023;
  let prevYear = 2022;
  const month = new Date().getMonth();
  const currentDate = new Date().getDate();
  const length = month == 3 && currentDate == 1 ? 2 : 2;
  const financialYear: any = [];
  for (let i = 0; i < length; i++) {
    startYear = startYear + 1;
    prevYear = prevYear + 1;
    financialYear.push({
      value: `${prevYear}-${startYear}`,
      label: `${prevYear}-${startYear}`,
      fromYear: `${prevYear}`,
      toYear: `${startYear}`,
    });
  }

  useEffect(() => {
    const role = customFunctions.getLocalStorage("role_id");
    setRoleId(Number(role));
    updateCurrentSixMonths();
  }, []);

  const monthsName = [
    {
      label: "April",
      value: 4,
      index: 0,
    },
    {
      label: "May",
      value: 5,
      index: 1,
    },
    {
      label: "June",
      value: 6,
      index: 2,
    },
    {
      label: "July",
      value: 7,
      index: 3,
    },
    {
      label: "August",
      value: 8,
      index: 4,
    },
    {
      label: "September",
      value: 9,
      index: 5,
    },
    {
      label: "October",
      value: 10,
      index: 6,
    },
    {
      label: "November",
      value: 11,
      index: 7,
    },
    {
      label: "December",
      value: 12,
      index: 8,
    },
    {
      label: "January",
      value: 1,
      index: 9,
    },
    {
      label: "February",
      value: 2,
      index: 10,
    },
    {
      label: "March",
      value: 3,
      index: 11,
    },
  ];

  const updateCurrentSixMonths = () => {
    const currentDateValue = new Date();
    const currentMonthIndex = currentDateValue.getMonth();

    const currentMonthValue: any = monthsName.find(
      (element) => element?.value == currentMonthIndex + 1
    );
    const sliceData = monthsName.slice(0, currentMonthValue?.index + 1);

    const updateData = sliceData.slice(
      sliceData?.length > 6 ? sliceData?.length - 6 : 0,
      currentMonthValue?.index + 1
    );

    const totalSixMonthsName: any = [];
    updateData &&
      updateData?.map((item: any) => {
        if (item?.label) {
          totalSixMonthsName.push(item?.label);
        }
      });
    setFinancialSixMonths(totalSixMonthsName);
  };

  useEffect(() => {
    monthsName?.filter((item: any) => {
      if (item?.value == new Date().getMonth() + 1) {
        setSelectedFinancialMonth(item);
      }
    });
  }, []);

  useEffect(() => {
    if (selectedFinancialYear?.value) {
      if (
        [2, 6].includes(roleId) ||
        customFunctions.getLocalStorage("admin_access") == "true"
      ) {
        getRevenueData();
      }
    }
  }, [selectedFinancialYear?.value, roleId]);

  useEffect(() => {
    if (selectedFinancialYear?.value) {
      if (
        [2, 6].includes(roleId) ||
        customFunctions.getLocalStorage("admin_access") == "true"
      ) {
        getAdminDashboard();
      }
    }
  }, [selectedFinancialYear?.value, roleId]);

  const getAdminDashboard = async () => {
    setShowLoader(true);
    const endpoint: any = `${Api.adminDashboardUrl}`;

    const params = {
      from_financial_year:
        selectedFinancialYear && selectedFinancialYear?.fromYear
          ? selectedFinancialYear?.fromYear
          : "",
      to_financial_year:
        selectedFinancialYear && selectedFinancialYear?.toYear
          ? selectedFinancialYear?.toYear
          : "",
    };

    const url: any = customFunctions.generateUrl(endpoint, params);

    const { data, message } = await AuthApi.getDataFromServer(url);

    if (data && data.success === true) {
      setShowLoader(false);
      setClientData({
        Proprietor:
          data?.data?.data?.financial_year_data?.year_clients_count
            ?.year_proprietor_clients_count,
        Corporate:
          data?.data?.data?.financial_year_data?.year_clients_count
            ?.year_corporate_clients_count,
        NonCorporate:
          data?.data?.data?.financial_year_data?.year_clients_count
            ?.year_non_corporate_clients_count,
      });

      setInvoiceData({
        Pending: data?.data?.financial_year_data?.year_unpaid_invoices_count,
        Paid: data?.data?.financial_year_data?.year_paid_invoices_count,
        Overdue: data?.data?.financial_year_data?.year_overdue_invoices_count,
        draft: data?.data?.financial_year_data?.year_draft_invoice_count,
        unpaid: data?.data?.financial_year_data?.year_unpaid_invoices_count,
      });

      setCount({
        approvalTask:
          data?.data?.financial_year_data?.year_tasks_approval_count
            ?.year_tasks_for_approval_count,
        earning: data?.data?.financial_year_data?.paid_revenue,
        clients:
          data?.data?.financial_year_data?.year_clients_count
            ?.year_total_clients_count,
        employees: data?.data?.financial_year_data?.year_total_employees_count,
        services: 0,
      });

      setServiceData(
        data?.data?.financial_year_data?.year_clients_count_by_services
      );
    } else {
      setShowLoader(false);
      swal(typeof message == "string" ? message : message[0], "", "error");
    }
  };

  const getRevenueData = async () => {
    setShowLoader(true);
    const endpoint: any = `${Api.adminDashboardUrl}`;

    const params = {
      from_financial_year:
        selectedFinancialYear && selectedFinancialYear?.fromYear
          ? selectedFinancialYear?.fromYear
          : "",
      to_financial_year:
        selectedFinancialYear && selectedFinancialYear?.toYear
          ? selectedFinancialYear?.toYear
          : "",
    };

    const url: any = customFunctions.generateUrl(endpoint, params);

    const { data, message } = await AuthApi.getDataFromServer(url);

    if (data && data.success === true) {
      setShowLoader(false);
      setRevenueData(data?.data?.financial_year_data);
    } else {
      setShowLoader(false);
      swal(typeof message == "string" ? message : message[0], "", "error");
    }
  };

  return (
    <>
      <Helmet title={title} />
      <Loader showLoader={isShowLoader} needFullPage={false} />
      <div className="page-header mb-3 d-flex align-items-end justify-content-between">
        <div className="title-breadcrumb-section">
          <h2 className="main-content-title tx-24 mg-b-5">Dashboard</h2>
          Welcome To Dashboard
        </div>

        <center>
          <div className="">
            <center>
              <b>
                <label>Financial Year</label>
              </b>
            </center>
            <div className="">
              <DropdownInput
                className=""
                id="degignation"
                placeholder="Select financial Year"
                options={financialYear}
                defaultValue={selectedFinancialYear}
                onSelectHandler={(select: any) => {
                  setSelectedFinancialYear(select);
                }}
              />
            </div>
          </div>
        </center>
      </div>
      <section className="content-area overview-new">
        <Row>
          {customFunctions.getLocalStorage("admin_access") == "true" ? (
            <>
              <Col className="col-xl-3 col-md-3 col">
                <Card className="widget-flat bg-primary text-white">
                  <Card.Body>
                    <center>
                      <h5 className="text-white fw-normal mt-3">EARNINGS</h5>
                      <h3 className="mt-3 mb-3">
                        {count?.earning ? count?.earning : 0}
                      </h3>
                    </center>
                  </Card.Body>
                </Card>
              </Col>

              <Col className="col-xl-3 col-md-3 col">
                <Card className="widget-flat bg-warning text-white">
                  <Card.Body>
                    <center>
                      <h5 className="text-white fw-normal mt-3">CLIENTS</h5>
                      <h3 className="mt-3 mb-3">
                        {count?.clients ? count?.clients : 0}
                      </h3>
                    </center>
                  </Card.Body>
                </Card>
              </Col>
              <Col className="col-xl-3 col-md-3 col">
                <Card className="widget-flat bg-success text-white">
                  <Card.Body>
                    <center>
                      <h5 className="text-white fw-normal mt-3">EMPLOYEES</h5>
                      <h3 className="mt-3 mb-3">
                        {count?.employees ? count?.employees : 0}
                      </h3>
                    </center>
                  </Card.Body>
                </Card>
              </Col>
              <Col className="col-xl-3 col-md-3 col">
                <Card className="widget-flat task-count text-white">
                  <Card.Body>
                    <center>
                      <h5 className="text-white fw-normal mt-3">
                        APPROVAL TASKS
                      </h5>
                      <h3 className="mt-3 mb-3 ">
                        {count?.approvalTask ? count?.approvalTask : 0}
                      </h3>
                    </center>
                  </Card.Body>
                </Card>
              </Col>
            </>
          ) : (
            ""
          )}
        </Row>

        <Row className="">
          <Col sm={7}>
            <Card className="">
              <Card.Header>
                <center>
                  <b className="card-title">
                    VIRTUAL REPRESENTATION OF REVENUE
                  </b>
                </center>
              </Card.Header>
              <Card.Body>
                <Row>
                  <Col sm={8}></Col>

                  <Col sm={4}>
                    <DropdownInput
                      placeholder="Upto month"
                      options={monthsName}
                      defaultValue={selectedFinancialMonth}
                      onSelectHandler={(select: any) => {
                        if (select) {
                          const allMonthsname = monthsName;
                          const sliceData = allMonthsname.slice(
                            0,
                            select?.index + 1
                          );
                          const updateData = sliceData.slice(
                            sliceData?.length > 6 ? sliceData?.length - 6 : 0,
                            select?.index + 1
                          );
                          const totalSixMonthsName: any = [];
                          updateData &&
                            updateData?.map((item: any) => {
                              if (item?.label) {
                                totalSixMonthsName.push(item?.label);
                              }
                            });
                          
                          setFinancialSixMonths(totalSixMonthsName);
                          const firstIndex =
                            sliceData?.length > 6 ? sliceData?.length - 6 : 0;
                          const lastIndex = select?.index + 1;

                          setIndexes({
                            first: firstIndex.toString(),
                            last: lastIndex.toString(),
                          });
                        } else {
                          // setFinancialSixMonths([...currentSixMonths]);
                          updateCurrentSixMonths();
                          setIndexes({
                            first: `${currentMonth - 9}`,
                            last: `${currentMonth - 3}`,
                          });
                        }
                        setSelectedFinancialMonth(select);
                      }}
                    />
                  </Col>
                </Row>
                <RevenueChartComponent
                  year={selectedFinancialYear}
                  revenueData={revenueData}
                  indexes={indexes}
                  financialSixMonths={
                    financialSixMonths?.length ? financialSixMonths : []
                  }
                  callback={(isHideTable: any) => {
                    setIsHideRevenueGraph(isHideTable);
                  }}
                />
              </Card.Body>
            </Card>
          </Col>
          <Col sm={5}>
            <Card
            // style={{
            //   height:
            //     invoiceData?.Paid == 0 &&
            //     invoiceData?.unpaid == 0 &&
            //     invoiceData?.Overdue == 0
            //       ? "385px"
            //       : "",
            // }}
            >
              <Card.Header>
                <center>
                  <b className="card-title">INVOICE STATUS</b>
                </center>
              </Card.Header>
              <Card.Body>
                <InvoicesPerTypeComponent invoiceData={invoiceData} />
              </Card.Body>
            </Card>
          </Col>
        </Row>

        {roleId == 2 ||
        customFunctions.getLocalStorage("admin_access") == "true" ? (
          <Row>
            <Col sm={7}>
              <InvoiceListComponent />
            </Col>
            <Col sm={5}>
              <RecentlyTaskApprovalComponent />
            </Col>
          </Row>
        ) : (
          ""
        )}
      </section>
    </>
  );
};
export default NewAdminDashboardComponent;
