import React, { useState, useRef, useEffect } from "react";
import { Card, Form, Row, Col, Button } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import swal from "sweetalert";
import Breadcrumb from "../../components/Breadcrumb/breadcrumb.component";
import { DropdownInput, Helmet, TextInput, Datepicker } from "../../components/FormInputs";
import { taskData } from "../../helper/tasks";
import { taskStatusData } from "../../helper/taskStatus";
import SimpleReactValidator from "simple-react-validator";
import customFunctions from "../../helper/customFunctions";
import Api from "../../helper/api";
import AuthApi from "../../helper/authApi";
import TaskTimelog from "../EmployeeViewTask/taskTimelog";

interface postTaskInterface {
  id?: any;
  selectTask?: string;
  desription?: string;
  totalTime?: any;
  progress?: string;
  date?: any;
}

const SelectTaskComponent = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [selectTask, setSelectTask] = useState<string>("");
  const [desription, setDescription] = useState<string>("");
  const [totalTime, setTotalTime] = useState<string>("");
  const [progress, setProgress] = useState<string>("");
  const [date, setDate] = useState<any>(new Date());
  const [roleId, setRoleId] = useState<number>(0);

  const [label, setLabel] = useState<string>("");
  const [title, setTitle] = useState<string>("");
  const validator = useRef(new SimpleReactValidator());
  const [disableButton, setDisableButton] = useState<boolean>(false);
  const [count, forceUpdate] = useState<number>(0);
  const [isShowLoader, setShowLoader] = useState(false);

  const [path,setPath]=useState<any>()

  useEffect(() => {
    const role = customFunctions.getLocalStorage("role_id");
    setRoleId(Number(role));
  }, [])

  useEffect(() => {

    if (location && location?.state?.pageType === "add") {
      let selectTask: any = {
        label: location.state.data.Task_name,
        value: location.state.data.Task_name,
      };
      setSelectTask(selectTask);
      setLabel("Log Time On Task");
      setTitle("Log Time On Task");
    }

    if (location && location?.state?.pageType === "edit time log") {
      let selectTask: any = {
        label: location.state.data1.data.Task_name,
        value: location.state.data1.data.Task_name,
      };
      setSelectTask(selectTask);

      let progress: any = {
        label: location.state?.data1?.data?.Task_status,
        value: location.state?.data1?.data?.Task_status,
      };
      setProgress(progress);

      // let bPath:any={
      //   label: location.state?.path,
      //   value: location.state?.path,
      // }
      // setPath(bPath)
      setTotalTime(location.state.data.time_spend);
      setLabel("Edit Log Time");
      setTitle("Edit Log Time");
    }
  }, []);

  const items: any = [
    { to: "/", label: "Dashboard" },
    { to: [1, 2, 3, 10].includes(roleId) ? "/timesheet" : "/view-task", label: "Task List" },
    // { to: "/view-task", label: "Task List" },
    { label: label },
  ];

  const addTask = async () => {
    // setShowLoader(true);
    const postTask: postTaskInterface = {
      selectTask: selectTask,
      totalTime: totalTime,
      progress: progress,
      date: date,
      desription: desription,
    };
    // if (location.state) {
    //     postTask.id = location.state.taskId;
    // }
    // const payload = customFunctions.cleanObject(postTask);
    // const endPoint = `${Api.doctorUrl}`;
    // const callback =
    // location.state && location.state.taskId
    //         ? AuthApi.putDataToServer
    //         : AuthApi.postDataToServer;
    // const { data, message } = await callback(endPoint, payload);
    // if (data && data.success === true) {
    //     setShowLoader(false);
    //     setDisableButton(false);
    //     swal(
    //         location.state && location.state.taskId
    //         ? "Task Updated successfully!"
    //         : "Task Added successfully!",
    //       "",
    //       "success"
    //     ).then(() => {
    //       navigate(-1);
    //     });
    //   } else {
    //     setDisableButton(false);
    //     setShowLoader(false);
    //     swal(message, "", "error");
    //   }
    swal("Task updated succesfully", "", "success").then(() => {
      navigate(-1);
    });
  };

  const isValidate = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (disableButton) return;
    setDisableButton(true);
    if (validator.current.allValid()) {
      addTask();
    } else {
      setDisableButton(false);
      validator.current.showMessages();
      forceUpdate(count + 1);
    }
  };

  // const cancelTask = (e: React.MouseEvent<HTMLButtonElement>) => {
  //     e.preventDefault();
  //     swal({
  //         title: "Are you sure?",
  //         text: "Do you want to cancel a task?",
  //         icon: "warning",
  //         // buttons: true,
  //         dangerMode: true,
  //     }).then((rejectTaskForNow) => {
  //         if (rejectTaskForNow) {
  //             swal("Task Cancel Successfully!", '', 'success')
  //         }
  //     }).then(() => {
  //         navigate("/view-task")
  //     })
  // }

  return (
    <>
      <Helmet title={title} />
      <div className="page-header">
        <div className="title-breadcrumb-section">
          <h2 className="main-content-title tx-24 mg-b-5">{title}</h2>
          <Breadcrumb items={items} />
        </div>
      </div>
      <section className="content-area"></section>
      <Card>
        <Card.Body>
          <Form>
            <Row>
              <Col sm="6">
                <DropdownInput
                  label="Task Name"
                  asterisk="*"
                  placeholder="Select task"
                  options={taskData}
                  disabled
                  defaultValue={selectTask}
                  onSelectHandler={(select: any) => {
                    setSelectTask(select);
                  }}
                  isClearable={true}
                  errorMessage={validator.current.message(
                    "selectTask",
                    selectTask,
                    "required"
                  )}
                  id="selectTask"
                />
              </Col>

              <Col sm="6">
                <TextInput
                  onInputChange={(
                    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
                  ) => {
                    const re = /^[0-9]*$/;
                    if (!e.target.value || re.test(e.target.value)) {
                      setTotalTime(e.target.value);
                    }
                  }}
                  label="Total Time(in hrs)"
                  asterisk="*"
                  containerClass="form-group"
                  type="text"
                  name="totalTime"
                  value={totalTime}
                  // disabled={state && state.status == 4}
                  placeholder="Enter total time"
                  errorMessage={validator.current.message(
                    "totalTime",
                    totalTime,
                    "required|numeric"
                  )}
                  autoComplete="new-totalTime"
                />
              </Col>

              <Col sm="6">
                <DropdownInput
                  label="Progress/Status"
                  asterisk="*"
                  placeholder="Select task status"
                  options={taskStatusData}
                  defaultValue={progress}
                  // defaultValue="Data"
                  onSelectHandler={(select: any) => {
                    setProgress(select);
                  }}
                  isClearable={true}
                  errorMessage={validator.current.message(
                    "task status",
                    progress,
                    "required"
                  )}
                  id="progress"
                />
              </Col>

              <Col sm={6}>
                <Datepicker
                  peekNextMonth={true}
                  showMonthDropdown={true}
                  showYearDropdown={true}
                  dropdownMode="select"
                  label="Date"
                  // disabled={pageType === "view"}
                  className="form-control"
                  selected={date}
                  value={date}
                  asterisk="*"
                  // disabled={[5, 6].includes(roleId) || referralStatus == "1"}
                  placeholder="Select date"
                  dateFormat="dd/MM/yyyy"
                  onChange={(e: any) => {
                    setDate(e);
                  }}
                  maxDate={new Date()}
                  // onKeyDown={true}
                  // showYearDropdown={true}
                  // isClearable={dateOfBirth && true}
                  errorMessage={validator.current.message(
                    "date",
                    date,
                    "required"
                  )}
                />
              </Col>

              <Col sm="6">
                <TextInput
                  onInputChange={(
                    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
                  ) => {
                    const re = /^[A-Z a-z 0-9]*$/;
                    if (!e.target.value || re.test(e.target.value)) {
                      setDescription(e.target.value);
                    }
                  }}
                  label="Description"
                  // asterisk="*"
                  containerClass="form-group"
                  type="text"
                  as="textarea"
                  name="description"
                  value={desription}
                  // disabled={state && state.status == 4}
                  placeholder="Enter description"
                  // errorMessage={validator.current.message(
                  //   "description",
                  //   desription,
                  //   "required"
                  // )}
                  autoComplete="new-desription"
                />
              </Col>
            </Row>

            <Row>
              <Col>
                <div style={{ float: "right" }} className="form-group mt-3">
                  <Button
                    onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                      e.preventDefault();
                      isValidate(e);
                    }}
                    variant="primary"
                    type="submit"
                    className="ms-2"
                  >
                    Submit
                  </Button>

                  <Button
                    onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                      e.preventDefault();
                      navigate(-1);
                      // cancelTask(e)
                    }}
                    variant="secondary"
                    className="ms-2"
                  >
                    Cancel
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>
        </Card.Body>
      </Card>
    </>
  );
};
export default SelectTaskComponent;
