import React, { useEffect, useState } from "react";
import { Card, Form, Col, Row, Button, Accordion, Image } from "react-bootstrap";
import swal from "sweetalert";
import Table from "../../components/Table/simpletable.component";
import { Helmet, TextInput } from "../../components/FormInputs";
import Breadcrumb from "../../components/Breadcrumb/breadcrumb.component";
import customFunctions from "../../helper/customFunctions";
import DesignationModel from "./designationModel";
import Api from "../../helper/api";
import AuthApi from "../../helper/authApi";

const AddDesignation = () => {

  const [rows, setRows] = useState<any>([]);
  const [roleId, setRoleId] = useState<number>(0);
  const [count, forceUpdate] = useState<any>(0);
  const [currentUserId, setCurrentUserId] = useState<any>(0);
  const [title, setTitle] = useState<any>("Designation");
  const [statusToggle, setStatusToggle] = useState<any>(false);
  const [isShowLoader, setShowLoader] = useState<boolean>(false);
  const [filterErr, setFilterErr] = useState<any>("");
  const [searchKeyword, setSearchKeyword] = useState<any>("");
  const [selectedStatus, setSelectedStatus] = useState<any>("");
  const [applyToggle, setApplyToggle] = useState<any>(false);
  const [designationData, setDesignationData] = useState<any>([]);
  const [statusId, setStatusId] = useState<any>("");
  const [show, updateShow] = useState<boolean>(false);
  const [page, setPage] = useState<string>("");
  const [data, setData] = useState<any>();

  const initialPagination = {
    currentPage: 1,
    itemsPerPage: 3,
    totalItems: 1,
  };
  const [pagination, setPagination] = useState(initialPagination);

  const items = [
    { to: "/", label: "Dashboard" },
    { label: "Designation" },
  ];

  const columns = [
    {
      label: "Designation",
      id: "designation",
    },
    {
      label: "Description",
      id: "description",
    },
    {
      label: "Status",
      id: "status",
    },
    {
      label: "Action",
      id: "action",
    },
  ];

  const statusArray: any = [
    {
      label: "Active",
      value: "1",
    },
    {
      label: "Inactive",
      value: "2",
    },
  ];

  useEffect(() => {
    getDesignationList()
  }, [])

  const getDesignationList = async (pageNo?: any, clearFilter?: boolean) => {

    // if (
    //   !currentUserId ||
    //   (!applyToggle && clearFilter && !searchKeyword && !selectedStatus)
    // )
    //   return;

    const page: any = pageNo || 1;

    setShowLoader(true);
    // setApplyToggle(false);
    const endPoint: any = Api.designationUrl;

    const params: any = {
      page: page,
      limit: pagination.itemsPerPage,
      keyword: !clearFilter && searchKeyword ? searchKeyword.trim() : "",
      // status: !clearFilter && selectedStatus ? selectedStatus.value : ""
    };

    const url: any = customFunctions.generateUrl(endPoint, params);

    const { data, message } = await AuthApi.getDataFromServer(url);

    if (data && data.success == true) {
      setShowLoader(false);
      if (
        data &&
        data.data &&
        data.data.length > 0
      ) {
        setShowLoader(false);

        setPagination({
          currentPage: data.paginator.current_page,
          itemsPerPage: data.paginator.limit,
          totalItems: data.paginator.total_count,
        });
        setDesignationData(data.data);
        forceUpdate(count + 1);
      } else {
        setDesignationData([]);
      }
    } else {
      setShowLoader(false);
      setDesignationData([]);
      swal(message[0], "", "error");
    }
  };

  useEffect(() => {
    setTableData();
  }, [designationData]);

  const setTableData = () => {
    const rowsData: any = [];
    designationData.map((dt: any, i: any) => {
      rowsData.push({
        id: dt.id,
        index: (pagination.currentPage - 1) * pagination.itemsPerPage + (i + 1),
        designation: (
          <>
            <a
              href="/"
              className="pl-2 ms-1"
              title="Edit Designation"
              onClick={(e: any) => {
                e.preventDefault();
                updateShow(true);
                setPage("edit")
                setData(dt)
              }}
            >{dt.designation}
            </a>
          </>
        ),
        description: dt.description,
        status: (
          <>
            <div className="switch-check">
              <div className="form-check form-switch" style={{ marginBottom: "0" }}>
                <input
                  className="form-check-input"
                  type="checkbox"
                  role="switch"
                  // disabled={[3, 4].includes(Number(roleId)) ? true : false}
                  id="flexSwitchCheckDefault"
                  checked={dt?.status == "1" ? true : false}
                  onClick={() => {
                    setStatusToggle(true);
                    setStatusId(dt?.status == "2" ? "1" : "2");
                    // setEmployeeId(dt?.id);
                  }}
                  title={dt?.status == "1" ? "Active" : "Inactive"}
                />
              </div>
            </div>
          </>
        ),
        action:
          <>
            <a
              href="/"
              className="pl-2 ms-1"
              title={"View"}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                updateShow(true);
                setPage("view");
                setData(dt)
              }}
            >
              <Image src="images/eye.svg" alt="view" />
            </a>
          </>
      });
      return dt;
    });
    setRows([...rowsData]);
  };

  const filterSearch = (e: any) => {
    e.preventDefault();
    let filterSearchErr = "";
    let isValid = true;
    if (
      customFunctions.checkIfEmpty(searchKeyword) &&
      customFunctions.checkIfEmpty(selectedStatus)
    ) {
      filterSearchErr = "Please enter designation name";
      setFilterErr(filterSearchErr);
      isValid = false;
    }
    if (isValid) {
      setFilterErr("");
      getDesignationList(1);

    }
  };

  const onClear = (e: any) => {
    setFilterErr("");
    setSearchKeyword("");
    // setSelectedStatus("");
    getDesignationList(1, true);
    setApplyToggle(false);
  };

  return (
    <>
      <Helmet title={title} />
      <div className="page-header">
        <div className="title-breadcrumb-section">
          <h2 className="main-content-title tx-24 mg-b-5">{title}</h2>
          <Breadcrumb items={items} />
        </div>
      </div>
      <section className="content-area">
        <Accordion defaultActiveKey="1">
          <Accordion.Item eventKey="1">
            <Accordion.Header>
              <h5 className="card-title mb-0">Filters</h5>
            </Accordion.Header>
            <Accordion.Body>
              <Form onSubmit={(e) => filterSearch(e)}>
                {filterErr && (
                  <div className="p-3  bg-danger text-white bg-danger-main table-red-bg mb-3">
                    <div className="img-text">
                      <img
                        style={{ cursor: "pointer" }}
                        onClick={(e) => onClear(e)}
                        src="/images/fail.svg"
                        alt="Success"
                      />{" "}
                      {filterErr}
                    </div>
                  </div>
                )}
                <Row className="mt-3">
                  <Col sm={3}>
                    <TextInput
                      value={searchKeyword}
                      placeholder="Search by designation name"
                      onInputChange={(e: any) => {
                        setSearchKeyword(e.target.value);
                      }}
                    />
                  </Col>

                  {/* <Col sm={3}>
                    <DropdownInput
                      placeholder="Select status"
                      options={statusArray}
                      defaultValue={selectedStatus}
                      onSelectHandler={(e: any) => {
                        setSelectedStatus(e);
                      }}
                    />
                  </Col> */}

                  <Col sm={3}>
                    <div className="filter-btn pull-right filter-btn-report">
                      <Button
                        variant="primary"
                        type="submit"
                        className="ms-4"
                        onClick={() => setApplyToggle(true)}
                      >
                        Apply
                      </Button>
                      <Button
                        variant="secondary"
                        type="button"
                        className="ms-4"
                        onClick={(e) => onClear(e)}
                      >
                        Clear
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Form>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        <Card className="mt-3">
          <Card.Header>
            <div className="d-flex align-items-center justify-content-end">
              <a href="/" className="underline" onClick={(e: any) => {
                e.preventDefault();
                updateShow(true);
                setPage("add")
              }}>
                Add Designation
              </a>
            </div>
          </Card.Header>
          <Card.Body>
            <Table
              columns={columns}
              // showCheckbox={true}
              rows={rows}
              showPagination
              role={roleId}
              paginationProps={{
                ...pagination,
                // activePage: pagination.currentPage,
                currentPage: Number(pagination.currentPage),
                onPageChange: (pageNo: any) => {
                  getDesignationList(pageNo);
                },
              }}
              dropdownContainerClass="mb-3"
              emptyRender={() => (
                <p className="text-center mb-0">No record found</p>
              )}
            />
          </Card.Body>
        </Card>
      </section>
      {
        show && (
          <DesignationModel
            show={show}
            handleClose={() => updateShow(!show)}
            pageType={page}
            data={data}
          />
        )
      }
    </>
  )
}
export default AddDesignation;