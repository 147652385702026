import React, { useEffect, useState, useRef } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { Button, Col, Row } from "react-bootstrap";
import swal from "sweetalert";
import customFunctions from "../../helper/customFunctions";
import Api from "../../helper/api";
import AuthApi from "../../helper/authApi";
import Loader from "../../components/Loader";
import { Helmet } from "../../components/FormInputs";
import Breadcrumb from "../../components/Breadcrumb/breadcrumb.component";
import SimpleReactValidator from "simple-react-validator";
import ReactQuill from "react-quill";
import "quill/dist/quill.snow.css";

const InvoiceTermsComponent = () => {
  const editorValue:any = ClassicEditor;
  const editorRef: any = useRef();
  const validator = useRef(new SimpleReactValidator());
  const [terms, setTerms] = useState<any>("");
  const [userId, setUserId] = useState<any>(null);
  const [isShowLoader, setShowLoader] = useState<any>(false);
  const items = [{ to: "/", label: "Dashboard" }, { label: "Terms" }];
  const [count, forceUpdate] = useState<any>(0);
  
  const editorConfiguration = {
    toolbar: [
      "undo",
      "redo",
      "|",
      "bold",
      "italic",
      "|",
      "bulletedList",
      "numberedList",
    ],
  };

  const containerStyle = {
    // width: "700px", // Set the width as needed
    height: "800px", // Set the height as needed
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getInvoiceTerms();
  }, []);

  const getInvoiceTerms = async () => {
    setShowLoader(true);
    const { data, message } = await AuthApi.getDataFromServer(Api.profileUrl);
    if (data && data.success === true) {
      setShowLoader(false);
      if (data && data.data) {
        let { company } = data.data;

        setUserId(
          company?.length && company[0]?.company ? company[0]?.company : null
        );
        setTerms(
          company?.length && company[0]?.invoice_terms_conditions
            ? company[0]?.invoice_terms_conditions
            : ""
        );
      }
    } else {
      setShowLoader(false);
      swal(typeof message === "string" ? message : message[0], "", "error");
    }
  };

  const onChangeValues = (event: any, editor: any) => {
    const content = editor.getData();
    setTerms(content);
  };

  const onSubmit = (e: any) => {
    e.preventDefault();

    if (validator.current.allValid()) {
      updateTerms();
    } else {
      validator.current.showMessages();
      forceUpdate(count + 1);
    }
  };

  const updateTerms = async () => {
    setShowLoader(true);
    let postData = {
      company: {
        company_id: userId,
        invoice_terms_conditions: terms ? terms : null,
      },
    };

    const payload = customFunctions.cleanObject(postData);
    const endPoint = `${Api.profileUrl}`;
    const callback = AuthApi.putDataToServer;

    const { data, message } = await callback(endPoint, payload);

    if (data && data.success === true) {
      setShowLoader(false);
      swal("Invoice terms updated successfully!", "", "success");
    } else {
      setShowLoader(false);
      swal(typeof message === "string" ? message : message[0], "", "error");
    }
  };

  const onChange = (value: any) => {
    if(value.length > 50) return;
    if(value.length > 50){
      setTerms(value.slice(0,50))
    }else{
      setTerms(value)

    }
  };
  return (
    <>
      <Helmet title={"Terms"} />
      <div className="page-header">
        <div className="title-breadcrumb-section">
          <h2 className="main-content-title tx-24 mg-b-5">{"Terms"}</h2>
          <Breadcrumb items={items} />
        </div>
      </div>
      <Loader showLoader={isShowLoader} needFullPage={false} />
      <Row>
        <Col sm={7}>
          <div style={containerStyle}>
            <CKEditor
              config={editorConfiguration}
              editor={editorValue}
              data={terms}
              onChange={(event, editor) =>{
                 onChangeValues(event, editor)}}
              ref={editorRef}
            />

            {/* <ReactQuill
              ref={editorRef}
              theme="snow"
              value={terms.slice(0, 50)}
              onChange={(value: any) => onChange(value)}
            /> */}     

            <span className="text-danger">
              {validator.current.message("terms", terms, "required", {
                messages: {
                  default: "Please enter terms.",
                  // max: "Please enter max terms.",
                },
              })}
            </span>
            <Button className="mt-3" onClick={(e: any) => onSubmit(e)}>
              Submit
            </Button>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default InvoiceTermsComponent;
