import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Button } from "react-bootstrap";

const PageNotFoundComponent = () => {
  const { state } = useLocation();
  const navigate = useNavigate();

  return (
    <div className="page-not-found">
      {
        <>
        <center className="mt-5">
          <h3>Page Not Found!</h3>
          <Button
            className="btn-primary rounded-pill"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              if(Number(localStorage.getItem("role_id")) != 1){
                  navigate("/");
              }
              else{
                navigate("/services");
              }
            }}
          >
            {Number(localStorage.getItem("role_id")) == 1 ? "Go To Back" : "Go Back To Dashboard"}
          </Button>
          </center>
        </>
      }
    </div>
  );
};

export default PageNotFoundComponent;
