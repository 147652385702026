import { login } from "../actions/authentication";

export interface init {
  isLoggedIn:boolean
 }

const init:any = {
  isLoggedIn: true,
};

const reducer = (state:init = init, action:login):any => {
  switch (action.type) {
    case "LOGIN":
      return {
        ...state,
        isLoggedIn: action.value,
      };
    default:
      return state;
  }
};

export default reducer;
