import React, { useEffect, useRef, useState } from "react";
import { Modal, Button, Card, Col, Row, Image } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { DropdownInput, TextInput } from "../../components/FormInputs";
import SimpleReactValidator from "simple-react-validator";
import swal from "sweetalert";
import SweetAlert from "react-bootstrap-sweetalert";

function TimelogModel(props: any) {
  const { data, pageType } = props;

  const navigate = useNavigate();
  const validator = useRef(new SimpleReactValidator());

  const [timelog, setTimelog] = useState<any>("");
  const [count, forceUpdate] = useState<number>(0);
  const [time, setTime] = useState<any>();
  const [timeLog, setTimeLog] = useState<any>([]);
  const [isCompleted, setIsCompleted] = useState<any>();
  const [modalShow, setModalShow] = useState<boolean>(false);
  const [statusToggle, setStatusToggle] = useState<any>(false);
  const [taskName, setTaskName] = useState<any>();
  const status = "Rejected";

  const handleShow = () => setModalShow(true);
  const handleClose = () => setModalShow(false);
  //   {
  //     validator.current.hideMessages(); setModalShow(false); if (props) {
  //       props.handleClose()
  //     }

  useEffect(() => {
    if (data && data.task) {
      setTaskName(data.task.task_name);
    } else if (pageType == "edit") {
      setTaskName(data);
    } else {
      return setTaskName("");
    }
  }, [taskName]);

  const onSubmit = (e: any) => {
    e.preventDefault();
    if (validator.current.allValid()) {
      handleClose();
      setStatusToggle(true);
    } else {
      validator.current.showMessages();
      forceUpdate(count + 1);
    }
  };

  for (var i = 1; i <= 10; i++) {
    timeLog.push({
      value: i,
      label: i,
    });
  }

  //   const postTask = () => {
  //     // data.discription = discription,
  //     //   data.status = status
  //     swal("Task rejected successfully!", '', 'success')
  //       .then(() => {
  //         navigate("/approval-list")
  //       })
  //   }

  return (
    <>
      <a
        href="/"
        className="pl-2 ms-1"
        title={" Reject Task"}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          handleShow();
        }}
      >
        {pageType == "edit" ? (
          "log time"
        ) : (
          <Image src="/images/timer-1.svg" alt="select task" />
        )}
      </a>
      <Modal
        // style={{ width: "400px", height: "400px" }}
        show={modalShow}
        onHide={handleClose}
        // size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <h5>Enter Time</h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col sm={6}>
              <TextInput
                onInputChange={(
                  e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
                ) => {
                  setTaskName(e.target.value);
                }}
                label="Task Name"
                //   asterisk="*"
                containerClass="form-group"
                type="text"
                name="taskName"
                value={taskName}
                disabled
                placeholder="Enter taskName"
                errorMessage={validator.current.message(
                  "task Name",
                  taskName,
                  "required"
                )}
                autoComplete="new-address"
              />
            </Col>
            <Col sm={6}>
              <DropdownInput
                label={"Time In Hrs"}
                asterisk="*"
                placeholder="Select Time"
                options={timeLog}
                defaultValue={time}
                onSelectHandler={(select: any) => {
                  setTime(select);
                }}
                isClearable={true}
                errorMessage={validator.current.message(
                  "time in hrs",
                  time,
                  "required"
                )}
                id="time"
              />
            </Col>
          </Row>
          <Col className="d-flex justify-content-start">
            <div className="form-check mt-2">
              <input
                className="form-check-input"
                type="checkbox"
                value={isCompleted}
                checked={isCompleted}
                id="flexCheckDefault"
                onChange={
                  (e) => setIsCompleted(e.target.checked)
                  // setJoiningDetails({
                  //   ...joiningDetails,
                  //   isAdmin: e.target.checked,
                  // })
                }
              />
              <div>
                <label className="form-check-label" htmlFor="flexCheckDefault">
                  Is Completed
                </label>
              </div>
            </div>
          </Col>
          <div className="d-flex justify-content-end">
            <Button
              onClick={(e) => {
                e.preventDefault();
                onSubmit(e);
              }}
            >
              Submit
            </Button>
            <Button
              className="ml-10"
              variant="secondary"
              onClick={() => {
                validator.current.hideMessages();
                handleClose();
              }}
            >
              Cancel
            </Button>
          </div>
        </Modal.Body>
      </Modal>

      {/* {statusToggle && (
        <div>
          <SweetAlert
            title={"Do you want to reject a task?"}
            warning
            showCancel
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            onConfirm={() => {
              setStatusToggle(false)
              postTask()
            }}
            onCancel={() => {
              setStatusToggle(false);
            }}
            reverseButtons={true}
          />
        </div>
      )} */}
    </>
  );
}

export default TimelogModel;
