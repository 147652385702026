import React from "react";
import { Chart } from "react-google-charts";

const InvoicesPerTypeComponent = (props: any) => {
  const { invoiceData } = props;
  const data: any = [
    ["Invoice", ""],
    ["Open", invoiceData?.unpaid],
    ["Closed", invoiceData?.Paid],
    ["Overdue", invoiceData?.Overdue],
  ];
  const options: any = {
    colors: ["#FEB272", "#28A745", "#FF0000"],
    legend: "none",
    tooltip: {
      isHtml: true,
      trigger: "focus",
      ignoreBounds: true,
      content: (row: any, size: any, value: any) => {
        return `<div>${value}</div>`;
      },
    },
    width: "100%",
    height: "100%",
    pieSliceText: "percentage",
    chartArea: {
      left: "3%",
      top: "3%",
      height: "140%",
      width: "94%",
    },
  };

  return (
    <>
      {invoiceData?.Paid == 0 &&
      invoiceData?.unpaid == 0 &&
      invoiceData?.Overdue == 0 ? (
        <div className="mt-5">
          <center>
            {" "}
            <h3>No records found!</h3>
          </center>
        </div>
      ) : (
        <center>
          <div className="card-body">
            <Chart
              style={{ maxHeight: "300px", height: "230px" }}
              chartType="PieChart"
              data={data}
              options={options}
            />
            <div className="mt-3 text-center small">
              {invoiceData?.unpaid ?
              <span className="mr-5">
                <i className="fas fa-circle piechartdotunpaid"></i>{" "}
                Open
              </span>:""}
              {invoiceData?.Paid ?
              <span className="mr-5">
                <i className="fas fa-circle piechartdotpaid"></i>{" "}
                Closed
              </span>:""}
              {invoiceData?.Overdue ? 
              <span className="mr-5">
                <i className="fas fa-circle piechartdotoverdue"></i>{" "}
                Overdue
              </span>:""}
            </div>
          </div>
        </center>
      )}
    </>
  );
};

export default InvoicesPerTypeComponent;
