import { Component } from "react";

export default class Api extends Component {
  static getBaseUrl = () => {
    const env: string = "dev";

    let url = "";
    switch (env) {
      case "production":
        // url = "https://ca-api.reviewtestlink.com/api/v1/";
        break;

      // case "dev":
      //   url = "https://ca-api.reviewtestlink.com/api/v1/";
      //   break;

      default:
        url = "https://ca-api.reviewtestlink.com/api/v1/";
        break;
    }
    return url;
  };

  static s3UrlnoProfile =
    "https://empportal-serverless.s3.ap-south-1.amazonaws.com/noProfile.jpeg";

  static baseUrl = Api.getBaseUrl();
  // static loginUrl = `${Api.baseUrl}login/`;
  // static changePasswordUrl = `${Api.baseUrl}auth/change-password`;
  // static forgotPasswordUrl = `${Api.baseUrl}auth/forgot-password`;
  static usersUrl = `${Api.baseUrl}users`;
  static statusUrl = `${Api.baseUrl}superadmin-staff/change-user-status`;
  // static resetPasswordUrl = `${Api.baseUrl}auth/reset-password`;
  // static forgotPasswordUrl = `${Api.baseUrl}auth/forgot-password`;

  static regionUrl = `${Api.baseUrl}region`;
  static serviceUrl = `${Api.baseUrl}service`;
  static referralUrl = `${Api.baseUrl}referral`;
  static referralAssignUrl = `${Api.baseUrl}referral/assign-clinic`;
  static profileUrl = `${Api.baseUrl}profile/`;
  // static clientUrl = `${Api.baseUrl}client`;
  static clientRequestUrl = `${Api.baseUrl}superadmin-staff/change-client-status`;
  // static cityUrl = `${Api.baseUrl}city`;
  static patientUrl = `${Api.baseUrl}patient`;
  static lineItemUrl = `${Api.baseUrl}line-item`;
  static patientAppointmentUrl = `${Api.baseUrl}patient-appointment`;
  static folderUrl = `${Api.baseUrl}folder`;
  static clinicTypeUrl = `${Api.baseUrl}clinic_type`;
  static staffRoleUrl = `${Api.baseUrl}staffRole`;
  static fileUrl = `${Api.baseUrl}file`;
  static clinicalServiceUrl = `${Api.baseUrl}clinicalservice`;
  static outboundUrl = `${Api.baseUrl}outboundRefferal`;
  static doctorUrl = `${Api.baseUrl}doctor`;
  // static invoiceUrl = `${Api.baseUrl}invoice`;
  // static downlodInvoiceUrl = `${Api.baseUrl}invoice/downloadInvoice`
  static employeeUrl = `${Api.baseUrl}employee/`;
  static clientUrl = `${Api.baseUrl}client/`;
  // static servicesUrl =`${Api.baseUrl}service/`;
  static invoiceUrl = `${Api.baseUrl}invoice`;
  static downlodInvoiceUrl = `${Api.baseUrl}invoice/downloadInvoice`;

  // ca
  static adminDashboardUrl = `${Api.baseUrl}dashboard/`;
  static tokenVerifyUrl = `${Api.baseUrl}verify_token/`;
  static resetPasswordUrl = `${Api.baseUrl}reset_password/`;
  static registrationUrl = `${Api.baseUrl}company_registration/`;
  static loginUrl = `${Api.baseUrl}login/`;
  static forgotPasswordUrl = `${Api.baseUrl}forget_password/`;
  static changePasswordUrl = `${Api.baseUrl}change-password/`;
  static designationUrl = `${Api.baseUrl}roles/`;
  static documentTypeUrl = `${Api.baseUrl}document_type/`;
  static documentUploadUrl1 = `${Api.baseUrl}upload/`;
  static documentUploadUrl = `${Api.baseUrl}upload/`;
  static stateUrl = `${Api.baseUrl}states/`;
  static cityUrl = `${Api.baseUrl}cities/`;
  static servicesUrl = `${Api.baseUrl}service/`;
  static taskUrl = `${Api.baseUrl}tasks/`;
  static employeeTaskUrl = `${Api.baseUrl}employee-tasks/`;
  static timesheetUrl = `${Api.baseUrl}timesheet/`;
  static employeetimesheetUrl =`${Api.baseUrl}employee-timesheet/`
  static taskApprovalUrl = `${Api.baseUrl}task-approval/`
  static invoiceListUrl = `${Api.baseUrl}client-line-items/`
  static getinvoiceUrl = `${Api.baseUrl}client-items/`
  static generatePdfUrl = `${Api.baseUrl}invoice/`
  static getPdfUrl = `${Api.baseUrl}generate-invoice-pdf/`
  static getInvoiceList = `${Api.baseUrl}invoice/`
  static sentInvoiceEmail = `${Api.baseUrl}send-invoice-email/`
  static lineItems = `${Api.baseUrl}line-items/`
  static serviceTimeLogUrl = `${Api.baseUrl}client-services-time-log/`
  static taskDueDateUrl = `${Api.baseUrl}company-task-due-date/`
  static litigationUrl = `${Api.baseUrl}litigation/`
  static employeeLitigationUrl = `${Api.baseUrl}employee-litigation/`
  static genarateReportUrl = `${Api.baseUrl}generate-task-reports/`
  static downloadedReportsUrl = `${Api.baseUrl}reports-link/`
  
  // Get base URL of APIs
}
