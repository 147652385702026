import React, { useEffect, useState, useRef } from "react";
import { Card, Row, Col, Button, Table } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";
import Loader from "../../components/Loader/index";
import Breadcrumb from "../../components/Breadcrumb/breadcrumb.component";
import { DropdownInput, Helmet } from "../../components/FormInputs";
import AuthApi from "../../helper/authApi";
import Api from "../../helper/api";
import customFunctions from "../../helper/customFunctions";
import UserRestrictions from "../../components/UserRestrictions/userrestrictions.component";
import SelectAsyncPaginate from "../../components/ReactSelectPagination/SelectAsyncPaginate";
import SimpleReactValidator from "simple-react-validator";
import SimpleTable from "../../components/Table/simpletable.component";
import swal from "sweetalert";
import { Chart } from "react-google-charts";

const ClientDashboard = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const validator = useRef(new SimpleReactValidator());

  const titleHeading = "Client Dashboard";
  const [isShowLoader, setShowLoader] = useState(false);
  const [selectedClient, setSelectedClient] = useState<any>("");
  const [selectedFinancialYear, setSelectedFinancialYear] = useState<any>("");
  const [clientType, setClientType] = useState<any>("");
  const [serviceOptions, setServiceOptions] = useState<any>([]);
  const [clientsOptions, setClientsOptions] = useState<any>([]);
  const [selectedService, setSelectedService] = useState<any>("");
  const [personalDetails, setPersonalDetails] = useState<any>("");
  const [companyDetails, setCompanyDetails] = useState<any>("");
  const [accountingDetails, setAccountingDetails] = useState<any>("");
  const [roleId, setRoleId] = useState<any>("");
  const [count, forceUpdate] = useState<number>(0);
  const [serviceData, setServiceData] = useState<any>([]);
  const [servicePiechartColors, setServicePiechartColors] = useState<any>([]);
  const [toggle, setToggle] = useState<any>(false);
  const [toggleService, setToggleService] = useState<any>(false);
  const [toggleServiceLog, setToggleServiceLog] = useState<any>(false);
  const [showDashboard, setShowDashboard] = useState<any>(false);
  const [showPiechart, setShowPiechart] = useState<any>(false);

  const [servicePerEmployeeData, setServicePerEmployeeData] = useState<any>([]);
  const [rows, setRows] = useState<any>([]);

  const initialPagination: any = {
    currentPage: 1,
    itemsPerPage: 10,
    totalItems: 1,
  };

  const [pagination, setPagination] = useState<any>(initialPagination);

  const taskInitialPagination: any = {
    currentPage: 1,
    itemsPerPage: 10,
    totalItems: 1,
  };

  const [taskPagination, setTaskPagination] = useState<any>(
    taskInitialPagination
  );

  const [items, updateItems] = useState<any>([
    { to: "/", label: "Dashboard" },
    { to: "/client-master-list", label: "Clients" },
    { label: "Client Dashboard" },
  ]);

  let startYear = 2023;
  let prevYear = 2022;
  const month = new Date().getMonth();
  const currentDate = new Date().getDate();
  const length = month == 3 && currentDate == 1 ? 2 : 2;
  const financialYear: any = [];
  for (let i = 0; i < length; i++) {
    startYear = startYear + 1;
    prevYear = prevYear + 1;
    financialYear.push({
      value: `${prevYear}-${startYear}`,
      label: `${prevYear}-${startYear}`,
      fromYear: `${prevYear}`,
      toYear: `${startYear}`,
    });
  }

  const clientTypeArray = [
    {
      label: "Proprietor",
      value: 1,
    },
    {
      label: "Corporate",
      value: 2,
    },
    {
      label: "Non-Corporate",
      value: 3,
    },
  ];

  const columns: any = [
    // {
    //   label: "Sr. No.",
    //   id: "no",
    // },
    {
      label: "Service",
      id: "service",
    },
    {
      label: "Log Time(Hrs)",
      id: "logtime",
    },
  ];

  const serviceColumns: any = [
    {
      label: "Sr. No.",
      id: "no",
    },
    {
      label: "Employee Name",
      id: "employee",
    },
    // {
    //   label: "Task Name",
    //   id: "task",
    // },
    {
      label: "Service Name",
      id: "service",
    },
    {
      label: "Log Time(Hrs)",
      id: "logtime",
    },
  ];

  useEffect(() => {
    getUserRole();
  }, []);

  useEffect(() => {
    if (state?.fromComponent == "clientList" && state?.clientId) {
      setSelectedFinancialYear({
        label: `${new Date().getFullYear()}-${new Date().getFullYear() + 1}`,
        value: `${new Date().getFullYear()}-${new Date().getFullYear() + 1}`,
        fromYear: `${new Date().getFullYear()}`,
        toYear: `${new Date().getFullYear() + 1}`,
      });

      setSelectedClient({
        name: state?.data?.client_name
          ? state?.data?.client_name
          : state?.data?.client?.client_name,
        id: state?.clientId ? state?.clientId : state?.data?.client?.id,
      });
      getClientData();
    }
  }, [state]);

  const getUserRole = async () => {
    const roleId = localStorage.getItem("role_id");
    setRoleId(Number(roleId));
  };

  useEffect(() => {
    // getClientName();
  }, []);

  const getClientName = async () => {
    setShowLoader(true);
    const params = {
      client_type: clientType?.value,
      limit: 1000,
      sort_direction: "descending",
    };

    const url: any = customFunctions.generateUrl(Api.clientUrl, params);

    const { data, message } = await AuthApi.getDataFromServer(url);
    if (data && data.success) {
      setShowLoader(false);
      const empData: any = [];
      data &&
        data.data &&
        data.data.map((dt: any) => {
          empData.push({
            label: dt?.client_name,
            value: dt?.id,
          });
        });
      setClientsOptions(empData);
    } else {
      setShowLoader(false);
      swal(typeof message == "string" ? message : message[0], "", "error");
    }
  };

  const getClientData = async () => {
    setShowLoader(true);
    const id = selectedClient?.id
      ? selectedClient?.id
      : state?.clientId
      ? state?.clientId
      : "";

    const { data, message } = await AuthApi.getDataFromServer(
      `${Api.clientUrl}${id}/`
    );
    if (data && data.success) {
      setShowLoader(false);
      if (data?.data) {
        setPersonalDetails({
          name: data?.data?.client_name,
          email: data?.data?.email,
          mobile: data?.data?.contact_no_m,
          panNumber: data?.data?.pan_no,
          clientType: data?.data?.client_type_name,
          address: data?.data?.address?.area,
        });

        setCompanyDetails({
          name: data?.data?.company_name,
          gstNumber: data?.data?.gst_no,
          cinNumber: data?.data?.cin_no,
          importCode: data?.data?.iec_no,
          clientType: data?.data?.client_type_name,
        });
        let serviceData: any = [];

        data?.data?.services?.map((service: any) => {
          serviceData.push({
            label: service?.service_name,
            value: service?.service_id,
          });
        });
        setServiceOptions(serviceData);
        setToggle(true);
        setToggleService(true);
        setToggleServiceLog(true);
      } else {
        setPersonalDetails("");
        setServiceOptions([]);
      }
    } else {
      setShowLoader(false);
      setServiceOptions([]);
      setPersonalDetails("");
      swal(typeof message == "string" ? message : message[0], "", "error");
    }
  };

  useEffect(() => {
    if (selectedClient?.id && state?.clientId) {
      getServiceLogTime();
      getClientAllDetails();
      getEmployeeTaskPerService(1);
      setShowDashboard(true);
    }
  }, [selectedClient?.id, state?.clientId]);

  const getClientAllDetails = async () => {
    setShowLoader(true);
    const endpoint: any = `${Api.adminDashboardUrl}`;

    const params = {
      client_id: selectedClient?.id
        ? selectedClient?.id
        : state?.clientId
        ? state?.clientId
        : "",
      from_financial_year:
        selectedFinancialYear && selectedFinancialYear?.fromYear
          ? selectedFinancialYear?.fromYear
          : "",
      to_financial_year:
        selectedFinancialYear && selectedFinancialYear?.toYear
          ? selectedFinancialYear?.toYear
          : "",
    };

    const url: any = customFunctions.generateUrl(endpoint, params);

    const { data, message } = await AuthApi.getDataFromServer(url);
    if (data && data.success) {
      setShowLoader(false);
      if (data?.data) {
        setAccountingDetails({
          totalInvoice:
            data?.data?.financial_year_data?.year_paid_invoices_count +
            data?.data?.financial_year_data?.year_unpaid_invoices_count +
            data?.data?.financial_year_data?.year_overdue_invoices_count,
          totalAmount:
            data?.data?.financial_year_data?.year_total_revenue_amount,
          closedInvoice:
            data?.data?.financial_year_data?.year_paid_invoices_count,
          closedAmount: data?.data?.financial_year_data?.paid_revenue,
          openInvoice:
            data?.data?.financial_year_data?.year_unpaid_invoices_count,
          openAmount: data?.data?.financial_year_data?.unpaid_revenue,
          overdueInvoice:
            data?.data?.financial_year_data?.year_overdue_invoices_count,
          overdueAmount:
            data?.data?.financial_year_data?.year_overdue_invoices_amount,
          draftInvoice:
            data?.data?.financial_year_data?.year_draft_invoice_count,
          hoursLog: data?.data?.financial_year_data?.hours_spent_for_client,
        });
      } else {
        setAccountingDetails("");
      }
    } else {
      setShowLoader(false);
      setAccountingDetails("");
      swal(typeof message == "string" ? message : message[0], "", "error");
    }
  };

  const getServiceLogTime = async () => {
    setShowLoader(true);
    const endpoint: any = `${Api.adminDashboardUrl}`;

    const params = {
      client_id: selectedClient?.id
        ? selectedClient?.id
        : state?.clientId
        ? state?.clientId
        : "",
      from_financial_year:
        selectedFinancialYear && selectedFinancialYear?.fromYear
          ? selectedFinancialYear?.fromYear
          : "",
      to_financial_year:
        selectedFinancialYear && selectedFinancialYear?.toYear
          ? selectedFinancialYear?.toYear
          : "",
    };

    const url: any = customFunctions.generateUrl(endpoint, params);

    const { data, message } = await AuthApi.getDataFromServer(url);
    if (data && data.success) {
      setShowLoader(false);
      if (data?.data) {
        setTaskPagination({
          itemsPerPage: Number(5),
          currentPage: Number(data?.paginator?.current_page),
          totalItems: data?.paginator?.total_count,
        });

        let serviceData: any = [];
        let serviceColors: any = [];

        data?.data?.financial_year_data?.year_services_time_log_by_client_id?.map(
          (item: any) => {
            serviceData.push([
              item?.services_name,
              Number(`${item?.time_log}`),
            ]);
            serviceColors.push(
              customFunctions.getPiechartColor(item?.services_name)
            );
          }
        );
        setServicePiechartColors(serviceColors);
        setServiceData(serviceData);
        const isShowPiechart: any =
          serviceData?.length &&
          serviceData.every((value: any) => value[1] == 0);
        setShowPiechart(isShowPiechart ? true : false);
      } else {
        setServiceData([]);
      }
    } else {
      setShowLoader(false);
      setServiceData([]);
      swal(typeof message == "string" ? message : message[0], "", "error");
    }
  };

  const getEmployeeTaskPerService = async (page: number) => {
    setShowLoader(true);
    const endpoint: any = `https://ca-api.reviewtestlink.com/api/v1/client-services-time-log/`;

    const params = {
      client_id: selectedClient?.id ? selectedClient?.id : "",
      // services_id:
      //   selectedService && selectedService?.value ? selectedService?.value : "",
      page: page || 1,
      limit: 5,
      // status: 1,
      from_financial_year:
        selectedFinancialYear && selectedFinancialYear?.fromYear
          ? selectedFinancialYear?.fromYear
          : "",
      to_financial_year:
        selectedFinancialYear && selectedFinancialYear?.toYear
          ? selectedFinancialYear?.toYear
          : "",
      sort_direction: "descending",
    };

    const url: any = customFunctions.generateUrl(endpoint, params);

    const { data, message } = await AuthApi.getDataFromServer(url);
    if (data && data.success) {
      setShowLoader(false);
      let taskData: any = [];
      if (data?.data) {
        setPagination({
          itemsPerPage: Number(5),
          currentPage: Number(data?.paginator?.current_page),
          totalItems: data?.paginator?.total_count,
        });

        data?.data?.map((item: any) => {
          taskData.push({
            employee: item?.employee_name,
            // task: item?.task_name,
            service: item?.services_name,
            logtime: item?.total_hours,
          });
        });
        setServicePerEmployeeData(taskData);
      } else {
        setServicePerEmployeeData([]);
      }
    } else {
      setShowLoader(false);
      setServicePerEmployeeData([]);
      swal(typeof message == "string" ? message : message[0], "", "error");
    }
  };

  const onsubmit = () => {
    if (validator.current.allValid()) {
      getClientData();
      getServiceLogTime();
      getClientAllDetails();
      getEmployeeTaskPerService(1);
      setShowDashboard(true);
    } else {
      validator.current.showMessages();
      forceUpdate(count + 1);
    }
  };

  const onClear = () => {
    setSelectedClient("");
    setClientType("");
    setSelectedFinancialYear("");
    setPersonalDetails("");
    setCompanyDetails("");
    setAccountingDetails("");
    setServiceData("");
    setServicePerEmployeeData("");
    setToggle(false);
    setToggleService(false);
    setToggleServiceLog(false);
    setSelectedService("");
    validator.current.hideMessages();
    forceUpdate(count + 1);
  };

  useEffect(() => {
    setTableData();
  }, [servicePerEmployeeData]);

  const setTableData = () => {
    const rowsData: any = [];
    servicePerEmployeeData.map((dt: any, i: any) => {
      rowsData.push({
        no: (pagination.currentPage - 1) * pagination.itemsPerPage + (i + 1),
        employee: dt?.employee,
        // task: dt?.task,
        service: dt?.service,
        logtime: dt?.logtime ? dt?.logtime : "-",
      });
    });
    setRows([...rowsData]);
  };

  const estimationData: any = [["Total", ""], ...serviceData];

  const estimationOptions: any = {
    colors: [...servicePiechartColors],
    // legend: "none",
    tooltip: {
      isHtml: true,
      trigger: "focus",
      ignoreBounds: true,
      content: (row: any, size: any, value: any) => {
        return `<div>${value}</div>`;
      },
    },
    title: "",
    width: "95%",
    height: "95%",
    pieSliceText: "percentage",
    chartArea: {
      left: "3%",
      top: "3%",
      height: "95%",
      width: "95%",
    },
  };

  const getTotalTimeLog = (data: any) => {
    let totalAmount: any =
      data?.length &&
      data.reduce((firstValue: any, secondValue: any) => {
        return firstValue + secondValue[1];
      }, 0);
    return totalAmount ? totalAmount : 0;
  };

  return (
    <>
      <Helmet title={titleHeading} />
      <div className="title-breadcrumb-section">
        <h2 className="main-content-title tx-24 mg-b-5">Client Dashboard</h2>
        <Breadcrumb items={items} />
      </div>
      <Loader showLoader={isShowLoader} needFullPage={false} />

      <Card>
        <Card.Body>
          <Row className="d-flex">
            <Col sm={3}>
              {/* <DropdownInput
                  label="Client Name"
                  asterisk="*"
                  defaultValue={selectedClient}
                  options={clientsOptions}
                  disabled={state?.clientId}
                  onSelectHandler={(select: any) => {
                    setSelectedClient(select);
                    if (selectedClient?.value != select?.value) {
                    }
                  }}
                  placeholder="Select client"
                  errorMessage={validator.current.message(
                    "Client name",
                    selectedClient,
                    "required"
                  )}
                  id="Client"
                /> */}
              <SelectAsyncPaginate
                selectPlaceholder={"Select client"}
                value={selectedClient}
                onSelectChange={(select: any) => {
                  setSelectedClient(select);
                }}
                apiname={"clientUrl"}
                endpoint={Api.clientUrl}
                id={"Client"}
                key={undefined}
                isClearable={undefined}
                clearSelected={undefined}
                className={undefined}
                disabled={state?.clientId}
                isSearch={undefined}
                isMulti={undefined}
                clearCacheOnID={undefined}
                clearSelectedByParent={undefined}
                label={"Client Name"}
                asterisk={"*"}
                errorMessage={validator.current.message(
                  "Client name",
                  selectedClient,
                  "required"
                )}
                roleId={undefined}
                currentUserId={undefined}
                fromComponent={undefined}
              />
            </Col>
            <Col sm={3}>
              <DropdownInput
                label="Financial Year"
                asterisk="*"
                placeholder="Select financial Year"
                options={financialYear}
                defaultValue={selectedFinancialYear}
                onSelectHandler={(select: any) => {
                  setSelectedFinancialYear(select);
                }}
                errorMessage={validator.current.message(
                  "financial year",
                  selectedFinancialYear,
                  "required"
                )}
              />
            </Col>
            <Col className="mt-3" sm={2}>
              <Button
                style={{ marginTop: "12px" }}
                onClick={(e) => {
                  e.preventDefault();
                  onsubmit();
                }}
              >
                Apply
              </Button>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      {showDashboard ? (
        <Row>
          <Col sm={8}>
            <Row>
              <Col sm={6}>
                <Row>
                  <Col sm={6}>
                    <Card className="widget-flat bg-warning text-white">
                      <Card.Body>
                        <center>
                          <h5 className="text-white fw-normal">
                            Total Invoice
                          </h5>
                        </center>
                        <center>
                          <h3>
                            {accountingDetails?.totalInvoice
                              ? accountingDetails?.totalInvoice
                              : 0}
                          </h3>
                        </center>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col sm={6}>
                    <Card className="widget-flat bg-info text-white">
                      <Card.Body>
                        <center>
                          <h5 className="text-white fw-normal">Open Invoice</h5>
                        </center>
                        <center>
                          <h3>
                            {accountingDetails?.openInvoice
                              ? accountingDetails?.openInvoice
                              : 0}
                          </h3>
                        </center>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col sm={6}>
                    <Card className="widget-flat bg-success text-white">
                      <Card.Body>
                        <center>
                          <h5 className="text-white fw-normal">
                            Closed Invoice
                          </h5>
                        </center>
                        <center>
                          <h3>
                            {accountingDetails?.closedInvoice
                              ? accountingDetails?.closedInvoice
                              : 0}
                          </h3>
                        </center>
                      </Card.Body>
                    </Card>
                  </Col>

                  <Col sm={6}>
                    <Card className="widget-flat bg-danger text-white">
                      <Card.Body>
                        <center>
                          <h5 className="text-white fw-normal">
                            Overdue Invoice
                          </h5>
                        </center>
                        <center>
                          <h3>
                            {accountingDetails?.overdueInvoice
                              ? accountingDetails?.overdueInvoice
                              : 0}
                          </h3>
                        </center>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              </Col>
              <Col sm={6}>
                <Card
                  style={{
                    height: accountingDetails?.hoursLog == 0 ? "95%" : "",
                  }}
                >
                  <Card.Body>
                    <h4 className="text-center text-success">
                      Time Log Per Service(In Hrs)
                    </h4>
                    {!showPiechart ? (
                      <Chart
                        chartType="PieChart"
                        data={estimationData}
                        options={estimationOptions}
                      />
                    ) : (
                      <center>No records found!</center>
                    )}
                    {accountingDetails?.hoursLog ? (
                      <div>
                        <center>
                          Total Time -{" "}
                          {accountingDetails?.hoursLog
                            ? `${accountingDetails?.hoursLog} Hrs`
                            : "0"}
                        </center>
                      </div>
                    ) : (
                      ""
                    )}
                  </Card.Body>
                </Card>
              </Col>
              <Col>
                <Card>
                  <Card.Header>
                    <center>
                      <b className="card-title">TEAM DETAILS</b>
                    </center>
                  </Card.Header>
                  <Card.Body>
                    <SimpleTable
                      columns={serviceColumns}
                      rows={rows}
                      showPagination={true}
                      role={roleId}
                      tableClass="table-striped"
                      paginationProps={{
                        totalItems: pagination.totalItems,
                        itemsPerPage: pagination.itemsPerPage,
                        currentPage: pagination.currentPage,
                        firstPageText: "<<",
                        lastPageText: ">>",
                        hideDisabled: true,
                        onPageChange: (pageNo: any) => {
                          getEmployeeTaskPerService(pageNo);
                        },
                      }}
                      showCheckbox={false}
                      emptyRender={() => {
                        return "No record found.";
                      }}
                    />
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Col>
          <Col sm={4}>
            <div className="project-info-box">
              <Card className="project-info-details mb-1">
                <Card.Body className="">
                  <h2>{personalDetails?.name ? personalDetails?.name : "-"}</h2>
                  <ul className="list-unstyled p-0 project-information-total-hr">
                    <li>
                      <b>Email :</b>
                      {personalDetails?.email ? personalDetails?.email : "-"}
                    </li>
                    <li>
                      <b>Mobile Number :</b>
                      {personalDetails?.mobile ? personalDetails?.mobile : "-"}
                    </li>
                    <li>
                      <b>PAN Number :</b>
                      {personalDetails?.panNumber
                        ? personalDetails?.panNumber
                        : "-"}
                    </li>
                  </ul>
                </Card.Body>
              </Card>
              <Card>
                <Card.Body>
                  <ul className="list-unstyled project-information-total-hr">
                    <li>
                      <b>Total Invoice Amount :</b>
                      {accountingDetails?.totalAmount
                        ? accountingDetails?.totalAmount
                        : "0"}
                    </li>
                    <li>
                      <b>Total Open Invoice Amount :</b>
                      {accountingDetails?.openAmount
                        ? accountingDetails?.openAmount
                        : "0"}
                    </li>
                    <li>
                      <b>Total Closed Invoice Amount :</b>
                      {accountingDetails?.closedAmount
                        ? accountingDetails?.closedAmount
                        : "0"}
                    </li>
                    <li>
                      <b>Total Overdue Invoice Amount :</b>
                      {accountingDetails?.overdueAmount
                        ? accountingDetails?.overdueAmount
                        : "0"}
                    </li>
                  </ul>
                </Card.Body>
              </Card>
            </div>
          </Col>
        </Row>
      ) : null}
    </>
  );
};

export default ClientDashboard;
