/* eslint-disable-line */
import React, { useState, useRef, useEffect } from "react";
import { Row, Col, Card, Form, Button } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";
import swal from "sweetalert";
import {
  Datepicker,
  DropdownInput,
  Helmet,
  TextInput,
} from "../../components/FormInputs";
import SimpleReactValidator from "simple-react-validator";
import customFunctions from "../../helper/customFunctions";
import AuthApi from "../../helper/authApi";
import Api from "../../helper/api";
import Loader from "../../components/Loader";
import Breadcrumb from "../../components/Breadcrumb/breadcrumb.component";
import SelectAsyncPaginate from "../../components/ReactSelectPagination/SelectAsyncPaginate";
import {
  serviceStatus,
  dateOptions,
  monthsOptions,
} from "../superAdmin/constant";

const CreateRecurringTaskComponent = () => {
  const navigate: any = useNavigate();
  const { state } = useLocation();

  const validator = useRef(new SimpleReactValidator());

  const [count, forceUpdate] = useState<any>(0);
  const [roleId, setRoleId] = useState<number>(0);
  const [currentUserId, setCurrentUserId] = useState<number>(0);
  const [pageType, setPageType] = useState<string>("add");
  const [title, setTitle] = useState("Create Recurring Task");
  const [disableButton, setDisableButton] = useState<boolean>(false);
  const [isShowLoader, setShowLoader] = useState<boolean>(false);

  const [selectTaskId, setSelectTaskId] = useState<any>();
  const [selectService, setSelectService] = useState<any>();
  const [selectTask, setSelectTask] = useState<any>();
  const [selectOption, setSelectOption] = useState<any>();
  const [servicesArray, setServicesArray] = useState<any>([]);
  const [repeatitionStatus, setRepeatitionStatus] = useState<any>("");
  const [statusDate, setStatusDate] = useState<any>({
    date: "",
  });
  
  const [monthlyStatus, setMonthlyStatus] = useState<any>("");
  const [companyId, setCompanyId] = useState<any>("");

  const [checkFile, setCheckFile] = useState<any>(false);
  const [checkNote, setCheckNote] = useState<any>(false);
  const [checkOther, setCheckOther] = useState<any>(false);
  const [taskId, setTaskId] = useState<any>();
  const [optionError, setOptionError] = useState<any>("");

  useEffect(() => {
    getUserRole();
  }, []);

  const getUserRole = () => {
    const role = customFunctions.getLocalStorage("role_id");
    setRoleId(Number(role));
    const currentUserId = customFunctions.getLocalStorage("id");
    setCurrentUserId(Number(currentUserId));
  };

  useEffect(() => {
    if (state && state?.pageType == "edit") {
      setTitle("Edit Recurring Task");
      setSelectTask({
        name: state?.data?.task?.task_name,
        id: state?.dtat?.task?.id,
      });
      setRepeatitionStatus({
        label:
          state?.data?.repetative_status_name == "Yearly"
            ? "Once in year"
            : "Every month",
        value: state?.data?.repetative_status,
      });

      if (state?.data?.repetative_status == 1) {
        
        setStatusDate({
          date: {
            label: `${new Date(state?.data?.due_date).getDate()}`,
            value: `${
              new Date(state?.data?.due_date).getDate() > 9
                ? new Date(state?.data?.due_date).getDate()
                : "0" + new Date(state?.data?.due_date).getDate()
            }`,
          },
        });
      } else {
        setStatusDate({
          date: new Date(state?.data?.due_date),
        });
      }
      setCompanyId(state?.data?.company_id);
      setTaskId(state?.data?.id);
    }
  }, [state]);

  const items = [
    {
      to: "/",
      label: "Dashboard",
    },
    {
      to: "/recurring-tasks-list",
      label: "Recurring Tasks List",
    },
    { label: title },
  ];

  useEffect(() => {
    getServices();
    if (state && state?.pageType == "add") {
      getAdminData();
    }
  }, []);

  const getAdminData = async () => {
    const { data, message } = await AuthApi.getDataFromServer(
      `${Api.profileUrl}`
    );

    if (data && data.success == true) {
      if (data && data?.data && data?.data?.company?.length) {
        setCompanyId(data?.data?.company[0]?.company);
      }
    } else {
      swal(typeof message == "string" ? message : message[0], "", "error");
      return;
    }
  };

  const getServices = async () => {
    setShowLoader(true);
    const { data, message } = await AuthApi.getDataFromServer(
      `${Api.servicesUrl}?limit=100`
    );

    if (data && data.success === true) {
      setShowLoader(false);
      let serviceArr: any = [];
      data &&
        data.data.map((item: any) => {
          serviceArr.push({
            label: item?.services,
            value: item?.id,
          });
        });
      setServicesArray(serviceArr);
    } else {
      setShowLoader(false);
      setServicesArray("");
      swal(typeof message === "string" ? message : message[0], "", "error");
    }
  };

  const addTask = async () => {
    setShowLoader(true);
    const monthValue =
      new Date().getMonth() + 1 > 9
        ? new Date().getMonth() + 1
        : `0${new Date().getMonth() + 1}`;
    const postData: any = {
      due_date:
        statusDate?.date?.value && repeatitionStatus?.value == "1"
          ? `${new Date().getFullYear()}-${monthValue}-${
              statusDate?.date?.value
            }`
          : repeatitionStatus?.value == "2" && statusDate?.date
          ? customFunctions.getDate(statusDate?.date)
          : null,
      repetative_status: repeatitionStatus?.value,
      company: companyId,
      task: selectTask?.id,
    };

    const endPoint = taskId
      ? `${Api.taskDueDateUrl}${taskId}/`
      : `${Api.taskDueDateUrl}`;

    const callback = taskId
      ? AuthApi.putDataToServer
      : AuthApi.postDataToServer;
    const { data, message } = await callback(endPoint, postData);
    if (data && data.success === true) {
      setShowLoader(false);
      setDisableButton(false);
      swal(
        taskId ? "Task updated successfully!" : "Task created successfully!",
        "",
        "success"
      ).then(() => {
        navigate(-1);
      });
    } else {
      setDisableButton(false);
      setShowLoader(false);
      swal(typeof message == "string" ? message : message[0], "", "error");
    }
  };

  const onSubmit = (e: any) => {
    e.preventDefault();

    if (validator.current.allValid()) {
      addTask();
    } else {
      validator.current.showMessages();
      forceUpdate(count + 1);
    }
  };

  return (
    <>
      <Helmet title={title} />
      <Button
        style={{ float: "right" }}
        className="form-group mt-4"
        type="submit"
        variant="primary"
        onClick={() => {
          navigate(-1);
        }}
      >
        Back
      </Button>
      <div className="page-header">
        <div className="title-breadcrumb-section">
          <h2 className="main-content-title tx-24 mg-b-5">{title}</h2>
          <Breadcrumb items={items} />
        </div>
      </div>
      <Loader showLoader={isShowLoader} needFullPage={false} />
      <section className="content-area">
        <Card>
          <Card.Body>
            <Form
              autoComplete="off"
              onSubmit={(e) => {
                onSubmit(e);
              }}
            >
              <Row>
                <Col sm={3}>
                  <SelectAsyncPaginate
                    label={"Recurring Task"}
                    asterisk={"*"}
                    id={"recurring-task"}
                    selectPlaceholder={"Select task"}
                    apiname={"taskUrl"}
                    endpoint={Api.taskUrl}
                    value={selectTask}
                    onSelectChange={(select: any) => {
                      setSelectTask(select);
                    }}
                    errorMessage={validator.current.message(
                      "task",
                      selectTask,
                      "required"
                    )}
                    fromComponent={""}
                  />
                </Col>
                <Col sm={3}>
                  <DropdownInput
                    label="Recurring Status"
                    asterisk="*"
                    placeholder="Select status"
                    options={serviceStatus}
                    defaultValue={repeatitionStatus}
                    onSelectHandler={(select: any) => {
                      setRepeatitionStatus(select);
                      setStatusDate({
                        date: "",
                      });
                      setMonthlyStatus("");
                    }}
                    errorMessage={validator.current.message(
                      "Recurring status",
                      repeatitionStatus,
                      "required"
                    )}
                    id="recurring-status"
                  />
                </Col>
                {/* {repeatitionStatus &&
                  (repeatitionStatus?.label == "Monthly" ||
                    repeatitionStatus?.label == "Yearly") && ( */}
                <>
                  <Col sm={3}>
                    {repeatitionStatus && repeatitionStatus?.value == "1" ? (
                      <DropdownInput
                        label="Date"
                        asterisk={!repeatitionStatus ? "" : "*"}
                        placeholder="Select date"
                        disabled={!repeatitionStatus}
                        options={dateOptions}
                        defaultValue={statusDate?.date}
                        onSelectHandler={(select: any) => {
                          setStatusDate({
                            ...statusDate,
                            date: select,
                          });
                        }}
                        errorMessage={validator.current.message(
                          "date",
                          statusDate?.date,
                          repeatitionStatus?.value == "2" ||
                            repeatitionStatus?.value == "1"
                            ? "required"
                            : ""
                        )}
                        id="date"
                      />
                    ) : (
                      <Datepicker
                        peekNextMonth={true}
                        showMonthDropdown={true}
                        showYearDropdown={true}
                        dropdownMode="select"
                        label="Date"
                        className="form-control"
                        selected={statusDate?.date}
                        asterisk={!repeatitionStatus ? "" : "*"}
                        disabled={!repeatitionStatus}
                        placeholder="Select date"
                        dateFormat="dd-MM-yyyy"
                        // minDate={new Date()}
                        // maxDate={new Date(new Date().getFullYear(), 2, 31)}
                        minDate={new Date("2024-04-01")}
                        maxDate={new Date("2025-03-31")}
                        // maxDate={minDate.setFullYear(minDate.getFullYear() - 18)}
                        onChange={(select: any) => {
                          setStatusDate({
                            ...statusDate,
                            date: select,
                          });
                        }}
                        errorMessage={validator.current.message(
                          "date",
                          statusDate?.date,
                          repeatitionStatus?.value == "2" ||
                            repeatitionStatus?.value == "1"
                            ? "required"
                            : ""
                        )}
                      />
                    )}
                  </Col>
                </>
                {/* <Col sm={3}>
                  <DropdownInput
                    label="Month"
                    asterisk={
                      !repeatitionStatus?.value || repeatitionStatus?.value != 2
                        ? ""
                        : "*"
                    }
                    disabled={
                      !repeatitionStatus || repeatitionStatus?.value == 1
                    }
                    placeholder="Select month"
                    options={monthsOptions}
                    defaultValue={statusDate?.month}
                    onSelectHandler={(select: any) => {
                      setStatusDate({
                        ...statusDate,
                        month: select,
                      });
                    }}
                    errorMessage={validator.current.message(
                      "Month",
                      statusDate?.month,
                      repeatitionStatus?.value == 2 ? "required" : ""
                    )}
                    id="selectOption"
                  />
                </Col> */}
              </Row>
              <Row>
                <Col>
                  <div style={{ float: "left" }} className="form-group ">
                    <Button
                      type="submit"
                      variant="primary"
                      disabled={disableButton}
                    >
                      Submit
                    </Button>
                    <Button
                      onClick={(e) => {
                        e.preventDefault();
                        navigate(-1);
                      }}
                      variant="secondary"
                      className="ms-2"
                    >
                      Cancel
                    </Button>
                  </div>
                </Col>
              </Row>
            </Form>
          </Card.Body>
        </Card>
      </section>
    </>
  );
};

export default CreateRecurringTaskComponent;
