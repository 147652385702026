import { Modal, Button, Row, Col, FormGroup } from "react-bootstrap";
import { useEffect, useState, useRef } from "react";
import SimpleReactValidator from "simple-react-validator";
import Api from "../../helper/api";
import AuthApi from "../../helper/authApi";
import Loader from "../../components/Loader";
import {
  Datepicker,
  DropdownInput,
  TextInput,
} from "../../components/FormInputs";
import swal from "sweetalert";
import { Checkbox, FormControlLabel, FormLabel, Radio } from "@mui/material";
import customFunctions from "../../helper/customFunctions";
import RadioGroup, { useRadioGroup } from "@mui/material/RadioGroup";

const InvoicePreviewDetailsModel = (props: any) => {
  const validator = useRef(new SimpleReactValidator());

  const { selectedInvoice, invoiceId, callBack } = props;

  const [isShowLoader, setShowLoader] = useState<boolean>(false);

  const onSubmit = async () => {
    setShowLoader(true);
    const postData: any = {
      invoice_id: invoiceId,
    };

    const endPoint = `${Api.sentInvoiceEmail}`;
    const callback: any = AuthApi.postDataToServer;

    const { data, message } = await callback(endPoint, postData);

    if (data && data.success === true) {
      setShowLoader(false);
      props?.popupClose();
      swal("Invoice Sent successfully!", "", "success").then(() => {
        callBack();
      });
    } else {
      setShowLoader(false);
      swal(typeof message == "string" ? message : message[0], "", "error");
    }
  };
  return (
    <>
      <Loader showLoader={isShowLoader} needFullPage={false} />

      <Modal
        className={""}
        centered
        onHide={props?.popupClose}
        show={true}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <h5>{"Invoice Details"}</h5>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Row>
              <Col sm={6}>
                <TextInput
                  label={"Invoice No"}
                  value={
                    selectedInvoice?.invoice_no
                      ? selectedInvoice?.invoice_no
                      : ""
                  }
                  disabled
                />
              </Col>

              <Col sm={6}>
                <Datepicker
                  className="form-control"
                  label="Invoice Date"
                  selected={
                    selectedInvoice?.invoice_date
                      ? new Date(selectedInvoice?.invoice_date)
                      : ""
                  }
                  dateFormat="dd/MM/yyyy"
                  disabled
                />
              </Col>
              <Col sm={6}>
                <Datepicker
                  className="form-control"
                  label="Invoice Due Date"
                  selected={
                    selectedInvoice?.due_date
                      ? new Date(selectedInvoice?.due_date)
                      : ""
                  }
                  dateFormat="dd/MM/yyyy"
                  disabled
                />
              </Col>
              <Col sm={6}>
                <TextInput
                  label={"Invoice Total Amount"}
                  value={
                    selectedInvoice?.total_amount
                      ? selectedInvoice?.total_amount
                      : ""
                  }
                  disabled
                />
              </Col>
              <Col sm={6}>
                <TextInput
                  label={"Client Name"}
                  value={
                    selectedInvoice?.client?.client_name
                      ? selectedInvoice?.client?.client_name
                      : ""
                  }
                  disabled
                />
              </Col>
              {selectedInvoice?.client?.gst_no ? (
                <Col sm={6}>
                  <TextInput
                    label={"GST Number"}
                    value={
                      selectedInvoice?.client?.gst_no
                        ? selectedInvoice?.client?.gst_no
                        : ""
                    }
                    disabled
                  />
                </Col>
              ) : null}
              <Col sm={6} className={selectedInvoice?.client?.gst_no ? "mt-3" : ""}>
                <TextInput
                  label={"Address"}
                  value={
                    selectedInvoice?.client?.address?.area
                      ? selectedInvoice?.client?.address?.area
                      : ""
                  }
                  disabled
                />
              </Col>
              <Col
                className="mt-3"
                sm={6}
              >
                <TextInput
                  label={"City"}
                  value={
                    selectedInvoice?.client?.address?.city_name
                      ? selectedInvoice?.client?.address?.city_name
                      : ""
                  }
                  disabled
                />
              </Col>
              <Col sm={6} className="mt-3">
                <TextInput
                  label={"State"}
                  value={
                    selectedInvoice?.client?.address?.state_name
                      ? selectedInvoice?.client?.address?.state_name
                      : ""
                  }
                  disabled
                />
              </Col>
              <Col sm={6} className="mt-3">
                <TextInput
                  label={"Pincode"}
                  value={
                    selectedInvoice?.client?.address?.zipcode
                      ? selectedInvoice?.client?.address?.zipcode
                      : ""
                  }
                  disabled
                />
              </Col>

              
            </Row>
          </div>
          <hr />
          <Button
            variant="primary"
            onClick={(e: any) => {
              e.preventDefault();
              if (invoiceId) {
                onSubmit();
              }
            }}
          >
            Send Invoice
          </Button>{" "}
          &nbsp;&nbsp;
          <Button
            variant="secondary"
            onClick={() => {
              props.popupClose();
            }}
          >
            Cancel
          </Button>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default InvoicePreviewDetailsModel;
