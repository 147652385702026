import React, { useEffect, useState } from "react";
import RejectTaskList from "./rejectTaskList";
import ApproveTaskList from "./approveTaskList";
import Breadcrumb from "../../components/Breadcrumb/breadcrumb.component";

import {
  Card,
  Form,
  Col,
  Row,
  Button,
  Accordion,
  Tab,
  Tabs,
} from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import TaskApprovalListComponent from "./taskApprovalList";
// import AllTaskList from "./AllTaskList";

const ApprovalTaskTabs = (props: any) => {
  const state = useLocation();
  const [title, setTitle] = useState<any>("Tasks Approval");
  const [defaultActiveTab, setDefaultActiveTab] = useState<any>("approval-pending");
  const comp = props?.fromComponent;

  const items = [
    { to: "/", label: "Dashboard" },
    { label: "Tasks Approval" },
  ];

useEffect(()=>{
if(state?.state?.fromComponent == "dashboard"){
  setDefaultActiveTab("reject-task")
}
},[state?.state?.fromComponent]);

  return (
    <>
        <>
          <div className="page-header">
            <div className="title-breadcrumb-section">
              <h2 className="main-content-title tx-24 mg-b-5">{title}</h2>
              <Breadcrumb items={items} />
            </div>
          </div>
          <div className="tabs-wrapper">
            <Tabs
              activeKey={defaultActiveTab}
              id="uncontrolled-tab-example"
              className="mb-3"
              onSelect={(e:any)=>{
                setDefaultActiveTab(e);
              }}
            >
              <Tab eventKey="approval-pending" title="Tasks Approval">
                <TaskApprovalListComponent isRender={true}/>
              </Tab>
              <Tab eventKey="approved-task" title="Approved Tasks">
                <ApproveTaskList isRender={true}/>
              </Tab>
            </Tabs>
          </div>
        </>
      
    </>
  );
};

export default ApprovalTaskTabs;
