import React, { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { TextInput } from "../../components/FormInputs";
import { Modal, Button, Card, Image } from "react-bootstrap";
import SimpleReactValidator from "simple-react-validator";
import swal from "sweetalert";

const SubmitTaskModel = (props: any) => {

  const { data } = props;

  const navigate = useNavigate();
  const validator = useRef(new SimpleReactValidator());

  const [modalShow, setModalShow] = useState<boolean>(false);
  const [uploadTaskFile, setUploadTaskFile] = useState<any>();
  const [disableButton, setDisableButton] = useState<boolean>(false);
  const [count, forceUpdate] = useState<number>(0);

  const handleShow = () => setModalShow(true)
  const handleClose = () => setModalShow(false)

  const isUploadFileValid = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (disableButton) return;
    setDisableButton(true);
    if (validator.current.allValid()) {
      isFileSubmit()
    } else {
      setDisableButton(false);
      validator.current.showMessages()
      forceUpdate(count + 1)
    }
  }

  const isFileSubmit = () => {
    data.uploadTaskFile = uploadTaskFile;
    swal("Submit Task For Approval!", '', 'success')
      .then(() => {
        navigate("/view-task")
      })
  }

  return (
    <>
      <a
        href="/"
        className="pl-2 ms-1"
        title={"Submit Task for Approval"}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          handleShow();
          
        }}
      >
        <Image src="/images/complete-task-green.svg" alt="submit Task" />
      </a>

      <Modal show={modalShow}
        onHide={handleClose}
        // size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <h5>
              Task For Approval
            </h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <TextInput
            onInputChange={(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
              setUploadTaskFile(e.target.value);
            }}
            label="Upload File"
            // asterisk="*"
            containerClass="form-group"
            type="file"
            name="uploadTaskFile"
            value={uploadTaskFile}
            // disabled={state && state.status == 4}
            placeholder="Upload file"
            // errorMessage={validator.current.message(
            //     "upload File",
            //     uploadTaskFile,
            //     "required"
            // )}
            autoComplete="new-uploadTaskFile"
          />
          <div className="d-flex justify-content-end">
            <Button onClick={(e) => {
              isUploadFileValid(e)
            }}>Submit</Button>
            <Button className="ml-10" variant="secondary" onClick={() => {
              validator.current.hideMessages(); handleClose()
            }}>Cancel</Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}
export default SubmitTaskModel;