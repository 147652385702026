import React, { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import Api from "../../helper/api";
import AuthApi from "../../helper/authApi";
import customFunctions from "../../helper/customFunctions";
import UserRestrictions from "../UserRestrictions/userrestrictions.component";
import SharedService from "../../services/SharedService/sharedServices";
import swal from "sweetalert";
import {Image}  from "react-bootstrap";

function Header() {
  const date = new Date().toDateString().split(" ");
  let imageUrl = localStorage.getItem("profileImage")
    ? localStorage.getItem("profileImage")
    : "";
  const formattedDate = `${date[0]}, ${date[1]} ${date[2]} ${date[3]} `;
  const [designation, setDesignation] = useState<any>("");
  const [empId, setEmpId] = useState<any>("");
  const [imageProfile, setImageProfile] = useState<any>(imageUrl);
  const [userRole, setUserRole] = useState<any>("");
  const [currentUserId, setCurrentUserId] = useState<any>(null);
  const [roleId, setRoleId] = useState<any>(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let token = localStorage.getItem("access_token");
  const employeeName = localStorage.getItem("userName");

  useEffect(() => {
    setImageProfile(localStorage.getItem("profileImage"));
  }, [localStorage.getItem("profileImage")]);

  useEffect(() => {
    const roleId = localStorage.getItem("role_id");
    const userId = localStorage.getItem("id");
    const profileImage = localStorage.getItem("profileImage");

    setImageProfile(profileImage);
    setCurrentUserId(userId);
    setRoleId(Number(roleId));
  }, []);

  useEffect(() => {
    getProfileData();
    let profileImageChange = SharedService.profileImageChange.subscribe(
      (value: any) => {}
    );

    return () => {
      if (profileImageChange) profileImageChange.unsubscribe();
    };
  }, []);

  const getProfileData = async () => {
    const { data, message } = await AuthApi.getDataFromServer(
      `${Api.profileUrl}`
    );

    if (data && data.success == true) {
      if (data?.data?.user?.file?.file_name) {
        setImageProfile(data?.data?.user?.file?.file_name);
        customFunctions.setLocalStorage(
          "profileImage",
          data?.data?.user?.file?.file_name
        );
      }
      customFunctions.setLocalStorage(
        "sac_code",
        data?.data?.company?.length && data?.data?.company[0]?.sac_code
          ? data?.data?.company[0]?.sac_code
          : ""
      );
      localStorage.setItem(
        "userName",
        `${
          data?.data?.user?.first_name
            ? customFunctions.capitalizeFirstLetter(
                data?.data?.user?.first_name
              )
            : ""
        } ${
          data?.data?.user?.last_name
            ? customFunctions.capitalizeFirstLetter(data?.data?.user?.last_name)
            : ""
        }`
      );
    } else {
      swal(typeof message == "string" ? message : message[0], "", "error");
      return;
    }
  };

  useEffect(() => {
    const uRole = localStorage.getItem("group");
    setUserRole(Number(uRole));
    token = localStorage.getItem("access_token");
    if (!token) {
      const path = window.location.pathname.split("/");
      const extractedPath = path.length > 1 ? path[1].toString() : "";
      if (
        extractedPath !== "login" &&
        extractedPath !== "forget-password" &&
        extractedPath !== "password"
      ) {
        navigate("/login");
      }
    } else {
      dispatch({ type: "LOGIN", value: true });
    }
  }, []);

  const onMenuChange = (e: any) => {
    document.dispatchEvent(e);
    return true;
  };

  return (
    <>
      <div className="main-header side-header sticky sticky-pin">
        <div className="container-fluid d-flex align-items-center justify-content-end h-100">
          <div className="me-auto siderbar-toggle-wrapper">
            <img src="images/hamburger.png" />
          </div>
          <div className="main-header-right">
            {![1, 2, 7].includes(roleId) ? (
              <div className="day-date">
                <h5>{localStorage.getItem("businessName")}</h5>
              </div>
            ) : null}

            <div className="day-date">
              <h5>{formattedDate}</h5>
            </div>

            <div className="profile-name-img align-items-center">
              <div>
                <h6 style={{ marginTop: "15px" }}>{employeeName}</h6>
              </div>
              <div>
                <span style={{ fontSize: "11px" }}>
                  {[1, 2].includes(roleId) ||
                  customFunctions.getLocalStorage("admin_access") == "true"
                    ? "Admin"
                    : roleId == 6
                    ? "Accountant"
                    : roleId == 3
                    ? "Manager"
                    : "Employee"}
                </span>
              </div>
            </div>

            <div className="profile-name-img d-flex align-items-center">
              <div className="mr-5">
                {imageProfile &&
                typeof imageProfile == "string" &&
                imageProfile?.length > 0 ? (
                  // <img
                  //   src={imageProfile}
                  //   alt="Profile"
                  //   style={{ height: "46px", width: "46px" }}
                  // />
                  <Image
                      width={46}
                      height={46}
                      src={imageProfile}
                      roundedCircle
                    />
                ) : (
                  <img
                    src="/images/user.svg"
                    alt=""
                    style={{ height: "32px", width: "40px" }}
                  />
                )}
              </div>
            </div>
            <div className="dropdown d-md-flex header-settings">
              <a
                href="#"
                className="nav-link icon"
                data-toggle="sidebar-right"
                data-target=".sidebar-right"
              >
                <img src="/images/profile-setting.svg" alt="profile" />
              </a>

              <div className="main-header-menu-icon">
                <div className="dropdown-menu">
                  <div className="header-navheading text-center"></div>
                  <ul className="m-0 list-unstyled p-0">
                    {roleId != 1 ? (
                      <li>
                        <Link
                          className="dropdown-item border-top"
                          to={
                            [2].includes(roleId)
                              ? "/company-profile"
                              : "/employee-profile"
                          }
                          // onClick={(e:any)=>onMenuChange(e)}
                        >
                          <img
                            src="/images/user.svg"
                            alt="User"
                            className="svg"
                          />{" "}
                          My Profile
                        </Link>
                      </li>
                    ) : null}
                    <li>
                      <Link
                        className="dropdown-item"
                        to="/change-password"
                        // onClick={(e:any)=>onMenuChange(e)}
                      >
                        <img
                          src="/images/change-password.svg"
                          alt="Change"
                          className="svg"
                        />{" "}
                        Change Password
                      </Link>
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        href="/login"
                        onClick={(e: any) => {
                          customFunctions.clearLocalStorage();
                          customFunctions.onLogout();
                          // document.dispatchEvent(e);
                          dispatch({ type: "LOGIN", value: false });
                          navigate("/login");
                        }}
                      >
                        <img
                          src="/images/logout-profile.svg"
                          alt="Log Out"
                          className="svg"
                        />{" "}
                        Log Out
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Header;
