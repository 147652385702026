import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import swal from "sweetalert";
import SweetAlert from "react-bootstrap-sweetalert";
import {
  Card,
  Form,
  Col,
  Row,
  Button,
  Accordion,
  Image,
} from "react-bootstrap";
import customFunctions from "../../helper/customFunctions";
import AuthApi from "../../helper/authApi";
import Api from "../../helper/api";
import Breadcrumb from "../../components/Breadcrumb/breadcrumb.component";
import Loader from "../../components/Loader/index";
import { Helmet, Datepicker, DropdownInput } from "../../components/FormInputs";
import Table from "../../components/Table/simpletable.component";
import AddNewTaskComponent from "./addNewTask";
import SelectAsyncPaginate from "../../components/ReactSelectPagination/SelectAsyncPaginate";
import AssignTaskModal from "./assignTaskModal";

const TaskListComponent = () => {
  const navigate = useNavigate();
  const { state } = useLocation();

  const [isShowLoader, setShowLoader] = useState<any>(false);
  const [rows, setRows] = useState<any>([]);
  const [title, setTitle] = useState<any>("Timesheets");
  const [statusToggle, setStatusToggle] = useState<any>(false);
  const [count, forceUpdate] = useState<any>(0);
  const [filterErr, setFilterErr] = useState<any>("");
  const [searchKeyword, setSearchKeyword] = useState<any>("");
  const [selectedEmployee, setSelectedEmployee] = useState<any>("");
  const [applyToggle, setApplyToggle] = useState<any>(false);
  const [showModal, setShowModal] = useState<any>(false);
  const [taskType, setTaskType] = useState<string>("");
  const [roleId, setRoleId] = useState<number>(0);
  const [currentUserId, setCurrentUserId] = useState<number>(0);
  const [taskData, setTaskData] = useState<any>([]);
  const [employeeDataArray, setEmployeeDataArray] = useState<any>([]);
  const [adminAccess, setAdminAccess] = useState<any>("");
  const [pageType, setPageType] = useState<string>("add");
  const [isShowFilter, setisShowFilter] = useState<boolean>(true);
  const [propsData, setPropsData] = useState<any>({
    data: "",
    empName: "",
    date: "",
    due_date: "",
    index: "",
  });
  const items = [{ to: "/", label: "Dashboard" }, { label: "Timesheets" }];

  const initialPagination = {
    currentPage: 1,
    itemsPerPage: 5,
    totalItems: 1,
  };
  const [pagination, setPagination] = useState(initialPagination);

  const columns = [
    {
      label: "Sr. No",
      id: "index",
    },
    {
      label: "",
      id: "reject_status",
    },
    {
      label: "Date",
      id: "date",
      roleAccess: [3, 4, 5],
    },
    {
      label: "Date",
      id: "due_date",
      roleAccess: [1, 2, 10],
    },
    {
      label: "Employee Name",
      id: "name",
      roleAccess: [1, 2, 10],
    },
    {
      label: `${
        ![2, 10].includes(roleId)
          ? "Total Time Log (In Hrs)"
          : "Total Time Logged (In Hours)"
      }`,
      id: "total_time_log",
      roleAccess: [2, 3, 4, 5, 10],
    },

    {
      label: "Action",
      id: "action",
    },
  ];

  useEffect(() => {
    getUserRole();
  }, []);

  useEffect(() => {
    setTableData();
  }, [taskData]);

  const getUserRole = () => {
    const role = customFunctions.getLocalStorage("role_id");
    const adminaccess = customFunctions.getLocalStorage("admin_access");
    setRoleId(Number(role));
    setAdminAccess(adminaccess == "true" ? true : false);
    const currentUserId = customFunctions.getLocalStorage("id");
    setCurrentUserId(Number(currentUserId));
  };

  useEffect(() => {
    if (!roleId) return;
    getTimesheetListData(1, true);
  }, [roleId]);

  useEffect(() => {
    if (roleId == 2) {
      getEmployeeList();
    }
  }, [roleId]);

  const getEmployeeList = async () => {
    setShowLoader(true);
    const { data, message } = await AuthApi.getDataFromServer(
      `${Api.employeeUrl}?limit=1000`
    );
    if (data && data.success === true) {
      setShowLoader(false);
      let employeeData: any = [];
      data &&
        data.data &&
        data.data.length > 0 &&
        data.data.map((item: any) => {
          if (
            item?.user?.role == 2 ||
            Number(localStorage.getItem("id")) == item?.user?.user
          )
            return;
          employeeData.push({
            label: `${item?.user?.first_name} ${item?.user?.last_name}`,
            value: item?.employee?.id,
          });
        });
      setEmployeeDataArray(employeeData);
    } else {
      setShowLoader(false);
      swal(typeof message === "string" ? message : message[0], "", "error");
    }
  };

  const getTimesheetListData = async (pageNo?: any, clearFilter?: boolean) => {
    const page = pageNo || 1;
    setShowLoader(true);
    const endPoint = `${Api.timesheetUrl}`;
    const params: any = {
      page: page,
      limit: [2, 10].includes(roleId) ? Number(10) : pagination.itemsPerPage,
      sort_direction: "descending",
      is_timesheet: [3, 4, 5].includes(Number(roleId)) ? true : null,
      date: [2, 10].includes(Number(roleId))
        ? customFunctions.getDate(new Date())
        : null,
      // date: [adminAccess == true].includes(adminAccess)
      //   ? customFunctions.getDate(new Date())
      //   : null,
      due_date:
        !clearFilter && searchKeyword
          ? customFunctions.getDate(searchKeyword)
          : "",
      employee_id:
        !clearFilter && selectedEmployee?.id ? selectedEmployee?.id : "",
    };

    const url = customFunctions.generateUrl(endPoint, params);

    const { data, message } = await AuthApi.getDataFromServer(url);

    if (data && data.success == true) {
      setShowLoader(false);
      if (data?.data && !data?.data?.length && clearFilter) {
        setisShowFilter(false);
      }

      if (data && data.data && data.data.length > 0) {
        setShowLoader(false);
        setPagination({
          currentPage: data.paginator.current_page,
          itemsPerPage: [2, 10].includes(roleId) ? Number(10) : Number(5),
          totalItems: data.paginator.total_count,
        });
        setTaskData(
          [2, 10].includes(roleId) ? data?.data?.reverse() : data?.data
        );
        forceUpdate(count + 1);
      } else {
        setTaskData([]);
      }
    } else {
      setShowLoader(false);
      setTaskData([]);
      swal(
        typeof message == "string"
          ? message
          : message?.length
          ? message[0]
          : "Something went wrong!",
        "",
        "error"
      );
    }
  };

  const setTableData = () => {
    const rowsData: any = [];
    taskData.map((dt: any, i: any) => {
      rowsData.push({
        index: [2, 10].includes(roleId) ? (
          (pagination.currentPage - 1) * pagination.itemsPerPage + (i + 1)
        ) : (
          <center>
            {(pagination.currentPage - 1) * pagination.itemsPerPage + (i + 1)}
          </center>
        ),
        date: (
          <center>
            {dt?.date ? customFunctions.getDMYDate(dt?.date, "dmy") : "-"}
          </center>
        ),
        time_log: dt?.time_log,
        total_time_log: [2, 10].includes(roleId) ? (
          dt.total_time_log ? (
            dt.total_time_log
          ) : (
            "-"
          )
        ) : (
          <center>{dt.total_time_log ? dt.total_time_log : "-"}</center>
        ),
        task_status_name: dt.task_status_name,
        name: (
          <>
            {`${
              dt && dt.employee__user__first_name
                ? customFunctions.capitalizeFirstLetter(
                    dt.employee__user__first_name
                  )
                : null
            }
               ${
                 dt && dt.employee__user__last_name
                   ? customFunctions.capitalizeFirstLetter(
                       dt.employee__user__last_name
                     )
                   : null
               }`}
          </>
        ),
        due_date: dt.due_date
          ? customFunctions.getDMYDate(dt?.due_date, "dmy")
          : null,
        action: (
          <>
            {dt.date === customFunctions.getDate(new Date()) ? (
              <>
                <a
                  href=""
                  className="pl-2 ms-1 underline"
                  title={"Edit Timesheet"}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    // navigate("/add-new-task", {
                    //   state: {
                    //     pageType: "edit",
                    //     data: dt,
                    //     date: dt.date,
                    //   },
                    // });
                    setPageType("edit");
                    setPropsData({
                      data: dt,
                      empName: `${dt?.employee__user__first_name} ${dt?.employee__user__last_name}`,
                      date: dt?.date,
                      // due_date: roleId == 2 ? dt.due_date : "",
                    });
                  }}
                >
                  {roleId == 2
                    ? null
                    : // <Image src="/images/edit-button.svg" alt="Edit Timesheet" />
                      "Edit"}
                </a>
                <br />
              </>
            ) : null}
            <a
              href="/"
              className="pl-2 ms-1 underline"
              title={"View Timesheet"}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                if ([2, 10].includes(roleId)) {
                  navigate("/view-timesheet", {
                    state: {
                      data: dt,
                      empName: `${dt.employee__user__first_name} ${dt.employee__user__last_name}`,
                      date: dt.date,
                      due_date: dt.due_date,
                      index: i,
                      pageType: "view",
                    },
                  });
                } else {
                  setPageType("view");
                  setPropsData({
                    data: dt,
                    empName: `${dt.employee__user__first_name} ${dt.employee__user__last_name}`,
                    date: dt.date,
                    due_date: dt.due_date,
                    index: i,
                  });
                }
              }}
            >
              {/* <Image src="/images/eye.svg" alt="View Timesheet" /> */}
              View
            </a>
          </>
        ),
      });
      return dt;
    });
    setRows([...rowsData]);
  };

  const filterSearch = (e: any) => {
    e.preventDefault();
    let filterSearchErr = "";
    let isValid = true;
    if (
      customFunctions.checkIfEmpty(searchKeyword) &&
      customFunctions.checkIfEmpty(selectedEmployee)
    ) {
      filterSearchErr = `Please select date ${
        customFunctions.getLocalStorage("admin_access") == "true"
          ? "or employee"
          : ""
      }`;
      setFilterErr(filterSearchErr);
      isValid = false;
    }
    if (isValid) {
      setFilterErr("");
      getTimesheetListData(1, false);
      setFilterErr("");
    }
  };

  const onClear = (e: any) => {
    setFilterErr("");
    setSearchKeyword("");
    setSelectedEmployee("");
    setApplyToggle(false);

    if (searchKeyword || selectedEmployee) {
      getTimesheetListData(1, true);
    }
  };

  return (
    <>
      <Helmet title={title} />
      <div className="page-header">
        <div className="title-breadcrumb-section">
          <h2 className="main-content-title tx-24 mg-b-5">{title}</h2>
          <Breadcrumb items={items} />
        </div>
      </div>
      <Loader showLoader={isShowLoader} needFullPage={false} />

      {statusToggle && (
        <div className="sweetalert">
          <SweetAlert
            title={"Are you sure? do you want to change the status?"}
            warning
            showCancel
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            onConfirm={() => {
              setStatusToggle(false);
              // changeStatus();
            }}
            onCancel={() => {
              setStatusToggle(false);
            }}
            reverseButtons={false}
          />
        </div>
      )}
      <section className="content-area">
        {isShowFilter && (
          <Accordion defaultActiveKey="1">
            <Accordion.Item eventKey="1">
              <Accordion.Header>
                <h5 className="card-title mb-0">Filters</h5>
              </Accordion.Header>
              <Accordion.Body>
                <Form onSubmit={(e) => filterSearch(e)}>
                  {filterErr && (
                    <div className="p-3  bg-danger text-white bg-danger-main table-red-bg mb-3">
                      <div className="img-text">
                        <img
                          style={{ cursor: "pointer" }}
                          onClick={(e) => onClear(e)}
                          src="/images/fail.svg"
                          alt="Success"
                        />{" "}
                        {filterErr}
                      </div>
                    </div>
                  )}
                  <Row className="mt-3">
                    {roleId == 2 || adminAccess ? (
                      <Col sm={3}>
                        <SelectAsyncPaginate
                          selectPlaceholder={"Select employee"}
                          value={selectedEmployee}
                          onSelectChange={(select: any) => {
                            setSelectedEmployee(select);
                          }}
                          apiname={"employeeUrl"}
                          endpoint={Api.employeeUrl}
                        />
                      </Col>
                    ) : null}
                    <Col sm={3}>
                      <Datepicker
                        peekNextMonth={true}
                        showMonthDropdown={true}
                        showYearDropdown={true}
                        selected={searchKeyword}
                        dropdownMode="select"
                        className="form-control"
                        placeholder="Select Date"
                        dateFormat="dd-MM-yyyy"
                        maxDate={new Date()}
                        onChange={(e: any) => {
                          setSearchKeyword(e);
                        }}
                      />
                    </Col>
                    <Col sm={3}>
                      <div className="filter-btn pull-right filter-btn-report">
                        <Button
                          id="apply-btn"
                          variant="primary"
                          type="submit"
                          className="ms-4"
                          onClick={() => setApplyToggle(true)}
                        >
                          Apply
                        </Button>
                        <Button
                          id="clear-btn"
                          variant="secondary"
                          type="button"
                          className="ms-4"
                          onClick={(e) => onClear(e)}
                        >
                          Clear
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        )}
        <Row>
          {![2, 10].includes(roleId) ? (
            <Col sm={7}>
              <AddNewTaskComponent
                pagetype={pageType}
                propsData={propsData}
                callBack={() => {
                  setPageType("add");
                  getTimesheetListData(1, true);
                }}
              />
            </Col>
          ) : null}

          <Col sm={![2, 10].includes(roleId) ? 5 : 12}>
            <Card className="mt-3">
              {/* {![2, 10].includes(roleId) ? (
                <Card.Header>
                  <div className="d-flex align-items-center justify-content-end">
                    <a
                      id="add-employee-btn-link"
                      href="#"
                      className="underline mr-5"
                      onClick={(e) => {
                        e.preventDefault();
                        setTaskType("task");
                        setShowModal(true);
                      }}
                    >
                      Assigned Tasks
                    </a>

                    <a
                      id="add-employee-btn-link"
                      href="#"
                      className="underline"
                      onClick={(e) => {
                        e.preventDefault();
                        setTaskType("litigation");
                        setShowModal(true);
                      }}
                    >
                      Assigned Litigations
                    </a>
                  </div>
                </Card.Header>
              ) : null} */}
              <Card.Body>
                <Table
                  columns={columns}
                  showCheckbox={false}
                  rows={rows}
                  showPagination
                  role={roleId}
                  paginationProps={{
                    ...pagination,
                    currentPage: Number(pagination.currentPage),
                    onPageChange: (pageNo: any) => {
                      getTimesheetListData(pageNo, true);
                    },
                  }}
                  dropdownContainerClass="mb-3"
                  emptyRender={() => (
                    <p className="text-center mb-0">No record found</p>
                  )}
                />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </section>
      {showModal && (
        <AssignTaskModal
          show={showModal}
          taskType={taskType}
          popupClose={() => {
            setShowModal(!showModal);
            setTaskType("");
            getTimesheetListData(1, true);
          }}
        />
      )}
    </>
  );
};

export default TaskListComponent;
