import React, { useEffect, useRef, useState } from "react";
import SimpleReactValidator from "simple-react-validator";
import { Button, Card, Col, Row, Table, Image } from "react-bootstrap";
import {
  TextInput,
  Helmet,
  DropdownInput,
  Datepicker,
} from "../../components/FormInputs";
import swal from "sweetalert";
import Breadcrumb from "../../components/Breadcrumb/breadcrumb.component";
import Loader from "../../components/Loader";
import SweetAlert from "react-bootstrap-sweetalert";
import AuthApi from "../../helper/authApi";
import Api from "../../helper/api";
import customFunctions from "../../helper/customFunctions";
import { useNavigate, useLocation } from "react-router-dom";
import { ToWords } from "to-words";
import CreateLineItemsModel from "../line items/createUpdateModel";
import AddClientModel from "./addClientModel";

// Component
const UpdateInvoiceComponent = (props: any) => {
  const validator = useRef(new SimpleReactValidator());
  const nevigate = useNavigate();
  const state = useLocation();
  const toWords = new ToWords();

  const [clientList, setClientList] = useState<any>([]);
  const [selectedClient, setSelectedClient] = useState<any>("");
  const [selectedService, setSelectedService] = useState<any>("");
  const [igst, setIGST] = useState<any>("");
  const [count, forceUpdate] = useState<number>(0);
  const [updateInvoice, setUpdateInvoice] = useState<any>(false);
  const [invoiceNumber, setInvoiceNumber] = useState<any>("");
  const [selectedDueDateTerm, setSelectedDueDateTerm] = useState<any>("");
  const [gstNumber, setGstNumber] = useState<any>("");
  const [title, setTitle] = useState<string>("Update Invoice");
  const [label, setLabel] = useState<string>("Update Invoice");
  const items: any = [
    { to: "/", label: "Dashboard" },
    { to: "/invoice-list", label: "Invoices List" },
    { label: label },
  ];
  const [roleId, setRoleId] = useState<any>("");

  const [date, setDate] = useState<any>();
  const [due_date, setDueDate] = useState<any>();
  const [terms, setTerms] = useState<any>();
  const [notes, setNotes] = useState<any>();
  const [hsn_sac, setHSNSAC] = useState<any>();
  const [cgst, setCGST] = useState<any>("");
  const [sgst, setSGST] = useState<any>("");
  const [amount, setAmount] = useState<any>();
  const [totalAmount, setTotalAmount] = useState<any>("");
  const [totalInWord, setTotalInWords] = useState<any>("");
  const [tableData, showTableData] = useState<any>(false);
  const [invoiceId, setInvoiceId] = useState<any>();
  const [lineItemsArray, setLineItemsArray] = useState<any>([]);
  const [childToggle, setChildToggle] = useState<boolean>(false);
  const [removeToggle, setRemoveToggle] = useState<boolean>(false);
  const [invoiceData, setInvoiceData] = useState<any>();
  const [rows, setRows] = useState<any>([]);
  const [applyToggle, setApplyToggle] = useState<any>(false);
  const [lineItemOpenToggle, setLineItemOpenToggle] = useState<boolean>(false);
  const [description, setDescription] = useState<any>("");
  const [addClientOpenToggle, setAddClientOpenToggle] =
    useState<boolean>(false);

  const [removeData, setRemoveData] = useState<any>({
    type: "",
    id: "",
    ind: "",
  });

  const [isShowLoader, setShowLoader] = useState<boolean>(false);
  const [companyId, setCompanyId] = useState<any>();
  const [serviceArray, setServicesArray] = useState<any>([]);
  const [allServicesData, setallServicesData] = useState<any>("");
  const [adminAddress, setAdminAddress] = useState<any>();
  const [adminGSTState, setAdminGSTState] = useState<any>();
  const [isClientMaharastra, setIsClientMaharastra] = useState<any>(false);
  const [isNotClientMaharastra, setIsNotClientMaharastra] =
    useState<any>(false);
  const [address, setAddress] = useState<any>("");
  const [city, setCity] = useState<any>("");
  const [stateAddress, setState] = useState<any>("");
  const [pincode, setPincode] = useState<any>("");
  const [lineItemsUpdateArray, setLineItemsUpdateArray] = useState<any>([]);
  const [getLineItems, setGetLine] = useState<any>(false);
  const [lineItemAmount, setLineItemAmount] = useState<any>();
  const [selectedLineItems, setSelectedLineItems] = useState<any>();
  const [addButton, setAddButton] = useState<any>(false);
  const [typeClient, setClientType] = useState<any>("");
  const [formLineItemArray, setFormLineItemArray] = useState<any>([
    {
      invoiceId: invoiceId ? invoiceId : "",
      date: "",
      due_date: "",
      description: "",
      terms: "",
      notes: "",
      client_id: selectedClient?.value,
      hsn_sac: "",
      cgst: "",
      sgst: "",
      amount: "",
      line_item: "",
      lineItemsList: "",
      availableLineItem: lineItemsUpdateArray,
    },
  ]);
  const [formDataArray, setFormDataArray] = useState([
    {
      date: customFunctions.getDate(date),
      due_date: customFunctions.getDate(due_date),
      client_id: selectedClient?.value,
      terms: terms,
      notes: notes,
      hsn_sac: "",
      cgst_percent: cgst,
      sgst_percent: sgst,
      amount: "",
      billing_type: "",
      billing_type_name: "",
      billing_lisitng: {},
      services_id: "",
      service_name: "",
      services_listing: "",
      availableServices: serviceArray,
      serviceDescription: "",
    },
  ]);

  useEffect(() => {
    setRoleId(localStorage.getItem("role_id"));
  }, []);

  useEffect(() => {
    if (state?.state?.updateInvoiceId) {
      setInvoiceId(state?.state?.updateInvoiceId);
    }
  }, [state]);

  useEffect(() => {
    if (!getLineItems) return;
    getLineItemsData();
  }, [getLineItems]);

  const getLineItemsData = async () => {
    setShowLoader(true);

    const callback: any = AuthApi.getDataFromServer;
    let url = `${Api.lineItems}`;

    const { data, message } = await callback(url);

    if (data && data.success === true) {
      setShowLoader(false);
      let lineItemArr: any = [];

      data &&
        data.data &&
        data.data.map((item: any) => {
          lineItemArr.push({
            label: item?.name,
            value: item?.id,
          });
        });
      setLineItemsUpdateArray(lineItemArr);
    } else {
      setShowLoader(false);
      swal(typeof message == "string" ? message : message[0], "", "error");
    }
  };

  const removeLineItemFormField = (index: any) => {
    setFormLineItemArray((prevDataArray: any) => {
      const updatedDataArray = [...prevDataArray];
      updatedDataArray.splice(index, 1);
      if (formLineItemArray.length == 1) {
        setAddButton(false);
      }
      return updatedDataArray;
    });
  };

  // for Paginations
  const initialPagination = {
    currentPage: 1,
    itemsPerPage: 10,
    totalItems: 1,
  };
  const [pagination, setPagination] = useState(initialPagination);

  const dueDataTerms = [
    {
      label: "Due on reciept",
      value: "Due on reciept",
    },
    {
      label: "Due on specified",
      value: "Due on specified",
    },
  ];

  useEffect(() => {
    if (!invoiceId) return;
    getInvoiceData();
  }, [invoiceId]);

  useEffect(() => {
    if (!typeClient) return;
    getClientName();
    showTableData(true);
  }, [typeClient]);

  const getInvoiceData = async () => {
    setShowLoader(true);
    const endPoint: any = Api.invoiceListUrl;

    setShowLoader(true);
    const params: any = {
      invoice_id: invoiceId,
    };

    const url: any = customFunctions.generateUrl(endPoint, params);
    const { data, message } = await AuthApi.getDataFromServer(url);

    if (data && data.success === true) {
      setShowLoader(false);
      setPagination({
        itemsPerPage: Number(10),
        currentPage: data.paginator.current_page,
        totalItems: data.paginator.total_count,
      });
      let serviceData: any = [];
      let lineItemData: any = [];
      if (data && data?.data && data?.data?.length) {
        data?.data &&
          data?.data.map((item: any) => {
            if (item?.billing_type) {
              setClientType({
                label:
                  item?.client?.client_type == 1
                    ? "Proprietor"
                    : item?.client?.client_type == 2
                    ? "Corporate"
                    : "Non-Corporate",
                value: item?.client?.client_type,
              });
              setInvoiceNumber(item?.invoice?.invoice_no);
              setDate(new Date(item?.date));

              setSelectedDueDateTerm(
                item?.invoice?.due_date_period
                  ? {
                      label: item?.invoice?.due_date_period,
                      value: item?.invoice?.due_date_period,
                    }
                  : ""
              );
              setSelectedClient({
                label: item?.client?.client_name,
                value: item?.client?.id,
                address: item?.client?.address,
                gstNo: item?.client?.gst_no ? item?.client?.gst_no : null,
              });

              let stateValue = item?.client?.gst_no
                ? item?.client?.gst_no.split("").slice(0, 2).join("")
                : "";
              if (
                item?.client?.gst_no &&
                stateValue &&
                stateValue == state?.state?.adminGSTState
              ) {
                setIsClientMaharastra(true);
                setCGST(item?.cgst_percent);
                setSGST(item?.sgst_percent);
              } else {
                if (
                  item?.client?.gst_no &&
                  stateValue &&
                  stateValue != state?.state?.adminGSTState
                ) {
                  setIsNotClientMaharastra(true);
                  setIGST(
                    state?.state?.data?.igst_percent
                      ? state?.state?.data?.igst_percent
                      : item?.cgst_percent + item?.sgst_percent
                  );
                  setCGST(item?.cgst_percent);
                  setSGST(item?.sgst_percent);
                }
              }

              if (
                !item?.client?.gst_no &&
                item?.client?.address?.state_id == state?.state?.adminAddress
              ) {
                setIsClientMaharastra(true);
                setCGST(item?.cgst_percent);
                setSGST(item?.sgst_percent);
              } else {
                if (
                  !item?.client?.gst_no &&
                  item?.client?.address?.state_id != state?.state?.adminAddress
                ) {
                  setIsNotClientMaharastra(true);
                  setIGST(
                    state?.state?.data?.igst_percent
                      ? state?.state?.data?.igst_percent
                      : item?.cgst_percent + item?.sgst_percent
                  );
                  setCGST(item?.cgst_percent);
                  setSGST(item?.sgst_percent);
                }
              }
              setGstNumber(item?.client?.gst_no ? item?.client?.gst_no : null);

              setNotes(item?.notes ? item?.notes : "");
              setTerms(item?.terms ? item?.terms : "");

              setDueDate(item?.due_date ? new Date(item?.due_date) : "");
              setAddress(
                item?.client?.address?.area ? item?.client?.address?.area : ""
              );
              setCity(
                item?.client?.address?.city_name
                  ? item?.client?.address?.city_name
                  : ""
              );
              setState(
                item?.client?.address?.state_name
                  ? item?.client?.address?.state_name
                  : ""
              );
              setPincode(
                item?.client?.address?.zipcode
                  ? item?.client?.address?.zipcode
                  : ""
              );
              setDescription(item?.description);
              serviceData.push({
                id: item?.id,
                invoiceId: item?.invoice?.id,
                hsn_sac: item?.hsn_sac,
                cgst_percent: item?.cgst_percent,
                sgst_percent: item?.sgst_percent,
                igst_percent: state?.state?.data?.igst_percent
                  ? state?.state?.data?.igst_percent
                  : item?.cgst_percent + item?.sgst_percent,
                amount: item?.amount,
                billing_lisitng: {
                  label: item?.billing_type,
                  value: item?.billing_type,
                },
                services_id: item?.services?.services,
                service_name: item?.services?.services_name,
                services_listing: {
                  label: item?.services?.services_name,
                  value: item?.services?.services,
                },
                availableServices: serviceArray,
                serviceDescription: item?.description,
              });
            } else {
              lineItemData.push({
                id: item?.id,
                invoiceId: item?.invoice?.id,
                amount: item?.amount,
                lineItemsList: {
                  label: item?.line_item?.name,
                  value: item?.line_item?.id,
                },
                availableLineItem: lineItemsUpdateArray,
              });
            }
          });

        if (serviceData?.length) {
          setFormDataArray(serviceData);
        } else {
          setFormDataArray([]);
        }
        if (lineItemData?.length) {
          setUpdateInvoice(true);
          setAddButton(true);
          setGetLine(true);
          setFormLineItemArray(lineItemData);
        } else {
          setFormLineItemArray([
            {
              invoiceId: invoiceId ? invoiceId : "",
              date: "",
              due_date: "",
              description: "",
              terms: "",
              notes: "",
              client_id: selectedClient?.value,
              hsn_sac: "",
              cgst: "",
              sgst: "",
              amount: "",
              line_item: "",
              lineItemsList: "",
              availableLineItem: lineItemsUpdateArray,
            },
          ]);
          setGetLine(true);
        }
      }
    } else {
      setShowLoader(false);
      swal(typeof message == "string" ? message : message[0], "", "error");
    }
  };

  const getClientName = async () => {
    setShowLoader(true);
    let url = `${Api.clientUrl}?client_type=${typeClient.value}&sort_direction=descending`;
    const { data, message } = await AuthApi.getDataFromServer(url);
    const empData: any = [];
    data &&
      data.data &&
      data.data.map((dt: any) => {
        empData.push({
          label: dt.client_name,
          value: dt.id,
        });
      });
    setClientList(empData);
    setShowLoader(false);
  };

  useEffect(() => {
    if (selectedClient?.value) {
      getClientServices(selectedClient?.value);
    }
  }, [selectedClient?.value]);

  const getClientServices = async (id: any) => {
    setShowLoader(true);

    const { data, message } = await AuthApi.getDataFromServer(
      `${Api.clientUrl}${id}/`
    );

    if (data && data.success === true) {
      setShowLoader(false);
      setCompanyId(data.data.company.company);
      let serviceArr: any = [];
      data &&
        data.data &&
        data.data.tasks &&
        data.data.services.length > 0 &&
        data.data.services.map((item: any) => {
          serviceArr.push({
            label: item?.service_name,
            value: item?.service_id,
            sac_code: item?.service_sac_code,
          });
        });

      const sortedData: any =
        serviceArr?.length &&
        serviceArr?.sort?.((acu: any, cur: any) =>
          acu?.label?.toLowerCase() > cur?.label?.toLowerCase() ? 1 : -1
        );

      setallServicesData(data?.data?.services);
      setServicesArray(sortedData?.length ? sortedData : []);
    } else {
      setShowLoader(false);
      swal(typeof message == "string" ? message : message[0], "", "error");
    }
  };

  const allValid = () => {
    if (validator.current.allValid()) {
      handleSubmit();
    } else {
      let errFieldsArr: any = Object.entries(validator?.current?.fields).find(
        (item) => item[1] == false
      );
      let erName = errFieldsArr[0].replace(/ /g, "");
      document.getElementById(`${erName}`)?.focus();
      validator.current.showMessages();
      forceUpdate(count + 1);
    }
  };

  const addForm = () => {
    let newData = [...formDataArray];
    let obj = {
      invoiceId: invoiceId ? invoiceId : "",
      date: customFunctions.getDate(date),
      due_date: customFunctions.getDate(due_date),
      client_id: selectedClient?.value,
      terms: terms,
      notes: notes,
      cgst_percent: cgst,
      sgst_percent: sgst,
      igst_percent: igst,
      amount: "",
      hsn_sac: "",
      billing_type: "",
      billing_type_name: "",
      services_id: "",
      service_name: "",
      services_listing: "",
      availableServices: serviceArray,
      billing_lisitng: {},
      serviceDescription: "",
    };
    setFormDataArray([...newData, obj]);
    validator.current.hideMessages();
    forceUpdate(count + 1);
  };

  /* Function for perfor action on '-' button to remove forms*/
  const removeFormField = (index: number) => {
    let updatedDataArray = [...formDataArray];
    updatedDataArray.splice(index, 1);
    setFormDataArray([...updatedDataArray]);
  };

  const removeForm = async (index: number, id: any, type: any) => {
    if (!id) {
      if (type == "service") {
        removeFormField(index);
      } else {
        removeLineItemFormField(index);
      }
    } else {
      const url = `${Api.invoiceListUrl}${id}/`;
      const callback: any = AuthApi.deleteDataFromServer;
      const { data, message } = await callback(url);

      if (data && data.success === true) {
        setShowLoader(false);
        setRemoveData({
          type: "",
          id: "",
          ind: "",
        });
        swal(`Line item deleted successfully!`, "", "success");
        if (invoiceId) {
          getInvoiceData();
        }
        return;
      } else {
        setShowLoader(false);
        swal(message, "", "error");
      }
    }
  };

  const getServicesData = (pIndex: any) => {
    const serviceDetailsArr = [...formDataArray];

    if (serviceDetailsArr && serviceDetailsArr.length) {
      let filterArr = serviceArray.filter((sr: any) => {
        return serviceDetailsArr.findIndex(
          (serv: any, i: any) =>
            sr?.value === serv?.services_listing?.value && i !== pIndex
        ) === -1
          ? true
          : false;
      });
      serviceDetailsArr[pIndex].availableServices = filterArr?.length
        ? filterArr
        : [];
    }
    setFormDataArray(serviceDetailsArr);
    forceUpdate(count + 1);
  };

  /* Function for handle submition for client line items
     API data creations
  */
  const handleSubmit = async () => {
    // setShowLoader(true);

    let formData: any = [];
    let lineItemData: any = [];
    let finalPostData: any = [];
    let igstPercent: any = "";
    let igstPercentAmount: any = "";
    let cgstPercent: any = "";
    let sgstPercent: any = "";

    formDataArray &&
      formDataArray?.length &&
      formDataArray.map((item: any) => {
        if (item?.id && item?.invoiceId) {
          formData.push({
            id: item?.id,
            invoice_id: item?.invoiceId,
            hsn_sac: item?.hsn_sac ? item?.hsn_sac : null,
            amount: item?.amount,
            client_id:
              selectedClient && selectedClient?.value
                ? selectedClient?.value
                : null,
            services_id: item?.services_id ? item?.services_id : null,
            cgst_percent: item?.cgst_percent
              ? Number(item?.cgst_percent)
              : null,
            sgst_percent: item?.sgst_percent
              ? Number(item?.sgst_percent)
              : null,
            igst_percent: item?.igst_percent ? Number(item?.igst_percent) : 0,
            notes: notes ? notes : null,
            billing_type: item?.billing_lisitng?.label
              ? item?.billing_lisitng?.label
              : null,
            terms: terms ? terms : null,
            is_another_state: isNotClientMaharastra ? true : false,
            due_date_period: selectedDueDateTerm?.value,
            due_date: customFunctions.getDate(due_date),
            invoice_no: invoiceNumber,
            igst: item?.igstTotalAmount ? item?.igstTotalAmount : 0,
            date: date ? customFunctions.getDate(date) : null,
            template_type: 1,
            description: item?.serviceDescription,
          });
          igstPercent = Number(item?.igst_percent);
          igstPercentAmount = item?.igstTotalAmount;
          cgstPercent = Number(item?.cgst_percent);
          sgstPercent = Number(item?.sgst_percent);
        } else {
          if (item?.invoiceId) {
            formData.push({
              invoice_id: item?.invoiceId,
              hsn_sac: item?.hsn_sac ? item?.hsn_sac : null,
              amount: item?.amount,
              client_id:
                selectedClient && selectedClient?.value
                  ? selectedClient?.value
                  : null,
              services_id: item?.services_id ? item?.services_id : null,
              cgst_percent: item?.cgst_percent
                ? Number(item?.cgst_percent)
                : null,
              sgst_percent: item?.sgst_percent
                ? Number(item?.sgst_percent)
                : null,
              notes: notes ? notes : null,
              billing_type: item?.billing_type_name
                ? item?.billing_type_name
                : null,
              terms: terms ? terms : null,
              igst_percent: item?.igst_percent ? Number(item?.igst_percent) : 0,
              is_another_state: isNotClientMaharastra ? true : false,
              due_date_period: selectedDueDateTerm?.value,
              due_date: customFunctions.getDate(due_date),
              invoice_no: invoiceNumber,
              igst: item?.igstTotalAmount ? item?.igstTotalAmount : 0,
              date: date ? customFunctions.getDate(date) : null,
              template_type: 1,
              description: item?.serviceDescription,
            });
            igstPercent = Number(item?.igst_percent);
            igstPercentAmount = item?.igstTotalAmount;
            cgstPercent = Number(item?.cgst_percent);
            sgstPercent = Number(item?.sgst_percent);
          } else {
            formData.push({
              invoice_id: invoiceId,
              hsn_sac: item?.hsn_sac ? item?.hsn_sac : null,
              amount: item?.amount,
              client_id:
                selectedClient && selectedClient?.value
                  ? selectedClient?.value
                  : null,
              services_id: item?.services_id ? item?.services_id : null,
              cgst_percent: item?.cgst_percent
                ? Number(item?.cgst_percent)
                : cgst
                ? cgst
                : null,
              sgst_percent: item?.sgst_percent
                ? Number(item?.sgst_percent)
                : sgst
                ? sgst
                : null,
              notes: notes ? notes : null,
              billing_type: item?.billing_type_name
                ? item?.billing_type_name
                : null,
              terms: terms ? terms : null,
              igst_percent: item?.igst_percent
                ? Number(item?.igst_percent)
                : igst
                ? igst
                : 0,
              is_another_state: isNotClientMaharastra ? true : false,
              due_date_period: selectedDueDateTerm?.value,
              due_date: customFunctions.getDate(due_date),
              invoice_no: invoiceNumber,
              igst: item?.igstTotalAmount ? item?.igstTotalAmount : 0,
              date: date ? customFunctions.getDate(date) : null,
              template_type: 1,
              description: item?.serviceDescription,
            });
            igstPercent = Number(item?.igst_percent);
            igstPercentAmount = item?.igstTotalAmount;
            cgstPercent = Number(item?.cgst_percent);
            sgstPercent = Number(item?.sgst_percent);
          }
        }
      });

    formLineItemArray &&
      formLineItemArray?.length &&
      formLineItemArray.map((item: any) => {
        if (item?.id && item?.invoiceId && item?.amount) {
          lineItemData.push({
            id: item?.id,
            invoice_id: item?.invoiceId,
            amount: item?.amount ? item?.amount : null,
            client_id:
              selectedClient && selectedClient?.value
                ? selectedClient?.value
                : null,
            line_item_id: item?.lineItemsList?.value
              ? item?.lineItemsList?.value
              : null,
            notes: notes ? notes : null,
            terms: terms ? terms : null,
            due_date: customFunctions.getDate(due_date),
            invoice_no: invoiceNumber,
            date: date ? customFunctions.getDate(date) : null,
            template_type: 1,
            cgst_percent: cgstPercent
              ? Number(cgstPercent)
              : cgst
              ? cgst
              : null,
            sgst_percent: sgstPercent
              ? Number(sgstPercent)
              : sgst
              ? sgst
              : null,
            igst_percent: igstPercent ? Number(igstPercent) : igst ? igst : 0,
            igst: igstPercentAmount ? igstPercentAmount : 0,
            is_another_state: isNotClientMaharastra ? true : false,
          });
        } else {
          if (item?.invoiceId && item?.line_item_id && item?.amount) {
            lineItemData.push({
              invoice_id: item?.invoiceId,
              amount: item?.amount ? item?.amount : null,
              client_id:
                selectedClient && selectedClient?.value
                  ? selectedClient?.value
                  : null,
              line_item_id: item?.line_item_id ? item?.line_item_id : null,
              notes: notes ? notes : null,
              terms: terms ? terms : null,
              due_date: customFunctions.getDate(due_date),
              invoice_no: invoiceNumber,
              date: date ? customFunctions.getDate(date) : null,
              template_type: 1,
              cgst_percent: cgstPercent
                ? Number(cgstPercent)
                : cgst
                ? cgst
                : null,
              sgst_percent: sgstPercent
                ? Number(sgstPercent)
                : sgst
                ? sgst
                : null,
              igst_percent: igstPercent ? Number(igstPercent) : igst ? igst : 0,
              igst: igstPercentAmount ? igstPercentAmount : 0,
              is_another_state: isNotClientMaharastra ? true : false,
            });
          } else {
            if (item?.line_item_id && item?.amount) {
              lineItemData.push({
                invoice_id: invoiceId,
                amount: item?.amount ? item?.amount : null,
                client_id:
                  selectedClient && selectedClient?.value
                    ? selectedClient?.value
                    : null,
                line_item_id: item?.line_item_id ? item?.line_item_id : null,
                notes: notes ? notes : null,
                terms: terms ? terms : null,
                due_date: customFunctions.getDate(due_date),
                invoice_no: invoiceNumber,
                date: date ? customFunctions.getDate(date) : null,
                template_type: 1,
                cgst_percent: cgstPercent
                  ? Number(cgstPercent)
                  : cgst
                  ? cgst
                  : null,
                sgst_percent: sgstPercent
                  ? Number(sgstPercent)
                  : sgst
                  ? sgst
                  : null,
                igst_percent: igstPercent
                  ? Number(igstPercent)
                  : igst
                  ? igst
                  : 0,
                igst: igstPercentAmount ? igstPercentAmount : 0,
              });
            }
          }
        }
      });

    if (formData?.length && lineItemData?.length) {
      finalPostData = [...formData, ...lineItemData];
    }

    if (formData?.length && !lineItemData?.length) {
      finalPostData = [...formData];
    }

    if (!formData?.length && lineItemData?.length) {
      finalPostData = [...lineItemData];
    }

    const postData: any = finalPostData?.length ? finalPostData : [];
    const callback = invoiceId
      ? AuthApi.putDataToServer
      : AuthApi.postDataToServer;
    const { data, message } = await callback(`${Api.invoiceListUrl}`, postData);
    if (data && data.success === true) {
      setShowLoader(false);
      swal(
        `Invoice ${invoiceId ? "updated" : "created"} successfully!`,
        "",
        "success"
      ).then(() => {
        validator.current.hideMessages();
        nevigate("/invoice-list");
      });
    } else {
      setShowLoader(false);
      swal(typeof message == "string" ? message : message[0], "", "error");
    }
  };

  const setBillingFromService = (select: any, index: any) => {
    allServicesData &&
      allServicesData.length > 0 &&
      allServicesData.map((chunks: any) => {
        if (chunks?.service_id == select?.value) {
          let billingObj = {
            label: chunks?.billing_type,
            value: chunks?.billing_type_id,
          };
          let chooseBilling = formDataArray;
          chooseBilling[index].billing_type = chunks?.billing_type_id;
          chooseBilling[index].billing_type_name = chunks?.billing_type;
          chooseBilling[index].billing_lisitng = billingObj;
          setFormDataArray(chooseBilling);
        }
      });
  };

  const totalAmountCalculate = (): any => {
    if (formDataArray?.length > 0 || formLineItemArray?.length > 0) {
      let finalAmmount: any = [];
      if (formDataArray?.length > 0 && !formLineItemArray?.length) {
        formDataArray &&
          formDataArray.length > 0 &&
          formDataArray.filter((fil: any) => {
            if (fil.finalAmount) {
              finalAmmount.push(fil.finalAmount);
            }
          });
        let totaAmt = finalAmmount.reduce((a: any, b: any) => {
          return a + b;
        }, 0);

        return totaAmt && totaAmt > 0 ? totaAmt.toFixed(2) : 0;
      }

      if (formLineItemArray?.length > 0 && !formDataArray?.length) {
        formLineItemArray &&
          formLineItemArray.length > 0 &&
          formLineItemArray.filter((fil: any) => {
            if (fil.amount) {
              finalAmmount.push(fil.amount);
            }
          });
        let totaAmt = finalAmmount.reduce((a: any, b: any) => {
          return a + b;
        }, 0);
        return totaAmt && totaAmt > 0 ? totaAmt.toFixed(2) : 0;
      }

      if (formLineItemArray?.length > 0 && formDataArray?.length > 0) {
        let firstTotal: any = [];
        let secondTotal: any = [];
        formLineItemArray &&
          formLineItemArray.length > 0 &&
          formLineItemArray.filter((fil: any) => {
            if (fil.amount) {
              firstTotal.push(fil.amount);
            }
          });

        formDataArray &&
          formDataArray.length > 0 &&
          formDataArray.filter((fil: any) => {
            if (fil.finalAmount) {
              secondTotal.push(fil.finalAmount);
            }
          });
        let thirdTotal = [...firstTotal, ...secondTotal];
        let totaAmt = thirdTotal.reduce((a: any, b: any) => {
          return a + b;
        }, 0);
        return totaAmt && totaAmt > 0 ? totaAmt.toFixed(2) : 0;
      }
    }
  };

  const totalCGSTAmountCalculate = () => {
    if (formDataArray?.length > 0) {
      let finalAmmount: any = [];

      formDataArray &&
        formDataArray.length > 0 &&
        formDataArray.filter((fil: any) => {
          if (fil.cgstTotalAmount) {
            finalAmmount.push(Number(fil.cgstTotalAmount));
          }
        });
      let totaAmt: any = finalAmmount.reduce((a: any, b: any) => {
        return a + b;
      }, 0);

      return totaAmt && totaAmt > 0 ? totaAmt.toFixed(2) : 0;
    }
  };

  const totalSGSTAmountCalculate = () => {
    if (formDataArray?.length > 0) {
      let finalAmmount: any = [];

      formDataArray &&
        formDataArray.length > 0 &&
        formDataArray.filter((fil: any) => {
          if (fil.sgstTotalAmount) {
            finalAmmount.push(Number(fil.sgstTotalAmount));
          }
        });
      let totaAmt: any = finalAmmount.reduce((a: any, b: any) => {
        return a + b;
      }, 0);

      return totaAmt && totaAmt > 0 ? totaAmt.toFixed(2) : 0;
    }
  };

  const totalIGSTAmountCalculate = () => {
    if (formDataArray?.length > 0) {
      let finalAmmount: any = [];

      formDataArray &&
        formDataArray.length > 0 &&
        formDataArray.filter((fil: any) => {
          if (fil.igstTotalAmount) {
            finalAmmount.push(Number(fil.igstTotalAmount));
          }
        });
      let totaAmt: any = finalAmmount.reduce((a: any, b: any) => {
        return a + b;
      }, 0);

      return totaAmt && totaAmt > 0 ? totaAmt.toFixed(2) : 0;
    }
  };

  const lineItemtotalAmountCalculate = () => {
    if (formLineItemArray?.length > 0) {
      let finalAmmount: any = [];

      formLineItemArray &&
        formLineItemArray.length > 0 &&
        formLineItemArray.filter((fil: any) => {
          if (fil.amount) {
            finalAmmount.push(Number(fil.amount));
          }
        });

      let totaAmt: any = finalAmmount.reduce((a: any, b: any) => {
        return a + b;
      }, 0);
      return totaAmt && totaAmt > 0 ? totaAmt.toFixed(2) : 0;
    }
  };

  const serviceTotalAmountCalculate = () => {
    if (formDataArray?.length > 0) {
      let finalAmmount: any = [];

      formDataArray &&
        formDataArray.length > 0 &&
        formDataArray.filter((fil: any) => {
          if (fil.amount) {
            finalAmmount.push(Number(fil.amount));
          }
        });
      let totaAmt: any = finalAmmount.reduce((a: any, b: any) => {
        return a + b;
      }, 0);

      return totaAmt && totaAmt > 0 ? totaAmt.toFixed(2) : 0;
    }
  };

  return (
    <>
      <Helmet title={title} />
      <div style={{ float: "right" }} className="form-group mt-4">
        <Button
          id="back-btn"
          type="button"
          variant="primary"
          onClick={(e) => {
            e.preventDefault();
            nevigate(-1);
          }}
        >
          Back
        </Button>
      </div>
      <div className="page-header">
        <div className="title-breadcrumb-section">
          <h2 className="main-content-title tx">{title}</h2>
          <Breadcrumb items={items} />
        </div>
      </div>
      <Card className="mt-3">
        <Card.Body>
          <h6 className="section-title">Invoice Deatils</h6>
          <hr />
          <Row>
            <Col>
              <TextInput
                label="Invoice No."
                value={invoiceNumber}
                placeholder="Enter invoice no"
                disabled
                onInputChange={(e: any) => {
                  setInvoiceNumber(e.target.value);
                }}
              />
            </Col>
            <Col>
              <DropdownInput
                label="Client Name"
                placeholder="Select client"
                defaultValue={selectedClient}
                onSelectHandler={(select: any) => {
                  setSelectedClient(select);
                  setSelectedService(null);
                  setIsClientMaharastra(false);
                  setIsNotClientMaharastra(false);
                  setAddress(
                    select?.address?.area ? select?.address?.area : ""
                  );
                  setState(
                    select?.address?.state_name
                      ? select?.address?.state_name
                      : ""
                  );
                  setCity(
                    select?.address?.city_name ? select?.address?.city_name : ""
                  );
                  setPincode(
                    select?.address?.zipcode ? select?.address?.zipcode : ""
                  );

                  if (select && select?.gstNo) {
                    setGstNumber(select?.gstNo);
                    let stateValue = select?.gstNo
                      .split("")
                      .slice(0, 2)
                      .join("");
                    if (stateValue && stateValue == adminGSTState) {
                      setIsClientMaharastra(true);
                      setCGST(9);
                      setSGST(9);

                      let dataformDataArray: any = [];
                      formDataArray.map((it: any) => {
                        dataformDataArray.push({
                          ...it,
                          cgst_percent: 9,
                          sgst_percent: 9,
                          igst_percent: "",
                        });
                      });
                      setFormDataArray([...dataformDataArray]);
                    } else if (stateValue && stateValue != adminGSTState) {
                      setIsNotClientMaharastra(true);
                      setIGST(18);
                      setCGST(9);
                      setSGST(9);

                      let dataformDataArray: any = [];
                      formDataArray.map((it: any) => {
                        dataformDataArray.push({
                          ...it,
                          cgst_percent: 9,
                          sgst_percent: 9,
                          igst_percent: 18,
                        });
                      });
                      setFormDataArray([...dataformDataArray]);
                    }
                  }

                  if (select && !select?.gstNo && select?.address?.state_id) {
                    if (select?.address?.state_id == adminAddress) {
                      setIsClientMaharastra(true);
                      setCGST(9);
                      setSGST(9);

                      let dataformDataArray: any = [];
                      formDataArray.map((it: any) => {
                        dataformDataArray.push({
                          ...it,
                          cgst_percent: 9,
                          sgst_percent: 9,
                          igst_percent: "",
                        });
                      });
                      setFormDataArray([...dataformDataArray]);
                    } else if (select?.address?.state_id != adminAddress) {
                      setIsNotClientMaharastra(true);
                      setIGST(18);
                      setCGST(9);
                      setSGST(9);

                      let dataformDataArray: any = [];
                      formDataArray.map((it: any) => {
                        dataformDataArray.push({
                          ...it,
                          cgst_percent: 9,
                          sgst_percent: 9,
                          igst_percent: 18,
                        });
                      });
                      setFormDataArray([...dataformDataArray]);
                    }
                  }
                }}
                options={clientList}
                isClearable
                disabled
                id="Clientname"
              />
            </Col>
            <Col>
              <Datepicker
                className="form-control"
                label="Invoice Date"
                asterisk="*"
                selected={date}
                dateFormat="dd/MM/yyyy"
                placeholder="dd/mm/yyyy"
                isClearable={date}
                onChange={(e: any) => {
                  setDate(e);
                  setDueDate("");
                  if (e && selectedDueDateTerm?.value == "Due on reciept") {
                    setDueDate(e);
                  } else {
                    setDueDate(null);
                  }
                }}
                errorMessage={validator.current.message(
                  "Invoice Date",
                  date,
                  "required"
                )}
                id="InvoiceDate"
              />
            </Col>

            <Col>
              <Datepicker
                className="form-control"
                label="Due date"
                isClearable={due_date}
                asterisk="*"
                placeholder="dd/mm/yyyy"
                dateFormat="dd/MM/yyyy"
                minDate={date}
                selected={due_date}
                onChange={(e: any) => {
                  setDueDate(e);
                }}
                errorMessage={validator.current.message(
                  "Due date",
                  due_date,
                  "required"
                )}
                id="Duedate"
              />
            </Col>
            <Col>
              <DropdownInput
                label="Due Date Terms"
                placeholder="Select terms"
                asterisk="*"
                defaultValue={selectedDueDateTerm}
                onSelectHandler={(select: any) => {
                  setSelectedDueDateTerm(select);
                  setDueDate("");

                  if (select && select?.value == "Due on reciept") {
                    setDueDate(date);
                  }
                  if (select && select?.value == "Due on specified") {
                    setDueDate("");
                  }
                }}
                options={dueDataTerms}
                errorMessage={validator.current.message(
                  "due date term",
                  selectedDueDateTerm,
                  "required"
                )}
                id="duedateterm"
              />
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <Card>
        <Card.Body>
          <h6 className="section-title">Line Item Details</h6>
          <hr />
          {formDataArray.map((formData: any, index: any) => (
            <>
              <Loader showLoader={isShowLoader} needFullPage={false} />
              <Row className="mb-3">
                <Col>
                  <DropdownInput
                    asterisk="*"
                    className="d-flex justify-content-around"
                    label={index == 0 && "Select Item"}
                    placeholder="Select"
                    defaultValue={formData?.services_listing}
                    onSelectHandler={(select: any) => {
                      setSelectedService(select);
                      let chooseService = formDataArray;
                      chooseService[index].services_listing = select;
                      chooseService[index].services_id = select.value;
                      chooseService[index].service_name = select.label;
                      chooseService[index].hsn_sac = select.sac_code;
                      setFormDataArray(chooseService);

                      setBillingFromService(select, index);
                    }}
                    options={formData.availableServices}
                    onMenuOpen={() => {
                      getServicesData(index);
                    }}
                    disabled={!selectedClient}
                    errorMessage={validator.current.message(
                      `lineitem`,
                      formDataArray[index].services_listing,
                      "required",
                      {
                        messages: {
                          required: "The line item field is required.",
                        },
                      }
                    )}
                    id={`lineitem`}
                  />
                </Col>
                <Col>
                  <TextInput
                    label={index == 0 && "Description"}
                    placeholder="Enter description"
                    as="textarea"
                    value={formData.serviceDescription}
                    onInputChange={(e: any) => {
                      setDescription(e.target.value);
                      let serviceData: any = formDataArray;
                      serviceData[index].serviceDescription = e.target.value;
                      setFormDataArray(serviceData);
                    }}
                    maxlength={100}
                  />
                </Col>
                <Col>
                  <TextInput
                    asterisk="*"
                    label={index == 0 && "Amount"}
                    value={formData.amount}
                    maxlength={12}
                    placeholder="0"
                    onInputChange={(e: any) => {
                      const re = /^[0-9]*$/;
                      if (!e.target.value || re.test(e.target.value)) {
                        setAmount(e.target.value);
                        let chooseService: any = formDataArray;
                        chooseService[index].amount = e.target.value
                          ? Number(e.target.value)
                          : "";
                        setFormDataArray(chooseService);
                      }
                    }}
                    errorMessage={validator.current.message(
                      `amount`,
                      formDataArray[index].amount,
                      "required",
                      {
                        messages: {
                          required: "The amount field is required.",
                        },
                      }
                    )}
                    id={`amount`}
                  />
                </Col>
                <Col>
                  <div className="mt-1">
                    {formDataArray.length > 1 && (
                      <button
                        className={index == 0 ? "mt-4" : "ml-10"}
                        style={{
                          width: "30px",
                          height: "30px",
                          color: "white",
                          backgroundColor: "#24959D",
                          border: "#24959D",
                          marginLeft: "8px",
                          marginTop: "2px",
                        }}
                        onClick={(e) => {
                          e.preventDefault();
                          if (!formData?.id) {
                            setRemoveToggle(false);
                            removeFormField(index);
                          } else {
                            setRemoveToggle(true);
                            setRemoveData({
                              type: "service",
                              id: formData?.id,
                              ind: index,
                            });
                          }
                        }}
                      >
                        <h3>-</h3>
                      </button>
                    )}
                    {formDataArray &&
                      formDataArray.length - 1 == index &&
                      serviceArray.length !== formDataArray.length && (
                        <button
                          className={index == 0 ? "mt-4" : "ml-10"}
                          style={{
                            width: "30px",
                            height: "30px",
                            color: "white",
                            backgroundColor: "#24959D",
                            border: "#24959D",
                            marginLeft: "10px",
                            marginTop: "2px",
                          }}
                          onClick={(e) => {
                            e.preventDefault();
                            addForm();
                          }}
                        >
                          <h3>+</h3>
                        </button>
                      )}{" "}
                    &nbsp;&nbsp;
                  </div>
                </Col>
              </Row>
            </>
          ))}
          <Col sm={12}>
            <>
              <hr />
              <Table>
                <thead>
                  <tr>
                    <th>
                      <center>Sr. No.</center>
                    </th>
                    <th>
                      <center>Line Item</center>
                    </th>
                    <th>
                      <center>Amount</center>
                    </th>
                    {isClientMaharastra ? (
                      <>
                        <th>
                          <center>CGST(9%)</center>
                        </th>
                        <th>
                          <center>SGST(9%)</center>
                        </th>
                      </>
                    ) : null}
                    {isNotClientMaharastra ? (
                      <>
                        <th>
                          <center>IGST(18%)</center>
                        </th>
                      </>
                    ) : null}

                    <th>
                      <center>Total amount</center>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {formDataArray && formDataArray?.length > 0
                    ? formDataArray.map(
                        (item: any, index: any) => (
                          (item.finalAmount =
                            isClientMaharastra &&
                            item?.cgst_percent &&
                            item?.sgst_percent
                              ? (parseFloat(`${item?.cgst_percent}%`) *
                                  Number(item?.amount)) /
                                  100 +
                                (parseFloat(`${item?.sgst_percent}%`) *
                                  Number(item?.amount)) /
                                  100 +
                                Number(item?.amount)
                              : isClientMaharastra &&
                                item?.cgst_percent &&
                                !item?.sgst_percent
                              ? (parseFloat(`${item?.cgst_percent}%`) *
                                  Number(item?.amount)) /
                                  100 +
                                Number(item?.amount)
                              : isClientMaharastra &&
                                !item?.cgst_percent &&
                                item?.sgst_percent
                              ? (parseFloat(`${item?.sgst_percent}%`) *
                                  Number(item?.amount)) /
                                  100 +
                                Number(item?.amount)
                              : isNotClientMaharastra && item?.igst_percent
                              ? (parseFloat(`${item?.igst_percent}%`) *
                                  Number(item?.amount)) /
                                  100 +
                                Number(item?.amount)
                              : ""),
                          (item.cgstTotalAmount =
                            item?.cgst_percent && item?.amount
                              ? (parseFloat(`${item?.cgst_percent}%`) *
                                  Number(item?.amount)) /
                                100
                              : ""),
                          (item.sgstTotalAmount =
                            item?.sgst_percent && item?.amount
                              ? (parseFloat(`${item?.sgst_percent}%`) *
                                  Number(item?.amount)) /
                                100
                              : ""),
                          (item.igstTotalAmount =
                            item?.igst_percent && item?.amount
                              ? (parseFloat(`${item?.igst_percent}%`) *
                                  Number(item?.amount)) /
                                100
                              : ""),
                          (
                            <>
                              <tr>
                                <td>
                                  <center>{index + 1}</center>
                                </td>
                                <td>
                                  <center>
                                    <h6>
                                      {item?.service_name
                                        ? `${item?.service_name}`
                                        : "-"}
                                    </h6>
                                    {item?.serviceDescription ? (
                                      <p>{item?.serviceDescription}</p>
                                    ) : (
                                      ""
                                    )}
                                  </center>
                                </td>
                                <td>
                                  <center>
                                    {item?.amount ? item?.amount : "-"}
                                  </center>
                                </td>
                                {isClientMaharastra ? (
                                  <>
                                    <td>
                                      <center>
                                        {item?.cgst_percent && item?.amount
                                          ? (parseFloat(
                                              `${item?.cgst_percent}%`
                                            ) *
                                              Number(item?.amount)) /
                                            100
                                          : "-"}
                                      </center>
                                    </td>
                                    <td>
                                      <center>
                                        {item?.sgst_percent && item?.amount
                                          ? (parseFloat(
                                              `${item?.sgst_percent}%`
                                            ) *
                                              Number(item?.amount)) /
                                            100
                                          : "-"}
                                      </center>
                                    </td>
                                  </>
                                ) : null}
                                {isNotClientMaharastra ? (
                                  <>
                                    <td>
                                      <center>
                                        {item?.igst_percent && item?.amount
                                          ? (parseFloat(
                                              `${item?.igst_percent}%`
                                            ) *
                                              Number(item?.amount)) /
                                            100
                                          : "-"}
                                      </center>
                                    </td>
                                  </>
                                ) : null}

                                <td>
                                  <center>
                                    {isClientMaharastra &&
                                    item?.cgst_percent &&
                                    item?.sgst_percent
                                      ? (parseFloat(`${item?.cgst_percent}%`) *
                                          Number(item?.amount)) /
                                          100 +
                                        (parseFloat(`${item?.sgst_percent}%`) *
                                          Number(item?.amount)) /
                                          100 +
                                        Number(item?.amount)
                                      : isClientMaharastra &&
                                        item?.cgst_percent &&
                                        !item?.sgst_percent
                                      ? (parseFloat(`${item?.cgst_percent}%`) *
                                          Number(item?.amount)) /
                                          100 +
                                        Number(item?.amount)
                                      : isClientMaharastra &&
                                        !item?.cgst_percent &&
                                        item?.sgst_percent
                                      ? (parseFloat(`${item?.sgst_percent}%`) *
                                          Number(item?.amount)) /
                                          100 +
                                        Number(item?.amount)
                                      : isNotClientMaharastra &&
                                        item?.igst_percent
                                      ? (parseFloat(`${item?.igst_percent}%`) *
                                          Number(item?.amount)) /
                                          100 +
                                        Number(item?.amount)
                                      : "-"}
                                  </center>
                                </td>
                              </tr>
                            </>
                          )
                        )
                      )
                    : "No records"}
                </tbody>
              </Table>
            </>
            <hr />
          </Col>
          <Row>
            {(formDataArray?.length > 0 || formLineItemArray?.length > 0) && (
              <div className="TotalAmountBox">
                <div>
                  <p style={{ textAlign: "right" }}>
                    <b>Line Item Total Amount :</b>{" "}
                    {formDataArray?.length && serviceTotalAmountCalculate()
                      ? `${serviceTotalAmountCalculate()} Rs`
                      : "-"}
                  </p>
                </div>
                {isClientMaharastra ? (
                  <>
                    <div>
                      <p style={{ textAlign: "right" }}>
                        <b> {`CGST(${cgst}%)`} :</b>{" "}
                        {cgst && totalCGSTAmountCalculate()
                          ? `${totalCGSTAmountCalculate()} Rs`
                          : "-"}
                      </p>
                    </div>
                    <div>
                      <p style={{ textAlign: "right" }}>
                        <b> {`SGST(${sgst}%)`} :</b>{" "}
                        {sgst && totalSGSTAmountCalculate()
                          ? `${totalSGSTAmountCalculate()} Rs`
                          : "-"}
                      </p>
                    </div>
                  </>
                ) : null}
                {isNotClientMaharastra ? (
                  <>
                    <div>
                      <p style={{ textAlign: "right" }}>
                        <b> {`IGST(${igst}%)`} :</b>{" "}
                        {igst && totalIGSTAmountCalculate()
                          ? `${totalIGSTAmountCalculate()} Rs`
                          : "-"}
                      </p>
                    </div>
                  </>
                ) : null}

                <div>
                  <p style={{ textAlign: "right" }}>
                    <b>Net Total Amount :</b>{" "}
                    {(formDataArray?.length || formLineItemArray?.length) &&
                    totalAmountCalculate()
                      ? `${totalAmountCalculate()} Rs`
                      : "-"}
                  </p>
                </div>
                <div>
                  <p style={{ textAlign: "right" }}>
                    <b>Net Total Amount In Words :</b>{" "}
                    {(formDataArray?.length || formLineItemArray?.length) &&
                    totalAmountCalculate()
                      ? toWords.convert(totalAmountCalculate(), {
                          currency: true,
                        })
                      : "-"}
                  </p>
                </div>
                <div>
                  <Button
                    type="button"
                    className="btn btn-primary btn-sm"
                    onClick={(e: any) => {
                      allValid();
                    }}
                  >
                    Update Invoice
                  </Button>
                </div>
              </div>
            )}
          </Row>
        </Card.Body>
      </Card>
      {childToggle && (
        <div className="sweetalert">
          <SweetAlert
            title={"Are you want to sure add invoice in draft!"}
            warning
            showCancel
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            onConfirm={() => {
              allValid();
              setChildToggle(false);
            }}
            onCancel={() => {
              setChildToggle(false);
            }}
          />
        </div>
      )}
      {removeToggle && (
        <div className="sweetalert">
          <SweetAlert
            title={"Are you sure? do you want to remove?"}
            warning
            showCancel
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            onConfirm={() => {
              setRemoveToggle(false);
              removeForm(removeData?.ind, removeData?.id, removeData?.type);
            }}
            onCancel={() => {
              setRemoveToggle(false);
            }}
          />
        </div>
      )}

      {lineItemOpenToggle && (
        <CreateLineItemsModel
          show={lineItemOpenToggle}
          popupClose={() => {
            setLineItemOpenToggle(false);
            getLineItemsData();
          }}
          callBack={() => {
            getLineItemsData();
          }}
        />
      )}
      {addClientOpenToggle && (
        <AddClientModel
          show={addClientOpenToggle}
          popupClose={() => {
            setAddClientOpenToggle(false);
            getClientName();
            getLineItemsData();
          }}
          callBack={() => {
            getClientName();
            getLineItemsData();
          }}
        />
      )}
    </>
  );
};

export default UpdateInvoiceComponent;
