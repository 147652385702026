import { Modal, Button, Row, Col } from "react-bootstrap";

const PaymentDetailsModel = (props: any) => {
  const { show, details, popupClose } = props;

  return (
    <>
      <Modal centered onHide={popupClose} show={show} size="sm">
        <Modal.Header closeButton>
          <h5>{"Bank Details"}</h5>
        </Modal.Header>
        <Modal.Body>
          <span>
            {details?.is_check ? (
              <b>Check ID :</b>
            ) : details?.is_online ? (
              <b>Transaction ID :</b>
            ) : (
              ""
            )}{" "}
            {details?.transaction_id ? details?.transaction_id : "-"}
          </span>
          {details?.payment_source ?
          <><br /><span>
              <b>{details?.payment_source ? "Payment Source :" : ""} </b>
              {details?.payment_source ? details?.payment_source : "-"}
            </span></> :""}
          {details?.bank_name?
          <>
          <br />
          <span>
            <b>{details?.bank_name ? "Bank Name :" : ""} </b>
            {details?.bank_name ? details?.bank_name : "-"}
          </span></>:""}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default PaymentDetailsModel;
