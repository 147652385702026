import React, { useEffect, useState } from "react";
import { Image, Card } from "react-bootstrap";
import customFunctions from "../../helper/customFunctions";
import Api from "../../helper/api";
import AuthApi from "../../helper/authApi";
import swal from "sweetalert";
import Table from "../../components/Table/simpletable.component";
import { Helmet } from "../../components/FormInputs";
import Breadcrumb from "../../components/Breadcrumb/breadcrumb.component";
import Loader from "../../components/Loader";
import CreateLineItemsModel from "./createUpdateModel";

const LineItemList = () => {
  const [lineItemsData, setLineItemsData] = useState<any>();
  const [title, setTitle] = useState<string>("Line Item List");

  const [lineItemsId, setLineItemsId] = useState<any>();
  const [oldLineItemsName, setOldLineItemsName] = useState<any>("");
  const [isShowLoader, setShowLoader] = useState<boolean>(false);
  const [createItem, setCreateItem] = useState<boolean>(false);
  const [updateItem, setUpdateItem] = useState<boolean>(false);
  const [deleteLine, setDeleteLine] = useState<any>(false);
  const [rows, setRows] = useState<any>([]);
  const [count, forceUpdate] = useState(0);
  const [label, setLabel] = useState<string>("Line Item List");

  const items: any = [{ to: "/", label: "Dashboard" }, { label: label }];

  const initialPagination = {
    currentPage: 1,
    itemsPerPage: 10,
    totalItems: 1,
  };
  const [pagination, setPagination] = useState(initialPagination);

  const isClear = () => {
    setLineItemsId("");
    setOldLineItemsName("");
    setShowLoader(false);
    setCreateItem(false);
    setUpdateItem(false);
    setDeleteLine(false);
    // window.location.reload();
  };

  const columns: any = [
    {
      label: "Sr.no",
      id: "sr_no",
    },
    {
      label: "Line items",
      id: "line_item_name",
    },
    {
      label: "Actions",
      id: "action",
    },
  ];

  useEffect(() => {
    getLineItemsData(1);
  }, []);

  useEffect(() => {
    setTableData();
  }, [lineItemsData]);

  useEffect(() => {
    if (!lineItemsId && !deleteLine) return;
    deleteLineItem();
  }, [deleteLine]);

  const deleteLineItem = async () => {
    const url = `${Api.lineItems}${lineItemsId}/`;
    const callback: any = AuthApi.deleteDataFromServer;
    const { data, message } = await callback(url);

    if (data && data.success === true) {
      swal("Line item deleted successfully!", "", "success").then(() => {
        window.location.reload();
        setShowLoader(false);
        return;
      });
    } else {
      setShowLoader(false);
      swal(typeof message == "string" ? message : message[0], "", "error");
    }
  };

  const getLineItemsData = async (page: any, clearFilter?: boolean) => {
    const pageNo: any = page || 1;
    setShowLoader(true);
    const endPoint: any = Api.lineItems;

    setShowLoader(true);
    const params: any = {
      page: pageNo,
      limit: pagination.itemsPerPage,
    };
    const url: any = customFunctions.generateUrl(endPoint, params);
    const { data, message } = await AuthApi.getDataFromServer(url);

    if (data && data.success === true) {
      setShowLoader(false);
      setPagination({
        itemsPerPage: Number(10),
        currentPage: data.paginator.current_page,
        totalItems: data.paginator.total_count,
      });
      setLineItemsData(data.data);
    } else {
      setShowLoader(false);
      swal(typeof message == "string" ? message : message[0], "", "error");
    }
  };

  const setTableData = async () => {
    const rowData: any = [];
    lineItemsData &&
      lineItemsData.map((item: any, index: any) => {
        rowData.push({
          index:
            (pagination.currentPage - 1) * pagination.itemsPerPage +
            (index + 1),
          sr_no: index + 1,
          line_item_name: item.name,
          action: (
            <>
              <a
                href="javascript:void(0)"
                style={{ color: "#009D9A", cursor: "pointer" }}
                title={"Edit"}
                onClick={(e: any) => {
                  e.preventDefault();
                  setLineItemsId(item.id);
                  setOldLineItemsName(item.name);
                  setUpdateItem(true);
                }}
              >
                <Image src="images/edit-button.svg" alt="view" />
              </a>
              &nbsp;&nbsp;
              <i
                className="fa-solid fa-trash"
                title={"Delete"}
                style={{ color: "maroon", cursor: "pointer" }}
                onClick={(e: any) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setLineItemsId(item.id);
                  setDeleteLine(true);
                }}
              ></i>
            </>
          ),
        });
      });
    setRows(rowData);
    forceUpdate(count + 1);
  };

  return (
    <>
      <Helmet title={title} />
      <div className="page-header">
        <div className="title-breadcrumb-section">
          <h2 className="main-content-title tx-24 mg-b-5">{title}</h2>
          <Breadcrumb items={items} />
        </div>
      </div>
      <Loader showLoader={isShowLoader} needFullPage={false} />

      <section className="content-area">
        <Card className="mt-3">
          <Card.Header>
            <div className="d-flex align-items-center justify-content-end">
              <a
                id="add-client-btn-link"
                href="#"
                className="underline"
                onClick={(e) => {
                  e.preventDefault();
                  setCreateItem(true);
                }}
              >
                Create Line items
              </a>
            </div>
          </Card.Header>
          <Card.Body>
            <Table
              columns={columns}
              showCheckbox={false}
              rows={rows}
              showPagination
              paginationProps={{
                ...pagination,
                currentPage: Number(pagination.currentPage),
                onPageChange: (pageNo: any) => {
                  getLineItemsData(pageNo);
                },
              }}
              dropdownContainerClass="mb-3"
              emptyRender={() => (
                <p className="text-center mb-0">No record found</p>
              )}
            />
            {createItem && (
              <CreateLineItemsModel
                show={createItem}
                lineItemId={null}
                oldLineItemData={lineItemsData}
                popupClose={() => {
                  setCreateItem(!createItem);
                  isClear();
                }}
                callBack={() => {
                  setTableData();
                  getLineItemsData(1);
                }}
              />
            )}

            {updateItem && (
              <CreateLineItemsModel
                show={updateItem}
                lineItemId={lineItemsId}
                oldLineItemName={oldLineItemsName}
                popupUpdateClose={() => {
                  setUpdateItem(!updateItem);
                  isClear();
                }}
                callBack={() => {
                  setTableData();
                  getLineItemsData(1);
                }}
              />
            )}
          </Card.Body>
        </Card>
      </section>
    </>
  );
};

export default LineItemList;
