import React, { useEffect, useRef, useState } from "react";
import SimpleReactValidator from "simple-react-validator";
import { Button, Card, Col, Row } from "react-bootstrap";
import { TextInput, Helmet, DropdownInput } from "../../components/FormInputs";
import swal from "sweetalert";
import Breadcrumb from "../../components/Breadcrumb/breadcrumb.component";
import Loader from "../../components/Loader";
import SweetAlert from "react-bootstrap-sweetalert";

// Helper imports
import AuthApi from "../../helper/authApi";
import Api from "../../helper/api";
import customFunctions from "../../helper/customFunctions";
import Table from "../../components/Table/simpletable.component";
import LineItemsModel from "./lineItesForm";
import { useLocation, useNavigate } from "react-router-dom";
import LineItemsUpdateModel from "./updateLineItemsForm";

// Component
const UpdateInvoice = (props: any) => {
  const validator = useRef(new SimpleReactValidator());
  const nevigate = useNavigate();

  const { show, updateInvoiceId } = props;
  const state = useLocation();
  // const updateInvoiceId
  // updateInvoiceId

  const [clientList, setClientList] = useState<any>([]);
  const [selectedClient, setSelectedClient] = useState<any>("");
  const [selectedService, setSelectedService] = useState<any>("");
  const [selectedBillingType, setSelectedBillingType] = useState<any>("");
  const [selectedLineItems, setSelectedLineItems] = useState<any>();

  const [count, forceUpdate] = useState<number>(0);

  //Popup
  const [updateInvoice, setUpdateInvoice] = useState<any>(false);
  const [showLineButton, setShowLineButton] = useState<any>(true);

  //Titles
  const [title, setTitle] = useState<string>("Generate Invoice");
  const [label, setLabel] = useState<string>("Generate Invoice");
  const items: any = [{ to: "/", label: "Dashboard" }, { label: label }];

  const [date, setDate] = useState<any>();
  const [due_date, setDueDate] = useState<any>();
  const [terms, setTerms] = useState<any>();
  const [notes, setNotes] = useState<any>();
  const [hsn_sac, setHSNSAC] = useState<any>();
  const [cgst, setCGST] = useState<any>("");
  const [sgst, setSGST] = useState<any>("");
  const [amount, setAmount] = useState<any>();
  const [totalAmount, setTotalAmount] = useState<any>("");
  const [totalInWord, setTotalInWords] = useState<any>("");
  const [tableData, showTableData] = useState<any>(false);
  const [invoiceId, setInvoiceId] = useState<any>();
  const [lineItemsArray, setLineItemsArray] = useState<any>([]);
  const [lineItemsUpdateArray, setLineItemsUpdateArray] = useState<any>([]);
  const [childToggle, setChildToggle] = useState<boolean>(false);
  const [invoiceData, setInvoiceData] = useState<any>();
  const [rows, setRows] = useState<any>([]);
  const [applyToggle, setApplyToggle] = useState<any>(false);

  // Get Client services Filter by Id
  const [isShowLoader, setShowLoader] = useState<boolean>(false);
  const [companyId, setCompanyId] = useState<any>();
  const [serviceArray, setServicesArray] = useState<any>("");
  const [getLineItems, setGetLine] = useState<any>(false);
  const [shouldShowDefault, setShowDefault] = useState<boolean>(false);
  const [availableServices, setAvailableServices] = useState<any>("");
  const [allServicesData, setallServicesData] = useState<any>("");

  // Line items states
  const [lineItemAmount, setLineItemAmount] = useState<any>();

  //Get Form Fields
  const [formLineItemArray, setFormLineItemArray] = useState<any>([
    {
      date: "",
      due_date: "",
      description: "",
      terms: "",
      notes: "",
      client_id: selectedClient?.value,
      hsn_sac: "",
      cgst: "",
      sgst: "",
      amount: "",
      line_item: "",
      lineItemsList: {},
      availableLineItem: lineItemsUpdateArray,
    },
  ]);

  const divStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
  };

  // ADD or Remove Form
  const addLineItemsForm = () => {
    setFormLineItemArray((prevDataArray: any) => [
      ...prevDataArray,
      {
        date: "",
        due_date: "",
        client_id: selectedClient?.value,
        terms: "",
        notes: "",
        hsn_sac: "",
        amount: "",
        line_item_id: "",
        lineItemsList: {},
        availableLineItem: lineItemsUpdateArray,
      },
    ]);
  };

  // Billing types
  const clientBillingTypeArray = [
    {
      label: "Recurring",
      value: 1,
    },
    {
      label: "One-Time",
      value: 2,
    },
    {
      label: "On-Going",
      value: 3,
    },
  ];

  const [formDataArray, setFormDataArray] = useState([
    {
      date: customFunctions.getDate(date),
      due_date: customFunctions.getDate(due_date),
      client_id: selectedClient?.value,
      terms: terms,
      notes: notes,
      hsn_sac: "",
      cgst_percent: cgst,
      sgst_percent: sgst,
      amount: "",
      billing_type: "",
      billing_type_name: "",
      billing_lisitng: {},
      services_id: "",
      service_name: "",
      services_listing: "",
      availableServices: serviceArray,
      availableBilling: clientBillingTypeArray,
    },
  ]);

  // Get Client Filter by Id
  const [typeClient, setClientType] = useState<any>("");

  // for Paginations
  const initialPagination = {
    currentPage: 1,
    itemsPerPage: 10,
    totalItems: 1,
  };
  const [pagination, setPagination] = useState(initialPagination);

  // Get Client Type Filter by Id
  const clientTypeArray = [
    {
      label: "Proprietor",
      value: 1,
    },
    {
      label: "Corporate",
      value: 2,
    },
    {
      label: "Non-Corporate",
      value: 3,
    },
  ];

  const [availableBilling, setAvailableBillingType] = useState<any>(
    clientBillingTypeArray
  );

  // Table Fields
  const tableColumns: any = [
    {
      label: "SR No",
      id: "sr_no",
    },
    // {
    //   label: "Client name",
    //   id: "client_name",
    // },
    {
      label: "Service/Line item",
      id: "services",
    },
    {
      label: "HSN/SAC",
      id: "hsn_sac",
    },
    {
      label: "CGST",
      id: "cgst",
    },
    {
      label: "SGST",
      id: "sgst",
    },
    {
      label: "Amount",
      id: "amount",
    },
  ];

  // useEffect(() => {
  //   getLineItemsData();
  // },[])

  // All use effects
  useEffect(() => {
    if (!tableData) return;
    getTableData(1);
  }, [tableData, invoiceId, totalAmount, totalInWord]);

  useEffect(() => {
    setTableData();
  }, [invoiceData]);

  useEffect(() => {
    if (!typeClient) return;
    getClientName();
  }, [typeClient]);

  useEffect(() => {
    if (!selectedClient) return;
    getClientServices(selectedClient.value);
  }, [selectedClient]);

  useEffect(() => {
    if (!state?.state?.updateInvoiceId) return;
    getDataForUpdate();
  }, [state?.state?.updateInvoiceId]);

  useEffect(() => {
    if (!formLineItemArray) return;
    setUpdateInvoice(true);
  }, [formLineItemArray]);

  useEffect(() => {
    if (!getLineItems) return;
    getLineItemsData();
  }, [getLineItems]);

  useEffect(() => {
    if (!selectedService) return;
    setShowDefault(true);
  }, [selectedService]);

  const getLineItemsData = async () => {
    setShowLoader(true);

    const callback: any = AuthApi.getDataFromServer;
    let url = `${Api.lineItems}`;

    const { data, message } = await callback(url);

    if (data && data.success === true) {
      setShowLoader(false);
      let lineItemArr: any = [];

      data &&
        data.data &&
        data.data.map((item: any) => {
          lineItemArr.push({
            label: item?.name,
            value: item?.id,
          });
        });
      setLineItemsUpdateArray(lineItemArr);
    } else {
      setShowLoader(false);
      swal(typeof message == "string" ? message : message[0], "", "error");
    }
  };

  const getDataForUpdate = async () => {
    setShowLoader(true);

    const callback: any = AuthApi.getDataFromServer;
    let url = `${Api.invoiceListUrl}?invoice_id=${Number(
      state?.state?.updateInvoiceId
    )}`;
    const { data, message } = await callback(url);

    if (data && data.success === true) {
      setShowLoader(false);
      // setInvoiceData(data.data);
      let updatedFormArray: any = [];
      let updatedLineItemsArray: any = [];

      data.data &&
        data.data.length &&
        data.data.map((item: any) => {

          setClientType({
            label:
              item.client.client_type == 1
                ? "Proprietor"
                : item.client.client_type == 2
                ? "Corporate"
                : "Non-Corporate",
            value: item.client.client_type,
          });
          setTerms(item?.terms);
          setNotes(item?.notes);
          setSelectedClient({
            label: item?.client.client_name,
            value: item.client.id,
          });

          if (item?.services) {
            let bill_name = setCGST(item.cgst_percent ? item.cgst_percent : 0);
            setSGST(item.sgst_percent);
            // setSelectedService({label: item?.services?.services, value:item?.services.services_name})
            updatedFormArray.push({
              id: item?.id ? item.id : null,
              invoice_id: item?.invoice?.id,
              date: date
                ? customFunctions.getDate(date)
                : customFunctions.getDate(item.date),
              due_date: due_date
                ? customFunctions.getDate(due_date)
                : customFunctions.getDate(item.due_date),
              client_id: item?.client.id,
              terms: item?.terms,
              notes: item.notes,
              hsn_sac: item?.hsn_sac,
              cgst_percent: item?.cgst_percent,
              sgst_percent: item?.sgst_percent,
              amount: item?.amount,
              availableBilling: clientBillingTypeArray,
              billing_type: item?.billing_type,
              billing_type_name:
                item?.billing_type == 1
                  ? "Recurring"
                  : item?.billing_type == 2
                  ? "One-Time"
                  : "On-Going",
              billing_lisitng: {
                label:
                  item?.billing_type == 1
                    ? "Recurring"
                    : item?.billing_type == 2
                    ? "One-Time"
                    : "On-Going",
                value: item?.billing_type,
              },
              services_id: item?.services.services,
              service_name: item?.services.services_name,
              services_listing: {
                label: item?.services.services_name,
                value: item?.services.services,
              },
            });
          } else {
            updatedLineItemsArray.push({
              id: item?.id ? item.id : null,
              invoice_id: item?.invoice?.id,
              date: date
                ? customFunctions.getDate(date)
                : customFunctions.getDate(item.date),
              due_date: due_date
                ? customFunctions.getDate(due_date)
                : customFunctions.getDate(item.due_date),
              client_id: item?.client.id,
              terms: item?.terms,
              notes: item.notes,
              amount: item?.amount,
              line_item_id: item?.line_item?.id,
              lineItemsList: {
                label: item?.line_item.name,
                value: item?.line_item.id,
              },
            });
          }
        });
      // const finalSetFormArray = updatedFormArray.concat(updatedLineItemsArray);
      setFormDataArray(updatedFormArray);
      setFormLineItemArray(updatedLineItemsArray);
      setGetLine(true);
    } else {
      setShowLoader(false);
      swal(typeof message == "string" ? message : message[0], "", "error");
    }
  };

  // get table data through API
  const getTableData = async (page: any, clearFilter?: boolean) => {
    const pageNo: any = page || 1;
    setShowLoader(true);
    const endPoint: any = Api.invoiceListUrl;

    const params: any = {
      page: pageNo,
      limit: pagination.itemsPerPage,
      client_id: !clearFilter && selectedClient ? selectedClient.value : "",
      invoice_id: invoiceId,
    };

    const url: any = customFunctions.generateUrl(endPoint, params);
    const { data, message } = await AuthApi.getDataFromServer(url);

    if (data && data.success === true) {
      setShowLoader(false);
      setPagination({
        itemsPerPage: Number(10),
        currentPage: data.paginator.current_page,
        totalItems: data.paginator.total_count,
      });

      setInvoiceData(data.data);
    } else {
      setShowLoader(false);
      swal(typeof message == "string" ? message : message[0], "", "error");
    }
  };

  // Function for set data into table rows
  const setTableData = async () => {
    const rowData: any = [];
    invoiceData &&
      invoiceData.map((item: any, index: any) => {
        rowData.push({
          index:
            (pagination.currentPage - 1) * pagination.itemsPerPage +
            (index + 1),

          sr_no: index + 1,
          // client_name: item.client.client_name,
          invoice_date: item.date,
          hsn_sac: item.hsn_sac ? item.hsn_sac : "-",
          due_date: item.due_date,
          cgst: item.cgst ? item.cgst : "-",
          sgst: item.sgst ? item.sgst : "-",
          amount: item.amount,
          // setTotalAmount(item.totalAmount),
          services: item.services
            ? item.services.services_name
            : item.line_item?.name,
        });
      });
    setRows(rowData);
    forceUpdate(count + 1);
  };

  /* function for get client name for client details 
    depend upone client_type */
  const getClientName = async () => {
    setShowLoader(true);
    let url = `${Api.clientUrl}?client_type=${typeClient.value}`;
    const { data, message } = await AuthApi.getDataFromServer(url);
    const empData: any = [];
    data &&
      data.data &&
      data.data.map((dt: any) => {
        empData.push({
          label: dt.client_name,
          value: dt.id,
        });
      });
    setClientList(empData);
    setShowLoader(false);
  };

  /* function for get client related services for client details 
    depend upone client */
  const getClientServices = async (id: any) => {
    setShowLoader(true);

    const { data, message } = await AuthApi.getDataFromServer(
      `${Api.clientUrl}${id}/`
    );

    if (data && data.success === true) {
      setShowLoader(false);
      setCompanyId(data.data.company.company);
      let serviceArr: any = [];
      data &&
        data.data &&
        data.data.tasks &&
        data.data.services.length > 0 &&
        data.data.services.map((item: any) => {
          serviceArr.push({
            label: item?.service_name,
            value: item?.service_id,
          });
          // }
        });
      setallServicesData(data?.data?.services);
      setServicesArray(serviceArr);
      setAvailableServices(serviceArr);
    } else {
      setShowLoader(false);
      swal(typeof message == "string" ? message : message[0], "", "error");
    }
  };

  // for validations
  const allValid = () => {
    if (validator.current.allValid()) {
      handleSubmit();
    } else {
      validator.current.showMessages();
      forceUpdate(count + 1);
    }
  };

  // for line items pop up validation
  const allValidForPop = (e: any) => {
    e.preventDefault();
    if (validator.current.allValid()) {
      setUpdateInvoice(true);
    } else {
      validator.current.showMessages();
      forceUpdate(count + 1);
    }
  };

  /* Function for perfor action on '-' button to Add forms*/
  const addForm = () => {
    setFormDataArray((prevDataArray) => [
      ...prevDataArray,
      {
        date: customFunctions.getDate(date),
        due_date: customFunctions.getDate(due_date),
        client_id: selectedClient?.value,
        terms: terms,
        notes: notes,
        cgst_percent: cgst,
        sgst_percent: sgst,
        amount: "",
        hsn_sac: "",
        billing_type: "",
        billing_type_name: "",
        billing_lisitng: {},
        services_id: "",
        service_name: "",
        services_listing: "",
        availableBilling: clientBillingTypeArray,
        availableServices: serviceArray,
      },
    ]);
  };

  /* Function for perfor action on '-' button to remove forms*/
  const removeFormField = (index: any) => {
    setFormDataArray((prevDataArray: any) => {
      const updatedDataArray = [...prevDataArray];
      updatedDataArray.splice(index, 1);
      return updatedDataArray;
    });
  };

  const removeLineItemFormField = (index: any) => {
    setFormLineItemArray((prevDataArray: any) => {
      const updatedDataArray = [...prevDataArray];
      updatedDataArray.splice(index, 1);
      return updatedDataArray;
    });
  };

  const removeForm = async (index: number, id: any) => {
    if (!id) {
      removeFormField(index);
    } else {
      const url = `${Api.invoiceListUrl}${id}/`;
      const callback: any = AuthApi.deleteDataFromServer;
      const { data, message } = await callback(url);

      if (data && data.success === true) {
        // setViewPdf(data.data.attachement);
        // setShowPDF(true);
        setShowLoader(false);
        window.location.reload();
        return;
      } else {
        setShowLoader(false);
        swal(message, "", "error");
      }
    }
  };

  const removeLineItesmForm = async (index: number, id: any) => {
    if (!id) {
      removeLineItemFormField(index);
    } else {
      const url = `${Api.invoiceListUrl}${id}/`;
      const callback: any = AuthApi.deleteDataFromServer;
      const { data, message } = await callback(url);

      if (data && data.success === true) {
        // setViewPdf(data.data.attachement);
        // setShowPDF(true);
        setShowLoader(false);
        window.location.reload();
        return;
      } else {
        setShowLoader(false);
        swal(message, "", "error");
      }
    }
  };

  /* Function for perfor action on save as a draft button */
  const saveAsDraft = (isChecked: boolean) => {
    setShowLoader(true);
    if (isChecked) {
      swal("Save as draft successfully!", "", "success").then(() => {
        window.location.reload();
        setShowLoader(false);
      });
    } else {
      setShowLoader(false);
      swal("Please refresh the page", "", "error");
    }
  };

  // Clear all filed and referesh the page
  const isClear = () => {
    setClientType("");
    setDate("");
    setDueDate("");
    setNotes("");
    setTerms("");
    setSelectedClient("");
    setSelectedService("");
    setHSNSAC("");
    setCGST("");
    setSGST("");
    setFormDataArray([
      {
        date: "",
        due_date: "",
        client_id: "",
        terms: "",
        notes: "",
        hsn_sac: "",
        cgst_percent: "",
        sgst_percent: "",
        amount: "",
        billing_type: "",
        billing_type_name: "",
        billing_lisitng: {},
        services_id: "",
        service_name: "",
        services_listing: "",
        availableBilling: clientBillingTypeArray,
        availableServices: serviceArray,
      },
    ]);
    // window.location.reload();
  };

  /* Function for handle submition for client line items
     API data creations
  */
  const handleSubmit = async () => {
    setShowLoader(true);

    let invoice: any = null;
    formDataArray &&
      formDataArray.length > 0 &&
      formDataArray.map((item: any) => {
        invoice = item?.invoice_id;
        if (item.availableBilling) {
          delete item.availableBilling;
        }
        if (item.billing_lisitng) {
          delete item.billing_lisitng;
        }
        if (item.billing_type_name) {
          delete item.billing_type_name;
        }
        if (item.services_listing) {
          delete item.services_listing;
        }
        if (item.availableServices) {
          delete item.availableServices;
        }
        if (item.service_name) {
          delete item.service_name;
        }
      });

    formLineItemArray &&
      formLineItemArray.length > 0 &&
      formLineItemArray.map((item: any) => {
        if (item.lineItemsList) {
          delete item.lineItemsList;
        }
        if (item.availableLineItem) {
          delete item.availableLineItem;
        }
      });

    const postdata = formDataArray.concat(formLineItemArray);

    postdata.map((items: any, index: any) => {
      items.date = null;
      items.due_date = null;
      items.invoice_id = invoice;
      items.client_id = selectedClient?.value;
      items.terms = terms;
      items.cgst_percent = Number(cgst);
      items.sgst_percent = Number(sgst);
      items.notes = notes;
    });

    // const postdata: any = formDataArray;

    const { data, message } = await AuthApi.putDataToServer(
      `${Api.invoiceListUrl}`,
      postdata
    );

    if (data && data.success === true) {
      let invoice = null;
      let totalAmounts = null;
      let totalInWords = null;

      data.data &&
        data.data.map((item: any, index: any) => {
          invoice = item?.invoice_id;
          totalAmounts = item?.total_amount;
          totalInWords = item?.total_in_words;
        });

      setInvoiceId(invoice);
      setTotalAmount(totalAmounts);
      setTotalInWords(totalInWords);

      setShowLoader(false);
      swal("Entries updated successfully!", "", "success").then(() => {
        // props.callBack();
        showTableData(true);
        // nevigate("/invoice-list-static");
        return;
      });
    } else {
      setShowLoader(false);
      swal(message, "", "error");
    }
  };

  const getServicesData = (pIndex: any) => {
    const serviceDetailsArr = [...formDataArray];
    if (serviceDetailsArr && serviceDetailsArr.length) {
      let filterArr = serviceArray.filter((sr: any) => {
        return serviceDetailsArr.findIndex(
          (serv: any, i: any) =>
            sr?.value === serv?.services_listing?.value && i !== pIndex
        ) === -1
          ? true
          : false;
      });
      serviceDetailsArr[pIndex].availableServices = filterArr?.length
        ? filterArr
        : [];
    }
    setFormDataArray(serviceDetailsArr);
    forceUpdate(count + 1);
  };

  const getLineItemsAvailable = (pIndex: any) => {
    const lineItemsDetailsArr = [...formLineItemArray];
    if (lineItemsDetailsArr && lineItemsDetailsArr.length) {
      let filterArr = lineItemsUpdateArray.filter((sr: any) => {
        return lineItemsDetailsArr.findIndex(
          (serv: any, i: any) =>
            sr?.value === serv?.lineItemsList?.value && i !== pIndex
        ) === -1
          ? true
          : false;
      });
      lineItemsDetailsArr[pIndex].availableLineItem = filterArr?.length
        ? filterArr
        : [];
    }
    setFormLineItemArray(lineItemsDetailsArr);
    forceUpdate(count + 1);
  };

  const setBillingFromService = (select: any, index: any) => {
    allServicesData &&
      allServicesData.length > 0 &&
      allServicesData.map((chunks: any) => {
        if (chunks?.service_id == select?.value) {
          let billingObj = {
            label: chunks?.billing_type,
            value: chunks?.billing_type_id,
          };
          let chooseBilling = formDataArray;
          chooseBilling[index].billing_type = chunks?.billing_type_id;
          chooseBilling[index].billing_type_name = chunks?.billing_type;
          chooseBilling[index].billing_lisitng = billingObj;
          setFormDataArray(chooseBilling);
        }
      });
  };

  return (
    <>
      <Helmet title={title} />
      <div className="page-header">
        <div className="title-breadcrumb-section">
          <h2
            className="main-content-title tx
      const payload = {
        ids:deleteEntries
      };-24 mg-b-5"
          >
            {title}
          </h2>
          <Breadcrumb items={items} />
        </div>
      </div>
      <Card className="mt-3">
        <Card.Body>
          <Row>
            <Col sm={4}>
              <DropdownInput
                label="Client type"
                asterisk="*"
                defaultValue={typeClient}
                options={clientTypeArray}
                onSelectHandler={(select: any) => {
                  setClientType(select);
                }}
                isClearable
                disabled={true}
                errorMessage={validator.current.message(
                  "Client type",
                  typeClient,
                  "required"
                )}
              />
            </Col>
            <Col sm={4}>
              <DropdownInput
                label="Client name"
                asterisk="*"
                defaultValue={selectedClient}
                onSelectHandler={(select: any) => {
                  setSelectedClient(select);
                  getClientServices(select.value);
                  setSelectedService(null);
                  setServicesArray("");
                }}
                options={clientList}
                isClearable
                disabled={true}
                errorMessage={validator.current.message(
                  "Client",
                  selectedClient,
                  "required"
                )}
              />
            </Col>
            {/* <Col sm={4}>
              <Datepicker
                className="form-control"
                label="Invoice date"
                asterisk="*"
                selected={date}
                dateFormat="dd/MM/yyyy"
                placeholder="dd/mm/yyyy"
                onChange={(e: any) => {
                  setDate(e);
                  // formData.date = e;
                }}
                errorMessage={validator.current.message(
                  "Date",
                  date,
                  "required"
                )}
              />
            </Col> 
             <Col sm={4}>
              <Datepicker
                className="form-control"
                label="Due date"
                asterisk="*"
                placeholder="dd/mm/yyyy"
                dateFormat="dd/MM/yyyy"
                minDate={date}
                selected={due_date}
                onChange={(e: any) => {
                  setDueDate(e);
                  // setShowLineButton(true);
                  // formData.due_date = e;
                }}
                errorMessage={validator.current.message(
                  "Due date",
                  due_date,
                  "required"
                )}
              />
            </Col> */}
            {/* </Row>
          <Row> */}
            <Col sm={2}>
              <TextInput
                label="CGST (%)"
                value={cgst}
                placeholder="%"
                onInputChange={(e: any) => {
                  const re = /^[0-9]+(\\.[0-9]+)?[%]?/;
                  if (!e.target.value || re.test(e.target.value)) {
                    e.preventDefault();
                    setCGST(e.target.value);
                    // formData.cgst = Number(e.target.value);
                  }
                }}
                // disabled={true}
              />
            </Col>
            <Col sm={2}>
              <TextInput
                label="SGST (%)"
                value={sgst}
                placeholder="%"
                onInputChange={(e: any) => {
                  const re = /^[0-9]+(\\.[0-9]+)?[%]?/;
                  if (!e.target.value || re.test(e.target.value)) {
                    e.preventDefault();
                    setSGST(e.target.value);
                    // formData.sgst = Number(e.target.value);
                  }
                }}

                // disabled={true}
              />
            </Col>
            {/* </Row>
          <Row> */}
            <Col sm={4}>
              <TextInput
                type="text"
                as="textarea"
                label="Notes"
                value={notes}
                onInputChange={(e: any) => {
                  e.preventDefault();
                  setNotes(e.target.value);
                  // formData.notes = e.target.value;
                }}
              />
            </Col>
            <Col sm={4}>
              <TextInput
                type="text"
                as="textarea"
                label="Terms"
                value={terms}
                onInputChange={(e: any) => {
                  e.preventDefault();
                  setTerms(e.target.value);
                  // formData.terms = e.target.value;
                }}
              />
            </Col>
          </Row>{" "}
          &nbsp;&nbsp;
          <Card>
            <Card.Body>
              {formDataArray.map((formData: any, index: any) => (
                <>
                  <Loader showLoader={isShowLoader} needFullPage={false} />
                  <Row>
                    {
                      <>
                        <Col sm={3}>
                          <DropdownInput
                            asterisk="*"
                            className="d-flex justify-content-around"
                            label={index == 0 && "Services"}
                            defaultValue={formData?.services_listing}
                            onSelectHandler={(select: any) => {
                              setSelectedService(select);
                              // formData.services_listing = select;
                              let chooseService = formDataArray;
                              chooseService[index].services_listing = select;
                              chooseService[index].services_id = select.value;
                              chooseService[index].service_name = select.label;
                              setFormDataArray(chooseService);

                              setBillingFromService(select, index);
                            }}
                            options={formData.availableServices}
                            onMenuOpen={() => {
                              getServicesData(index);
                            }}
                            disabled={!selectedClient}
                            errorMessage={validator.current.message(
                              "services",
                              formData?.services_listing,
                              "required"
                            )}
                          />
                        </Col>
                        <Col sm={2}>
                          <DropdownInput
                            asterisk="*"
                            className="d-flex justify-content-around"
                            label={index == 0 && "Billing type"}
                            defaultValue={formData.billing_lisitng}
                            disabled={true}
                            errorMessage={validator.current.message(
                              "billing type",
                              formData.billing_lisitng,
                              "required"
                            )}
                          />
                        </Col>
                        <Col sm={2}>
                          <TextInput
                            label={index == 0 && "HSN/SAC"}
                            asterisk="*"
                            value={formData.hsn_sac}
                            maxlength={6}
                            placeholder="0"
                            onInputChange={(e: any) => {
                              const re = /^[0-9]/;
                              if (!e.target.value || re.test(e.target.value)) {
                                setHSNSAC(e.target.value);
                                formData.hsn_sac = Number(e.target.value);
                              }
                            }}
                            errorMessage={validator.current.message(
                              "hsn_sac value",
                              formData.hsn_sac,
                              "required"
                            )}
                          />
                        </Col>
                        <Col sm={2}>
                          <TextInput
                            asterisk="*"
                            label={index == 0 && "Amount"}
                            value={formData.amount}
                            placeholder="0"
                            onInputChange={(e: any) => {
                              const re = /^[0-9]+(\\.[0-9]+)?[%]?/;
                              if (!e.target.value || re.test(e.target.value)) {
                                setAmount(e.target.value);
                                formData.amount = Number(e.target.value);
                              }
                            }}
                            errorMessage={validator.current.message(
                              "Amount value",
                              formData.amount,
                              "required"
                            )}
                          />
                        </Col>
                        <Col>
                        <div className="mt-1">
                    {formDataArray.length > 1 && (
                      <button
                      className={index==0 ? "mt-4" : "ml-10"}
                      style={{
                        width: "30px",
                        height: "30px",
                        color: "white",
                        backgroundColor: "#24959D",
                        border: "#24959D",
                        marginLeft: "10px",
                        marginTop: "2px",
                      }}
                        onClick={(e) => {
                          e.preventDefault();
                          removeForm(index, formData.id);
                        }}
                        // disabled={disableButton}
                      >
                        <h3>-</h3>
                      </button>
                    )}{" "}
                    &nbsp;&nbsp;
                    {!tableData && formDataArray &&
                      formDataArray.length - 1 === index &&
                      formDataArray.length - 1 !== formDataArray.length && (
                        <button
                        className={index==0 ? "mt-4" : "ml-10"}
                        style={{
                          width: "30px",
                          height: "30px",
                          color: "white",
                          backgroundColor: "#24959D",
                          border: "#24959D",
                          marginLeft: "10px",
                          marginTop: "2px",
                        }}
                          onClick={(e) => {
                            e.preventDefault();
                            addForm();
                          }}
                          // disabled={disableButton}
                        >
                          <h3>+</h3>
                        </button>
                      )}{" "}
                    &nbsp;&nbsp;
                  </div>
                        </Col>
                      </>
                    }
                  </Row>
                  
                </>
              ))}
            </Card.Body>
          </Card>
          <Card>
            <Card.Body>
              {updateInvoice &&
                formLineItemArray.map(
                  (formLineItemData: any, LineItemIndex: any) => (
                    <>
                      <Loader showLoader={isShowLoader} needFullPage={false} />
                      <Row>
                        <Col sm={3}>
                          <DropdownInput
                            asterisk="*"
                            className="d-flex justify-content-around"
                            label={LineItemIndex == 0 && "Line items"}
                            defaultValue={formLineItemData.lineItemsList}
                            onSelectHandler={(select: any) => {
                              setSelectedLineItems(select);
                              let chooseService = formLineItemArray;
                              chooseService[LineItemIndex].lineItemsList =
                                select;
                              chooseService[LineItemIndex].line_item_id =
                                select.value;
                              setFormLineItemArray(chooseService);
                            }}
                            options={formLineItemData.availableLineItem}
                            onMenuOpen={() => {
                              getLineItemsAvailable(LineItemIndex);
                            }}
                            errorMessage={validator.current.message(
                              "Line items",
                              formLineItemData.lineItemsList,
                              "required"
                            )}
                          />
                        </Col>
                        <Col sm={3}>
                          <TextInput
                            asterisk="*"
                            label={LineItemIndex == 0 && "Amount"}
                            value={formLineItemData.amount}
                            placeholder="0"
                            onInputChange={(e: any) => {
                              const re = /^[0-9]+(\\.[0-9]+)?[%]?/;
                              if (!e.target.value || re.test(e.target.value)) {
                                e.preventDefault();
                                setLineItemAmount(e.target.value);
                                formLineItemData.amount = Number(
                                  e.target.value
                                );
                              }
                            }}
                            errorMessage={validator.current.message(
                              "Amount value",
                              formLineItemData.amount,
                              "required"
                            )}
                          />
                        </Col>
                        <Col>
                        <div className="mt-1">
                        {formLineItemArray.length > 1 && (
                          <button
                          className={LineItemIndex==0 ? "mt-4" : "ml-10"}
                          style={{
                            width: "30px",
                            height: "30px",
                            color: "white",
                            backgroundColor: "#24959D",
                            border: "#24959D",
                            marginLeft: "10px",
                            marginTop: "2px",
                          }}
                            onClick={(e) => {
                              e.preventDefault();
                              removeLineItesmForm(
                                LineItemIndex,
                                formLineItemData.id
                              );
                            }}
                            // disabled={disableButton}
                          >
                            <h3>-</h3>
                          </button>
                        )}
                        {!tableData && formLineItemArray &&
                          formLineItemArray.length - 1 === LineItemIndex &&
                          formLineItemArray.length - 1 !==
                            formLineItemArray.length && (
                            <button
                            className={LineItemIndex==0 ? "mt-4" : "ml-10"}
                            style={{
                              width: "30px",
                              height: "30px",
                              color: "white",
                              backgroundColor: "#24959D",
                              border: "#24959D",
                              marginLeft: "10px",
                              marginTop: "2px",
                            }}
                              onClick={(e) => {
                                e.preventDefault();
                                addLineItemsForm();
                              }}
                              // disabled={disableButton}
                            >
                              <h3>+</h3>
                            </button>
                          )}
                      </div>
                        </Col>
                      </Row>
                      {/* &nbsp; */}
                      {/* <hr /> */}
                    </>
                  )
                )}
            </Card.Body>
          </Card>
          <div>
            {!tableData && (
              <Button
              type="submit"
              onClick={(e: any) => {
                e.preventDefault();
                allValid();
                // handleSubmit()
                // setChildToggle(true);
                showTableData(true);
              }}
            >
              Submit
            </Button>
            )}{" "}
            &nbsp;&nbsp;
            {!tableData && (
              <Button
              type="button"
              onClick={() => {
                isClear();
                nevigate("/invoice-list-static");
                showTableData(false);
              }}
            >
              Cancel
            </Button>
            )}
            </div>{" "}
          &nbsp;&nbsp;
            
          {/* <div className="d-flex flex-row-reverse">
            {showLineButton && (
              <Button
                type="button"
                className="btn btn-primary btn-sm"
                onClick={(e: any) => {
                  // allValidForPop(e);
                  // setUpdateInvoice(true);
                  getLineItemsData();
                }}
              >
                Add Lineitem
              </Button>
            )}{" "}
            &nbsp;&nbsp;
          </div> */}
          <hr />
          <div>
            {tableData && (
              <Table
                columns={tableColumns}
                showCheckbox={false}
                rows={rows}
                showPagination
                paginationProps={{
                  ...pagination,
                  currentPage: Number(pagination.currentPage),
                  onPageChange: (pageNo: any) => {
                    getTableData(pageNo);
                  },
                }}
                dropdownContainerClass="mb-3"
                emptyRender={() => (
                  <p className="text-center mb-0">No record found</p>
                )}
              />
            )}
          </div>
          <hr />
          {tableData && (
            <div className="">
              <div>
                <p style={{ textAlign: "right" }}>
                  <b> Total Amount :</b> {totalAmount} Rs
                </p>
              </div>
              <div>
                <p style={{ textAlign: "right" }}>
                  <b> Total Amount in Words :</b> {totalInWord} only
                </p>
              </div>
              <div>
                {showLineButton && (
                  <Button
                    type="button"
                    className="btn btn-primary btn-sm"
                    onClick={(e: any) => {
                      // saveAsDraft(true);
                      nevigate('/invoice-list-static')
                    }}
                  >
                    Save as a draft
                  </Button>
                )}{" "}
                &nbsp;&nbsp;
              </div>
            </div>
          )}
        </Card.Body>
      </Card>
      {childToggle && (
        <div className="sweetalert">
          <SweetAlert
            title={"Are you sure? you want to store this updated invoice?"}
            warning
            showCancel
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            onConfirm={() => {
              // allValid();
              handleSubmit();
              // setCancelBtn(true);
              showTableData(true);
              setChildToggle(false);
              // setIsOkay(true)
            }}
            onCancel={() => {
              // setCancelBtn(true);
              setChildToggle(false);
            }}
          />
        </div>
      )}
    </>
  );
};

export default UpdateInvoice;
