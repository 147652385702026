import { Modal, Button, Card, Col, Row } from "react-bootstrap";

import { useEffect, useState, useRef } from "react";
import Api from "../../helper/api";
import AuthApi from "../../helper/authApi";
import Loader from "../../components/Loader";
import {
  Datepicker,
  DropdownInput,
  TextInput,
  Helmet,
} from "../../components/FormInputs";
import swal from "sweetalert";
import SimpleReactValidator from "simple-react-validator";
import customFunctions from "../../helper/customFunctions";
import Breadcrumb from "../../components/Breadcrumb/breadcrumb.component";

const LineItemsUpdateModel = (props: any) => {
  const validator = useRef(new SimpleReactValidator());
  const [isShowLoader, setShowLoader] = useState<boolean>(false);
  const [selectedStatus, setSelectedStatus] = useState<any>();
  const [date, setDate] = useState<any>();
  const [due_date, setDueDate] = useState<any>();
  const [amount, setAmount] = useState<any>();
  const [total_amount, setTotalAmount] = useState<any>();
  const [invoiceData, setInvoiceData] = useState<any>();
  const [selectedLineItems, setSelectedLineItems] = useState<any>();

  //Titles
  const [title, setTitle] = useState<string>("Add in line items");
  const [label, setLabel] = useState<string>("In line items");
  const items: any = [{ to: "/", label: "Dashboard" }, { label: label }];

  //Get Form Fields
  const [formDataArray, setFormDataArray] = useState([
    {
      date: "",
      due_date: "",
      description: "",
      terms: "",
      notes: "",
      client: "",
      hsn_sac: "",
      cgst: "",
      sgst: "",
      amount: "",
      line_item: "",
    },
  ]);

  /*
  {
"hsn_sac":"NEWDEMOssjnjsn",
"description": "client line items ===============",
"cgst":12.2,
"sgst":10.5,
"amount":3650,
"line_item":2,
"client":63,
"spent_hours":6,
"total_amount":4108,
"notes":"Hello this is demo note {{{{{{{{",
"date":"2023-10-15",
"due_date":"2023-10-20",
"services":5,
"billing_type": "One time",
"terms":"No terms"
},
  */

  const [description, setDescription] = useState<any>();
  const [terms, setTerms] = useState<any>();
  const [notes, setNotes] = useState<any>();
  const [hsn_sac, setHSNSAC] = useState<any>();
  const [cgst, setCGST] = useState<any>("");
  const [sgst, setSGST] = useState<any>("");

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number
  ) => {
    const { name, value } = event.target;

    setFormDataArray((prevDataArray) => {
      const updatedDataArray = [...prevDataArray];
      updatedDataArray[index] = {
        ...updatedDataArray[index],
        [name]: value,
      };
      return updatedDataArray;
    });
  };

  // ADD or Remove Form
  const addForm = () => {
    setFormDataArray((prevDataArray) => [
      ...prevDataArray,
      {
        date: customFunctions.getDate(props?.invoiceData.date),
        due_date: customFunctions.getDate(props?.invoiceData.due_date),
        description: "",
        client: props.invoiceData.client.value,
        terms: props.invoiceData.terms,
        notes: props.invoiceData.notes,
        hsn_sac: "",
        cgst: "",
        sgst: "",
        amount: "",
        line_item: "",
      },
    ]);
  };

  const lineItemsArray: any = [
    {
      label: "Checking all bills",
      value: 1,
    },
    {
      label: "Testing with AI",
      value: 2,
    },
  ];
  const statusArray: any = [
    {
      label: "Draft",
      value: 1,
    },
    {
      label: "Pending",
      value: 2,
    },
    {
      label: "Published",
      value: 3,
    },
    {
      label: "Completed",
      value: 4,
    },
  ];

  const handleSubmit = async (event: React.FormEvent, index: number) => {
    // if (invoiceId) {
    const postdata: any = formDataArray;

    const { data, message } = await AuthApi.postDataToServer(
      `${Api.invoiceListUrl}`,
      postdata
    );

    if (data && data.success === true) {
      props.updatePopupClose();
      swal("Line items save successfully!", "", "success").then(() => {
        props.callBack();
        return;
      });
    } else {
      swal(message, "", "error");
    }
    // }
  };

  const removeForm = (index: number) => {
    setFormDataArray((prevDataArray) => {
      const updatedDataArray = [...prevDataArray];
      updatedDataArray.splice(index, 1);
      return updatedDataArray;
    });
  };

  const isClear = () => {
    setDate("");
    setDueDate("");
    setAmount("");
    setTotalAmount("");
    setSelectedStatus("");
    setFormDataArray([
      {
        date: customFunctions.getDate(props?.invoiceData.date),
        due_date: customFunctions.getDate(props?.invoiceData.due_date),
        description: "",
        client: props.invoiceData.client.value,
        terms: "",
        notes: "",
        hsn_sac: "",
        cgst: "",
        sgst: "",
        amount: "",
        line_item: "",
      },
    ]);
    // window.location.reload();
  };

  return (
    <>
      <Helmet title={title} />
      <div className="page-header">
        <div className="title-breadcrumb-section">
          <h2
            className="main-content-title tx
      const payload = {
        ids:deleteEntries
      };-24 mg-b-5"
          >
            {title}
          </h2>
          <Breadcrumb items={items} />
        </div>
      </div>
      <Loader showLoader={isShowLoader} needFullPage={false} />
      <Card>
        <Card.Body>
          {formDataArray.map((formData: any, index: any) => (
            <>
              <Loader showLoader={isShowLoader} needFullPage={false} />
              <Row>
                <Col sm={6}>
                  <DropdownInput
                    asterisk="*"
                    className="d-flex justify-content-around"
                    label="Line items"
                    defaultValue={selectedLineItems}
                    onSelectHandler={(select: any) => {
                      setSelectedLineItems(select);
                      // handleDropdownChange(select, index, 1)
                    }}
                    options={lineItemsArray}
                    errorMessage={validator.current.message(
                      "Service",
                      selectedLineItems,
                      "required"
                    )}
                  />
                </Col>
                <Col sm={6}>
                  <TextInput
                    asterisk="*"
                    label="Amount"
                    value={amount}
                    placeholder="0"
                    onInputChange={(e: any) => {
                      const re = /^[0-9]+(\\.[0-9]+)?[%]?/;
                      if (!e.target.value || re.test(e.target.value)) {
                        e.preventDefault();
                        setAmount(e.target.value);
                        // formData.amount = Number(e.target.value);
                      }
                    }}
                    errorMessage={validator.current.message(
                      "Amount value",
                      amount,
                      "required"
                    )}
                  />
                </Col>
              </Row>{" "}
              &nbsp;&nbsp;
              <hr />
              <div className="d-flex flex-row-reverse">
                {formDataArray.length > 1 && (
                  <Button
                    type="button"
                    className="btn btn-primary btn-sm"
                    onClick={(e: any) => {
                      e.preventDefault();
                      removeForm(index);
                    }}
                  >
                    -
                  </Button>
                )}{" "}
                &nbsp;&nbsp;
                {formDataArray &&
                  formDataArray.length - 1 === index &&
                  formDataArray.length - 1 !== formDataArray.length && (
                    <Button
                      type="button"
                      className="btn btn-primary btn-sm"
                      onClick={addForm}
                    >
                      +
                    </Button>
                  )}{" "}
                &nbsp;&nbsp;
              </div>
              <div>
                <Button
                  variant="primary"
                  onClick={(e: any) => {
                    handleSubmit(e, index);
                  }}
                >
                  Save
                </Button>{" "}
                &nbsp;&nbsp;
                <Button
                  variant="secondary"
                  onClick={() => {
                    isClear();
                  }}
                >
                  Cancle
                </Button>
              </div>
            </>
          ))}
        </Card.Body>
      </Card>
    </>
  );
};

export default LineItemsUpdateModel;
