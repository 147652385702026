/* eslint-disable */
import React, { useEffect, useState } from "react";
import { AsyncPaginate } from "react-select-async-paginate";
import { Form } from "react-bootstrap";
import AuthApi from "../../helper/authApi";
import customFunctions from "../../helper/customFunctions";

const SelectAsyncPaginate = (props) => {
  const {
    isClearable,
    value,
    clearSelected,
    onSelectChange,
    selectPlaceholder,
    key,
    className,
    disabled,
    isSearch,
    endpoint,
    apiname,
    isMulti,
    clearCacheOnID,
    clearSelectedByParent,
    label,
    asterisk,
    errorMessage,
    id,
    roleId,
    currentUserId,
    fromComponent,
  } = props;
  const [selectedValue, setSelectedValue] = useState(isMulti ? [] : null);
  const [totalLoadLength, setTotalLoadLength] = useState(0);
  const [apiTotalCount, setApiTotalCount] = useState(0);

  useEffect(() => {
    setSelectedValue(value);
  }, [value]);

  useEffect(() => {
    if (clearSelectedByParent) setSelectedValue({});
  }, [clearSelectedByParent]);

  const loadOptions = async (searchQuery, loadedOptions, { page }) => {
    const params = {
      page: page,
      limit: 10,
      keyword: `${searchQuery ? searchQuery.toLowerCase() : ""}`,
      sort_direction: "ascending",
      sort_by:
        apiname === "employeeUrl"
          ? "user__first_name"
          : apiname === "clientUrl"
          ? "client_name"
          : apiname === "taskUrl"
          ? "task_name"
          : apiname === "litigationUrl"
          ? "title"
          : "",
    };

    if (["addTask", "createInvoice"].includes(fromComponent)) {
      params.status = "1";
    }
    const url = customFunctions.generateUrl(endpoint, params);

    if (!searchQuery && apiTotalCount !== 0 && apiTotalCount == totalLoadLength)
      return {
        options: [],
        hasMore: false,
      };

    const { data } = await AuthApi.getDataFromServer(url);

    if (apiname === "litigationUrl") {
      if (data && data.data && data.data.length > 0) {
        setApiTotalCount(data.paginator.total_count);
        setTotalLoadLength(Number(totalLoadLength) + Number(data.data.length));
        let litigations = [];
        data.data.map((dt) => {
          if (dt?.title) {
            litigations.push({
              name: `${dt?.title}`,
              id: dt?.id,
            });
          }
        });
        return {
          options: litigations,
          hasMore:
            Number(totalLoadLength) === Number(data.paginator.total_count)
              ? false
              : true,
          additional: {
            page: page + 1,
          },
        };
      } else {
        return {
          options: [],
          hasMore: false,
        };
      }
    }

    if (apiname === "taskUrl") {
      if (data && data.data && data.data.length > 0) {
        setApiTotalCount(data.paginator.total_count);
        setTotalLoadLength(Number(totalLoadLength) + Number(data.data.length));
        let taskData = [];
        data.data.map((dt) => {
          if (!dt?.repitative_status) {
            taskData.push({
              name: `${dt?.task_name}`,
              id: dt?.id,
            });
          }
        });
        return {
          options: taskData,
          hasMore:
            Number(totalLoadLength) === Number(data.paginator.total_count)
              ? false
              : true,
          additional: {
            page: page + 1,
          },
        };
      } else {
        return {
          options: [],
          hasMore: false,
        };
      }
    }

    if (apiname === "employeeUrl") {
      if (data && data.data && data.data.length > 0) {
        setApiTotalCount(data.paginator.total_count);
        setTotalLoadLength(Number(totalLoadLength) + Number(data.data.length));
        let employeeData = [];
        data.data.map((dt) => {
          if (
            dt?.user?.role == 2 ||
            Number(localStorage.getItem("id")) == dt?.user?.user
          )
            return;
          if (fromComponent == "litigation") {
            if (
              !dt?.user?.admin_access &&
              [3, 4, 5].includes(Number(dt?.user?.role))
            ) {
              employeeData.push({
                name: `${dt?.user?.first_name} ${dt?.user?.last_name}`,
                id: dt?.employee?.id,
              });
            }
          } else {
            employeeData.push({
              name: `${dt?.user?.first_name} ${dt?.user?.last_name}`,
              id: dt?.employee?.id,
            });
          }
        });
        return {
          options: employeeData,
          hasMore:
            Number(totalLoadLength) === Number(data.paginator.total_count)
              ? false
              : true,
          additional: {
            page: page + 1,
          },
        };
      } else {
        return {
          options: [],
          hasMore: false,
        };
      }
    }

    if (apiname === "clientUrl") {
      if (data && data.data && data.data.length > 0) {
        setApiTotalCount(data.paginator.total_count);
        setTotalLoadLength(Number(totalLoadLength) + Number(data.data.length));
        let employeeData = [];
        data.data.map((dt) => {
          if (fromComponent == "createInvoice") {
            employeeData.push({
              name: `${dt?.client_name}`,
              id: dt?.id,
              address: dt?.address,
              gstNo: dt?.gst_no,
            });
          } else {
            employeeData.push({
              name: `${dt?.client_name}`,
              id: dt?.id,
            });
          }
        });
        return {
          options: employeeData,
          hasMore:
            Number(totalLoadLength) === Number(data.paginator.total_count)
              ? false
              : true,
          additional: {
            page: page + 1,
          },
        };
      } else {
        return {
          options: [],
          hasMore: false,
        };
      }
    }
  };

  const onSelectChangeData = (option) => {
    if (clearSelectedByParent) setSelectedValue(option);
    if (clearSelectedByParent) {
      setSelectedValue({});
    } else {
      setSelectedValue(option);
    }
    if (onSelectChange) {
      onSelectChange(option);
    }
  };

  const customStyles = {
    option: (styles, state) => ({
      ...styles,
      cursor: "pointer",
    }),
    control: (styles) => ({
      ...styles,
      cursor: "pointer",
    }),
  };

  return (
    <>
      {label && (
        <Form.Label>
          {" "}
          {label} <span style={{ color: "red" }}>{asterisk}</span>
          <span className="serviceNote">
            {fromComponent == "addRefferal" && language}
          </span>
        </Form.Label>
      )}
      <AsyncPaginate
        key={key}
        isMulti={isMulti}
        value={selectedValue}
        className={className}
        styles={customStyles}
        loadOptions={loadOptions}
        getOptionValue={(option) => option.name}
        getOptionLabel={(option) => option.name}
        onChange={onSelectChangeData}
        isDisabled={disabled}
        isSearchable={isSearch}
        placeholder={selectPlaceholder}
        isClearable={isClearable ? isClearable : false}
        cacheUniqs={[clearCacheOnID]}
        additional={{
          page: 1,
        }}
        inputId={id}
      />
      {errorMessage && <p style={{ color: "red" }}> {errorMessage} </p>}
    </>
  );
};

export default SelectAsyncPaginate;
