import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";
import {
  Card,
  Form,
  Col,
  Row,
  Button,
  Accordion,
  Image,
} from "react-bootstrap";
import customFunctions from "../../helper/customFunctions";
import AuthApi from "../../helper/authApi";
import Api from "../../helper/api";
import Breadcrumb from "../../components/Breadcrumb/breadcrumb.component";
import Loader from "../../components/Loader/index";
import { Helmet, DropdownInput } from "../../components/FormInputs";
import Table from "../../components/Table/simpletable.component";
import SweetAlert from "react-bootstrap-sweetalert";
import { serviceStatus } from "../superAdmin/constant";
import SelectAsyncPaginate from "../../components/ReactSelectPagination/SelectAsyncPaginate";
import { DisplayModel } from "../Litigation/employeeModal";

const AssignEmployeeTaskList = () => {
  const navigate = useNavigate();
  const [isShowLoader, setShowLoader] = useState<any>(false);
  const [rows, setRows] = useState<any>([]);
  const [title, setTitle] = useState<any>("Assign Tasks");
  const [count, forceUpdate] = useState<any>(0);
  const [filterErr, setFilterErr] = useState<any>("");
  const [searchKeyword, setSearchKeyword] = useState<any>("");
  const [applyToggle, setApplyToggle] = useState<any>(false);
  const [deleteToggle, setDeleteToggle] = useState<any>(false);
  const [roleId, setRoleId] = useState<number>(0);
  const [currentUserId, setCurrentUserId] = useState<number>(0);
  const [taskData, setTaskData] = useState<any>([]);
  const [taskId, setTaskId] = useState<any>("");
  const [selectedStatus, setSelectedStatus] = useState<any>("");
  const [selectedEmployee, setSelectedEmployee] = useState<any>("");
  const [selectedClient, setSelectedClient] = useState<any>("");
  const [showModal, setShowModal] = useState<boolean>(false);
  const [employeeNames, setEmployeeNames] = useState<any>("");

  const items = [{ to: "/", label: "Dashboard" }, { label: "Assign Tasks" }];
  const initialPagination = {
    currentPage: 1,
    itemsPerPage: 10,
    totalItems: 1,
  };
  const [pagination, setPagination] = useState(initialPagination);

  const columns: any = [
    {
      label: "Sr. No.",
      id: "index",
    },
    {
      label: "Employee Name",
      id: "employee_name",
    },
    {
      label: "Client Name",
      id: "client_name",
    },
    {
      label: "Task Name",
      id: "task_name",
    },
    // {
    //   label: "Status",
    //   id: "status",
    // },
    {
      label: "Action",
      id: "action",
    },
  ];

  useEffect(() => {
    getUserRole();
  }, []);

  const getUserRole = () => {
    const role = customFunctions.getLocalStorage("role_id");
    setRoleId(Number(role));
    const currentUserId = customFunctions.getLocalStorage("id");
    setCurrentUserId(Number(currentUserId));
  };

  useEffect(() => {
    getTaskListData();
  }, [currentUserId]);

  const getTaskListData = async (pageNo?: any, clearFilter = false) => {
    const page = pageNo || 1;
    setShowLoader(true);
    setApplyToggle(false);
    const endPoint = Api.employeeLitigationUrl;
    const params = {
      page: page,
      limit: pagination.itemsPerPage,
      sort_direction: "descending",
      task_type: 1,
      employee: !clearFilter && selectedEmployee ? selectedEmployee?.id : "",
      client_id: !clearFilter && selectedClient ? selectedClient?.id : "",
    };

    const url = customFunctions.generateUrl(endPoint, params);

    const { data, message } = await AuthApi.getDataFromServer(url);

    if (data && data.success === true) {
      setShowLoader(false);
      if (data && data.data && data.data.length > 0) {
        setShowLoader(false);
        setPagination({
          currentPage: data.paginator.current_page,
          itemsPerPage: Number(10),
          totalItems: data.paginator.total_count,
        });
        setTaskData(data.data);
        forceUpdate(count + 1);
      } else {
        setTaskData([]);
      }
    } else {
      setShowLoader(false);
      setTaskData([]);
      swal(
        typeof message == "string"
          ? message
          : message?.length
          ? message[0]
          : "Something went wrong!",
        "",
        "error"
      );
    }
  };

  useEffect(() => {
    setTableData();
  }, [taskData]);

  const setTableData = () => {
    const rowsData: any = [];

    taskData.map((dt: any, i: any) => {
      rowsData.push({
        id: dt?.id,
        index: (pagination.currentPage - 1) * pagination.itemsPerPage + (i + 1),
        employee_name: <>{getEmployee(dt)}</>,
        client_name: (
          <>{dt?.client?.client_name ? dt?.client?.client_name : "-"}</>
        ),
        task_name: <>{dt?.task?.task_name ? dt?.task?.task_name : "-"}</>,
        action: (
          <>
            <a
              href="/"
              className="pl-2 ms-2 underline"
              title={"Edit Task"}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                navigate("/assign-task", {
                  state: {
                    data: dt,
                    pageType: "edit",
                  },
                });
              }}
            >
              Edit
            </a>
            {/* <a
              href="/"
              className="pl-2 ms-1"
              title={"Delete Task"}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                
                setTaskId(dt?.id);
                setDeleteToggle(true);
              }}
            >
              <Image src="images/delete.png" alt="view" />
            </a> */}
            {/* <i
              title="Delete Task"
              className="fa-regular fa-trash-can fa-xl ml-2"
              style={{ color: "#e65b5b", cursor: "pointer" }}
              onClick={() => {
                setTaskId(dt?.id);
                setDeleteToggle(true);
              }}
            ></i> */}
            <a
              href="/"
              className="pl-2 ms-2 underline"
              title={"View Task"}
              onClick={(e) => {
                e?.preventDefault();
                e?.stopPropagation();

                const empIds = dt?.employee?.map((item: any) => item?.id) || [];
                const taskTimeLog = dt?.task_time_logs?.[0] ? dt?.task_time_logs?.[0] : dt?.task_time_logs; // Get the first task time log object

                navigate("/view-taskdetails", {
                  state: {
                    data: dt,
                    pageType: "view",
                    empId: empIds, // Use the assigned variable
                    taskTimeLog: taskTimeLog, // Pass the task time log object
                  },
                });
              }}
            >
              View
            </a>
          </>
        ),
      });
      return dt;
    });
    setRows([...rowsData]);
  };

  const getEmployee = (dt: any) => {
    if (dt?.employee) {
      const arrData = dt?.employee.map((item: any) => item?.employee_name);
      return arrData.length <= 2 ? (
        arrData.join(", ")
      ) : (
        <div>
          <a
            href="/"
            title={"View Employees"}
            onClick={(e) => {
              e.preventDefault();
              setEmployeeNames(arrData);
              setShowModal(true);
            }}
          >{`${arrData.slice(0, 2).join(", ")}...`}</a>
        </div>
      );
    }
  };

  const filterSearch = (e: any) => {
    e.preventDefault();
    let filterSearchErr = "";
    let isValid = true;
    if (customFunctions.checkIfEmpty(selectedEmployee) && customFunctions.checkIfEmpty(selectedClient)) {
      filterSearchErr = "Please select client or employee";
      setFilterErr(filterSearchErr);
      isValid = false;
    }
    if (isValid) {
      setFilterErr("");
      getTaskListData(1, false);
    }
  };

  const onClear = () => {
    setFilterErr("");
    setSearchKeyword("");
    setSelectedStatus("");
    setSelectedEmployee("");
    setSelectedClient("");
    getTaskListData(1, true);
    setApplyToggle(false);
  };

  const deleteTask = async () => {
    const postData: any = {
      id: Number(taskId),
    };

    if (!taskId) return;
    setShowLoader(true);

    const callback: any = AuthApi.deleteDataFromServer;
    let url = `${Api.taskUrl}${taskId}/`;
    const { data, message } = await callback(url);

    if (data && data.success == true) {
      setShowLoader(false);
      setDeleteToggle(false);
      swal("Task deleted successfully!", "", "success", {
        closeOnClickOutside: false,
      }).then(() => {
        setTaskId(null);
        getTaskListData(1, true);
      });
    } else {
      setShowLoader(false);
      swal(message || "Something went wrong", "", "error").then(() => {
        setTaskId(null);
        getTaskListData(1, true);
      });
    }
  };

  return (
    <>
      <Helmet title={title} />
      <div className="page-header">
        <div className="title-breadcrumb-section">
          <h2 className="main-content-title tx-24 mg-b-5">{title}</h2>
          <Breadcrumb items={items} />
        </div>
      </div>
      <Loader showLoader={isShowLoader} needFullPage={false} />
      {deleteToggle && (
        <div className="sweetalert">
          <SweetAlert
            title={"Are you sure? do you want to delete the task?"}
            warning
            showCancel
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            onConfirm={() => {
              setDeleteToggle(false);
              deleteTask();
            }}
            onCancel={() => {
              setDeleteToggle(false);
            }}
            reverseButtons={false}
          />
        </div>
      )}
      <section className="content-area">
        <Accordion defaultActiveKey="1">
          <Accordion.Item eventKey="1">
            <Accordion.Header>
              <h5 className="card-title mb-0">Filters</h5>
            </Accordion.Header>
            <Accordion.Body>
              <Form onSubmit={(e) => filterSearch(e)}>
                {filterErr && (
                  <div className="p-3  bg-danger text-white bg-danger-main table-red-bg mb-3">
                    <div className="img-text">
                      <img
                        style={{ cursor: "pointer" }}
                        onClick={() => onClear()}
                        src="/images/fail.svg"
                        alt="Success"
                      />{" "}
                      {filterErr}
                    </div>
                  </div>
                )}
                <Row className="mt-3">
                  <Col sm={3}>
                    <SelectAsyncPaginate
                      selectPlaceholder={"Select client"}
                      apiname={"clientUrl"}
                      endpoint={Api.clientUrl}
                      value={selectedClient}
                      onSelectChange={(select: any) => {
                        setSelectedClient(select);
                      }}
                      fromComponent={"addTask"}
                    />
                  </Col>
                  <Col sm={3}>
                    <SelectAsyncPaginate
                      selectPlaceholder={"Select employee"}
                      value={selectedEmployee}
                      onSelectChange={(select: any) => {
                        setSelectedEmployee(select);
                      }}
                      apiname={"employeeUrl"}
                      endpoint={Api.employeeUrl}
                    />
                  </Col>

                  <Col sm={3}>
                    <div className="filter-btn pull-right filter-btn-report">
                      <Button
                        variant="primary"
                        type="submit"
                        className="ms-4"
                        onClick={() => setApplyToggle(true)}
                      >
                        Apply
                      </Button>
                      <Button
                        variant="secondary"
                        type="button"
                        className="ms-4"
                        onClick={() => onClear()}
                      >
                        Clear
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Form>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        <Card className="mt-3">
          <Card.Header>
            <div className="d-flex align-items-center justify-content-end">
              <a
                href="#"
                className="underline"
                onClick={(e) => {
                  e.preventDefault();
                  navigate("/assign-task", {
                    state: { pageType: "add" },
                  });
                }}
              >
                Assign Task To Employee
              </a>
            </div>
          </Card.Header>
          <Card.Body>
            <Table
              columns={columns}
              showCheckbox={false}
              rows={rows}
              showPagination
              role={roleId}
              paginationProps={{
                ...pagination,
                currentPage: Number(pagination.currentPage),
                onPageChange: (pageNo: any) => {
                  getTaskListData(pageNo);
                },
              }}
              dropdownContainerClass="mb-3"
              emptyRender={() => (
                <p className="text-center mb-0">No record found</p>
              )}
            />
          </Card.Body>
        </Card>
      </section>
      {showModal && (
        <DisplayModel
          employee={employeeNames}
          popupClose={() => {
            setShowModal(!showModal);
          }}
        />
      )}
    </>
  );
};

export default AssignEmployeeTaskList;
