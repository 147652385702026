import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { createStore } from "redux";
import { Provider } from "react-redux";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import adminStore from "./store";
import Interceptor from "./services/Interceptor";
import { render } from "react-dom";
// import 'bootstrap/dist/css/bootstrap.min.css';

const store = createStore(adminStore);
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement );

Interceptor.setupInterceptors();
root.render(
  <>
    {/* <React.StrictMode> */}
      <Provider store={store}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </Provider>
    {/* </React.StrictMode> */}
  </>
);

reportWebVitals();
