import React, { useEffect, useRef, useState } from "react";
import { Modal, Button, Card, Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { TextInput } from "../../components/FormInputs";
import SimpleReactValidator from "simple-react-validator";
import swal from "sweetalert";
import Api from "../../helper/api";
import AuthApi from "../../helper/authApi";
import { useCallback } from "react";

const AddDocument = (props: any) => {
  const { show, fromComponent, data, callback, updateShow } = props;
  const navigate = useNavigate();
  const validator = useRef(new SimpleReactValidator());

  const [isShowLoader, setShowLoader] = useState<boolean>(false);
  const [count, forceUpdate] = useState<number>(0);
  const [newDocument, setNewDocument] = useState<any>("");

  const [modalShow, setModalShow] = useState<boolean>(false);
  const handleShow = () => setModalShow(true);
  const handleClose = () => {
    validator.current.hideMessages();
    setModalShow(false);
    if (props) {
      props.handleClose();
    }
  };

  const addDocument = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    if (!validator.current.allValid()) {
      validator.current.showMessages();
      forceUpdate(count + 1);
      return;
    } else {
      props.handleClose();
      postDocumentType();
    }
    // swal(
    //   "Document name added successfully","","success")
    //   .then(()=>{
    //     props.handleClose()
    //     callback([{
    //       value:newDocument,label:newDocument
    //     },...data])
    //   })
  };

  const postDocumentType = async () => {
    setShowLoader(true);
    const payload: any = {
      name: newDocument,
    };

    const { data, message } = await AuthApi.postDataToServer(
      Api.documentTypeUrl,
      payload
    );

    if (data && data.success === true) {
      setShowLoader(false);

      swal(data.message[0], "", "success");
      callback();
    } else {
      setShowLoader(false);
      swal(message, "", "error");
    }
  };

  return (
    <>
      <Modal
        show={updateShow || show}
        onHide={props.handleClose}
        // size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <h5>Add Document Name</h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <TextInput
            onInputChange={(
              e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
            ) => {
              setNewDocument(e.target.value);
            }}
            label="Name"
            asterisk="*"
            containerClass="form-group"
            type="text"
            name="text"
            // disabled={pageType == "view"}
            value={newDocument}
            placeholder="Enter document name"
            errorMessage={validator.current.message(
              " document",
              newDocument,
              "required"
            )}
            autocomplete="new-files"
          />
          <div className="d-flex justify-content-end">
            <Button
              onClick={(e) => {
                addDocument(e);
              }}
            >
              Add
            </Button>
            <Button
              className="ml-10"
              variant="secondary"
              onClick={() => {
                validator.current.hideMessages();
                props.handleClose();
              }}
            >
              Cancel
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddDocument;
