import { useState, useRef, useEffect } from "react";
import { Modal, Button, Row, Col } from "react-bootstrap";
import SelectAsyncPaginate from "../../components/ReactSelectPagination/SelectAsyncPaginate";
import Api from "../../helper/api";
import SimpleReactValidator from "simple-react-validator";
import { DropdownInput, TextInput } from "../../components/FormInputs";
import AuthApi from "../../helper/authApi";
import swal from "sweetalert";
import customFunctions from "../../helper/customFunctions";

const AssignTaskModal = (props: any) => {
  const { show, popupClose, taskType } = props;
  const validator = useRef(new SimpleReactValidator());
  const [count, forceUpdate] = useState<any>(0);
  const [isShowLoader, setShowLoader] = useState<boolean>(false);
  const [taskOptions, setTaskOptions] = useState<any>([]);

  const [taskDetails, setTaskDetails] = useState<any>([
    {
      client_name: "",
      task_name: "",
      time: null,
      is_complete: 1,
      id: "",
      file: "",
      file_name: "",
      file_link: "",
      description: "",
      employee_name: "",
      employee_id: "",
      is_file: "",
      on_complition_doc: "",
      is_note: "",
      is_other: "",
      isChecked: false,
      taskArray: [],
      dueDate: "",
    },
  ]);

  const getTasks = async (id?: any, index?: any) => {
    setShowLoader(true);

    const params: any = {
      user_id: Number(customFunctions.getLocalStorage("id")),
      limit: 300,
      task_type: taskType == "task" ? 1 : 2,
      sort_direction: "descending",
    };

    if (taskType == "task") {
      params.client_id = id ? id : null;
    } else {
      params.litigation_client = id ? id : null;
    }

    const url: any = customFunctions.generateUrl(
      Api.employeeLitigationUrl,
      params
    );

    const { data, message } = await AuthApi.getDataFromServer(url);

    if (data && data.success === true) {
      setShowLoader(false);
      const taskOptions: any = [];
      data?.data &&
        data?.data?.map((element: any) => {
          taskOptions.push({
            label:
              taskType == "task"
                ? element?.task?.task_name
                : element?.litigation_title,
            value: taskType == "task" ? element?.task?.id : element?.litigation,
            dueDate:
              taskType == "task" && element?.seprated_due_date?.length
                ? element?.seprated_due_date
                : "",
          });
        });
      const taskData = [...taskDetails];
      taskData[index].taskArray = taskOptions;
      setTaskDetails(taskData);
      setTaskOptions(taskOptions);
    } else {
      setShowLoader(false);
      setTaskOptions([]);
      swal(typeof message == "string" ? message : message[0], "", "error");
    }
  };

  const addField = () => {
    const payload = {
      client_name: "",
      service_name: "",
      employee_name: "",
      task_name: "",
      time: null,
      is_complete: "",
      file: "",
      file_name: "",
      file_link: "",
      description: "",
      is_file: "",
      on_complition_doc: "",
      is_note: "",
      is_other: "",
      dueDate: "",
    };
    setTaskDetails([...taskDetails, payload]);
  };

  const removeField = (ind: any) => {
    const taskData: any = [...taskDetails];
    taskData.splice(ind, 1);
    setTaskDetails([...taskData]);
  };

  const addTimesheet = async () => {
    const Arr: any = [];
    taskDetails.map((item: any) => {
      if (taskType == "task") {
        Arr.push({
          client: item?.client_name?.id,
          task: item?.task_name?.value,
          time_log: Number(item?.time),
          task_status: item?.is_complete == 1 ? 1 : 2,
          file: item.file ? item?.file : "",
          description: item?.description,
        });
      } else {
        Arr.push({
          client: item?.client_name?.id,
          litigation: item?.task_name?.value,
          time_log: Number(item?.time),
          task_status: item?.is_complete == 1 ? 1 : 2,
          file: item.file ? item?.file : "",
          description: item?.description,
        });
      }
    });

    const postData: any = {
      task: Arr,
    };

    const endPoint = Api.timesheetUrl;

    const callback: any = AuthApi.postDataToServer;

    const { data, message } = await callback(endPoint, postData);

    if (data && data.success === true) {
      setShowLoader(false);
      swal("Timesheet created successfully!", "", "success").then(() => {
        // callBack();
        popupClose();
      });
    } else {
      setShowLoader(false);
      swal(typeof message === "string" ? message : message[0], "", "error");
    }
  };

  const onSubmit = (e: any) => {
    e.preventDefault();
    if (validator.current.allValid()) {
      addTimesheet();
    } else {
      validator.current.showMessages();
      forceUpdate(count + 1);
    }
  };

  return (
    <>
      <Modal centered onHide={popupClose} show={show} size="lg">
        <Modal.Header closeButton>
          <h5>{`${
            taskType == "task" ? "Assigned Task" : "Assigned Litigation"
          }`}</h5>
        </Modal.Header>
        <Modal.Body>
          {taskDetails &&
            taskDetails.length > 0 &&
            taskDetails.map((item: any, index: any) => (
              <>
                <Row className={index > 0 ? "mt-2" : ""}>
                  <Col sm={3}>
                    <SelectAsyncPaginate
                      asterisk={"*"}
                      label={index == 0 ? "Client" : ""}
                      id={"clientname"}
                      selectPlaceholder={"Select"}
                      apiname={"clientUrl"}
                      endpoint={Api.clientUrl}
                      value={item.client_name}
                      onSelectChange={(select: any) => {
                        let client = [...taskDetails];
                        client[index].task_name = "";
                        client[index].time = "";
                        client[index].client_name = select;
                        client[index].is_complete = "1";
                        client[index].dueDate = "";
                        setTaskDetails([...client]);
                        if (select?.id) {
                          getTasks(select?.id, index);
                        }
                      }}
                      errorMessage={validator.current.message(
                        "client",
                        item.client_name,
                        "required"
                      )}
                      fromComponent={"addTask"}
                    />
                  </Col>
                  <Col sm={3}>
                    <DropdownInput
                      asterisk="*"
                      label={
                        index == 0
                          ? `${taskType == "task" ? "Task" : "Litigation"}`
                          : ""
                      }
                      placeholder="Select"
                      options={item.taskArray}
                      defaultValue={item.task_name}
                      disabled={!item?.client_name}
                      onSelectHandler={(select: any) => {
                        let task = [...taskDetails];
                        task[index].task_name = select;
                        task[index].is_complete = "1";
                        task[index].dueDate = select?.dueDate
                          ? customFunctions.genareteDate(select?.dueDate)
                          : "";
                        setTaskDetails([...task]);
                      }}
                      errorMessage={validator.current.message(
                        `${taskType == "task" ? "Task" : "Litigation"}`,
                        item.task_name,
                        "required"
                      )}
                    />
                  </Col>
                  {taskType == "task" && (
                    <Col sm={3}>
                      <TextInput
                        containerClass="form-group"
                        type="text"
                        disabled
                        label={index == 0 ? "Due Date" : ""}
                        value={item.dueDate}
                        placeholder="Date"
                      />
                    </Col>
                  )}
                  <Col sm={3}>
                    <TextInput
                      onInputChange={(e: any) => {
                        const re = /^[0-9]*$/;
                        if (!e.target.value || re.test(e.target.value)) {
                          let Arr = [...taskDetails];
                          Arr[index].time = e.target.value;
                          setTaskDetails([...Arr]);
                        }
                      }}
                      id="time"
                      asterisk="*"
                      containerClass="form-group"
                      type="text"
                      label={index == 0 ? "Time" : ""}
                      name="time"
                      inputVarible="Hrs"
                      value={item.time}
                      placeholder="Time"
                      errorMessage={validator.current.message(
                        "time",
                        item.time,
                        "required|integer|min:1,num|max:24,num",
                        {
                          messages: {
                            min: "Please enter correct time",
                          },
                        }
                      )}
                      maxlength={2}
                    />
                  </Col>
                  <Col sm={4}>
                    <div className="text-size">
                      <div className={"form-check"}>
                        <>
                          <input
                            disabled={item.task_name == ""}
                            className="form-check-input"
                            type={"checkbox"}
                            value={item.is_complete}
                            checked={item.is_complete == "2"}
                            id={`${index}flexCheckDefault`}
                            onChange={(e: any) => {
                              let complete = [...taskDetails];
                              item.is_complete = e.target.checked ? 2 : 1;
                              complete[index].file_name = "";
                              complete[index].file = "";
                              complete[index].description = "";
                              setTaskDetails([...complete]);
                            }}
                          />
                        </>

                        <div className="d-flex justify-content-start">
                          <label
                            className="form-check-label"
                            htmlFor={`${index}flexCheckDefault`}
                          >
                            {"Mark as complete"}
                          </label>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col sm={5}>
                    <div className="">
                      {taskDetails.length > 1 && (
                        <a
                          className="underline"
                          href={"javascript:void(0)"}
                          onClick={() => {
                            removeField(index);
                          }}
                        >
                          Remove
                        </a>
                      )}
                      {taskDetails &&
                        taskDetails.length - 1 === index &&
                        taskDetails.length - 1 !== taskDetails.length && (
                          <a
                            href={"javascript:void(0)"}
                            className={
                              index == 0 ? "underline" : "underline ml-3"
                            }
                            onClick={() => {
                              addField();
                            }}
                          >
                            Add More
                          </a>
                        )}
                    </div>
                  </Col>
                </Row>
              </>
            ))}
          <Row>
            <Col>
              <div style={{ float: "right" }} className="form-group mt-3">
                <Button
                  type="submit"
                  variant="primary"
                  onClick={(e: any) => {
                    e.preventDefault();
                    onSubmit(e);
                  }}
                >
                  Submit
                </Button>
                <Button
                  onClick={(e) => {
                    e.preventDefault();
                    popupClose();
                  }}
                  variant="secondary"
                  className="ms-2"
                >
                  Cancel
                </Button>
              </div>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AssignTaskModal;
