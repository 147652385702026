import React, { useEffect, useRef, useState } from "react";
import { Modal, Button, Card, Col, Row, Image } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { TextInput } from "../../components/FormInputs";
import SimpleReactValidator from "simple-react-validator";
import swal from "sweetalert";
import SweetAlert from "react-bootstrap-sweetalert";

function RejectTaskComponent(props: any) {
  const { data, callback } = props;
  

  const navigate = useNavigate();
  const validator = useRef(new SimpleReactValidator());

  const [note, setNote] = useState<string>("");
  const [count, forceUpdate] = useState<number>(0);
  const [modalShow, setModalShow] = useState<boolean>(false);
  const [statusToggle, setStatusToggle] = useState<any>(false);

  const status = "Rejected";

  const handleShow = () => setModalShow(true);
  const handleClose = () => setModalShow(false);
  //   {
  //     validator.current.hideMessages(); setModalShow(false); if (props) {
  //       props.handleClose()
  //     }

  const onSubmit = (e: any) => {
    e.preventDefault();
    if (validator.current.allValid()) {
      if (note) {
        data.note = note;
        data.approved_status = 2;
      }
      callback(data);
      
      handleClose();
      setStatusToggle(true);
    } else {
      validator.current.showMessages();
      forceUpdate(count + 1);
    }
  };

  // const postTask = () => {
  //   // data.discription = discription,
  //   //   data.status = status
  //   swal("Task rejected successfully!", "", "success").then(() => {
  //     navigate("/approval-list");
  //   });
  // };

  return (
    <>
      <a
        href="/"
        className="pl-2 ms-1"
        title={" Reject Task"}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          handleShow();
        }}
      >
        <Image src="/images/fail.svg" alt="select task" />
      </a>
      <Modal
        // style={{ width: "400px", height: "400px" }}
        show={modalShow}
        onHide={handleClose}
        // size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <h5>Reason For Rejection</h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <TextInput
            onInputChange={(
              e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
            ) => {
              const re = /^[0-9 A-Z a-z]*$/;
              if (!e.target.value || re.test(e.target.value)) {
                setNote(e.target.value);
              }
            }}
            label="Reason"
            // asterisk={approvalStatus.value == "Reject" ? "*" : ""}
            asterisk="*"
            containerClass="form-group"
            type="text"
            as="textarea"
            name="discription"
            value={note}
            // disabled={state && state.status == 4}
            placeholder="Enter reason"
            errorMessage={validator.current.message(
              "reason",
              note,
              "required"
            )}
            autoComplete="new-discription"
          />
          <div className="d-flex justify-content-end">
            <Button
              onClick={(e) => {
                e.preventDefault();
                onSubmit(e);
              }}
            >
              Submit
            </Button>
            <Button
              className="ml-10"
              variant="secondary"
              onClick={() => {
                validator.current.hideMessages();
                handleClose();
              }}
            >
              Cancel
            </Button>
          </div>
        </Modal.Body>
      </Modal>

      {/* {statusToggle && (
        <div>
          <SweetAlert
            title={"Do you want to reject a task?"}
            warning
            showCancel
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            onConfirm={() => {
              setStatusToggle(false)
              postTask()
            }}
            onCancel={() => {
              setStatusToggle(false);
            }}
            reverseButtons={true}
          />
        </div>
      )} */}
    </>
  );
}

export default RejectTaskComponent;
