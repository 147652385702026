/* eslint-disable-line */
import React, { useState, useRef, useEffect } from "react";
import { Row, Col, Card, Form, Button } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";
import swal from "sweetalert";
import { DropdownInput, Helmet } from "../../components/FormInputs";
import SimpleReactValidator from "simple-react-validator";
import customFunctions from "../../helper/customFunctions";
import AuthApi from "../../helper/authApi";
import Api from "../../helper/api";
import Loader from "../../components/Loader";
import Breadcrumb from "../../components/Breadcrumb/breadcrumb.component";
import { monthsOptions, serviceStatus } from "../superAdmin/constant";
import Table from "../../components/Table/simpletable.component";

const RequestedReport = () => {
  const validator = useRef(new SimpleReactValidator());
  const navigate = useNavigate();

  const [count, forceUpdate] = useState<any>(0);
  const [roleId, setRoleId] = useState<number>(0);
  const [title, setTitle] = useState("Downloaded Reports");
  const [isShowLoader, setShowLoader] = useState<boolean>(false);
  const [reportData, setReportData] = useState<any>([
    {
      fileName: "Report 1",
      reportDate: "21/02/2024",
    },
    {
      fileName: "Report 2",
      reportDate: "22/02/2024",
    },
    {
      fileName: "Report 3",
      reportDate: "21/02/2024",
    },
  ]);

  useEffect(() => {
    getUserRole();
  }, []);

  const getUserRole = () => {
    const role = customFunctions.getLocalStorage("role_id");
    setRoleId(Number(role));
  };

  const items = [
    {
      to: "/",
      label: "Dashboard",
    },
    {
      to: "/recurring-tasks-report",
      label: "Recurring Tasks Reports",
    },
    {
      label: "Downloaded Reports",
    },
  ];

  const downloadReport = async () => {
    setShowLoader(true);
    const params: any = {};
    const endPoint = `${Api.genarateReportUrl}`;

    const callback = AuthApi.getDataFromServer;
    const { data, message } = await callback(endPoint, params);
    if (data && data.success === true) {
      setShowLoader(false);
      swal("Report downloaded successfully!", "", "success").then(() => {});
    } else {
      setShowLoader(false);
      swal(typeof message == "string" ? message : message[0], "", "error");
    }
  };

  return (
    <>
      <Helmet title={title} />
      <Button
        style={{ float: "right" }}
        className="form-group mt-4"
        type="submit"
        variant="primary"
        onClick={() => {
          navigate(-1);
        }}
      >
        Back
      </Button>
      <div className="page-header">
        <div className="title-breadcrumb-section">
          <h2 className="main-content-title tx-24 mg-b-5">{title}</h2>
          <Breadcrumb items={items} />
        </div>
      </div>
      <Loader showLoader={isShowLoader} needFullPage={false} />
      <section className="content-area">
        <Card>
          <Card.Body>
            {reportData?.length
              ? 
            <ul>
              {
              reportData?.map((element: any) => <>
              <li className="mt-3">
                {`${element?.fileName} (Date : ${element?.reportDate})`}
                <Button
                className="ml-3"
                variant="success"
                >
                    Download Report
                </Button>
              </li>
              </>)}
              </ul>
              : null}
          </Card.Body>
        </Card>
      </section>
    </>
  );
};

export default RequestedReport;
