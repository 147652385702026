import { Routes, Route } from "react-router-dom";
import Layout from "./components/Layout/layout.component";
import Login from "./containers/authentication/Login/login.component";
import ChangePassword from "./containers/Profile/changePassword.component";
import Profile from "./containers/Profile/profilecomponent";
import PartnerComponent from "./containers/partner";
import AddPartnerComponent from "./containers/partner/addPartner.component";
import EmployeeComponent from "./containers/employee";
import AddEmployee from "./containers/employee/addEmployee.component";
import AddNewTaskComponent from "./containers/Task/addNewTask";
import CompanyProfile from "./containers/company/companyProfile";
import TaskListComponent from "./containers/Task";
import TaskApprovalDetails from "./containers/ManagerTaskApprovalDetails/taskApprovalDetails";
import EmployeeProfileComponent from "./containers/EmployeeProfile/EmployeeProfile";
import EmployeeViewTaskComponent from "./containers/EmployeeViewTask/employeeViewTask";
import SelectTaskComponent from "./containers/Employee_Select_Task/Employee_Select_Task";
import TaskApprovalListComponent from "./containers/ManagerTaskApprovalDetails/taskApprovalList";
import IndividualClientOnboardComponent from "./containers/Client/IndividualClientOnboard.component";
import IndividualClientComponent from "./containers/Client/IndividualClientList";
import NonCorporateClientOnboardComponent from "./containers/Client/NonCorporateClientOnboard";
import ClientNavbar from "./containers/Client/ClientNavbar";
import NonCorporateClientComponent from "./containers/Client/NonCorporateClientList";
import CorporateClientComponent from "./containers/Client/CorporateClientList";
import CorporateClientOnboardComponent from "./containers/Client/CorporateClientOnboard";
import ClientPanValidationComponent from "./containers/Client/ClientPanValidation";
import ClientMasterListComponent from "./containers/Client";
import AddDesignation from "./containers/Designation/designationList";
import DesignationModel from "./containers/Designation/designationModel";
import TransferTaskList from "./containers/Task/transferTaskList";
import TransferTaskComponent from "./containers/Task/transferTask";
import MainDashboardComponent from "./containers/dashboard/Dashboard/MainDashboard";
import AddNewSubTask from "./containers/Task/subTask";
import TaskTimelog from "./containers/EmployeeViewTask/taskTimelog";
import SuperAdminTaskList from "./containers/superAdmin/taskList";
import CreateTaskComponent from "./containers/superAdmin/createTask";
import RejectTaskList from "./containers/ManagerTaskApprovalDetails/rejectTaskList";
import EmployeeTaskReport from "./containers/ManagerTaskApprovalDetails/mangerTaskReport";
import ApproveTaskList from "./containers/ManagerTaskApprovalDetails/approveTaskList";
import InvoiceList from "./containers/Invoice/index";
import AddInvoice from "./containers/Invoice/generateInvoiceForm";
import UpdateInvoice from "./containers/Invoice/updateInvoiceForm";
import LineItemsUpdateModel from "./containers/Invoice/updateLineItemsForm";
import LineItemList from "./containers/line items";
import UpdateInvoiceComponent from "./containers/Invoice/updateInvoiceComponent";
import CreateInvoice from "./containers/Invoice/createInvoiceComponent";
import ClientDashboard from "./containers/Client/clientDashboard";
import NewAdminDashboardComponent from "./containers/dashboard/Dashboard/NewDashboard";
import ServicesList from "./containers/superAdmin/servicesList";
import ApprovalTaskTabs from "./containers/ManagerTaskApprovalDetails/taskTabs";
import PageNotFoundComponent from "./containers/PageNotFound/pageNotFound";
import InvoiceTermsComponent from "./containers/Invoice/invoiceTerms";
import CreateServiceComponent from "./containers/superAdmin/createService";
import RecurringTaskList from "./containers/Task/recurringTaskList";
import CreateRecurringTaskComponent from "./containers/Task/createRecurringTask";
import AssignEmployeeTaskList from "./containers/Task/assignTaskList";
import AssignTaskComponent from "./containers/Task/assignTask";
import LitigationListComponent from "./containers/Litigation/litigationList";
import AddLitigationComponent from "./containers/Litigation/addLitigation";
import AssignLitigationListComponent from "./containers/Litigation/assignLitigationList";
import AssignLitigationComponent from "./containers/Litigation/assignLitigation";
import RecurringTaskReport from "./containers/Reports/recurringTaskReport";
import RequestedReport from "./containers/Reports/requestedReport";
import ViewTaskDetails from "./containers/Task/viewTaskDetails";

function RouterFile() {
  const roleId = Number(localStorage.getItem("role_id"));
  const adminAccess = localStorage.getItem("admin_access");

  return (
    <Layout>
      <Routes>
        {/* <Route path="/" element={<NewAdminDashboardComponent/>}/> */}
        <Route path="/" element={<MainDashboardComponent />} />
        <Route path="/login" element={<Login />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/change-password" element={<ChangePassword />} />
        <Route path="/partner-list" element={<PartnerComponent />} />
        <Route path="/add-partner" element={<AddPartnerComponent />} />
        <Route path="/edit-partner" element={<AddPartnerComponent />} />
        <Route
          path="/employee-list"
          element={
            adminAccess == "true" ? (
              <EmployeeComponent />
            ) : (
              <PageNotFoundComponent />
            )
          }
        />
        <Route
          path="/create-employee"
          element={
            adminAccess == "true" ? <AddEmployee /> : <PageNotFoundComponent />
          }
        />
        <Route
          path="/edit-employee"
          element={
            adminAccess == "true" ? <AddEmployee /> : <PageNotFoundComponent />
          }
        />
        <Route
          path="/view-employee"
          element={
            adminAccess == "true" ? <AddEmployee /> : <PageNotFoundComponent />
          }
        />
        <Route
          path="/timesheet"
          element={
            roleId != 6 ? <TaskListComponent /> : <PageNotFoundComponent />
          }
        />
        {/* <Route path="/task-list" element={<TaskListComponent />} /> */}
        <Route
          path="/add-new-task"
          element={
            adminAccess == "true" ? (
              <AddNewTaskComponent />
            ) : (
              <PageNotFoundComponent />
            )
          }
        />
        <Route
          path="/view-timesheet"
          element={
            adminAccess == "true" ? (
              <AddNewTaskComponent />
            ) : (
              <PageNotFoundComponent />
            )
          }
        />
        <Route
          path="/company-profile"
          element={
            adminAccess == "true" ? (
              <CompanyProfile />
            ) : (
              <PageNotFoundComponent />
            )
          }
        />
        <Route path="/task-approval" element={<TaskApprovalDetails />} />
        <Route
          path="/employee-profile"
          element={
            ![1, 2].includes(roleId) ? (
              <EmployeeProfileComponent />
            ) : (
              <PageNotFoundComponent />
            )
          }
        />
        <Route path="/view-task" element={<EmployeeViewTaskComponent />} />
        <Route
          path="/employee-select-task"
          element={
            ![1, 2, 6].includes(roleId) ? (
              <SelectTaskComponent />
            ) : (
              <PageNotFoundComponent />
            )
          }
        />
        <Route
          path="/approval-list"
          element={
            adminAccess == "true" ? (
              <TaskApprovalListComponent />
            ) : (
              <PageNotFoundComponent />
            )
          }
        />
        <Route
          path="/client-navbar"
          element={
            adminAccess == "true" ? <ClientNavbar /> : <PageNotFoundComponent />
          }
        />
        <Route
          path="/client-master-list"
          element={
            adminAccess == "true" ? (
              <ClientMasterListComponent />
            ) : (
              <PageNotFoundComponent />
            )
          }
        />
        <Route
          path="/client-pan-validation"
          element={<ClientPanValidationComponent />}
        />
        <Route
          path="/individual-client-list"
          element={<IndividualClientComponent />}
        />
        <Route
          path="/individual-client-onboard"
          element={<IndividualClientOnboardComponent />}
        />
        <Route
          path="/create-client"
          element={
            adminAccess == "true" ? (
              <IndividualClientOnboardComponent />
            ) : (
              <PageNotFoundComponent />
            )
          }
        />

        <Route
          path="/edit-client"
          element={
            adminAccess == "true" ? (
              <IndividualClientOnboardComponent />
            ) : (
              <PageNotFoundComponent />
            )
          }
        />

        <Route
          path="/corporate-client-list"
          element={<CorporateClientComponent />}
        />
        <Route
          path="/corporate-client-onboard"
          element={<CorporateClientOnboardComponent />}
        />
        <Route
          path="/non-corporate-client-list"
          element={<NonCorporateClientComponent />}
        />
        <Route
          path="/non-corporate-client-onboard"
          element={<NonCorporateClientOnboardComponent />}
        />
        <Route path="/designation" element={<AddDesignation />} />
        <Route path="/designation-model" element={<DesignationModel />} />
        <Route path="/transfer-task" element={<TransferTaskList />} />
        <Route path="/transfer" element={<TransferTaskComponent />} />
        <Route path="/sub-task" element={<AddNewSubTask />} />
        <Route path="/task-timelog" element={<TaskTimelog />} />
        <Route
          path="/super-admin-task-list"
          element={
            roleId == 1 ? <SuperAdminTaskList /> : <PageNotFoundComponent />
          }
        />
        <Route
          path="/task-list"
          element={
            roleId == 1 ? <SuperAdminTaskList /> : <PageNotFoundComponent />
          }
        />
        <Route
          path="/create-task"
          element={
            roleId == 1 ? <CreateTaskComponent /> : <PageNotFoundComponent />
          }
        />
        <Route
          path="/edit-task"
          element={
            roleId == 1 ? <CreateTaskComponent /> : <PageNotFoundComponent />
          }
        />
        <Route path="/reject-list" element={<RejectTaskList />} />
        <Route
          path="/approved-task"
          element={
            adminAccess == "true" ? (
              <ApproveTaskList />
            ) : (
              <PageNotFoundComponent />
            )
          }
        />
        <Route
          path="/employee-report"
          element={
            roleId != 6 ? <EmployeeTaskReport /> : <PageNotFoundComponent />
          }
        />
        <Route
          path="/invoice-list"
          element={
            adminAccess == "true" || roleId == 6 ? (
              <InvoiceList />
            ) : (
              <PageNotFoundComponent />
            )
          }
        />
        <Route
          path="/add-invoice"
          element={
            adminAccess == "true" || roleId == 6 ? (
              <AddInvoice />
            ) : (
              <PageNotFoundComponent />
            )
          }
        />
        <Route
          path="/update-invoice"
          element={
            adminAccess == "true" || roleId == 6 ? (
              <UpdateInvoice />
            ) : (
              <PageNotFoundComponent />
            )
          }
        />
        <Route
          path="/update-line-items"
          element={
            adminAccess == "true" ? (
              <LineItemsUpdateModel />
            ) : (
              <PageNotFoundComponent />
            )
          }
        />
        <Route
          path="/line-items"
          element={
            adminAccess == "true" ? <LineItemList /> : <PageNotFoundComponent />
          }
        ></Route>
        {/* <Route path="/create-invoice" element={<GenerateLineItemsModel/>}></Route> */}
        <Route
          path="/edit-invoice"
          element={
            adminAccess == "true" || roleId == 6 ? (
              <UpdateInvoiceComponent />
            ) : (
              <PageNotFoundComponent />
            )
          }
        ></Route>
        <Route
          path="/create-invoice"
          element={
            adminAccess == "true" || roleId == 6 ? (
              <CreateInvoice />
            ) : (
              <PageNotFoundComponent />
            )
          }
        ></Route>
        <Route
          path="/client-dashboard"
          element={
            adminAccess == "true" ? (
              <ClientDashboard />
            ) : (
              <PageNotFoundComponent />
            )
          }
        ></Route>
        {/* <Route path="/admin-dashboard" element={<NewAdminDashboardComponent/>}></Route> */}
        <Route
          path="/services"
          element={roleId == 1 ? <ServicesList /> : <PageNotFoundComponent />}
        ></Route>

        <Route
          path="/create-service"
          element={
            roleId == 1 ? <CreateServiceComponent /> : <PageNotFoundComponent />
          }
        ></Route>
        <Route
          path="/edit-service"
          element={
            roleId == 1 ? <CreateServiceComponent /> : <PageNotFoundComponent />
          }
        ></Route>

        <Route
          path="/tasks-approval"
          element={
            adminAccess == "true" ? (
              <ApprovalTaskTabs />
            ) : (
              <PageNotFoundComponent />
            )
          }
        ></Route>
        <Route
          path="/invoice-terms"
          element={
            adminAccess == "true" ? (
              <InvoiceTermsComponent />
            ) : (
              <PageNotFoundComponent />
            )
          }
        ></Route>

        <Route
          path="/recurring-tasks-list"
          element={
            adminAccess == "true" ? (
              <RecurringTaskList />
            ) : (
              <PageNotFoundComponent />
            )
          }
        />
        <Route
          path="/create-recurring-task"
          element={
            adminAccess == "true" ? (
              <CreateRecurringTaskComponent />
            ) : (
              <PageNotFoundComponent />
            )
          }
        />
        <Route
          path="/edit-recurring-task"
          element={
            adminAccess == "true" ? (
              <CreateRecurringTaskComponent />
            ) : (
              <PageNotFoundComponent />
            )
          }
        />

        <Route
          path="/assign-task-list"
          element={
            adminAccess == "true" ? (
              <AssignEmployeeTaskList />
            ) : (
              <PageNotFoundComponent />
            )
          }
        />

        <Route
          path="/assign-task"
          element={
            adminAccess == "true" ? (
              <AssignTaskComponent />
            ) : (
              <PageNotFoundComponent />
            )
          }
        />

        <Route
          path="/litigation-list"
          element={
            adminAccess == "true" ? (
              <LitigationListComponent />
            ) : (
              <PageNotFoundComponent />
            )
          }
        />

        <Route
          path="/create-litigation"
          element={
            adminAccess == "true" ? (
              <AddLitigationComponent />
            ) : (
              <PageNotFoundComponent />
            )
          }
        />

        <Route
          path="/edit-litigation"
          element={
            adminAccess == "true" ? (
              <AddLitigationComponent />
            ) : (
              <PageNotFoundComponent />
            )
          }
        />

        <Route
          path="/assign-litigations-list"
          element={
            adminAccess == "true" ? (
              <AssignLitigationListComponent />
            ) : (
              <PageNotFoundComponent />
            )
          }
        />

        <Route
          path="/assign-litigation"
          element={
            adminAccess == "true" ? (
              <AssignLitigationComponent />
            ) : (
              <PageNotFoundComponent />
            )
          }
        />

        <Route
          path="/recurring-tasks-report"
          element={
            adminAccess == "true" ? (
              <RecurringTaskReport />
            ) : (
              <PageNotFoundComponent />
            )
          }
        />

        <Route
          path="/downloaded-report"
          element={
            adminAccess == "true" ? (
              <RequestedReport />
            ) : (
              <PageNotFoundComponent />
            )
          }
        />
          <Route
          path="/view-taskdetails"
          element={
            adminAccess == "true" ? (
              <ViewTaskDetails />
            ) : (
              <PageNotFoundComponent />
            )
          }
        />
      </Routes>
    </Layout>
  );
}

export default RouterFile;
