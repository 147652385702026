import React, { useRef, useState } from "react";
import swal from "sweetalert";
import { Modal, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import SimpleReactValidator from "simple-react-validator";
import { TextInput } from "../../components/FormInputs";
import Api from "../../helper/api";
import AuthApi from "../../helper/authApi";

const DocumentModel = (props: any) => {
  const { show, callBack, data } = props;
  const validator = useRef(new SimpleReactValidator());
  const navigate = useNavigate();

  const [clientId, setClientId] = useState<any>(null);
  const [documentName, setDocumentName] = useState<any>("");
  const [count, forceUpdate] = useState<number>(0);
  const [isShowLoader, setShowLoader] = useState<boolean>(false);
  const [disableButton, setDisableButton] = useState<boolean>(false);

  const [modalShow, setModalShow] = useState<boolean>(false);
  const handleClose = () => {
    validator.current.hideMessages();
    setModalShow(false);
    if (props) {
      props.handleClose();
    }
  };

  const AddDocument = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (!validator.current.allValid()) {
      validator.current.showMessages();
      forceUpdate(count + 1);
      return;
    } else {
      // swal("Document name added successfully", "", "success").then(() => {
        handleClose();
        // callback([{ value: documentName, label: documentName }, ...data]);
        postDocumentType()
      // });
    }
  };

  const postDocumentType = async () => {
    setShowLoader(true);

    const payload: any = {
      name: documentName,
    };

    const endPoint: any = `${Api.documentTypeUrl}`;

    const callback: any = AuthApi.postDataToServer;

    const { data, message } = await callback(endPoint, payload);
    
    if (data && data.success === true) {
      setShowLoader(false);
      setDisableButton(false);
      swal(
        "Document name added successfully!",
        "",
        "success"
      ).then(()=>{
        callBack();
      })
    } else {
      setDisableButton(false);
      setShowLoader(false);
      swal(message[0], "", "error");
    }
  };
  
  return (
    <>
      <Modal
        show={modalShow || show}
        onHide={handleClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <h5>Add Document Name</h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <TextInput
            onInputChange={(e: any) => {
              setDocumentName(e.target.value);
            }}
            label="Name"
            asterisk="*"
            containerClass="form-group"
            type="text"
            name="documentName"
            value={documentName}
            placeholder="Enter name"
            errorMessage={validator.current.message(
              "document name",
              documentName,
              "required"
            )}
            autoComplete="new-documentName"
          />
          <div className="d-flex justify-content-end">
            <Button
              onClick={(e) => {
                AddDocument(e);
              }}
            >
              Add
            </Button>
            <Button
              className="ml-10"
              variant="secondary"
              onClick={() => {
                validator.current.hideMessages();
                handleClose();
              }}
            >
              Cancel
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default DocumentModel;
