import React from "react";
import { Modal, Col, Row } from "react-bootstrap";

function RejectReasonModel(props: any) {
  const { data, show } = props;


  return (
    <>
      <Modal
        show={show}
        onHide={() => {
          props.handleClose();
        }}
        // size={"md"}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <h5>Reason</h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col sm={4}>Reason :-</Col>
            <Col>{data?.note}</Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default RejectReasonModel;
