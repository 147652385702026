import { Helmet } from "../../components/FormInputs";
import react, { useState } from "react";
import { Button, Card, Image } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";
import Table from "../../components/Table/simpletable.component";
import Breadcrumb from "../../components/Breadcrumb/breadcrumb.component";
import { useEffect } from "react";
import customFunctions from "../../helper/customFunctions";
import TimelogModel from "../Task/logTimeModel";

const TaskTimelog = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [rows, setRows] = useState<any>([]);
  const [roleId, setRoleId] = useState<number>(0);
  const [taskData, setTaskData] = useState<any>([]);
  const [title, setTitle] = useState<any>("");

  const items = [
    { to: "/", label: "Dashboard" },
    {
      to: [1, 2, 3, 10].includes(roleId) ? "/task-list" : "/view-task",
      label: "Task List",
    },
    { label: title },
  ];

  useEffect(() => {
    const role = customFunctions.getLocalStorage("role_id");
    setRoleId(Number(role));
  }, []);

  const initialPagination = {
    currentPage: 1,
    itemsPerPage: 2,
    totalItems: 1,
  };
  const [pagination, setPagination] = useState(initialPagination);

  useEffect(() => {
    if (state?.pageType === "edit") {
      setTitle(state?.data?.task?.task_name);
    }
  }, []);

  // useEffect(() => {
  //   if (state.pageType === "Select Task") {
  //     setTitle(state.data.Task_name);
  //   }
  // }, []);

  const columns = [
    {
      label: "Submitted by",
      id: "submitted_by",
    },
    {
      label: "Date",
      id: "date",
    },
    {
      label: "Time Spend",
      id: "time_spend",
    },
    {
      label: "Description",
      id: "description",
    },
    // [4, 5].includes(roleId) ?
    {
      label: "Action",
      id: "action",
    },
    // : "",
  ];
  useEffect(() => {
    setTableData();
  }, [taskData]);

  useEffect(() => {
    SetTaskLog();
  }, []);

  const SetTaskLog = async (pageNo?: any, clearFilter?: boolean) => {
    // if (
    //     !currentUserId ||
    //     (!applyToggle && clearFilter && !searchKeyword && !selectedStatus)
    // )
    //     return;
    // const page = pageNo || 1;
    // setShowLoader(true);
    // // setApplyToggle(false);
    // const endPoint = Api.doctorUrl;

    // const params = {
    //     page: page,
    //     limit: pagination.itemsPerPage,
    //     search: !clearFilter && searchKeyword ? searchKeyword.trim() : "",
    //     status: !clearFilter && selectedStatus ? selectedStatus.value : "",
    // };

    // const url = customFunctions.generateUrl(endPoint, params);

    // const { data, message } = await AuthApi.getDataFromServer(url);
    // if (data && data.success == true) {
    //     setShowLoader(false);
    //     if (
    //         data &&
    //         data.data &&
    //         data.data.response &&
    //         data.data.response.length > 0
    //     ) {
    //         setShowLoader(false);
    //         setPagination({
    //             currentPage: data.data.page,
    //             itemsPerPage: 2,
    //             totalItems: data.data.count,
    //         });
    //         setTaskData(data.data.response);
    //         forceUpdate(count + 1);
    //     } else {
    //         setTaskData([]);
    //     }
    // } else {
    //     setShowLoader(false);
    //     setTaskData([]);
    //     swal(message, "", "error");
    // }
  };

  const setTableData = () => {
    const rowsData: any = [];
    taskData.map((dt: any, i: any) => {
      rowsData.push({
        id: dt.id,
        index: (pagination.currentPage - 1) * pagination.itemsPerPage + (i + 1),
        submitted_by: dt.submitted_by,
        date: dt.date,
        time_spend: dt.time_spend,
        description: dt.description,
        action: (
          <>
            <a
              href="/"
              onClick={(e: any) => {
                e.preventDefault();
                navigate("/employee-select-task", {
                  state: { data: dt, data1: state, pageType: "edit time log" },
                });
              }}
            >
              <Image src="/images/edit-button.svg" />
            </a>
          </>
        ),
      });
      return dt;
    });
    setRows([...rowsData]);
  };

  return (
    <>
      <Helmet title={title} />
      <div style={{ float: "right" }} className="form-group mt-4">
        <Button
          type="button"
          variant="primary"
          onClick={(e) => {
            e.preventDefault();
            navigate(-1);
          }}
        >
          Back
        </Button>
      </div>
      <div className="page-header">
        <div className="title-breadcrumb-section">
          <h2 className="main-content-title tx-24 mg-b-5">{title}</h2>
          <Breadcrumb items={items} />
        </div>
      </div>
      <Card className="mt-3">
        <div className="d-flex align-items-center justify-content-end">
          {/* <a
              href="#"
              className="underline"
              onClick={(e) => {
                e.preventDefault();
                navigate("/employee-select-task", {
                  state: { pageType: "add" },
                });
              }}
            >
              Log Time
            </a> */}
        </div>
        <Card.Header>
          <span style={{ float: "left", fontWeight: "600", color: "#24959D" }}>
            Total time logged: {"16"} hrs
          </span>
          <div className="d-flex align-items-center justify-content-end">
            {
              // [4, 5].includes(roleId) ?
              <a
                href="#"
                className="underline"
                onClick={(e) => {
                  e.preventDefault();
                  // navigate("/employee-select-task", {
                  //   state: { data: state.data, pageType: "add" },
                  // });
                }}
              >
                <TimelogModel pageType={"edit"} data={title} />
              </a>
              // : ""
            }
          </div>
          {/* <h5 style={{ float: 'right', textDecoration: 'underline', cursor:'pointer' }}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              navigate("/employee-select-task", {
                state: { data: state.data, pageType: "add" },
              });
            }}
          >
            Log Time
          </h5> */}
        </Card.Header>
        <Card.Body>
          <Table
            columns={columns}
            // showCheckbox={true}
            rows={rows}
            showPagination
            role={roleId}
            paginationProps={{
              ...pagination,
              activePage: pagination.currentPage,
              onPageChange: (pageNo: any) => {
                SetTaskLog(pageNo);
              },
            }}
            dropdownContainerClass="mb-3"
            emptyRender={() => (
              <p className="text-center mb-0">No record found</p>
            )}
          />
        </Card.Body>
      </Card>
    </>
  );
};
export default TaskTimelog;
