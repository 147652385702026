/* eslint-disable-line */
import React, { useState, useRef, useEffect } from "react";
import { Row, Col, Card, Form, Button, Accordion } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";
import { DropdownInput, Helmet } from "../../components/FormInputs";
import SimpleReactValidator from "simple-react-validator";
import customFunctions from "../../helper/customFunctions";
import AuthApi from "../../helper/authApi";
import Api from "../../helper/api";
import Loader from "../../components/Loader";
import Breadcrumb from "../../components/Breadcrumb/breadcrumb.component";
import { serviceStatus } from "../superAdmin/constant";
import Table from "../../components/Table/simpletable.component";

const RecurringTaskReport = () => {
  const validator = useRef(new SimpleReactValidator());
  const navigate = useNavigate();

  const [count, forceUpdate] = useState<any>(0);
  const [roleId, setRoleId] = useState<number>(0);
  const [title, setTitle] = useState("Recurring Tasks Report");
  const [isShowLoader, setShowLoader] = useState<boolean>(false);
  const [showTextLoader, setShowTextLoader] = useState<boolean>(false);
  const [accordionOpen, setAccordionOpen] = useState<string>("0");
  const [selectedStatus, setSelectedStatus] = useState<any>("");
  const [selectedTask, setSelectedTask] = useState<any>("");
  const [selectedMonth, setSelectedMonth] = useState<any>("");
  const [tasksOptions, setTasksOptions] = useState<any>([]);
  const [tableData, setTableData] = useState<any>([]);
  const [reportData, setReportData] = useState<any>([
    {
      fileName: "monthly_report.xlsx",
      reportDate: "22/02/2024",
    },
    {
      fileName: "yearly_report.xlsx",
      reportDate: "22/02/2024",
    },
    {
      fileName: "monthly_report.xlsx",
      reportDate: "21/02/2024",
    },
  ]);
  const [financialYear, setFinancialYear] = useState<any>({
    label: `${new Date().getFullYear()}-${new Date().getFullYear() + 1}`,
    value: `${new Date().getFullYear()}-${new Date().getFullYear() + 1}`,
    fromYear: `${new Date().getFullYear()}`,
    toYear: `${new Date().getFullYear() + 1}`,
  });

  let startYear = 2023;
  let prevYear = 2022;
  const month = new Date().getMonth();
  const currentDate = new Date().getDate();
  const length = month == 3 && currentDate == 1 ? 2 : 2;
  const financialYearOptions: any = [];
  for (let i = 0; i < length; i++) {
    startYear = startYear + 1;
    prevYear = prevYear + 1;
    financialYearOptions.push({
      value: `${prevYear}-${startYear}`,
      label: `${prevYear}-${startYear}`,
      fromYear: `${prevYear}`,
      toYear: `${startYear}`,
    });
  }

  const monthsOptions = [
    {
      label: "All Months",
      value: "All",
    },
    {
      label: "January",
      value: "1",
    },
    {
      label: "February",
      value: "2",
    },
    {
      label: "March",
      value: "3",
    },
    {
      label: "April",
      value: "4",
    },
    {
      label: "May",
      value: "5",
    },
    {
      label: "June",
      value: "6",
    },
    {
      label: "July",
      value: "7",
    },
    {
      label: "August",
      value: "8",
    },
    {
      label: "September",
      value: "9",
    },
    {
      label: "October",
      value: "10",
    },
    {
      label: "November",
      value: "11",
    },
    {
      label: "December",
      value: "12",
    },
  ];

  const items = [
    {
      to: "/",
      label: "Dashboard",
    },
    {
      label: "Recurring Tasks Report",
    },
  ];

  const columns = [
    {
      label: "Sr. No.",
      id: "index",
    },
    {
      label: "Client Name",
      id: "client_name",
    },
    {
      label: "Task Name",
      id: "task_name",
    },
    {
      label: "Employee Name",
      id: "employee_name",
    },
    {
      label: "Total Time Log",
      id: "total_time_log",
    },
    {
      label: "Status",
      id: "status",
    },
    {
      label: "Due Date",
      id: "due_date",
    },
    {
      label: "Completed Date",
      id: "completed_date",
    },
  ];

  useEffect(() => {
    getUserRole();
    getReports();
  }, []);

  const getUserRole = () => {
    const role = customFunctions.getLocalStorage("role_id");
    setRoleId(Number(role));
  };

  useEffect(() => {
    getTasks();
  }, []);

  const getTasks = async () => {
    setShowLoader(true);
    const endPoint = Api.taskDueDateUrl;
    const params = {
      limit: 300,
      sort_direction: "ascending",
      // repetative_status: selectedStatus ? selectedStatus?.value : "",
      sort_by: "task__task_name",
    };

    const url = customFunctions.generateUrl(endPoint, params);

    const { data, message } = await AuthApi.getDataFromServer(url);

    if (data && data.success === true) {
      setShowLoader(false);
      if (data && data?.data && data?.data.length > 0) {
        setShowLoader(false);

        const tasks: any = [];
        data?.data?.map((element: any) => {
          tasks.push({
            label: `${element?.task?.task_name} (${
              element?.repetative_status == 1 ? "M" : "Y"
            })`,
            value: element?.task?.id,
            status: element?.repetative_status,
          });
        });
        setTasksOptions([
          // {
          //   label: "All Tasks",
          //   value: "All",
          // },
          {
            label: "All Monthly Tasks",
            value: "All Monthly",
            status: 1,
          },
          {
            label: "All Yearly Tasks",
            value: "All Yearly",
            status: 2,
          },

          ...tasks,
        ]);
        forceUpdate(count + 1);
      } else {
        setTasksOptions([]);
      }
    } else {
      setShowLoader(false);
      setTasksOptions([]);
      swal(typeof message == "string" ? message : message[0], "", "error");
    }
  };

  const getReports = async () => {
    setShowLoader(true);
    const endPoint = Api.downloadedReportsUrl;
    const params = {
      type: "all",
      sort_direction: "descending",
    };

    const url = customFunctions.generateUrl(endPoint, params);
    const { data, message } = await AuthApi.getDataFromServer(url);

    if (data && data.success === true) {
      setShowLoader(false);
      if (data && data?.data && data?.data?.length > 0) {
        setShowLoader(false);
        const reportData: any = [];
        data?.data?.map((element: any) => {
          reportData.push({
            fileName: element?.file_name,
            reportDate: element?.created_at,
            fileUrl: element?.url,
          });
        });
        setReportData(reportData);
        forceUpdate(count + 1);
      } else {
        setReportData([]);
      }
    } else {
      setShowLoader(false);
      setReportData([]);
      swal(typeof message == "string" ? message : message[0], "", "error");
    }
  };
  const downloadReport = async () => {
    setShowLoader(true);

    const endPoint = Api.genarateReportUrl;
    const params: any = {
      task_status: selectedTask?.status ? selectedTask?.status : null,
      from_financial_year: financialYear?.fromYear
        ? financialYear?.fromYear
        : null,
      to_financial_year: financialYear?.toYear ? financialYear?.toYear : null,
      // is_all_tasks: true,
      task_id:
        selectedTask &&
        !["All", "All Monthly", "All Yearly"].includes(selectedTask?.value) &&
        selectedTask?.value
          ? selectedTask?.value
          : null,
      for_financial_month:
        selectedMonth && selectedMonth?.value != "All" && selectedMonth?.value
          ? `${new Date().getFullYear()}-${
              selectedMonth?.value?.length == 2
                ? selectedMonth?.value
                : "0" + selectedMonth?.value
            }-${new Date().getDate()}`
          : null,
      all_monthly_tasks: selectedTask?.value == "All Monthly" ? true : false,
      all_yearly_tasks: selectedTask?.value == "All Yearly" ? true : false,
    };

    const { data, message } = await AuthApi.postDataToServer(endPoint, params);

    if (data && data.success === true) {
      setShowLoader(false);
      if (
        (selectedTask &&
          ["All", "All Monthly", "All Yearly"].includes(selectedTask?.value)) ||
        (selectedMonth && selectedMonth?.value == "All")
      ) {
        swal(
          "Your request has been sent. We will update you once the report is ready.",
          "",
          "success"
        );
        setSelectedStatus("");
        setSelectedTask("");
        setSelectedMonth("");
        getReports();
        setAccordionOpen("0");
        validator.current.hideMessages();
        forceUpdate(count + 1);
      } else {
        if (data && data?.data && data?.data?.length > 0) {
          const reportData: any = [];
          data?.data?.map((element: any, index: number) => {
            reportData.push({
              index: index + 1,
              client_name: element?.client_name ? element?.client_name : "-",

              task_name: element?.task__task_name
                ? element?.task__task_name
                : "-",
              employee_name: element?.employee_name
                ? element?.employee_name
                : "-",
              total_time_log: element?.total_time_log
                ? element?.total_time_log
                : "-",
              status: element?.task_status == 2 ? "Completed" : "Pending",

              completed_date: element?.due_date
                ? customFunctions.getDMYDate(element?.due_date, "dmy")
                : "-",

              due_date: element?.task_due_date
                ? customFunctions.getDMYDate(element?.task_due_date, "dmy")
                : "-",
            });
          });

          setTableData(reportData);
          forceUpdate(count + 1);
        } else {
          setTableData([]);
        }
      }
    } else {
      setShowLoader(false);
      if (selectedTask && selectedTask?.value == "All") {
        // setShowTextLoader(false);
      } else {
      }
      setTableData([]);
      swal(
        typeof message == "string"
          ? message
          : message?.length
          ? message[0]
          : "Something went wrong!",
        "",
        "error"
      );
    }
  };

  const onSubmit = (e: any) => {
    e.preventDefault();

    if (validator.current.allValid()) {
      downloadReport();
    } else {
      validator.current.showMessages();
      forceUpdate(count + 1);
    }
  };

  const clearFilter = () => {
    setSelectedStatus("");
    setSelectedTask("");
    setSelectedMonth("");
    setTableData([]);
    validator.current.hideMessages();
    forceUpdate(count + 1);
  };

  const downloadFile = (fileUrl?: string, fileName?: string) => {
    const link: any = document.createElement("a");
    link.href = fileUrl;
    link.setAttribute("download", fileName);
    link.rel = "noreferrer";
    document.body.appendChild(link);
    link.click();
    link.remove();
  };

  return (
    <>
      <Helmet title={title} />
      <div className="page-header">
        <div className="title-breadcrumb-section">
          <h2 className="main-content-title tx-24 mg-b-5">{title}</h2>
          <Breadcrumb items={items} />
        </div>
      </div>
      <Loader
        showLoader={isShowLoader}
        needFullPage={false}
        isLoaderText={showTextLoader}
      />
      {reportData?.length ? (
        <Accordion
          activeKey={accordionOpen}
          onSelect={(newKey: any) => setAccordionOpen(newKey)}
        >
          <Accordion.Item eventKey={"0"}>
            <Accordion.Header>Downloaded Reports</Accordion.Header>
            <Accordion.Body className="custom-card-body">
              <ul>
                {reportData?.map((element: any) => (
                  <>
                    <li className="">
                      {`${element?.fileName}.xlsx (Date : ${
                        element?.reportDate
                          ? customFunctions.getDMYDate(
                              element?.reportDate,
                              "dmy"
                            )
                          : "-"
                      })`}
                      <Button
                        variant="link"
                        onClick={() =>
                          downloadFile(element?.fileUrl, element?.fileName)
                        }
                      >
                        Download Report
                      </Button>
                    </li>
                  </>
                ))}
              </ul>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      ) : null}

      <Card className={`mt-3`}>
        <Card.Body>
          <Form
            autoComplete="off"
            onSubmit={(e) => {
              onSubmit(e);
            }}
          >
            <Row>
              {/* <Col sm={3}>
                <DropdownInput
                  asterisk="*"
                  label={"Recurring Status"}
                  placeholder="Select status"
                  defaultValue={selectedStatus}
                  options={serviceStatus}
                  onSelectHandler={(select: any) => {
                    setSelectedStatus(select);
                    setSelectedTask("");
                    setSelectedMonth("");
                    setTableData([]);
                  }}
                  errorMessage={validator.current.message(
                    `status`,
                    selectedStatus,
                    "required"
                  )}
                  isClearable={true}
                />
              </Col> */}
              <Col sm={4}>
                <DropdownInput
                  asterisk="*"
                  label={"Recurring Task"}
                  placeholder="Select recurring task"
                  defaultValue={selectedTask}
                  onSelectHandler={(select: any) => {
                    setSelectedTask(select);
                    setSelectedMonth("");
                    setTableData([]);
                  }}
                  options={tasksOptions}
                  // disabled={!selectedStatus}
                  errorMessage={validator.current.message(
                    `recurring task`,
                    selectedTask,
                    "required"
                  )}
                  isClearable={true}
                />
              </Col>
              <Col sm={4}>
                <DropdownInput
                  asterisk={selectedTask?.status != 2 && "*"}
                  label="Month"
                  placeholder="Select month"
                  options={monthsOptions}
                  defaultValue={selectedMonth}
                  onSelectHandler={(select: any) => {
                    setSelectedMonth(select);
                    setTableData([]);
                  }}
                  isClearable={true}
                  disabled={selectedTask?.status == 2}
                  errorMessage={validator.current.message(
                    `month`,
                    selectedMonth,
                    selectedTask?.status != 2 ? "required" : ""
                  )}
                />
              </Col>
              <Col sm={4}>
                <DropdownInput
                  label={"Financial Year"}
                  placeholder="Select year"
                  defaultValue={financialYear}
                  onSelectHandler={(select: any) => {
                    setFinancialYear(select);
                    setTableData([]);
                  }}
                  options={financialYearOptions}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <div style={{ float: "left" }}>
                  <Button type="submit" variant="primary">
                    Submit
                  </Button>
                  <Button
                    onClick={(e) => {
                      e.preventDefault();
                      clearFilter();
                    }}
                    variant="secondary"
                    className="ms-2"
                  >
                    Reset
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>
        </Card.Body>
      </Card>

      {tableData && tableData?.length ? (
        <Card className="custom-card">
          <Card.Body className="custom-card-body">
            <Table
              columns={columns}
              showCheckbox={false}
              rows={tableData}
              dropdownContainerClass="mb-3"
              emptyRender={() => (
                <p className="text-center mb-0">No record found</p>
              )}
            />
          </Card.Body>
        </Card>
      ) : null}
    </>
  );
};

export default RecurringTaskReport;
