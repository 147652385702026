import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";
import SweetAlert from "react-bootstrap-sweetalert";
import {
  Card,
  Form,
  Col,
  Row,
  Button,
  Accordion,
  Image,
} from "react-bootstrap";
import customFunctions from "../../../helper/customFunctions";
import AuthApi from "../../../helper/authApi";
import Api from "../../../helper/api";
import Breadcrumb from "../../../components/Breadcrumb/breadcrumb.component";
import Loader from "../../../components/Loader/index";
import { Helmet, DropdownInput } from "../../../components/FormInputs";
import Table from "../../../components/Table/simpletable.component";
import RejectTaskComponent from "../../ManagerTaskApprovalDetails/rejectTaskComponent";
import ViewDocumentModel from "../../ManagerTaskApprovalDetails/viewDocumentModel";

const RecentlyTaskApprovalComponent = (props: any) => {
  const { fromComponent } = props;
  const navigate = useNavigate();
  const [isShowLoader, setShowLoader] = useState<any>(false);
  const [rows, setRows] = useState<any>([]);
  const [title, setTitle] = useState<any>("Task Approval List");
  const [statusToggle, setStatusToggle] = useState<any>(false);
  const [count, forceUpdate] = useState<any>(0);
  const [filterErr, setFilterErr] = useState<any>("");
  const [searchClient, setSearchClient] = useState<any>("");
  const [selectedStatus, setSelectedStatus] = useState<any>("");
  const [applyToggle, setApplyToggle] = useState<any>(false);
  const [roleId, setRoleId] = useState<number>(0);
  const [currentUserId, setCurrentUserId] = useState<number>(0);
  const [updateShow, setUpdateShow] = useState<boolean>(false);
  const [modalData, setModalData] = useState<boolean>(false);
  const [taskData, setTaskData] = useState<any>([]);
  const [disableButton, setDisableButton] = useState<boolean>(false);
  const [data, setData] = useState<any>();

  const items = [
    { to: "/", label: "Dashboard" },
    { label: "Task Approval List" },
  ];

  const initialPagination = {
    currentPage: 1,
    itemsPerPage: 10,
    totalItems: 1,
  };

  const [pagination, setPagination] = useState(initialPagination);

  const columns = [
    // {
    //   label: "Sr.No",
    //   id: "index",
    // },
    {
      label: "Client",
      id: "client_name",
    },

    {
      label: "Task Name",
      id: "task_name",
    },
    {
      label: "Submitted By",
      id: "submitedby",
    },
    // {
    //   label: "Submitted At",
    //   id: "submitedat",
    // },

    {
      label: "Details",
      id: "documentattachment",
    },
    // {
    //   label: "Action",
    //   id: "action",
    // },
  ];

  useEffect(() => {
    getUserRole();
  }, []);

  const getUserRole = () => {
    const role = customFunctions.getLocalStorage("role_id");
    setRoleId(Number(role));
    const currentUserId = customFunctions.getLocalStorage("id");
    setCurrentUserId(Number(currentUserId));
  };

  useEffect(() => {
    if (
      roleId == 2 ||
      customFunctions.getLocalStorage("admin_access") == "true"
    ) {
      getTaskApprovalData();
    }
  }, [currentUserId, roleId]);

  const getTaskApprovalData = async (pageNo?: any, clearFilter?: boolean) => {
    const page = pageNo || 1;
    setShowLoader(true);
    // setApplyToggle(false);
    const endPoint = Api.taskApprovalUrl;
    const params = {
      page: page,
      sort_direction: "descending",
      approved_status: 3,
      task_status: 2,
      keyword:
        !clearFilter && selectedStatus
          ? selectedStatus.label
          : searchClient
          ? searchClient.label
          : "",
    };
    const url = customFunctions.generateUrl(endPoint, params);
    const { data, message } = await AuthApi.getDataFromServer(url);
    if (data && data.success == true) {
      setShowLoader(false);
      if (data && data.data && data.data.length > 0) {
        if (data && data.data && data?.data?.length > 5) {
          let newData = data.data.slice(0, 5);
          setTaskData(newData);
        } else {
          setTaskData(data.data);
        }
        forceUpdate(count + 1);
      } else {
        setTaskData([]);
      }
    } else {
      setShowLoader(false);
      setTaskData([]);
      swal(message, "", "error");
    }
  };

  useEffect(() => {
    setTableData();
  }, [taskData]);

  const setTableData = () => {
    const rowsData: any = [];
    taskData.map((dt: any, i: any) => {
      // if (dt.task_status_id == 1) return;
      rowsData.push({
        id: dt.id,
        index: (pagination.currentPage - 1) * pagination.itemsPerPage + (i + 1),
        task_name: dt.task?.task_name,
        documentattachment: (
          <>
            {dt?.task?.on_complition_doc == true ? (
              <center>
                <a
                  href=""
                  className="underline"
                  title={
                    dt?.task?.is_note
                      ? "View Note"
                      : dt?.task?.is_file
                      ? "View File"
                      : ""
                  }
                  onClick={(e) => {
                    e.preventDefault();
                    setUpdateShow(true);
                    setModalData(dt);
                  }}
                >
                  View
                  {/* <Image src="/images/notes.svg" title="View Document" /> */}
                </a>
              </center>
            ) : (
              <center>-</center>
            )}
          </>
        ),
        submitedby: dt.name,
        submitedat: customFunctions.getDMYDate(dt.updated_at, "dmy"),
        client_name: dt.client_name,
        action: (
          // (
          <>
            <a
              href="/"
              className="pl-2 ms-1"
              title={" Approve Task"}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setStatusToggle(true);
                setData(dt);
              }}
            >
              <Image src="/images/success.svg" alt="Approve task" />
            </a>
            &nbsp;&nbsp;
            <RejectTaskComponent
              data={dt}
              callback={(dt: any) => {
                taskApproval(dt);
              }}
            />
          </>
        ),
      });
      return dt;
    });
    setRows([...rowsData]);
  };

  const taskApproval = async (dt: any) => {
    const Approve: any = [];
    const postdata: any = {
      approved_status: dt.approved_status,
      note: dt.note,
    };

    const endPoint = `${Api.taskApprovalUrl}${dt.id}/`;
    const PutAPi: any = AuthApi.putDataToServer;
    const { data, message } = await PutAPi(endPoint, postdata);

    if (data && data.success === true) {
      setShowLoader(false);
      setDisableButton(false);
      swal(
        data.data.approved_status == 1
          ? "Task approved successfully"
          : "Task rejected successfully!",
        "",
        "success"
      ).then(() => {
        navigate("/approval-list");
        getTaskApprovalData();
      });
    } else {
      setDisableButton(false);
      setShowLoader(false);
      swal(typeof message === "string" ? message : message[0], "", "error");
    }
  };

  const filterSearch = (e: any) => {
    e.preventDefault();
    let filterSearchErr = "";
    let isValid = true;
    if (
      customFunctions.checkIfEmpty(searchClient) &&
      customFunctions.checkIfEmpty(selectedStatus)
    ) {
      filterSearchErr = "Please select client or employee name";
      setFilterErr(filterSearchErr);
      isValid = false;
    }
    if (isValid) {
      setFilterErr("");
      getTaskApprovalData();
    }
  };

  const onClear = (e: any) => {
    setFilterErr("");
    setSearchClient("");
    setSelectedStatus("");
    getTaskApprovalData(1, true);
    setApplyToggle(false);
  };

  return (
    <>
      <>
        {statusToggle && (
          <div className="sweetalert">
            <SweetAlert
              title={"Are you sure? do you want to approve a task?"}
              warning
              showCancel
              confirmBtnBsStyle="success"
              cancelBtnBsStyle="danger"
              onConfirm={() => {
                setStatusToggle(false);
                let Approve: any = data;
                Approve.approved_status = 1;
                setData(Approve);
                taskApproval(data);
              }}
              onCancel={() => {
                setStatusToggle(false);
              }}
              reverseButtons={false}
            />
          </div>
        )}
        {/* <section className="content-area"> */}
        <Card className="mt-1">
          <Card.Header>
            <b className="card-title text-uppercase">
              <center>Recently Task Approval</center>
            </b>
          </Card.Header>
          <Card.Body className="table-scroll">
            <Table
              columns={columns}
              // showCheckbox={true}
              rows={rows}
              showPagination
              role={roleId}
              paginationProps={{
                ...pagination,
                currentPage: Number(pagination.currentPage),
                onPageChange: (pageNo: any) => {
                  getTaskApprovalData(pageNo);
                },
              }}
              dropdownContainerClass="mb-3"
              emptyRender={() => (
                <p className="text-center mb-0 mt-5">
                  <h3>No records found!</h3>
                </p>
              )}
            />
          </Card.Body>
          {rows && rows.length > 0 && (
            <Card.Footer>
              <Row>
                <Col sm={9}></Col>
                <Col sm={3}>
                  <div>
                    <a
                      className="d-flex align-items-center justify-content-end underline"
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        navigate("/tasks-approval");
                      }}
                    >
                      {"View All"}
                    </a>
                  </div>
                </Col>
              </Row>
            </Card.Footer>
          )}
        </Card>
        {/* </section> */}
        {
          <ViewDocumentModel
            show={updateShow}
            data={modalData}
            handleClose={() => {
              setUpdateShow(false);
            }}
          />
        }
      </>
    </>
  );
};

export default RecentlyTaskApprovalComponent;
