import React, { useEffect, useState, useRef } from "react";
import { Form, Button, Image } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";
import swal from "sweetalert";
import AuthApi from "../../../helper/authApi";
import Api from "../../../helper/api";
import SimpleReactValidator from "simple-react-validator";
import Loader from "../../../components/Loader";
import customFunctions from "../../../helper/customFunctions";

const ResetPassword = (props) => {

  const navigate = useNavigate();
  const state = useLocation();

  const validator = useRef(new SimpleReactValidator());
  
  const [email, setEmail] = useState("");
  const [token, setToken] = useState(null);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfPass] = useState("");
  const [cpassErr, setcpassErr] = useState("");
  const [count, forceUpdate] = useState(0);
  const [title, setTitle] = useState("");
  const [isShowLoader, setShowLoader] = useState(false);
  const [switchItem, setSwitchItem] = useState(false);
  const [confPasSwitchItem, setConfPasSwitchItem] = useState(false);

  useEffect(() => {
    if (!state) return;
    if (state && state.state && state.state.token) {
      setEmail(state.state.email);
      setToken(state.state.token)
    }
  }, [state]);

  const validateForm = (e) => {
    e.preventDefault();
    let isValid = true;
    let cpassErr = "";

    if (password !== confirmPassword) {
      cpassErr = "Password did not match";
      isValid = false;
    }
    setcpassErr(cpassErr);

    if (validator.current.allValid() && isValid) {
      changePassword();
    } else {
      validator.current.showMessages();
      forceUpdate(count + 1);
    }
  };

  const changePassword = async (e) => {
    // e.preventDefault();
    setShowLoader(true);
    const payload = {
      email: email,
      password: password,
      confirm_password: confirmPassword
    };
    const endpoint = `${Api.resetPasswordUrl}?token=${token}`;

    const { data, message } = await AuthApi.postDataToServer(
      endpoint,
      payload
    );
    if (data && data.success === true) {
      setShowLoader(false);
      setTitle("");
      if (
        data &&
        data.data === "This Token has been expired, please created new Token."
      ) {
        swal(
          "This Token has been expired, please created new Token.",
          "",
          "error"
        ).then(() => {
          navigate("/login");
        });
      } else {
        swal("Password changed successfully", "", "success").then(() => {
          navigate("/login");
        });
      }
    } else {
      setShowLoader(false);
      swal(!customFunctions.checkIfEmpty(message, 'O') ? message[0] : 'Something went wrong', "", "error");
    }
  };

  const handlePasswordView = (e) => {
    e.preventDefault();
    setSwitchItem(!switchItem);
  };

  const handleConfPasswordView = (e) => {
    e.preventDefault();
    setConfPasSwitchItem(!confPasSwitchItem);
  };

  return (
    <>
      <div className="login-page recover-password">
        <div className="logo text-center d-flex">
          <Image src="/images/CA-logo_in_black" />
        </div>
        <Loader showLoader={isShowLoader} needFullPage={false} />
        <Form className="">
          <div className="title text-center">
            <h5>Recover Password</h5>
          </div>
          <div className="form-group">
            <img src="/images/user.svg" alt="Username" />
            <input
              className="form-control form-control-lg"
              type="email"
              name="email"
              minLength={6}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Enter registered email address"
              disabled={email}
            />
          </div>
          <div className="form-group">
            <img src="/images/password.svg" alt="Username" />
            <input
              className="form-control form-control-lg"
              type={switchItem ? "text" : "password"}
              name="password"
              minLength={6}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Enter new password"
            />
            {password && (
              <img
                style={{ cursor: "pointer" }}
                src={
                  !switchItem ? "/images/visibility.svg" : "/images/eyePass.svg"
                }
                alt="Username"
                className="showpass"
                onClick={(e) => handlePasswordView(e)}
              />
            )}
            {validator.current.message("new password", password, "required", {
              className: "text-danger",
            })}
          </div>
          <div className="form-group">
            <img src="/images/password.svg" alt="Username" />
            <input
              className="form-control form-control-lg"
              type={confPasSwitchItem ? "text" : "password"}
              name="confirmPassword"
              minLength={6}
              value={confirmPassword}
              onChange={(e) => setConfPass(e.target.value)}
              placeholder="Re-enter the password"
            />
            {confirmPassword && (
              <img
                style={{ cursor: "pointer" }}
                src={
                  !confPasSwitchItem ? "/images/visibility.svg" : "/images/eyePass.svg"
                }
                alt="Username"
                className="showpass"
                onClick={(e) => handleConfPasswordView(e)}
              />
            )}

            {password !== "" && (
              <span>
                {validator.current.message(
                  "re-enter password",
                  confirmPassword,
                  "required",
                  { className: "text-danger" }
                )}
              </span>
            )}
            {confirmPassword !== "" && (
              <span className="text-danger">{cpassErr}</span>
            )}
          </div>
          <div className="text-center mt-3">
            <Button
              type="submit"
              className="btn btn-lg btn-primary"
              onClick={(e) => validateForm(e)}
            >
              Reset
            </Button>
          </div>
          <a href="/login" className="back-to-login">
            Back to Login
          </a>
        </Form>
      </div>
    </>
  );
};

export default ResetPassword;
