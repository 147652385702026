import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";
import {
  Card,
  Form,
  Col,
  Row,
  Button,
  Accordion,
  Image,
} from "react-bootstrap";
import customFunctions from "../../helper/customFunctions";
import AuthApi from "../../helper/authApi";
import Api from "../../helper/api";
import Breadcrumb from "../../components/Breadcrumb/breadcrumb.component";
import Loader from "../../components/Loader/index";
import { Helmet, DropdownInput } from "../../components/FormInputs";
import Table from "../../components/Table/simpletable.component";
import SweetAlert from "react-bootstrap-sweetalert";
import { serviceStatus } from "../superAdmin/constant";

const RecurringTaskList = () => {
  const navigate = useNavigate();
  const [isShowLoader, setShowLoader] = useState<any>(false);
  const [rows, setRows] = useState<any>([]);
  const [title, setTitle] = useState<any>("Recurring Tasks List");
  const [count, forceUpdate] = useState<any>(0);
  const [filterErr, setFilterErr] = useState<any>("");
  const [searchKeyword, setSearchKeyword] = useState<any>("");
  const [applyToggle, setApplyToggle] = useState<any>(false);
  const [deleteToggle, setDeleteToggle] = useState<any>(false);
  const [roleId, setRoleId] = useState<number>(0);
  const [currentUserId, setCurrentUserId] = useState<number>(0);
  const [taskData, setTaskData] = useState<any>([]);
  const [taskId, setTaskId] = useState<any>("");
  const [serviceArray, setServiceArray] = useState<any>();
  const [selectedStatus, setSelectedStatus] = useState<any>("");
  const [isShowFilter, setisShowFilter] = useState<boolean>(true);

  const items = [
    { to: "/", label: "Dashboard" },
    { label: "Recurring Tasks List" },
  ];
  const initialPagination = {
    currentPage: 1,
    itemsPerPage: 10,
    totalItems: 1,
  };
  const [pagination, setPagination] = useState(initialPagination);

  const columns: any = [
    {
      label: "Sr. No.",
      id: "index",
    },
    {
      label: "Recurring Task Name",
      id: "task_name",
    },
    {
      label: "Recurring Status",
      id: "status",
    },
    {
      label: "Due Date",
      id: "due_date",
    },

    {
      label: "Action",
      id: "action",
    },
  ];

  useEffect(() => {
    getUserRole();
  }, []);

  const getUserRole = () => {
    const role = customFunctions.getLocalStorage("role_id");
    setRoleId(Number(role));
    const currentUserId = customFunctions.getLocalStorage("id");
    setCurrentUserId(Number(currentUserId));
  };

  useEffect(() => {
    getTaskListData(1, true);
  }, [currentUserId]);

  const getTaskListData = async (pageNo?: any, clearFilter = false) => {
    const page = pageNo || 1;
    setShowLoader(true);
    setApplyToggle(false);
    const endPoint = Api.taskDueDateUrl;
    const params = {
      page: page,
      limit: pagination.itemsPerPage,
      sort_direction: "descending",
      repetative_status:
        !clearFilter && selectedStatus ? selectedStatus?.value : "",
    };

    const url = customFunctions.generateUrl(endPoint, params);

    const { data, message } = await AuthApi.getDataFromServer(url);

    if (data && data.success === true) {
      setShowLoader(false);
      if (data && data.data && !data.data.length && clearFilter) {
        setisShowFilter(false);
      }

      if (data && data.data && data.data.length > 0) {
        setShowLoader(false);
        setPagination({
          currentPage: data.paginator.current_page,
          itemsPerPage: Number(10),
          totalItems: data.paginator.total_count,
        });
        setTaskData(data.data);
        forceUpdate(count + 1);
      } else {
        setTaskData([]);
      }
    } else {
      setShowLoader(false);
      setTaskData([]);
      swal(
        typeof message == "string"
          ? message
          : message?.length
          ? message[0]
          : "Something went wrong!",
        "",
        "error"
      );
    }
  };

  useEffect(() => {
    setTableData();
  }, [taskData]);

  const setTableData = () => {
    const rowsData: any = [];

    taskData.map((dt: any, i: any) => {
      rowsData.push({
        id: dt.id,
        index: (pagination.currentPage - 1) * pagination.itemsPerPage + (i + 1),
        task_name: <>{dt && dt?.task?.task_name}</>,
        status: (
          <>
            {dt?.repetative_status_name &&
            dt?.repetative_status_name == "Yearly"
              ? "Once in year"
              : dt?.repetative_status_name &&
                dt?.repetative_status_name == "Monthly"
              ? "Every month"
              : "-"}
          </>
        ),
        due_date: (
          <>
            {dt?.repetative_status == "1"
              ? `${
                  dt?.due_date &&
                  customFunctions.getDateSuffix(
                    new Date(dt?.due_date).getDate()
                  )
                } of every month`
              : dt?.repetative_status == "2"
              ? `${customFunctions.getDMYDate(dt?.due_date, "dmy")}`
              : "-"}
          </>
        ),
        action: (
          <>
            <a
              href="/"
              className="pl-2 ms-2 underline"
              title={"Edit Task"}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                navigate("/edit-recurring-task", {
                  state: {
                    data: dt,
                    pageType: "edit",
                  },
                });
              }}
            >
              {/* <Image src="images/edit-button.svg" alt="Edit task" /> */}
              Edit
            </a>
            <br />
            <a
              href="/"
              className="pl-2 ms-2 underline"
              title={"Delete Task"}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();

                setTaskId(dt?.id);
                setDeleteToggle(true);
              }}
            >
              Delete
            </a>
          </>
        ),
      });
      return dt;
    });
    setRows([...rowsData]);
  };

  const filterSearch = (e: any) => {
    e.preventDefault();
    let filterSearchErr = "";
    let isValid = true;
    if (customFunctions.checkIfEmpty(selectedStatus)) {
      filterSearchErr = "Please select status";
      setFilterErr(filterSearchErr);
      isValid = false;
    }
    if (isValid) {
      setFilterErr("");
      getTaskListData(1, false);
    }
  };

  const onClear = (e: any) => {
    setFilterErr("");
    setSearchKeyword("");
    setSelectedStatus("");
    setApplyToggle(false);

    if (selectedStatus) {
      getTaskListData(1, true);
    }
  };

  const deleteTask = async () => {
    if (!taskId) return;
    setShowLoader(true);

    const callback: any = AuthApi.deleteDataFromServer;
    let url = `${Api.taskDueDateUrl}${taskId}/`;
    const { data, message } = await callback(url);

    if (data && data.success == true) {
      setShowLoader(false);
      setDeleteToggle(false);
      swal("Task deleted successfully!", "", "success", {
        closeOnClickOutside: false,
      }).then(() => {
        setTaskId(null);
        getTaskListData(1, true);
      });
    } else {
      setShowLoader(false);
      swal(
        typeof message == "string"
          ? message
          : message?.length
          ? message[0]
          : "Something went wrong!",
        "",
        "error"
      ).then(() => {
        setTaskId(null);
        getTaskListData(1, true);
      });
    }
  };

  return (
    <>
      <Helmet title={title} />
      <div className="page-header">
        <div className="title-breadcrumb-section">
          <h2 className="main-content-title tx-24 mg-b-5">{title}</h2>
          <Breadcrumb items={items} />
        </div>
      </div>
      <Loader showLoader={isShowLoader} needFullPage={false} />
      {deleteToggle && (
        <div className="sweetalert">
          <SweetAlert
            title={"Are you sure? do you want to delete the task?"}
            warning
            showCancel
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            onConfirm={() => {
              setDeleteToggle(false);
              deleteTask();
            }}
            onCancel={() => {
              setDeleteToggle(false);
            }}
            reverseButtons={false}
          />
        </div>
      )}
      <section className="content-area">
        {isShowFilter && (
          <Accordion defaultActiveKey="1">
            <Accordion.Item eventKey="1">
              <Accordion.Header>
                <h5 className="card-title mb-0">Filters</h5>
              </Accordion.Header>
              <Accordion.Body>
                <Form onSubmit={(e) => filterSearch(e)}>
                  {filterErr && (
                    <div className="p-3  bg-danger text-white bg-danger-main table-red-bg mb-3">
                      <div className="img-text">
                        <img
                          style={{ cursor: "pointer" }}
                          onClick={(e) => onClear(e)}
                          src="/images/fail.svg"
                          alt="Success"
                        />{" "}
                        {filterErr}
                      </div>
                    </div>
                  )}
                  <Row className="mt-3">
                    <Col sm={3}>
                      <DropdownInput
                        placeholder="Select status"
                        options={serviceStatus}
                        defaultValue={selectedStatus}
                        onSelectHandler={(select: any) => {
                          setSelectedStatus(select);
                        }}
                      />
                    </Col>

                    <Col sm={3}>
                      <div className="filter-btn pull-right filter-btn-report">
                        <Button
                          variant="primary"
                          type="submit"
                          className="ms-4"
                          onClick={() => setApplyToggle(true)}
                        >
                          Apply
                        </Button>
                        <Button
                          variant="secondary"
                          type="button"
                          className="ms-4"
                          onClick={(e) => onClear(e)}
                        >
                          Clear
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        )}
        <Card className="mt-3">
          <Card.Header>
            <div className="d-flex align-items-center justify-content-end">
              <a
                href="#"
                className="underline"
                onClick={(e) => {
                  e.preventDefault();
                  navigate("/create-recurring-task", {
                    state: { pageType: "add" },
                  });
                }}
              >
                Create Recurring Task
              </a>
            </div>
          </Card.Header>
          <Card.Body>
            <Table
              columns={columns}
              showCheckbox={false}
              rows={rows}
              showPagination
              role={roleId}
              paginationProps={{
                ...pagination,
                currentPage: Number(pagination.currentPage),
                onPageChange: (pageNo: any) => {
                  getTaskListData(pageNo, true);
                },
              }}
              dropdownContainerClass="mb-3"
              emptyRender={() => (
                <p className="text-center mb-0">No record found</p>
              )}
            />
          </Card.Body>
        </Card>
      </section>
    </>
  );
};

export default RecurringTaskList;
