import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";
import {
  Card,
  Form,
  Col,
  Row,
  Button,
  Accordion,
  Image,
} from "react-bootstrap";
import customFunctions from "../../helper/customFunctions";
import AuthApi from "../../helper/authApi";
import Api from "../../helper/api";
import Breadcrumb from "../../components/Breadcrumb/breadcrumb.component";
import Loader from "../../components/Loader/index";
import { Helmet, DropdownInput, TextInput } from "../../components/FormInputs";
import Table from "../../components/Table/simpletable.component";
import SweetAlert from "react-bootstrap-sweetalert";
import { serviceStatus } from "../superAdmin/constant";
import SelectAsyncPaginate from "../../components/ReactSelectPagination/SelectAsyncPaginate";
import { DisplayModel } from "./employeeModal";

const AssignLitigationListComponent = () => {
  const navigate = useNavigate();
  const [isShowLoader, setShowLoader] = useState<any>(false);
  const [rows, setRows] = useState<any>([]);
  const [title, setTitle] = useState<any>("Assign Litigations List");
  const [count, forceUpdate] = useState<any>(0);
  const [filterErr, setFilterErr] = useState<any>("");
  const [searchKeyword, setSearchKeyword] = useState<any>("");
  const [roleId, setRoleId] = useState<number>(0);
  const [currentUserId, setCurrentUserId] = useState<number>(0);
  const [litigationData, setLitigationData] = useState<any>([]);
  const [selectedStatus, setSelectedStatus] = useState<any>("");
  const [selectedEmployee, setSelectedEmployee] = useState<any>("");
  const [selectedClient, setSelectedClient] = useState<any>("");
  const [showModal, setShowModal] = useState<boolean>(false);
  const [employeeNames, setEmployeeNames] = useState<any>("");
  const [isShowFilter, setisShowFilter] = useState<boolean>(true);

  const items = [
    { to: "/", label: "Dashboard" },
    { label: "Assign Litigations List" },
  ];
  const initialPagination = {
    currentPage: 1,
    itemsPerPage: 10,
    totalItems: 1,
  };
  const [pagination, setPagination] = useState(initialPagination);

  const statusArray: any = [
    {
      label: "Pending",
      value: 1,
    },
    {
      label: "Closed",
      value: 2,
    },
  ];

  const columns: any = [
    {
      label: "Sr. No.",
      id: "index",
    },
    {
      label: "Litigation",
      id: "litigation_title",
    },
    {
      label: "Employee Name",
      id: "employee_name",
    },
    {
      label: "Action",
      id: "action",
    },
  ];

  useEffect(() => {
    getUserRole();
  }, []);

  const getUserRole = () => {
    const role = customFunctions.getLocalStorage("role_id");
    setRoleId(Number(role));
    const currentUserId = customFunctions.getLocalStorage("id");
    setCurrentUserId(Number(currentUserId));
  };

  useEffect(() => {
    getLigationTask(1, true);
  }, [currentUserId]);

  const getLigationTask = async (pageNo?: any, clearFilter = false) => {
    const page = pageNo || 1;
    setShowLoader(true);
    const endPoint = Api.employeeLitigationUrl;
    const params = {
      page: page,
      limit: pagination.itemsPerPage,
      sort_direction: "descending",
      task_type: 2,
      employee: !clearFilter && selectedEmployee ? selectedEmployee?.id : "",
      litigation_client:
        !clearFilter && selectedClient ? selectedClient?.id : "",
    };

    const url = customFunctions.generateUrl(endPoint, params);

    const { data, message } = await AuthApi.getDataFromServer(url);

    if (data && data.success === true) {
      setShowLoader(false);
      if (data && data?.data && !data?.data?.length && clearFilter) {
        setisShowFilter(false);
      }

      if (data && data.data && data.data.length > 0) {
        setShowLoader(false);
        setPagination({
          currentPage: data.paginator.current_page,
          itemsPerPage: Number(10),
          totalItems: data.paginator.total_count,
        });
        setLitigationData(data.data);
        forceUpdate(count + 1);
      } else {
        setLitigationData([]);
      }
    } else {
      setShowLoader(false);
      setLitigationData([]);
      swal(
        typeof message == "string"
          ? message
          : message?.length
          ? message[0]
          : "Something went wrong!",
        "",
        "error"
      );
    }
  };

  useEffect(() => {
    setTableData();
  }, [litigationData]);

  const setTableData = () => {
    const rowsData: any = [];

    litigationData.map((dt: any, i: any) => {
      rowsData.push({
        id: dt.id,
        index: (pagination.currentPage - 1) * pagination.itemsPerPage + (i + 1),
        employee_name: <>{getEmployee(dt)}</>,
        litigation_title: (
          <>{dt?.litigation_title ? dt?.litigation_title : "-"}</>
        ),
        action: (
          <>
            <a
              href="/"
              className="pl-2 underline"
              title={"Edit Litigation"}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                navigate("/assign-litigation", {
                  state: {
                    id: dt?.id,
                    data: dt,
                    pageType: "edit",
                    // clientId: dt?.id,
                  },
                });
              }}
            >
              {/* <Image src="images/edit-button.svg" alt="Edit task" /> */}
              Edit
            </a>
            {/* <a
              href="/"
              className="pl-2 ms-1"
              title={"Delete Task"}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                
                setTaskId(dt?.id);
                setDeleteToggle(true);
              }}
            >
              <Image src="images/delete.png" alt="view" />
            </a> */}
            {/* <i
              title="Delete Task"
              className="fa-regular fa-trash-can fa-xl ml-2"
              style={{ color: "#e65b5b", cursor: "pointer" }}
              onClick={() => {
                setTaskId(dt?.id);
                setDeleteToggle(true);
              }}
            ></i> */}
          </>
        ),
        status: <>{dt?.status_name}</>,
      });
      return dt;
    });
    setRows([...rowsData]);
  };

  const getEmployee = (dt: any) => {
    if (dt?.employee) {
      const arrData = dt?.employee.map((item: any) => item?.employee_name);
      return arrData.length <= 2 ? (
        arrData.join(", ")
      ) : (
        <div>
          <a
            href="/"
            title={"View Employees"}
            onClick={(e) => {
              e.preventDefault();
              setEmployeeNames(arrData);
              setShowModal(true);
            }}
          >{`${arrData.slice(0, 2).join(", ")}...`}</a>
        </div>
      );
    }
  };

  const filterSearch = (e: any) => {
    e.preventDefault();
    let filterSearchErr = "";
    let isValid = true;
    if (
      customFunctions.checkIfEmpty(selectedEmployee) &&
      customFunctions.checkIfEmpty(selectedClient)
    ) {
      filterSearchErr = "Please select client or employee";
      setFilterErr(filterSearchErr);
      isValid = false;
    }
    if (isValid) {
      setFilterErr("");
      getLigationTask(1, false);
    }
  };

  const onClear = (e: any) => {
    setFilterErr("");
    setSearchKeyword("");
    setSelectedEmployee("");
    setSelectedClient("");
    setSelectedStatus("");
    getLigationTask(1, true);
  };

  return (
    <>
      <Helmet title={title} />
      <div className="page-header">
        <div className="title-breadcrumb-section">
          <h2 className="main-content-title tx-24 mg-b-5">{title}</h2>
          <Breadcrumb items={items} />
        </div>
      </div>
      <Loader showLoader={isShowLoader} needFullPage={false} />

      <section className="content-area">
        {isShowFilter && (
          <Accordion defaultActiveKey="1">
            <Accordion.Item eventKey="1">
              <Accordion.Header>
                <h5 className="card-title mb-0">Filters</h5>
              </Accordion.Header>
              <Accordion.Body>
                {filterErr && (
                  <div className="p-3  bg-danger text-white bg-danger-main table-red-bg mb-3">
                    <div className="img-text">
                      <img
                        style={{ cursor: "pointer" }}
                        onClick={(e) => onClear(e)}
                        src="/images/fail.svg"
                        alt="Success"
                      />{" "}
                      {filterErr}
                    </div>
                  </div>
                )}
                <Row className="mt-3">
                  <Col sm={3}>
                    <SelectAsyncPaginate
                      selectPlaceholder={"Select client"}
                      apiname={"clientUrl"}
                      endpoint={Api.clientUrl}
                      value={selectedClient}
                      onSelectChange={(select: any) => {
                        setSelectedClient(select);
                      }}
                      fromComponent={"addTask"}
                    />
                  </Col>
                  <Col sm={3}>
                    <SelectAsyncPaginate
                      selectPlaceholder={"Select employee"}
                      value={selectedEmployee}
                      onSelectChange={(select: any) => {
                        setSelectedEmployee(select);
                      }}
                      apiname={"employeeUrl"}
                      endpoint={Api.employeeUrl}
                    />
                  </Col>

                  <Col sm={3}>
                    <div className="filter-btn pull-right filter-btn-report">
                      <Button
                        variant="primary"
                        type="submit"
                        className="ms-4"
                        onClick={(e: any) => filterSearch(e)}
                      >
                        Apply
                      </Button>
                      <Button
                        variant="secondary"
                        type="button"
                        className="ms-4"
                        onClick={(e) => onClear(e)}
                      >
                        Clear
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        )}
        <Card className="mt-3">
          <Card.Header>
            <div className="d-flex align-items-center justify-content-end">
              <a
                href="#"
                className="underline"
                onClick={(e) => {
                  e.preventDefault();
                  navigate("/assign-litigation", {
                    state: { pageType: "add" },
                  });
                }}
              >
                Assign Litigation To Employee
              </a>
            </div>
          </Card.Header>
          <Card.Body>
            <Table
              columns={columns}
              showCheckbox={false}
              rows={rows}
              showPagination
              role={roleId}
              paginationProps={{
                ...pagination,
                currentPage: Number(pagination.currentPage),
                onPageChange: (pageNo: any) => {
                  getLigationTask(pageNo, true);
                },
              }}
              dropdownContainerClass="mb-3"
              emptyRender={() => (
                <p className="text-center mb-0">No record found</p>
              )}
            />
          </Card.Body>
        </Card>
      </section>
      {showModal && (
        <DisplayModel
          employee={employeeNames}
          popupClose={() => {
            setShowModal(!showModal);
          }}
        />
      )}
    </>
  );
};

export default AssignLitigationListComponent;
