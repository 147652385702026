import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Row } from "react-bootstrap";
import { Helmet } from "../../../components/FormInputs";
import Loader from "../../../components/Loader/index";
import customFunctions from "../../../helper/customFunctions";
import EmployeeDashboard from "../../employee/employeeDashboard";
import NewAdminDashboardComponent from "./NewDashboard";

const MainDashboardComponent = () => {
  const title = "Dashboard";

  const [isShowLoader, setShowLoader] = useState<any>(false);
  const [roleId, setRoleId] = useState<number>(0);
  const [currentEmpId, setCurrentUserId] = useState<number>(0);
  const userName = customFunctions.getLocalStorage("userName");

  const [count, setCount] = useState<any>({
    clients: 0,
    employees: 0,
    services: 0,
  });

 
  useEffect(() => {
    getTaskData();
  }, []);
  const getTaskData = () => {
    const role = customFunctions.getLocalStorage("role_id");
    setRoleId(Number(role));
    const currentUserId = customFunctions.getLocalStorage("id");
    setCurrentUserId(Number(currentUserId));
  };

  return (
    <>
      <Helmet title={title} />
      <Loader showLoader={isShowLoader} needFullPage={false} />
      <Row>
        {[2, 6].includes(roleId) || customFunctions.getLocalStorage("admin_access") == "true" ? (
         <NewAdminDashboardComponent/>
        ) : null}
       
        {[3, 4, 5].includes(roleId) && customFunctions.getLocalStorage("admin_access") == "false"? (
        <EmployeeDashboard/>
        ) : null}
      </Row>
    </>
  );
};
export default MainDashboardComponent;
