import { useState, useEffect } from "react";
import swal from "sweetalert";
import { Card, Image, Button, Row, Col } from "react-bootstrap";
import Loader from "../../components/Loader";
import Table from "../../components/Table/simpletable.component";
import AuthApi from "../../helper/authApi";
import Api from "../../helper/api";
import AdminViewDocumentComponent from "./adminViewDocument";
import { Helmet } from "../../components/FormInputs";
import Breadcrumb from "../../components/Breadcrumb/breadcrumb.component";
import { useNavigate } from "react-router-dom";
import customFunctions from "../../helper/customFunctions";
import { useLocation } from 'react-router-dom';

const ViewTaskDetails = (props: any) => {
  const navigate: any = useNavigate();
  const location = useLocation();
  const { dueDate, roleId, employeeData } = props;
  const { data, pageType, empId, taskTimeLog } = location.state || {};
  const [isShowLoader, setShowLoader] = useState<any>(false);
  const [createTask, setCreateTask] = useState<any>([]);
  const [count, forceUpdate] = useState<any>(0);
  const [updateShow, setUpdateShow] = useState<boolean>(false);
  const [modalData, setModalData] = useState<boolean>(false);
  const [taskID, setTaskID] = useState<any>();
  const [totalCount, setTotalCount] = useState<any>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const items = [
    { to: "/", label: "Dashboard" },
    { to: "/assign-task-list", label: "Assign Tasks" },
    { label: "View Timesheet" },
  ];

  const initialPagination = {
    currentPage: 1,
    itemsPerPage: 10,
    totalItems: 1,
  };
  const [pagination, setPagination] = useState(initialPagination);
  useEffect(()=>{
    if(data?.task_time_logs?.length > 0){
      setCreateTask(data?.task_time_logs?.map((element:any)=>{
        setPagination({...pagination,
          totalItems:data?.task_time_logs?.length
        })
        // setPagination({
        //   currentPage: data.paginator.current_page,
        //   itemsPerPage: Number(10),
        //   totalItems: data.paginator.total_count,
        // });
        return {
          ...element,
          created_at: customFunctions.getDate(element?.created_at)
        }
      }));
    }else{
    setCreateTask([]);
    }
  },[data])
  const statusLabel = taskTimeLog?.task_status === 1 ? "WIP" : "Completed";
  
  const columns = [
    {
      label: "Employee Name",
      id: "employee_name",
    },
    {
      label: "Client Name",
      id: "client__client_name",
    },
    {
      label: "Task Name",
      id: "task__task_name",
    },
    {
      label: "Time Logged (In Hours)",
      id: "time_log",
    },
    {
      label: "Time Log Date",
      id: "created_at",
    },
    {
      label: "Task Due Date",
      id: "due_date",
      render:(row:any)=>{
        return(
          row?.due_date ? customFunctions.getDMYDate(row?.due_date ,"dmy") : "-"
        )
      }
    },
    {
      label: "Status",
      id: "task_status",
    render:(row:any)=>{
      return(
        row?.task_status == 1 ? "WIP" : "Completed"
      )
    }
    }
  ];

  const totalTime: any = data?.task_time_logs?.reduce((a: any, b: any) => {
    return Number(a) + Number(b.time_log);
  }, 0);

  const showEntries = (data:any) => {
    let end = currentPage * 10;
    let start = end - 10;
    let formListData = data?.slice(start, end);
    return formListData
    };

  return (
    <>
      <Helmet title={"View Timesheet"} />
      <Button
          style={{ float: "right" }}
          className="form-group mt-4"
          type="submit"
          variant="primary"
          onClick={() => {
            navigate(-1);
          }}
        >
          Back
        </Button>
      <div className="page-header">
        <div className="title-breadcrumb-section">
          <h2 className="main-content-title tx-24 mg-b-5">
            {`Timesheet of ${data?.employee?.map((item:any)=>(item?.employee_name))}`}
            </h2>
          <Breadcrumb items={items} />
        </div>
      </div>
      
      <Loader showLoader={isShowLoader} needFullPage={false} />
      <section className="content-area">
        <Card>
          <Card.Header>
            <h5 className="d-flex justify-content-end">
              Total Time Logged - {totalTime} hours
            </h5>
          </Card.Header>
          <Card.Body>
            <Table
              columns={columns}
              showCheckbox={false}
              rows={showEntries(createTask)}
              role={roleId}
              showPagination={true}
              paginationProps={{
                ...pagination,
                currentPage: Number(pagination.currentPage),
                onPageChange: (pageNo: any) => {
                  // showEntries(pageNo);
                },
              }}
              dropdownContainerClass="mb-3"
              emptyRender={() => (
                <p className="text-center mb-0">No record found</p>
              )}
            />
          </Card.Body>
        </Card>
      </section>
      {updateShow && (
        <AdminViewDocumentComponent
          show={updateShow}
          data={modalData}
          handleClose={() => {
            setUpdateShow(false);
          }}
        />
      )}
    </>
  );
};

export default ViewTaskDetails;
