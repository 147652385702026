import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";
import SweetAlert from "react-bootstrap-sweetalert";
import {
  Card,
  Form,
  Col,
  Row,
  Button,
  Accordion,
  Image,
  Modal,
} from "react-bootstrap";
import customFunctions from "../../helper/customFunctions";
import AuthApi from "../../helper/authApi";
import Api from "../../helper/api";
import Breadcrumb from "../../components/Breadcrumb/breadcrumb.component";
import { Helmet, TextInput, DropdownInput } from "../../components/FormInputs";
import Table from "../../components/Table/simpletable.component";
import SimpleReactValidator from "simple-react-validator";
import SubmitTaskModel from "./submitTaskModel";

const EmployeeViewTaskComponent = () => {
  const navigate = useNavigate();
  const validator = useRef(new SimpleReactValidator());
  const [isShowLoader, setShowLoader] = useState<any>(false);
  const [rows, setRows] = useState<any>([]);
  const [title, setTitle] = useState<any>("Task List");
  const [statusToggle, setStatusToggle] = useState<any>(false);
  // const [statusId, setStatusId] = useState<any>("");
  const [count, forceUpdate] = useState<any>(0);
  const [filterErr, setFilterErr] = useState<any>("");
  const [searchKeyword, setSearchKeyword] = useState<any>("");
  const [selectedStatus, setSelectedStatus] = useState<any>("");
  const [applyToggle, setApplyToggle] = useState<any>(false);
  const [roleId, setRoleId] = useState<number>(0);
  const [doctorId, setDoctorId] = useState<any>(null);
  const [currentUserId, setCurrentUserId] = useState<number>(0);
  const [taskData, setTaskData] = useState<any>([]);

  const items = [
    { to: "/", label: "Dashboard" },
    // { to: "/employee-profile", label: "Employee" },
    { label: "Task List" },
  ];

  const statusArray = [
    {
      label: "Nilesh",
      value: "Nilesh",
    },
    {
      label: "Laxi",
      value: "Laxi",
    },
    {
      label: "Aakash",
      value: "Aakash",
    },
    {
      label: "Rahul",
      value: "Rahul",
    },
    {
      label: "Shani",
      value: "Shani",
    },
  ];

  const initialPagination = {
    currentPage: 1,
    itemsPerPage: 2,
    totalItems: 1,
  };
  const [pagination, setPagination] = useState(initialPagination);

  const columns = [
    {
      label: "",
      id: "reject_status",
    },
    {
      label: "Service Name",
      id: "Service_name",
    },
    {
      label: "Task Name",
      id: "Task_name"
    },
    {
      label: "Client Name",
      id: "Client_name",
    },
    {
      label: "Assigned By",
      id: "assigned_by",
    },

    {
      label: "Due Date",
      id: "due_date",
    },
    {
      label: "Task Priority",
      id: "Task_priority",
    },
    // {
    //   label: "Task Status",
    //   id: "task_status",
    // },
    {
      label: "Total Time (in Hrs)",
      id: "total_time",
    },
    {
      label: "Action",
      id: "action",
      // roleAccess:{roleId}
    },
  ];

  // const statusArray = [
  //   {
  //     label: "Active",
  //     value: "1",
  //   },
  //   {
  //     label: "Inactive",
  //     value: "2",
  //   },
  // ];

  useEffect(() => {
    getUserRole();
  }, []);

  const getUserRole = () => {
    const role = customFunctions.getLocalStorage("role_id");
    setRoleId(Number(role));
    const currentUserId = customFunctions.getLocalStorage("id");
    setCurrentUserId(Number(currentUserId));
  };

  useEffect(() => {
    getTaskData();
  }, [currentUserId]);

  const getTaskData = async (pageNo?: any, clearFilter?: boolean) => {
    // if (
    //     !currentUserId ||
    //     (!applyToggle && clearFilter && !searchKeyword && !selectedStatus)
    // )
    //     return;
    // const page = pageNo || 1;
    // setShowLoader(true);
    // // setApplyToggle(false);
    // const endPoint = Api.doctorUrl;

    // const params = {
    //     page: page,
    //     limit: pagination.itemsPerPage,
    //     search: !clearFilter && searchKeyword ? searchKeyword.trim() : "",
    //     status: !clearFilter && selectedStatus ? selectedStatus.value : "",
    // };

    // const url = customFunctions.generateUrl(endPoint, params);

    // const { data, message } = await AuthApi.getDataFromServer(url);
    // if (data && data.success == true) {
    //     setShowLoader(false);
    //     if (
    //         data &&
    //         data.data &&
    //         data.data.response &&
    //         data.data.response.length > 0
    //     ) {
    //         setShowLoader(false);
    //         setPagination({
    //             currentPage: data.data.page,
    //             itemsPerPage: 2,
    //             totalItems: data.data.count,
    //         });
    //         setTaskData(data.data.response);
    //         forceUpdate(count + 1);
    //     } else {
    //         setTaskData([]);
    //     }
    // } else {
    //     setShowLoader(false);
    //     setTaskData([]);
    //     swal(message, "", "error");
    // }
  };

  useEffect(() => {
    setTableData();
  }, [taskData]);

  const setTableData = () => {
    const rowsData: any = [];
    taskData.map((dt: any, i: any) => {
      rowsData.push({
        id: dt.id,
        rowClass: dt?.colorCode ? dt?.colorCode : "",
        reject_status: (
          <>
            {dt.colorCode == "red" ? (
              <span
                style={{ cursor: "pointer" }}
                className="dot"
                title="Click here to view reason"
                onClick={() => alert("Rejected task")}
              ></span>
            ) : null}
          </>
        ),
        index: (pagination.currentPage - 1) * pagination.itemsPerPage + (i + 1),
        subtask_name: dt.subtask_name,
        assigned_by: dt.assigned_by,
        Client_name: dt.Client_name,
        due_date: dt.due_date,
        Task_priority: dt.Task_priority,
        total_time: dt.total_time,
        Task_name:dt.Task_name,
        Task_status: dt.Task_status,
        //     name: (
        //         <>
        //             <a
        //                 href="/"
        //                 className="pl-2 ms-1"
        //                 title="View Partner"
        //                 onClick={(e) => {
        //                     e.preventDefault();
        //                     e.stopPropagation();
        //                     navigate("/edit-partner", {
        //                         state: { doctorId: dt?.id, type: "edit" },
        //                     });
        //                 }}
        //             >
        //                 {`${dt && dt.first_name
        //                     ? customFunctions.capitalizeFirstLetter(dt.first_name)
        //                     : null
        //                     }
        //    ${dt && dt.last_name
        //                         ? customFunctions.capitalizeFirstLetter(dt.last_name)
        //                         : null
        //                     }`}
        //             </a>
        //         </>
        //     ),
        //     email: dt?.email,
        //     mobile: customFunctions.formatPhoneNumber(dt?.mobile) || "-",
        Service_name: (
          <>
            <a
              href="/"
              className="pl-2 ms-1"
              title={"Edit Task"}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                navigate("/add-new-task", {
                  state: {
                    data: dt,
                    pageType: "edit",
                  },
                });
              }}
            >
              {dt && dt.Service_name}
            </a>
          </>
        ),
        action: (
          // (
          <>
            {/* <a
              href="/"
              className="pl-2 ms-1"
              title={"Edit Task"}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                navigate("/employee-select-task", {
                  state: { data: dt, pageType: "Select Task" },
                });
              }}
            >
              <Image src="/images/edit-button.svg" alt="select task" />
            </a> */}
            &nbsp;&nbsp;
            <a
              href="/"
              className="pl-2 ms-1"
              title={"Log Time"}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                navigate("/task-timelog", {
                  state: { data: dt, pageType: "edit" },
                });
              }}
            >
              <Image src="/images/timer-1.svg" alt="select task" />
            </a>
            &nbsp;&nbsp;
            {dt.Task_status === "Completed" ? (
              <SubmitTaskModel data={dt} />
            ) : (
              // <a
              //   href="/"
              //   className="pl-2 ms-1"
              //   title={"Submit Task"}
              //   onClick={(e) => {
              //     e.preventDefault();
              //     e.stopPropagation();
              //     
              //   }}
              // >
              //   <Image src="/images/Submit.png" alt="submit Task" />
              // </a>
              null
            )}
          </>
        ),
      });
      return dt;
    });
    setRows([...rowsData]);
  };

  // change switch
  // const changeStatus = async () => {
  //     const postData = {
  //         id: Number(doctorId),
  //         status: statusId,
  //     };
  //     if (!doctorId) return;
  //     setShowLoader(true);
  //     const endpoint = `${Api.doctorUrl}/change-doctor-status`;
  //     const { data, message } = await AuthApi.putDataToServer(endpoint, postData);
  //     if (data && data.success == true) {
  //         setStatusToggle(false);
  //         swal("Doctor status changed successfully!", "", "success", {
  //             closeOnClickOutside: false,
  //         }).then(() => {
  //             setDoctorId(null);
  //             setStatusId("");
  //             getTaskData(1);
  //         });
  //     } else {
  //         setShowLoader(false);
  //         swal(message || "Something went wrong", "", "error").then(() => {
  //             setDoctorId(null);
  //             setStatusId("");
  //             getTaskData(1);
  //         });
  //     }
  // };

  const filterSearch = (e: any) => {
    
  };

  const onClear = (e: any) => {
    setFilterErr("");
    setSearchKeyword("");
    setSelectedStatus("");
    getTaskData(1, true);
    setApplyToggle(false);
  };

  return (
    <>
      <>
        <Helmet title={title} />
        <div className="page-header">
          <div className="title-breadcrumb-section">
            <h2 className="main-content-title tx-24 mg-b-5">{title}</h2>
            <Breadcrumb items={items} />
          </div>
        </div>
        {/* <Loader showLoader={isShowLoader} needFullPage={false} /> */}

        {statusToggle && (
          <div className="sweetalert">
            <SweetAlert
              title={"Are you sure? do you want to change the status?"}
              warning
              showCancel
              confirmBtnBsStyle="success"
              cancelBtnBsStyle="danger"
              onConfirm={() => {
                setStatusToggle(false);
                // changeStatus();
              }}
              onCancel={() => {
                setStatusToggle(false);
              }}
              reverseButtons={false}
            />
          </div>
        )}
        <section className="content-area">
          <Accordion defaultActiveKey="1">
            <Accordion.Item eventKey="1">
              <Accordion.Header>
                <h5 className="card-title mb-0">Filters</h5>
              </Accordion.Header>
              <Accordion.Body>
                <Form onSubmit={(e) => filterSearch(e)}>
                  {filterErr && (
                    <div className="p-3  bg-danger text-white bg-danger-main table-red-bg mb-3">
                      <div className="img-text">
                        <img
                          style={{ cursor: "pointer" }}
                          onClick={(e) => onClear(e)}
                          src="/images/fail.svg"
                          alt="Success"
                        />{" "}
                        {filterErr}
                      </div>
                    </div>
                  )}
                  <Row className="mt-3">
                    <Col sm={3}>
                      <TextInput
                        value={searchKeyword}
                        placeholder="Search by service name"
                        onInputChange={(e: any) => {
                          setSearchKeyword(e.target.value);
                        }}
                      />
                    </Col>

                    <Col sm={3}>
                      <DropdownInput
                        placeholder="Select client"
                        options={statusArray}
                        defaultValue={selectedStatus}
                        onSelectHandler={(e: any) => {
                          setSelectedStatus(e);
                        }}
                      />
                    </Col>

                    <Col sm={3}>
                      <div className="filter-btn pull-right filter-btn-report">
                        <Button
                          variant="primary"
                          type="submit"
                          className="ms-4"
                          onClick={() => setApplyToggle(true)}
                        >
                          Apply
                        </Button>
                        <Button
                          variant="secondary"
                          type="button"
                          className="ms-4"
                          onClick={(e) => onClear(e)}
                        >
                          Clear
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
          <Card className="mt-3">
            <Card.Header>
              <div className="d-flex align-items-center justify-content-end">
                <a
                  href="#"
                  className="underline"
                  onClick={(e) => {
                    e.preventDefault();
                    navigate("/add-new-task", {
                      state: { pageType: "add" },
                    });
                  }}
                >
                  Add Task
                </a>
              </div>
            </Card.Header>
            <Card.Body>
              {/* <div className="d-flex align-items-center mb-3  d-flex align-items-center justify-content-between">
                                <h5 className="card-title mb-0 text-blue ">
                                    View Assigned Task
                                </h5>
                            </div> */}
              <Table
                columns={columns}
                // showCheckbox={true}
                rows={rows}
                showPagination
                role={roleId}
                paginationProps={{
                  ...pagination,
                  activePage: pagination.currentPage,
                  onPageChange: (pageNo: any) => {
                    getTaskData(pageNo);
                  },
                }}
                dropdownContainerClass="mb-3"
                emptyRender={() => (
                  <p className="text-center mb-0">No record found</p>
                )}
              />
            </Card.Body>
          </Card>
        </section>
      </>
    </>
  );
};

export default EmployeeViewTaskComponent;
