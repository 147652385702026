import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";
import SweetAlert from "react-bootstrap-sweetalert";
import { Card, Form, Col, Row, Button, Accordion, Image } from "react-bootstrap";
import customFunctions from "../../helper/customFunctions";
import AuthApi from "../../helper/authApi";
import Api from "../../helper/api";
import Breadcrumb from "../../components/Breadcrumb/breadcrumb.component";
import Loader from "../../components/Loader/index";
import { Helmet, TextInput, DropdownInput } from "../../components/FormInputs";
import Table from '../../components/Table/simpletable.component';
import { Data, columns } from "../../helper/clientData";

const NonCorporateClientComponent = (props: any) => {
  const { activeTab } = props;
  customFunctions.setLocalStorage('activeTab', activeTab);
  const navigate = useNavigate();

  const [clientType, setClientType] = useState<string>('');
  const [isShowLoader, setShowLoader] = useState<any>(false);
  const [rows, setRows] = useState<any>([]);
  const [title, setTitle] = useState<any>("Non Corporate Client");
  const [statusToggle, setStatusToggle] = useState<any>(false);
  const [statusId, setStatusId] = useState<any>("");
  const [count, forceUpdate] = useState<any>(0);
  const [filterErr, setFilterErr] = useState<any>("");
  const [searchKeyword, setSearchKeyword] = useState<any>("");
  const [selectedStatus, setSelectedStatus] = useState<any>("");
  const [applyToggle, setApplyToggle] = useState<any>(false);
  const [roleId, setRoleId] = useState<number>(0);
  const [clientId, setClientId] = useState<any>(null);
  const [currentUserId, setCurrentUserId] = useState<number>(0);
  const [clientData, setClientData] = useState<any>([])

  const initialPagination: any = {
    currentPage: 1,
    itemsPerPage: 2,
    totalItems: 1,
  };
  const [pagination, setPagination] = useState<any>(initialPagination);

  const statusArray: any = [
    {
      label: "Active",
      value: "1",
    },
    {
      label: "Inactive",
      value: "2",
    },
  ];

  // useEffect(() => {
  //   getUserRole();
  // }, []);

  // const getUserRole = () => {
  //   const role: any = customFunctions.getLocalStorage("role_id");
  //   setRoleId(Number(role));
  //   const currentUserId: any = customFunctions.getLocalStorage("id");
  //   setCurrentUserId(Number(currentUserId));
  // };

  // api integration
  // useEffect(() => {
  //   getClientList();
  // }, [currentUserId]);

  //static mode
  useEffect(() => {
    getClientList();
  }, [currentUserId]);

  const getClientList = async (pageNo?: any, clearFilter?: boolean) => {

    setClientData(Data)
    // if (
    //   !currentUserId ||
    //   (!applyToggle && clearFilter && !searchKeyword && !selectedStatus)
    // )
    //   return;
    // const page: any = pageNo || 1;
    // setShowLoader(true);
    // // setApplyToggle(false);
    // const endPoint: any = Api.clientUrl;

    // const params: any = {
    //   page: page,
    //   limit: pagination.itemsPerPage,
    //   search: !clearFilter && searchKeyword ? searchKeyword.trim() : "",
    //   status: !clearFilter && selectedStatus ? selectedStatus.value : "",
    // };

    // const url: any = customFunctions.generateUrl(endPoint, params);

    // const { data, message } = await AuthApi.getDataFromServer(url);
    // if (data && data.success == true) {
    //   setShowLoader(false);
    //   if (
    //     data &&
    //     data.data &&
    //     data.data.response &&
    //     data.data.response.length > 0
    //   ) {
    //     setShowLoader(false);
    //     setPagination({
    //       currentPage: data.data.page,
    //       itemsPerPage: 2,
    //       totalItems: data.data.count,
    //     });
    //     setClientData(data.data.response);
    //     forceUpdate(count + 1);
    //   } else {
    //     setClientData([]);
    //   }
    // } else {
    //   setShowLoader(false);
    //   setClientData([]);
    //   swal(message, "", "error");
    // }
  };

  useEffect(() => {
    setTableData();
  }, [clientData]);

  const setTableData = () => {
    const rowsData: any = [];
    clientData.map((dt: any, i: any) => {
      rowsData.push({
        id: dt.id,
        index: (pagination.currentPage - 1) * pagination.itemsPerPage + (i + 1),
        client_type: dt?.client_type,
        name: (
          <>
            <a
              href="/"
              className="pl-2 ms-1"
              title="Edit Client"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                navigate("/non-corporate-client-onboard", {
                  state: {
                    data: dt,
                    pageType: "edit"
                  },
                });
              }}
            >
              {`${dt && dt.first_name
                ? customFunctions.capitalizeFirstLetter(dt.first_name)
                : null
                }
               ${dt && dt.last_name
                  ? customFunctions.capitalizeFirstLetter(dt.last_name)
                  : null
                }`}
            </a>
          </>
        ),
        email: dt?.email,
        contact_number: dt?.contact_number,
        address: dt?.address,
        pan_card_number: dt?.pan_card_number,
        business_description: dt?.business_description,
        financial_year: dt?.financial_year,
        // financial_statement: dt?.financial_statement,
        financial_statement:(
          <>
          <Image src="/images/notes.svg" title="Download Document"/>
          </>
        ),
        engagement: dt?.engagement,
        status: (
          <>
            <div className="switch-check">
              <div className="form-check form-switch" style={{ marginBottom: "0" }}>
                <input
                  className="form-check-input"
                  type="checkbox"
                  role="switch"
                  // disabled={[3, 4].includes(Number(roleId)) ? true : false}
                  id="flexSwitchCheckDefault"
                  checked={dt?.status == "1" ? true : false}
                  onClick={() => {
                    setStatusToggle(true);
                    setStatusId(dt?.status == "2" ? "1" : "2");
                    setClientId(dt?.id);
                  }}
                  title={dt?.status == "1" ? "Active" : "Inactive"}
                />
              </div>
            </div>
          </>
        ),
        action: (
          <>
            <a
              href="/"
              className="pl-2 ms-1"
              title={"View Details"}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                navigate("/non-corporate-client-onboard", {
                  state: {
                    data: dt,
                    pageType: "view"
                  }
                });
              }}
            >
              <Image src="images/eye.svg" alt="view" />
            </a>
            &nbsp;&nbsp;
            {/* <a
              href="/"
              className="pl-2 ms-1"
              title={"Edit Client"}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                navigate("/non-corporate-client-onboard", {
                  state: {
                    data: dt,
                    pageType: "edit"
                  }
                });
              }}
            >
              <Image src="/images/edit-button.svg" alt="edit" />
            </a> */}
          </>
        ),
      });
      return dt;
    });
    setRows([...rowsData]);
  };

  const changeStatus = async () => {
    // const postData: any = {
    //   id: Number(clientId),
    //   status: statusId,
    // };

    // if (!clientId) return;
    // setShowLoader(true);
    // const endpoint: any = `${Api.clientUrl}/change-client-status`;
    // const { data, message } = await AuthApi.putDataToServer(endpoint, postData);
    // if (data && data.success == true) {
    //   setStatusToggle(false);
    //   swal("Client status changed successfully!", "", "success", {
    //     closeOnClickOutside: false,
    //   }).then(() => {
    //     setClientId(null);
    //     setStatusId("");
    //     getClientList(1);
    //   });
    // } else {
    //   setShowLoader(false);
    //   swal(message || "Something went wrong", "", "error").then(() => {
    //     setClientId(null);
    //     setStatusId("");
    //     getClientList(1);
    //   });
    // }
  };

  const filterSearch = (e: any) => {
    e.preventDefault();
    let filterSearchErr: string = "";
    let isValid: boolean = true;
    if (
      customFunctions.checkIfEmpty(searchKeyword) &&
      customFunctions.checkIfEmpty(selectedStatus)
    ) {
      filterSearchErr = "Please enter name, email or select status";
      setFilterErr(filterSearchErr);
      isValid = false;
    }
    if (isValid) {
      setFilterErr("");
      getClientList(1);
    }
  };

  const onClear = (e: any) => {
    setFilterErr("");
    setSearchKeyword("");
    setSelectedStatus("");
    getClientList(1, true);
    setApplyToggle(false);
  };

  return (
    <>
      <>
        <Helmet title={title} />
        <div className="page-header">
          <div className="title-breadcrumb-section">
            {/* <h2 className="main-content-title tx-24 mg-b-5">{title}</h2>
            <Breadcrumb items={items} /> */}
          </div>
        </div>

        {/* <Loader showLoader={isShowLoader} needFullPage={false} /> */}

        {statusToggle && (
          <div className="sweetalert">
            <SweetAlert
              title={"Are you sure, do you want to change the status ?"}
              warning
              showCancel
              confirmBtnBsStyle="success"
              cancelBtnBsStyle="danger"
              onConfirm={() => {
                setStatusToggle(false);
                changeStatus();
              }}
              onCancel={() => {
                setStatusToggle(false);
              }}
              reverseButtons={true}
            />
          </div>
        )}
        <section className="content-area">
          <Accordion defaultActiveKey="1">
            <Accordion.Item eventKey="1">
              <Accordion.Header>
                <h5 className="card-title mb-0">Filters</h5>
              </Accordion.Header>
              <Accordion.Body>
                <Form onSubmit={(e) => filterSearch(e)}>
                  {filterErr && (
                    <div className="p-3  bg-danger text-white bg-danger-main table-red-bg mb-3">
                      <div className="img-text">
                        <img
                          style={{ cursor: "pointer" }}
                          onClick={(e) => onClear(e)}
                          src="/images/fail.svg"
                          alt="Success"
                        />{" "}
                        {filterErr}
                      </div>
                    </div>
                  )}
                  <Row className="mt-3">
                    <Col sm={3}>
                      <TextInput
                        value={searchKeyword}
                        placeholder="Search by name, email"
                        onInputChange={(e: any) => {
                          setSearchKeyword(e.target.value);
                        }}
                      />
                    </Col>

                    <Col sm={3}>
                      <DropdownInput
                        placeholder="Select status"
                        options={statusArray}
                        defaultValue={selectedStatus}
                        onSelectHandler={(e: any) => {
                          setSelectedStatus(e);
                        }}
                      />
                    </Col>

                    <Col sm={3}>
                      <div className="filter-btn pull-right filter-btn-report">
                        <Button
                          variant="primary"
                          type="submit"
                          className="ms-4"
                          onClick={() => setApplyToggle(true)}
                        >
                          Apply
                        </Button>
                        <Button
                          variant="secondary"
                          type="button"
                          className="ms-4"
                          onClick={(e) => onClear(e)}
                        >
                          Clear
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
          <Card className="mt-3">
            <Card.Body>
              <Table
                columns={columns}
                // showCheckbox={true}
                rows={rows}
                showPagination
                role={roleId}
                paginationProps={{
                  ...pagination,
                  activePage: pagination.currentPage,
                  onPageChange: (pageNo: any) => {
                    getClientList(pageNo);
                  },
                }}
                dropdownContainerClass="mb-3"
                emptyRender={() => (
                  <p className="text-center mb-0">No record found</p>
                )}
              />
            </Card.Body>
          </Card>
        </section>
      </>
    </>
  );
}

export default NonCorporateClientComponent;
