import React from 'react';

function Breadcrumb(props) {
  const { items } = props;
  return (
    <>
      <ol className="breadcrumb">
        {items &&
          items.map(({ to, label }) => (
            <li key={`key-${to}`} className={`breadcrumb-item ${to ? "active" : ""}`}>
              <a href={to} onClick={()=>{if(to == '/') localStorage.setItem('menuIndex',Number(0)) }}>{label}</a>
            </li>
          ))}
      </ol>
    </>
  );
}

export default Breadcrumb;
