import React, { useEffect, useRef, useState } from "react";
import Breadcrumb from "../../components/Breadcrumb/breadcrumb.component";
import { DropdownInput, Helmet, TextInput } from "../../components/FormInputs";
import { Card, Form, Row, Col, Button } from "react-bootstrap";
import swal from "sweetalert";
import Api from "../../helper/api";
import AuthApi from "../../helper/authApi";
import SimpleReactValidator from "simple-react-validator";
import { employee } from "../../helper/taskList";
import { useLocation, useNavigate } from "react-router-dom";
import { Message } from "@mui/icons-material";

const TransferTaskComponent = () => {
  const navigate = useNavigate();
  const state =  useLocation();
  const validator = useRef(new SimpleReactValidator());
  const [pageType, setPageType] = useState<string>("Transfer_task");
  const [label, setLabel] = useState<string>("Transfer_task");
  const [title, setTitle] = useState<any>("Transfer Task");
  const [disableButton, setDisableButton] = useState<boolean>(false);
  const [count, forceUpdate] = useState<number>(0);
  const [isShowLoader, setShowLoader] = useState<boolean>(false);

  const [transferFrom, setTransferFrom] = useState<any>();
  const [transferTo, setTransferTo] = useState<any>();
  const [clientName, setClientName] = useState<any>("");
  const [service, setService] = useState<any>();
  const [subTask, setSubTask] = useState<any>();
  const [description, setDescription] = useState<any>();
  const [isRemove, setIsRemove] = useState<any>(false);
  const [servicesValues, setServicesValues] = useState<any>([]);
  const [getClientName, setGetClientName] = useState<any>([]);
  const [getEmployeeName, setGetEmployeeName] = useState<any>([]);

  const items = [
    { to: "/", label: "Dashboard" },
    { to: "/transfer-task", label: "Transfer Task List" },
    { label: "Transfer Task" },
  ];
    // useEffect(()=>{
    //   setClientName({label:state.state.data.Client_name,value:state.state.data.Client_name})
    //   setService({label:state.state.data.Services,value:state.state.data.Services})
    //   setSubTask(state.state.data.Sub_task)
    //   setDescription("NA")
    //   setTransferFrom({label:state.state.data.Assign_to,value:state.state.data.Assign_to})
    // },[])


useEffect(()=>{
  if(state.state.pageType ===  "Transfer Task"){
    setClientName({label:state.state.data.client_name,value:state.state.data.client_name})
    setService({label:state.state.data.service,value:state.state.data.service})
    setSubTask(state.state.data.sub_task)
    setDescription(state.state.data.description)
    setTransferFrom({label:state.state.data.employee_name,value:state.state.data.employee_name})
    // setTransferTo({label:state.state.data.transfer_to,value:state.state.data.transfer_to})
  }
},[])

  useEffect(() => {
    getServices();
  }, []);
  useEffect(() => {
    getClient();
  }, []);

  useEffect(() => {
    getEmployee();
  }, []);

  const getEmployee = async () => {
    setShowLoader(true);
    const { data, message } = await AuthApi.getDataFromServer(
      `${Api.employeeUrl}`
    );

    if (data && data.success === true) {
      setShowLoader(false);
      if (data && data.data && data.data.length > 0) {
        let employeeArr: any = [];
        data.data.length > 0 &&
          data.data.map((item: any) => {
            employeeArr.push({ label: item?.user?.name, value: item?.id });
          });

        setGetEmployeeName(employeeArr);
      } else {
        setGetEmployeeName([]);
      }
    } else {
      setShowLoader(false);
      swal(message, "", "error");
      return;
    }
  };

  const getClient = async () => {
    setShowLoader(true);
    const { data, message } = await AuthApi.getDataFromServer(
      `${Api.clientUrl}`
    );

    if (data && data.success === true) {
      setShowLoader(false);
      if (data && data.data && data.data.length > 0) {
        let clientArr: any = [];
        data.data.length > 0 &&
          data.data.map((item: any) => {
            clientArr.push({ label: item?.client_name, value: item?.id });
          });

        setGetClientName(clientArr);
      } else {
        setGetClientName([]);
      }
    } else {
      setShowLoader(false);
      swal(message, "", "error");
      return;
    }
  };

  const getServices = async () => {
    setShowLoader(true);
    const { data, message } = await AuthApi.getDataFromServer(
      `${Api.servicesUrl}`
    );

    if (data && data.success === true) {
      setShowLoader(false);
      if (data && data.data && data.data.length > 0) {
        let servicesArr: any = [];
        data.data.length > 0 &&
          data.data.map((item: any) => {
            servicesArr.push({ label: item?.services, value: item?.id });
          });
        setServicesValues(servicesArr);
      } else {
        setServicesValues([]);
      }
    } else {
      setShowLoader(false);
      swal(message, "", "error");
      return;
    }
  };

  const onSubmit = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (disableButton) return;
    setDisableButton(true);
    if (validator.current.allValid()) {
      submitTaskTransfer();
    } else {
      setDisableButton(false);
      validator.current.showMessages();
      forceUpdate(count + 1);
    }
  };

  const submitTaskTransfer = async () => {
    // setShowLoader(true);
    const transferTaskData: any = {
      transferFrom: transferFrom,
      transferTo: transferTo,
      clientName: clientName,
      service: service,
      subTask: subTask,
      description: description,
      removeEmployee: isRemove,
    };
    // if (location.state) {
    //     submitData.id = location.state.taskId
    // }
    // const payload = customFunctions.cleanObject(submitData);
    // const endPoint = `${Api.doctorUrl}`;
    // const callback =
    // location.state && location.state.taskId
    //         ? AuthApi.putDataToServer
    //         : AuthApi.postDataToServer;
    // const { data, message } = await callback(endPoint, payload);
    // if (data && data.success === true) {
    //     setShowLoader(false);
    //     setDisableButton(false);
    //     swal(
    //       location.state && location.state.taskId
    //         ? "Submit Task For Approval!"
    //         : "Task Created successfully!",
    //       "",
    //       "success"
    //     ).then(() => {
    //       navigate(-1);
    //     });
    //   } else {
    //     setDisableButton(false);
    //     setShowLoader(false);
    //     swal(message, "", "error");
    //   }
    swal("Task transfer successfully!", "", "success").then(() => {
      navigate(-1);
    });
  };

  return (
    <>
      <Helmet title={title} />
      <div className="page-header">
        <div className="title-breadcrumb-section">
          <h2 className="main-content-title tx-24 mg-b-5">{title}</h2>
          <Breadcrumb items={items} />
        </div>
      </div>
      <Card>
        <Card.Body>
          <Form>
            <Row>
              <Col sm="6">
                <DropdownInput
                  label="Client"
                  asterisk="*"
                  placeholder="Select client"
                  options={getClientName}
                  disabled={pageType==="Transfer_task"}
                  defaultValue={clientName}
                  onSelectHandler={(select: any) => {
                    setClientName(select);
                  }}
                  isClearable={true}
                  errorMessage={validator.current.message(
                    "clientName",
                    clientName,
                    "required"
                  )}
                  id="clientName"
                />
              </Col>
              <Col sm="6">
                <DropdownInput
                  label="Service"
                  asterisk="*"
                  placeholder="Select service"
                  options={servicesValues}
                  disabled={pageType==="Transfer_task"}
                  defaultValue={service}
                  onSelectHandler={(select: any) => {
                    setService(select);
                  }}
                  isClearable={true}
                  errorMessage={validator.current.message(
                    "service",
                    service,
                    "required"
                  )}
                  id="service"
                />
              </Col>
              <Col sm="6">
                <TextInput
                  onInputChange={(
                    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
                  ) => {
                    setSubTask(e.target.value);
                  }}
                  label="Sub Task"
                  asterisk="*"
                  containerClass="form-group"
                  type="text"
                  name="subTask"
                  value={subTask}
                  // disabled={state && state.status == 4}
                  placeholder="Enter sub task"
                  errorMessage={validator.current.message(
                    "sub Task",
                    subTask,
                    "required"
                  )}
                  autoComplete="new-subTask"
                />
              </Col>
              <Col sm="6">
                <TextInput
                  onInputChange={(
                    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
                  ) => {
                    const re = /^[A-Z a-z 0-9]*$/;
                    if (!e.target.value || re.test(e.target.value)) {
                      setDescription(e.target.value);
                    }
                  }}
                  label="Description"
                  asterisk="*"
                  containerClass="form-group"
                  type="text"
                  // as="textarea"
                  name="description"
                  value={description}
                  // disabled={state && state.status == 4}
                  placeholder="Enter description"
                  errorMessage={validator.current.message(
                    "description",
                    description,
                    "required"
                  )}
                  autoComplete="new-description"
                />
              </Col>
              <Col sm="6">
                <DropdownInput
                  label="Transfer From"
                  asterisk="*"
                  placeholder="Select employee" 
                  options={employee}
                  disabled={pageType==="Transfer_task"}
                  defaultValue={transferFrom}
                  onSelectHandler={(select: any) => {
                    setTransferFrom(select);
                  }}
                  isClearable={true}
                  errorMessage={validator.current.message(
                    "transfer From",
                    transferFrom,
                    "required"
                  )}
                  id="transferFrom"
                />
              </Col>
              <Col sm="6">
                <DropdownInput
                  label="Transfer To"
                  asterisk="*"
                  placeholder="Select employee"
                  options={employee}
                  defaultValue={transferTo}
                  onSelectHandler={(select: any) => {
                    setTransferTo(select);
                  }}
                  isClearable={true}
                  errorMessage={validator.current.message(
                    "transfer to",
                    transferTo,
                    "required"
                  )}
                  id="transferTo"
                />
              </Col>

            
              <Col sm={6}>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value={isRemove}
                    checked={isRemove}
                    // disabled={pageType == 'view'}
                    id="flexCheckDefault"
                    onChange={(e) => setIsRemove(e.target.checked)}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="flexCheckDefault"
                  >
                    Remove From Current Employee
                  </label>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div style={{ float: "right" }} className="form-group mt-3">
                  <Button
                    onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                      e.preventDefault();
                      onSubmit(e);
                    }}
                    variant="primary"
                    type="submit"
                    className="ms-2"
                  >
                    Submit
                  </Button>
                  <Button
                    onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                      e.preventDefault();
                      navigate(-1);
                    }}
                    variant="secondary"
                    className="ms-2"
                  >
                    Cancel
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>
        </Card.Body>
      </Card>
    </>
  );
};
export default TransferTaskComponent;
