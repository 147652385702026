/* eslint-disable-line */
import React, { useState, useRef, useEffect } from "react";
import { Row, Col, Card, Form, Button } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";
import swal from "sweetalert";
import { DropdownInput, Helmet, TextInput } from "../../components/FormInputs";
import SimpleReactValidator from "simple-react-validator";
import customFunctions from "../../helper/customFunctions";
import AuthApi from "../../helper/authApi";
import Api from "../../helper/api";
import Loader from "../../components/Loader";
import Breadcrumb from "../../components/Breadcrumb/breadcrumb.component";
import SelectAsyncPaginate from "../../components/ReactSelectPagination/SelectAsyncPaginate";

const AssignTaskComponent = () => {
  const navigate: any = useNavigate();
  const { state } = useLocation();

  const validator = useRef(new SimpleReactValidator());

  const [count, forceUpdate] = useState<any>(0);
  const [roleId, setRoleId] = useState<number>(0);
  const [currentUserId, setCurrentUserId] = useState<number>(0);
  const [pageType, setPageType] = useState<string>("add");
  const [title, setTitle] = useState("Create Task");
  const [disableButton, setDisableButton] = useState<boolean>(false);
  const [isShowLoader, setShowLoader] = useState<boolean>(false);

  const [selectTask, setSelectTask] = useState<any>();
  const [taskOptions, setTaskOptions] = useState<any>([]);
  const [selectedEmployee, setSelectedEmployee] = useState<any>("");
  const [selectedClient, setSelectedClient] = useState<any>("");
  const [taskId, setTaskId] = useState<any>();

  useEffect(() => {
    getUserRole();
  }, []);

  const getUserRole = () => {
    const role = customFunctions.getLocalStorage("role_id");
    setRoleId(Number(role));
    const currentUserId = customFunctions.getLocalStorage("id");
    setCurrentUserId(Number(currentUserId));
  };

  const items = [
    {
      to: "/",
      label: "Dashboard",
    },
    {
      to: "/assign-task-list",
      label: "Task List",
    },
    { label: title },
  ];

  useEffect(() => {
    if (state && state.pageType === "add") return;

    if (state && state) {
      if (state.pageType === "edit") {
        setPageType("edit");
        setTitle("Edit Task");
        setTaskId(state?.data?.id);
        const employees: any = [];
        state?.data?.employee?.length &&
          state?.data?.employee?.map((element: any) => {
            employees.push({
              name: element?.employee_name,
              id: element?.id,
            });
          });
        setSelectedEmployee(employees?.length ? employees : "");
        setSelectedClient(
          state?.data?.client
            ? {
                name: state?.data?.client?.client_name,
                id: state?.data?.client?.id,
              }
            : ""
        );
        setSelectTask(
          state?.data?.task
            ? {
                label: state?.data?.task?.task_name,
                value: state?.data?.task?.id,
              }
            : ""
        );
      }
    }
  }, [state]);

  useEffect(() => {
    if (selectedClient && selectedClient?.id) {
      getServices(selectedClient?.id);
    }
  }, [selectedClient]);

  const getServices = async (id: any) => {
    setShowLoader(true);
    const { data, message } = await AuthApi.getDataFromServer(
      `${Api.clientUrl}${id}/`
    );

    if (data && data.success === true) {
      setShowLoader(false);
      let serviceArr: any = [];
      data &&
        data.data &&
        data.data.tasks &&
        data.data.tasks.length > 0 &&
        data.data.tasks.map((item: any) => {
          serviceArr.push({
            label: item?.task_name,
            value: item?.task_id,
            service_id: item?.service_id,
          });
        });
      setTaskOptions(serviceArr);
    } else {
      setShowLoader(false);
      setTaskOptions("");
      swal(typeof message === "string" ? message : message[0], "", "error");
    }
  };

  const addTask = async () => {
    setShowLoader(true);
    const postData: any = {
      // service: selectTask?.service_id,
      client: selectedClient?.id,
      employee:
        selectedEmployee?.length &&
        selectedEmployee?.map((element: any) => element?.id),
      task: selectTask?.value,
      task_type: 1,
    };

    const payload = customFunctions.cleanObject(postData);
    const endPoint = taskId
      ? `${Api.employeeLitigationUrl}${taskId}/`
      : `${Api.employeeLitigationUrl}`;

    const callback = taskId
      ? AuthApi.putDataToServer
      : AuthApi.postDataToServer;
    const { data, message } = await callback(endPoint, payload);
    if (data && data.success === true) {
      setShowLoader(false);
      setDisableButton(false);
      swal(
        taskId ? "Task updated successfully!" : "Task assigned successfully!",
        "",
        "success"
      ).then(() => {
        navigate(-1);
      });
    } else {
      setDisableButton(false);
      setShowLoader(false);
      swal(
        typeof message == "string"
          ? message
          : message?.length
          ? message[0]
          : "Something went wrong!",
        "",
        "error"
      );
    }
  };

  const onSubmit = (e: any) => {
    e.preventDefault();

    if (validator.current.allValid()) {
      addTask();
    } else {
      validator.current.showMessages();
      forceUpdate(count + 1);
    }
  };

  return (
    <>
      <Helmet title={title} />
      <Button
        style={{ float: "right" }}
        className="form-group mt-4"
        type="submit"
        variant="primary"
        onClick={() => {
          navigate(-1);
        }}
      >
        Back
      </Button>
      <div className="page-header">
        <div className="title-breadcrumb-section">
          <h2 className="main-content-title tx-24 mg-b-5">{title}</h2>
          <Breadcrumb items={items} />
        </div>
      </div>
      <Loader showLoader={isShowLoader} needFullPage={false} />
      <section className="content-area">
        <Card>
          <Card.Body>
            <Form
              autoComplete="off"
              onSubmit={(e) => {
                onSubmit(e);
              }}
            >
              <Row>
                <Col sm={4}>
                  <SelectAsyncPaginate
                    label={"Employee"}
                    asterisk={"*"}
                    selectPlaceholder={"Select employee"}
                    value={selectedEmployee}
                    onSelectChange={(select: any) => {
                      setSelectedEmployee(select);
                    }}
                    apiname={"employeeUrl"}
                    endpoint={Api.employeeUrl}
                    errorMessage={validator.current.message(
                      "employee",
                      selectedEmployee,
                      "required"
                    )}
                    isMulti={true}
                    isClearable={true}
                  />
                </Col>
                <Col sm={4}>
                  <SelectAsyncPaginate
                    label={"Client"}
                    asterisk={"*"}
                    selectPlaceholder={"Select client"}
                    value={selectedClient}
                    onSelectChange={(select: any) => {
                      setSelectedClient(select);
                      setSelectTask("");
                      setTaskOptions([]);
                    }}
                    apiname={"clientUrl"}
                    endpoint={Api.clientUrl}
                    errorMessage={validator.current.message(
                      "client",
                      selectedClient,
                      "required"
                    )}
                  />
                </Col>
                <Col sm={4}>
                  <DropdownInput
                    label={"Task"}
                    asterisk={"*"}
                    placeholder="Select task"
                    options={taskOptions}
                    defaultValue={selectTask}
                    onSelectHandler={(select: any) => {
                      setSelectTask(select);
                    }}
                    disabled={!selectedClient}
                    errorMessage={validator.current.message(
                      "task",
                      selectTask,
                      "required"
                    )}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <div style={{ float: "right" }} className="form-group mt-4">
                    <Button
                      type="submit"
                      variant="primary"
                      disabled={disableButton}
                    >
                      Submit
                    </Button>
                    <Button
                      onClick={(e) => {
                        e.preventDefault();
                        navigate(-1);
                      }}
                      variant="secondary"
                      className="ms-2"
                    >
                      Cancel
                    </Button>
                  </div>
                </Col>
              </Row>
            </Form>
          </Card.Body>
        </Card>
      </section>
    </>
  );
};

export default AssignTaskComponent;
