import axios from "axios";
import swal from "sweetalert";
import Swal from "sweetalert2";

export default {
  setupInterceptors: async () => {
    axios.interceptors.request.use(
      async (config: any) => {
        const configuration = config;
        
        if (configuration.url.indexOf("upload") > -1) {
          configuration.headers["Content-Type"] = "multipart/form-data"; // eslint-disable-line
        } else {
          configuration.headers["Content-Type"] = "application/json"; // eslint-disable-line
        }
        const token = localStorage.getItem("access_token");

        // if (token) configuration.headers['access-control-allow-origin'] = '*'; // eslint-disable-line
        // if (token) configuration.headers['access-control-allow-headers'] = '*'; // eslint-disable-line
        if (token) configuration.headers["Authorization"] = `Bearer ${token}`; // eslint-disable-line
        return configuration;
      },
      (error: any) => {
        Promise.reject(error);
      }
    );

    axios.interceptors.response.use(
      (response: any) => response,
      async (error: any) => {
        const data = await error.response;
        if (data && (data.status === 401 || data.status === 403)) {
          if (data?.status === 401 && data?.data?.detail == "Authentication credentials were not provided.") {
            Swal.fire({
              title: "Your session has expired",
              text: "You will be redirected to the Login Page.",
              icon: "error",
              confirmButtonText: "OK",
            }).then((result: any) => {
              if (result?.isConfirmed) {
                localStorage.clear();
                window.location.replace("/login");
                window.location.reload();
              }
            });
          }
          if (data && data.data && data.data.message == "User does not exist") {
            localStorage.clear();
            if (window.location.pathname !== "/login") {
              localStorage.clear();
              window.location.reload();
            }
            return Promise.reject(error.response.data);
          } else {
            localStorage.clear();
            if (window.location.pathname !== "/login") {
              localStorage.clear();
              window.location.reload();
            }
            return Promise.reject(error.response.data);
          }
        }
        return Promise.reject(error.response);
      }
    );
  },
};
