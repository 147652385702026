import { useState, useEffect } from "react";
import swal from "sweetalert";
import { Card, Image, Button, Row, Col } from "react-bootstrap";
import Loader from "../../components/Loader";
import Table from "../../components/Table/simpletable.component";
import AuthApi from "../../helper/authApi";
import Api from "../../helper/api";
import AdminViewDocumentComponent from "./adminViewDocument";
import { Helmet } from "../../components/FormInputs";
import Breadcrumb from "../../components/Breadcrumb/breadcrumb.component";
import { useNavigate } from "react-router-dom";
import customFunctions from "../../helper/customFunctions";

const TaskViewComponent = (props: any) => {
  const { empId, dueDate, roleId, employeeData } = props;
  const navigate: any = useNavigate();

  const [isShowLoader, setShowLoader] = useState<any>(false);
  const [createTask, setCreateTask] = useState<any>([]);
  const [count, forceUpdate] = useState<any>(0);
  const [updateShow, setUpdateShow] = useState<boolean>(false);
  const [modalData, setModalData] = useState<boolean>(false);
  const [taskID, setTaskID] = useState<any>();
  const [totalCount, setTotalCount] = useState<any>(0);
  const items = [
    { to: "/", label: "Dashboard" },
    { to: "/timesheet", label: "Timesheets" },
    { label: "View Timesheet" },
  ];

  const initialPagination = {
    currentPage: 1,
    itemsPerPage: 10,
    totalItems: 1,
  };
  const [pagination, setPagination] = useState(initialPagination);

  const columns = [
    // {
    //   label: "Sr. No",
    //   id: "no",
    // },
    {
      label: "Client Name",
      id: "client_name",
    },
    {
      label: "Task Name",
      id: "task_name",
    },
    {
      label: "Time Logged (In Hours)",
      id: "time",
    },
    {
      label: "Status",
      id: "task_status",
    },
    {
      label: "Action",
      id: "action",
      render: (row: any) => {
        const rowData = row;
        return (
          <>
            {rowData.task_status === "Completed" &&
            rowData.on_complition_doc === true ? (
              rowData?.is_note ? (
                <>
                  <a
                    className="underline"
                    href="javascript:void(0)"
                    title="View Submitted Note"
                    onClick={() => {
                      setModalData(row);
                      setUpdateShow(true);
                    }}
                  >
                    View Note
                  </a>
                </>
              ) : rowData?.is_file ? (
                <a
                  className="underline"
                  title="View Submitted File"
                  href="javascript:void(0)"
                  onClick={(e: any) => {
                    e.preventDefault();
                    if (rowData?.file_link) {
                      window.open(rowData?.file_link);
                    }
                  }}
                >
                  View File
                </a>
              ) : (
                "-"
              )
            ) : (
              "-"
            )}
          </>
        );
      },
    },
  ];

  useEffect(() => {
    getAdminData();
  }, []);

  const getAdminData = async () => {
    setShowLoader(true);
    const { data, message } = await AuthApi.getDataFromServer(
      `${Api.employeetimesheetUrl}?employee_id=${empId}&due_date=${dueDate}&days=10`
    );

    if (data && data.success === true) {
      setShowLoader(false);
      let AdminData: any = [];
      let link: any = "https://ca-api.reviewtestlink.com/media/";
      data &&
        data.data &&
        data.data.length > 0 &&
        data.data.map((item: any, index: number) => {
          AdminData.push({
            id: item.id,
            // no:(pagination.currentPage - 1) * pagination.itemsPerPage + (index + 1),
            employee_name: `${item.employee__user__first_name} ${item.employee__user__last_name}`,
            client_name: item?.client__client_name,
            is_file: item.task__is_file,
            is_note: item.task__is_note,
            is_other: item.task__is_other,
            on_complition_doc: item.task__on_complition_doc,
            task_name: item?.task__task_name
              ? item?.task__task_name
              : item?.litigation__title,
            time: item?.time_log,
            is_complete: item?.task__status ? item?.task__status : "",
            file: item.file_id,
            note: item.note ? item.note : "",
            file_link: `${link}${item.file__file_name}`,
            file_name: item.file__f_name,
            task_status: item.task_status === 2 ? "Completed" : "WIP",
            description: item.description,
          });
        });
      const totalTime: any = AdminData.reduce((a: any, b: any) => {
        return Number(a) + Number(b.time);
      }, 0);
      setTotalCount(totalTime);

      setCreateTask(AdminData?.reverse());
      forceUpdate(count + 1);
    } else {
      setShowLoader(false);
      swal(typeof message === "string" ? message : message[0], "", "error");
    }
  };

  return (
    <>
      <Helmet title={"View Timesheet"} />

      <div className="page-header">
        <div className="title-breadcrumb-section">
          <h2 className="main-content-title tx-24 mg-b-5">
            {`Timesheet of ${employeeData?.empName} on ${customFunctions.getDMYDate(employeeData?.due_date, "dmy")}`}
          </h2>
          <Breadcrumb items={items} />
        </div>
      </div>
      
      <Loader showLoader={isShowLoader} needFullPage={false} />
      <section className="content-area">
        <Card>
          <Card.Header>
            <h5 className="d-flex justify-content-end">
              Total Time Logged - {totalCount} hours
            </h5>
          </Card.Header>
          <Card.Body>
            <Table
              columns={columns}
              showCheckbox={false}
              rows={createTask}
              role={roleId}
              dropdownContainerClass="mb-3"
              emptyRender={() => (
                <p className="text-center mb-0">No record found</p>
              )}
            />
          </Card.Body>
        </Card>
      </section>
      {updateShow && (
        <AdminViewDocumentComponent
          show={updateShow}
          data={modalData}
          handleClose={() => {
            setUpdateShow(false);
          }}
        />
      )}
    </>
  );
};

export default TaskViewComponent;
