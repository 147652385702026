import React, { useEffect } from "react";
import { Modal, Col, Row } from "react-bootstrap";

function ViewDocumentModel(props: any) {
  const { show, data } = props;
  const getFile = () => {
    return window.open(data?.file.file_name);
  };
  return (
    <>
      <Modal
        show={show}
        onHide={() => {
          props.handleClose();
        }}
        // size={"md"}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <h5>Submitted Details</h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {!data?.description && !data.file?.f_name ? (
            <h5 className="d-flex justify-content-center">No Record Found</h5>
          ) : null}
          {data.description ? (
            <Row>
              <Col sm={4}>Note :-</Col>
              <Col>{data?.description}</Col>
            </Row>
          ) : null}
          {data?.file?.f_name ? (
            <Row>
              <Col sm={4}>View File :-</Col>
              <Col>
                <img
                  title={"View File"}
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    getFile();
                  }}
                  src="images/notes.svg"
                  alt=""
                />
              </Col>
            </Row>
          ) : null}
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ViewDocumentModel;
