import { Modal, Button, Row, Col, FormGroup } from "react-bootstrap";
import { useEffect, useState, useRef } from "react";
import SimpleReactValidator from "simple-react-validator";
import Api from "../../helper/api";
import AuthApi from "../../helper/authApi";
import Loader from "../../components/Loader";
import {
  Datepicker,
  DropdownInput,
  TextInput,
} from "../../components/FormInputs";
import swal from "sweetalert";
import { Checkbox, FormControlLabel } from "@mui/material";
import customFunctions from "../../helper/customFunctions";

const CreateLineItemsModel = (props: any) => {
  const validator = useRef(new SimpleReactValidator());

  const { show, lineItemId, oldLineItemName } = props;
  const [isShowLoader, setShowLoader] = useState<boolean>(false);
  const [selectedStatus, setSelectedStatus] = useState<any>();
  const [lineItemName, setLineItemName] = useState<any>();
  const [count, forceUpdate] = useState<number>(0);
  const [title, setTitle] = useState<string>(
    "Click here to update invoice status."
  );

  useEffect(() => {
    setLineItemName(oldLineItemName);
  }, [oldLineItemName]);

  const isClear = () => {
    setLineItemName("");
    props.popupClose()
    // window.location.reload();
  };

  // for validations
  const allValid = () => {
    if (lineItemId) {
      // validator.current.errorMessages.Line_item = null;
      // validator.current.fields.Line_item = true;
      if (oldLineItemName === lineItemName) {
        swal("Please update line items!", "", "error");
      } else {
        if (validator.current.allValid()) {
          onSubmit();
        } else {
          validator.current.showMessages();
          forceUpdate(count + 1);
        }
      }
    } else if (validator.current.allValid()) {
      onSubmit();
    } else {
      validator.current.showMessages();
      forceUpdate(count + 1);
    }
  };

  const onSubmit = async () => {
    setShowLoader(true);

    if (lineItemId) {
      let putData = {
        name: lineItemName ? lineItemName : oldLineItemName,
      };
      const { data, message } = await AuthApi.putDataToServer(
        `${Api.lineItems}${lineItemId}/`,
        putData
      );
      if (data && data.success === true) {
        setShowLoader(false);
        props.popupUpdateClose();
        swal("Line items updated successfully!", "", "success").then(() => {
          props.callBack();
          return;
        });
      } else {
        setShowLoader(false);
        swal(typeof message == "string" ? message : message[0], "", "error");
      }
    } else {
      let postData = {
        name: lineItemName,
      };

      const { data, message } = await AuthApi.postDataToServer(
        `${Api.lineItems}`,
        postData
      );

      if (data && data.success === true) {
        setShowLoader(false);
        props.popupClose();
        swal("Line items created successfully!", "", "success").then(() => {
          props.callBack();
          return;
        });
      } else {
        setShowLoader(false);
        swal(typeof message == "string" ? message : message[0], "", "error");
      }
    }
  };

  return (
    <>
      <Loader showLoader={isShowLoader} needFullPage={false} />

      <Modal
        // title={title}
        className={""}
        centered
        onHide={props.popupClose ? props.popupClose : props.popupUpdateClose}
        show={show}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <h5>{"Add Line Item"}</h5>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Row>
              <Col sm={6}>
                <TextInput
                  label={"Line item name"}
                  asterisk="*"
                  value={lineItemName}
                  placeholder="Enter Line Item Name"
                  onInputChange={(e: any) => {
                    const re = /^[a-zA-Z\s]+$/;
                    if (!e.target.value || re.test(e.target.value)) {
                      setLineItemName(e.target.value);
                    }
                  }}
                  isClearable
                  // disabled = {tableData}
                  errorMessage={validator.current.message(
                    "Line_item",
                    lineItemName,
                    "required"
                  )}
                />
              </Col>
            </Row>
          </div>
          <hr />
          <Button
            variant="primary"
            onClick={(e: any) => {
              e.preventDefault();
              // onSubmit();
              allValid();
            }}
          >
            Submit
          </Button>{" "}
          &nbsp;&nbsp;
          <Button
            variant="secondary"
            onClick={() => {
              isClear();
            }}
          >
            Cancel
          </Button>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default CreateLineItemsModel;
