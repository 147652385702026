import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Row, Col, Button, Form, Card } from "react-bootstrap";
import SimpleReactValidator from "simple-react-validator";
import swal from "sweetalert";
import { TextInput, Helmet } from "../../components/FormInputs";
import AuthApi from "../../helper/authApi";
import Api from "../../helper/api";
import customFunctions from "../../helper/customFunctions";
import Loader from "../../components/Loader/index";
import Breadcrumb from "../../components/Breadcrumb/breadcrumb.component";

const AddPartnerComponent = () => {
  const navigate = useNavigate();
  const validator = useRef(new SimpleReactValidator());
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [contactNumber, setContactNumber] = useState<string>("");
  const [Email, setEmail] = useState<any>();
  const [dinNumber, setDinNumber] = useState<string>();
  const [designation, setDesignation] = useState<string>();
  const [isShowLoader, setShowLoader] = useState<boolean>(false);
  const [count, forceUpdate] = useState<any>(0);
  const [title, setTitle] = useState("Add Partner Information");
  const [disableButton, setDisableButton] = useState<boolean>(false);
  const [roleId, setRoleId] = useState<number>(0);
  const [currentUserId, setCurrentUserId] = useState<number>(0);
  const [pageType, setPageType] = useState<any>("add");
  const [label, setLabel] = useState<any>("Add Partner");

  const { state } = useLocation();

  const items: any = [
    { to: "/", label: "Dashboard" },
    { to: "/partner-list", label: "Partner List" },
    { label: label },
  ];

  // const items: any = [
  //   { to: "/partner-list", label: "Dashboard" },
  //   { to: "/partner-list", label: "Partner" },
  //   { label: state?.doctorId ? "Edit Partner" : "Create partner" },
  // ];

  // useEffect(() => {
  //   const role = customFunctions.getLocalStorage("role_id");
  //   setRoleId(Number(role));
  //   const currentUserId = customFunctions.getLocalStorage("id");
  //   setCurrentUserId(Number(currentUserId));
  // }, []);

  const getPartner = async () => {
    // setShowLoader(true)
    // const { data, message } = await AuthApi.getDataFromServer(
    //   `${Api.doctorUrl}?id=${state.doctorId}`
    // );
    // if (data && data.success == true) {
    //   setShowLoader(false);
    //   if (
    //     data &&
    //     data.data &&
    //     data.data.response &&
    //     data.data.response.length > 0
    //   ) {
    //     let doctor = data.data.response[0];
    //     setFirstName(doctor.first_name);
    //     setLastName(doctor.last_name);
    //     setUserEmail(doctor.email);
    //     setUserMobile(doctor.mobile);
    //   }
    // } else {
    //   setShowLoader(false);
    //   swal(message, "", "error");
    // }
  };

  useEffect(() => {
    if (state && state.pageType == "add") return;

    if (state) {
      if (state && state.pageType == "edit") {
        setPageType("edit");
        setTitle("Edit Partner");
        setLabel("Edit Partner");
      } else {
        setPageType("view");
        setTitle("View Partner");
        setLabel("View Partner");
      }
      setFirstName(state?.data?.first_name);
      setLastName(state?.data?.last_name);
      setContactNumber(state?.data?.contact_number);
      setEmail(state?.data?.email);
      setDesignation(state?.data?.designation);
      setFirstName(state?.data?.first_name);
      setCurrentUserId(state?.data?.index);
    }
  }, [state]);

  const addUser = async () => {
    setShowLoader(true);
    const postData = {
      firs_tname: firstName,
      last_name: lastName,
      contact_number: contactNumber,
      email: Email.trim(),
      din_Number: dinNumber,
      designation: designation,
    };

    swal(
      currentUserId
        ? "Update Partner successfully!"
        : "  Partner added successfully!",
      "",
      "success"
    ).then(() => {
      navigate("/partner-list");
    });


    // if (state) {
    //   postData.id = state.doctorId;
    // }

    // const payload = customFunctions.cleanObject(postData);
    // const endPoint = `${Api.doctorUrl}`;
    // const callback =
    //   state && state.doctorId
    //     ? AuthApi.putDataToServer
    //     : AuthApi.postDataToServer;
    // const { data, message } = await callback(endPoint, payload);
    // if (data && data.success === true) {
    //   setShowLoader(false);
    //   setDisableButton(false);
    //   swal(
    //     state && state.doctorId
    //       ? "Doctor updated successfully!"
    //       : "Doctor created successfully!",
    //     "",
    //     "success"
    //   ).then(() => {
    //     navigate(-1);
    //   });
    // } else {
    //   setDisableButton(false);
    //   setShowLoader(false);
    //   swal(message, "", "error");
    // }
  };

  const onSubmit = (e: any) => {
    e.preventDefault();
    if (disableButton) return;
    setDisableButton(true);
    if (validator.current.allValid()) {
      navigate("/partner-list");
      addUser();
    } else {
      validator.current.showMessages();
      forceUpdate(count + 1);
      setDisableButton(false);
    }
  };

  return (
    <>
      <Helmet title={title} />
      {pageType == "view" && (
        <Button
          style={{ float: "right" }}
          className="form-group mt-3"
          type="submit"
          variant="primary"
          onClick={() => {
            navigate(-1);
          }}
        >
          Back
        </Button>
      )}
      <div className="page-header">
        <div className="title-breadcrumb-section">
          <h2 className="main-content-title tx-24 mg-b-5">{title}</h2>
          <Breadcrumb items={items} />
        </div>
      </div>
      {/* <Loader showLoader={isShowLoader} needFullPage={false} /> */}
      <section className="content-area">
        <Card>
          <Card.Body>
            <Form
              autoComplete="off"
              onSubmit={(e) => {
                onSubmit(e);
              }}
            >
              <Row>
                <Col sm={6}>
                  <TextInput
                    onInputChange={(
                      e: React.ChangeEvent<
                        HTMLTextAreaElement | HTMLInputElement
                      >
                    ) => {
                      const re = /^[a-zA-Z ]*$/;
                      if (!e.target.value || re.test(e.target.value)) {
                        setFirstName(e.target.value);
                      }
                    }}
                    label="First Name"
                    asterisk="*"
                    containerClass="form-group"
                    type="text"
                    name="firstName"
                    value={firstName}
                    disabled={pageType == "view"}
                    maxlength={12}
                    placeholder="Enter first name"
                    errorMessage={validator.current.message(
                      "First_Name",
                      firstName,
                      "required|alpha_space"
                    )}
                    autoComplete="new-first_name"
                  />
                </Col>
                <Col sm={6} className="">
                  <TextInput
                    onInputChange={(
                      e: React.ChangeEvent<
                        HTMLTextAreaElement | HTMLInputElement
                      >
                    ) => {
                      const re = /^[a-zA-Z ]*$/;
                      if (!e.target.value || re.test(e.target.value)) {
                        setLastName(e.target.value);
                      }
                    }}
                    label="Last Name"
                    asterisk="*"
                    containerClass="form-group"
                    type="text"
                    name="lastName"
                    disabled={pageType == "view"}
                    value={lastName}
                    maxlength={12}
                    placeholder="Enter last name"
                    errorMessage={validator.current.message(
                      "Last Name",
                      lastName,
                      "required|alpha_space"
                    )}
                    autocomplete="new-firstName"
                  />
                </Col>
                <Col sm={6}>
                  <TextInput
                    onInputChange={(e: any) => {
                      const re = /^[0-9 ]*$/;
                      if (!e.target.value || re.test(e.target.value)) {
                        setContactNumber(e.target.value);
                      }
                    }}
                    label="contact number"
                    asterisk="*"
                    containerClass="form-group"
                    type="text"
                    name="contactNumber"
                    disabled={pageType == "view"}
                    value={contactNumber}
                    minlength={10}
                    maxlength={10}
                    placeholder="Enter contact  number"
                    errorMessage={validator.current.message(
                      "Mobile number",
                      contactNumber,
                      "required|regex:^([0-9]){10}",
                      {
                        messages: {
                          required: "The mobile number field is required",
                          regex: "Invalid mobile number ",
                        },
                      }
                    )}
                  />
                </Col>
                <Col sm={6} className="">
                  <TextInput
                    onInputChange={(
                      event: React.ChangeEvent<
                        HTMLTextAreaElement | HTMLInputElement
                      >
                    ) => {
                      setEmail(event.target.value);
                    }}
                    label="Email"
                    asterisk="*"
                    containerClass="form-group"
                    disabled={pageType == "view"}
                    type="email"
                    name="Email"
                    value={Email}
                    placeholder="Enter email"
                    errorMessage={validator.current.message(
                      "email address",
                      Email,
                      "required|email"
                    )}
                    autoComplete="new-firstName"
                  />
                </Col>

                <Col sm={6} className="">
                  <TextInput
                    onInputChange={(
                      e: React.ChangeEvent<
                        HTMLTextAreaElement | HTMLInputElement
                      >
                    ) => {
                      const re = /^[a-zA-Z ]*$/;
                      if (!e.target.value || re.test(e.target.value)) {
                        setDinNumber(e.target.value);
                      }
                    }}
                    label="DIN Number"
                    //   asterisk="*"
                    containerClass="form-group"
                    type="text"
                    name="lastName"
                    disabled={pageType == "view"}
                    value={dinNumber}
                    placeholder="Enter din number"
                    // errorMessage={validator.current.message(
                    //   "First Name",
                    //   firstName,
                    //   "required"
                    // )}
                    autoComplete="new-firstName"
                  />
                </Col>
                <Col sm={6} className="">
                  <TextInput
                    onInputChange={(
                      e: React.ChangeEvent<
                        HTMLTextAreaElement | HTMLInputElement
                      >
                    ) => {
                      const re = /^[a-zA-Z ]*$/;
                      if (!e.target.value || re.test(e.target.value)) {
                        setDesignation(e.target.value);
                      }
                    }}
                    label="Designation"
                    asterisk="*"
                    containerClass="form-group"
                    disabled={pageType == "view"}
                    type="text"
                    name="lastName"
                    value={designation}
                    maxlength={14}
                    placeholder="Enter designation"
                    errorMessage={validator.current.message(
                      "designation",
                      designation,
                      "required"
                    )}
                    // autoComplete="new-firstName"
                  />
                </Col>
              </Row>
              {pageType !== "view" && (
                <Row>
                  <Col>
                    <div style={{ float: "right" }} className="form-group mt-3">
                      <Button
                        type="submit"
                        variant="primary"
                        // disabled={disableButton}
                      >
                        Submit
                      </Button>
                      <Button
                        onClick={(e) => {
                          e.preventDefault();
                          navigate(-1);
                        }}
                        variant="secondary"
                        className="ms-2"
                      >
                        Cancel
                      </Button>
                    </div>
                  </Col>
                </Row>
              )}
            </Form>
          </Card.Body>
        </Card>
      </section>
    </>
  );
};

export default AddPartnerComponent;
