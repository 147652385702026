import React, { useEffect, useRef, useState } from "react";
import SimpleReactValidator from "simple-react-validator";
import { Button, Col, Row, Image, Accordion, Card } from "react-bootstrap";
import {
  TextInput,
  Datepicker,
  Helmet,
  DropdownInput,
} from "../../../components/FormInputs";
import Breadcrumb from "../../../components/Breadcrumb/breadcrumb.component";
import swal from "sweetalert";
import Api from "../../../helper/api";
import AuthApi from "../../../helper/authApi";
import customFunctions from "../../../helper/customFunctions";
import Loader from "../../../components/Loader";
import Table from "../../../components/Table/simpletable.component";
// import StatusModel from "./../statusModel";
// import PreviewPdfModel from "./../previewPdfModel";
// import UpdateInvoiceModel from "./../updateInvoiceModel";
import SweetAlert from "react-bootstrap-sweetalert";
// import AddInvoice from "./generateInvoiceForm";
import { useNavigate } from "react-router-dom";

const InvoiceListComponent = () => {
  const validator = useRef(new SimpleReactValidator());
  const navigate = useNavigate();

  const [title, setTitle] = useState<string>("Invoice List");
  const [label, setLabel] = useState<string>("Invoice List");
  const [invoiceData, setInvoiceData] = useState<any>();
  const [rows, setRows] = useState<any>([]);
  const [isShowLoader, setShowLoader] = useState<boolean>(false);
  const [updateStatus, setUpdateStatus] = useState<any>(false);
  const [updateInvoice, setUpdateInvoice] = useState<any>(false);
  const [previewStatus, setPreviewStatus] = useState<any>(false);
  const [invoiceId, setInvoiceId] = useState<any>();
  const [count, forceUpdate] = useState(0);
  const [applyToggle, setApplyToggle] = useState<any>(false);
  const [invoiceNumber, setSearchInvoiceNo] = useState<any>();
  const [invoiceDate, setInvoiceDate] = useState<any>();
  const [invoiceDueDate, setInvoiceDueDate] = useState<any>();
  const [date, setDate] = useState<any>();
  const [selectedClient, setSelectedClient] = useState<any>("");
  const [clientList, setClientList] = useState<any>("");
  const [invoiceStatus, setInvoiceStatus] = useState<any>("");
  const [searchKeyword, setSearchKeyword] = useState<any>("");
  const [selectedStatus, setSelectedStatus] = useState<any>("");
  const [selectedClientName, setSelectedClientName] = useState<any>("");
  const [filterErr, setFilterErr] = useState<any>("");
  const [typeClient, setClientType] = useState<any>("");
  const [selectedService, setSelectedService] = useState<any>();
  const [createPDF, setCreatePDF] = useState<boolean>(true);
  const [showPDF, setShowPDF] = useState<boolean>(false);
  const [sentEmail, setSentEmail] = useState<boolean>(false);
  const [editInvoice, setEditInvoice] = useState<boolean>(true);
  const [viewPdf, setViewPdf] = useState<any>();
  const [pdfInvoiceId, setPdfInvoiceId] = useState<any>();
  const [isDelete, setIsDelete] = useState<boolean>(false);
  const [childToggle, setChildToggle] = useState<boolean>(false);
  const [selectedOldStatus, setOldStatus] = useState<any>("");
  const [roleId, setRoleId] = useState<number>(0);

  const initialPagination = {
    currentPage: 1,
    itemsPerPage: 10,
    totalItems: 1,
  };
  const [pagination, setPagination] = useState(initialPagination);

  const columns: any = [
    // {
    //   label: "Invoice No",
    //   id: "invoice_id",
    // },
    {
      label: "Client Name",
      id: "client_name",
    },
    // {
    //   label: "Email",
    //   id: "email",
    // },
    {
      label: "Invoice Date",
      id: "invoice_date",
    },
    {
      label: "Due Date",
      id: "due_date",
    },
    {
      label: "Total Amount",
      id: "total_amount",
    },
    {
      label: "Invoice Status",
      id: "invoice_status_name",
    },
    // {
    //   label: "Payment Mode",
    //   id: "payment_mode",
    // },
    // {
    //   label: "Action",
    //   id: "action",
    // },
  ];

  useEffect(()=>{
    const role = customFunctions.getLocalStorage("role_id");
    setRoleId(Number(role));
  },[]);

  useEffect(() => {
    if(roleId == 2 || customFunctions.getLocalStorage("admin_access") == "true"){
      getInvoiceData(1);
    }
  }, [roleId]);

  const getInvoiceData = async (page: any, clearFilter?: boolean) => {
    if (
      !applyToggle &&
      clearFilter &&
      !searchKeyword &&
      !selectedClientName &&
      !invoiceNumber &&
      !invoiceStatus &&
      !selectedClient &&
      !selectedService
    )
      return;
    const pageNo: any = page || 1;
    setShowLoader(true);
    setApplyToggle(false);
    const endPoint: any = Api.getInvoiceList;

    setShowLoader(true);
    const params: any = {
      page: pageNo,
      limit: pagination.itemsPerPage,
      keyword:
        !clearFilter && String(selectedClientName)
          ? String(selectedClientName)
          : "",
      invoice_no: !clearFilter && invoiceNumber,
      invoice_status: !clearFilter && invoiceStatus ? invoiceStatus.value : "",
      invoice_date:
        !clearFilter && invoiceDate ? customFunctions.getDate(invoiceDate) : "",
      due_date:
        !clearFilter && invoiceDueDate
          ? customFunctions.getDate(invoiceDueDate)
          : "",
      client_id: !clearFilter && selectedClient ? selectedClient.value : "",
      services_id: !clearFilter && selectedService ? selectedService.id : "",
      client_type: !clearFilter && typeClient ? typeClient.value : "",
      sort_direction: "descending",
    };

    const url: any = customFunctions.generateUrl(endPoint, params);
    const { data, message } = await AuthApi.getDataFromServer(url);

    if (data && data.success === true) {
      setShowLoader(false);

      if (data && data.data && data?.data?.length > 5) {
        let newData = data.data.slice(0, 5);
        setInvoiceData(data?.data);
      } else {
        setInvoiceData(data.data);
      }
    } else {
      setShowLoader(false);
      swal(typeof message == "string" ? message : message[0], "", "error");
    }
  };

  const filterSearch = (e: any) => {
    e.preventDefault();
    let filterSearchErr: string = "";
    let isValid: boolean = true;
    if (
      customFunctions.checkIfEmpty(searchKeyword) &&
      customFunctions.checkIfEmpty(selectedStatus) &&
      customFunctions.checkIfEmpty(selectedClientName) &&
      customFunctions.checkIfEmpty(invoiceNumber) &&
      customFunctions.checkIfEmpty(invoiceStatus) &&
      customFunctions.checkIfEmpty(invoiceDate) &&
      customFunctions.checkIfEmpty(invoiceDueDate) &&
      customFunctions.checkIfEmpty(selectedClient) &&
      customFunctions.checkIfEmpty(selectedService) &&
      customFunctions.checkIfEmpty(typeClient)
    ) {
      filterSearchErr =
        "Please enter client name or select client type or select status";
      setFilterErr(filterSearchErr);
      isValid = false;
    }
    if (isValid) {
      setFilterErr("");
      getInvoiceData(1);
    }
  };

  const onClear = (e: any) => {
    setClientType("");
    setDate("");
    setFilterErr("");
    setSearchKeyword("");
    setSelectedStatus("");
    setSelectedClientName("");
    getInvoiceData(1, true);
    setInvoiceStatus("");
    setIsDelete(false);
    window.location.reload();
  };

  const getPdf = async () => {
    setShowLoader(true);

    const payload = {
      invoice_id: pdfInvoiceId,
    };

    const callback: any = AuthApi.postDataToServer;
    const { data, message } = await callback(Api.getPdfUrl, payload);

    if (data && data.success === true) {
      swal("PDF generated successfully!", "", "success").then(() => {
        setViewPdf(data.data.attachement);
        // setShowPDF(true);
        setShowLoader(false);
        window.location.reload();
        return;
      });
    } else {
      setShowLoader(false);
      swal(message, "", "error");
      window.location.reload();
    }
  };

  useEffect(() => {
    if (!pdfInvoiceId) return;
    getPdf();
  }, [pdfInvoiceId]);

  useEffect(() => {
    setTableData();
  }, [invoiceData]);

  useEffect(() => {
    if (!invoiceId && !isDelete) return;
    deleteInvoice();
  }, [isDelete]);

  const deleteInvoice = async () => {
    setShowLoader(true);

    const callback: any = AuthApi.deleteDataFromServer;
    let url = `${Api.generatePdfUrl}${invoiceId}/`;
    const { data, message } = await callback(url);

    if (data && data.success === true) {
      swal("Invoice deleted successfully!", "", "success").then(() => {
        // setViewPdf(data.data.attachement);
        // setShowPDF(true);
        setShowLoader(false);
        window.location.reload();
        return;
      });
    } else {
      setShowLoader(false);
      swal(message, "", "error");
    }
  };

  const setTableData = async () => {
    let toggle: boolean = false;
    const rowData: any = [];
    invoiceData &&
      invoiceData.map((item: any, index: any) => {
        rowData.push({
          index:
            (pagination.currentPage - 1) * pagination.itemsPerPage +
            (index + 1),
          invoice_id: item?.invoice_no,
          client_name: item?.client?.client_name,
          email: item?.client?.email,
          invoice_date: item?.invoice_date ? (
            customFunctions.getFormattedDate(new Date(item.invoice_date))
          ) : (
            <center>-</center>
          ),
          due_date: item?.due_date ? (
            customFunctions.getFormattedDate(new Date(item.due_date))
          ) : (
            <center>-</center>
          ),
          total_amount: <center>{item?.total_amount ? item?.total_amount : "-"}</center>,
          editInvoice: true,
          createPDF: true,
          showPDF: item?.file_name ? true : false,
          sentEmail: false,
          invoice_status_name:
            item?.invoice_status_name === "Paid" ? (
              <center><span>{item?.invoice_status_name ? item?.invoice_status_name : '-'}</span></center>
            ) : (
              <>
              <center>
                <span
                  className="pl-2 ms-1"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setUpdateStatus(true);
                    setInvoiceId(item.id);
                    setOldStatus({
                      label: item?.invoice_status_name,
                      value: item?.invoice_status,
                    });
                  }}
                >
                  {item.invoice_status_name ? item.invoice_status_name : "-"}
                </span>
                </center>
              </>
            ),
          payment_mode: item.is_online ? (
            <>
              <span
                className="pl-2 ms-1"
                title={item.is_online ? item.transaction_id : ""}
              >
                {item.is_cash ? "Cash" : item.is_online ? "Online" : "NA"}
              </span>
            </>
          ) : (
            <>
              <span>{item.is_cash ? "Cash" : "NA"}</span>
            </>
          ),
          action: (
            <>
              {item && item.file_name && (
                <a
                  href="javascript:void(0)"
                  className="ml-2"
                  style={{ color: "#009D9A", cursor: "pointer" }}
                  title={"View"}
                  onClick={(e: any) => {
                    e.preventDefault();
                    e.stopPropagation();
                    getInvoicePdf(Number(item.id));
                    setPreviewStatus(true);
                    setInvoiceId(item.id);
                  }}
                >
                  <Image src="images/eye.svg" alt="view" />
                </a>
              )}
            </>
          ),
        });
      });
    setRows(rowData);
    forceUpdate(count + 1);
  };

  useEffect(() => {
    if (!selectedClient) return;
    getInvoiceData(1);
  }, [selectedClient]);

  const invoiceStatusArray: any = [
    {
      label: "Draft",
      value: "1",
    },
    {
      label: "Unpaid",
      value: "2",
    },
    {
      label: "Paid",
      value: "4",
    },
  ];

  useEffect(() => {
    if (!typeClient) return;
    getClientName();
  }, [typeClient]);

  const getClientName = async () => {
    let url = `${Api.clientUrl}?client_type=${typeClient.value}`;
    const { data, message } = await AuthApi.getDataFromServer(url);
    const empData: any = [];
    data &&
      data.data &&
      data.data.map((dt: any) => {
        empData.push({
          label: dt.client_name,
          value: dt.id,
        });
      });
    setClientList(empData);
  };

  const getInvoicePdf = async (id: number) => {
    setShowLoader(true);
    const { data, message } = await AuthApi.getDataFromServer(
      `${Api.getInvoiceList}${id}/`
    );
    if (data && data.success === true) {
      setShowLoader(false);
      window.open(data.data.file_name);
    } else {
      setShowLoader(false);
      swal(typeof message == "string" ? message : message[0], "", "error");
    }
  };

  return (
    <>
      <Loader showLoader={isShowLoader} needFullPage={false} />
      {/* <section className="content-area"> */}
        <Card className="mt-1">
          <Card.Header>
            <b className="card-title text-uppercase">
              <center>Recently Created Invoices</center>
            </b>
          </Card.Header>
          <Card.Body className="table-scroll">
            <Table
              columns={columns}
              showCheckbox={false}
              rows={rows}
              showPagination
              paginationProps={{
                ...pagination,
                currentPage: Number(pagination.currentPage),
                onPageChange: (pageNo: any) => {
                  getInvoiceData(pageNo);
                },
              }}
              dropdownContainerClass="mb-3"
              emptyRender={() => (
                <p className="text-center mb-0 mt-5"><h3>No records found!</h3></p>
              )}
            />
            {childToggle && (
              <div className="sweetalert">
                <SweetAlert
                  title={"Are you sure? do you want to delete invoice?"}
                  warning
                  showCancel
                  confirmBtnBsStyle="success"
                  cancelBtnBsStyle="danger"
                  onConfirm={() => {
                    setIsDelete(true);
                    setChildToggle(false);
                  }}
                  onCancel={() => {
                    setIsDelete(false);
                    setChildToggle(false);
                  }}
                />
              </div>
            )}
          </Card.Body>
          {rows && rows.length > 0 && (
            <Card.Footer>
              <Row>
                <Col sm={10}></Col>
                <Col sm={2}>
              <div>
                <a
                  className="d-flex align-items-center justify-content-end underline"
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    navigate("/invoice-list");
                  }}
                >
                  {"View All"}
                </a>
              </div>
              </Col>
              </Row>
            </Card.Footer>
          )}
        </Card>
      {/* </section> */}
    </>
  );
};

export default InvoiceListComponent;
