import { Modal, Button, Row, Col, FormGroup } from "react-bootstrap";
import { useEffect, useState, useRef } from "react";
import SimpleReactValidator from "simple-react-validator";
import Api from "../../helper/api";
import AuthApi from "../../helper/authApi";
import Loader from "../../components/Loader";
import {
  Datepicker,
  DropdownInput,
  TextInput,
} from "../../components/FormInputs";
import swal from "sweetalert";
import { Checkbox, FormControlLabel, FormLabel, Radio } from "@mui/material";
import customFunctions from "../../helper/customFunctions";
import RadioGroup, { useRadioGroup } from "@mui/material/RadioGroup";

const StatusModel = (props: any) => {
  const validator = useRef(new SimpleReactValidator());

  const { show, invoiceId, oldDueDate, selectedOldStatus, selectedInvoice } =
    props;

  const statusArray: any = [
    {
      label: "Draft",
      value: "1",
    },
    {
      label: "Publish",
      value: "3",
    },
    {
      label: "Closed",
      value: "4",
    },
  ];
  const [isShowLoader, setShowLoader] = useState<boolean>(false);
  const [selectedStatus, setSelectedStatus] = useState<any>({
    label: "Closed",
    value: "4",
  });
  const [byCash, setByCash] = useState<any>();
  const [byOnline, setByOnline] = useState<any>();
  const [showOptions, setShowOptions] = useState<any>(false);
  const [isRemove, setIsRemove] = useState<any>(false);
  const [cashDisabled, setCashDisabled] = useState<any>(false);
  const [onlineDisabled, setOnlineDisabled] = useState<any>(false);
  const [showDatePicker, setShowDatePicker] = useState<any>(false);
  const [dueDate, setDueDate] = useState<any>();
  const [count, forceUpdate] = useState<number>(0);
  const [transactionId, setTransactionId] = useState<any>("");
  const [bankName, setBankName] = useState<any>("");
  const [availableStatus, setAvailableStatus] = useState<any>(statusArray);
  const [paymentModeArr, setPaymentModeArr] = useState<any>([
    {
      label: "By Cash",
      value: "By Cash",
    },
    {
      label: "By Check",
      value: "By Check",
    },
    {
      label: "Bank Transfer",
      value: "Bank Transfer",
    },
  ]);
  const [selectedPaymentMode, setSelectedPaymentMode] = useState<any>("");
  const [selectedBankTransfer, setSelectedBankTransfer] = useState<any>("");

  const [title, setTitle] = useState<string>(
    "Click here to update invoice status."
  );

  const bankTransferArr: any = [
    {
      label: "NEFT",
      value: "NEFT",
    },
    {
      label: "RTGS",
      value: "RTGS",
    },
    {
      label: "UPI",
      value: "UPI",
    },
  ];
  useEffect(() => {
    if (!selectedOldStatus) return;
    setNewStatusArray();
  }, []);

  const setNewStatusArray = () => {
    let newStatusArray: any = [];
    if (selectedOldStatus.value === 1) {
      newStatusArray = [
        {
          label: "Publish",
          value: "3",
        },
      ];
    } else if (selectedOldStatus.value === 2) {
      newStatusArray = [
        {
          label: "Closed",
          value: "4",
        },
      ];
    }

    setAvailableStatus(newStatusArray);
  };

  useEffect(() => {
    if (!selectedStatus) {
      setShowDatePicker(false);
      return;
    }

    if (selectedStatus.value === "4") {
      setShowOptions(true);
      setShowDatePicker(false);
    }
    if (selectedStatus.value === "3") {
      setShowDatePicker(true);
      setShowOptions(false);
    }
  }, [selectedStatus]);

  const isClear = () => {
    setSelectedStatus("");
    setShowOptions(false);
    setShowDatePicker(false);
    // window.location.reload();
  };

  // for validations
  const allValid = () => {
    // e.preventDefault();

    // if (!selectedStatus || selectedStatus.value === "1") {
    //   props.popupClose();
    //   return;
    // }

    if (selectedStatus?.value != 3) {
      validator.current.errorMessages.Due_date = null;
      validator.current.fields.Due_date = true;
    }

    if (selectedPaymentMode && selectedPaymentMode?.value == "By Cash") {
      validator.current.errorMessages.transactionid = null;
      validator.current.fields.transactionid = true;
      validator.current.errorMessages.bankname = null;
      validator.current.fields.bankname = true;
      validator.current.errorMessages.source = null;
      validator.current.fields.source = true;
    }

    if (validator.current.allValid()) {
      onSubmit();
    } else {
      validator.current.showMessages();
      forceUpdate(count + 1);
    }
  };

  const onSubmit = async () => {
    setShowLoader(true);
    if (invoiceId) {
      const postdata: any = {
        invoice_status: Number(selectedStatus?.value),
        is_cash:
          selectedPaymentMode && selectedPaymentMode?.value == "By Cash"
            ? true
            : false,
        is_check:
          selectedPaymentMode && selectedPaymentMode?.value == "By Check"
            ? true
            : false,
        is_online:
          selectedPaymentMode && selectedPaymentMode?.value == "Bank Transfer"
            ? true
            : false,
        due_date: dueDate ? customFunctions.getDate(dueDate) : oldDueDate,
        transaction_id: transactionId ? transactionId : null,
        bank_name: bankName ? bankName : null,
        payment_source:
          selectedBankTransfer && selectedBankTransfer?.value
            ? selectedBankTransfer?.value
            : null,
      };

      const { data, message } = await AuthApi.putDataToServer(
        `${Api.getInvoiceList}${invoiceId}/`,
        postdata
      );

      if (data && data.success === true) {
        setShowLoader(false);
        props.popupClose();
        swal("Status updated successfully!", "", "success").then(() => {
          props.callBack();
          return;
        });
      } else {
        setShowLoader(false);
        swal(typeof message == "string" ? message : message[0], "", "error");
      }
    }
  };

  return (
    <>
      <Loader showLoader={isShowLoader} needFullPage={false} />

      <Modal
        className={""}
        centered
        onHide={props.popupClose}
        show={show}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <h5>{"Status"}</h5>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Row>
              <Col sm={6}>
                <TextInput
                  label={"Invoice No"}
                  value={
                    selectedInvoice?.invoice_no
                      ? selectedInvoice?.invoice_no
                      : ""
                  }
                  disabled
                />
              </Col>
              <Col sm={6}>
                <TextInput
                  label={"Invoice Total Amount"}
                  value={
                    selectedInvoice?.total_amount
                      ? selectedInvoice?.total_amount
                      : ""
                  }
                  disabled
                />
              </Col>
              <Col sm={6} className="mt-3">
                <Datepicker
                  className="form-control"
                  label="Invoice Date"
                  selected={
                    selectedInvoice?.invoice_date
                      ? new Date(selectedInvoice?.invoice_date)
                      : ""
                  }
                  dateFormat="dd/MM/yyyy"
                  disabled
                />
              </Col>
              <Col sm={6} className="mt-3">
                <Datepicker
                  className="form-control"
                  label="Invoice Due Date"
                  selected={
                    selectedInvoice?.due_date
                      ? new Date(selectedInvoice?.due_date)
                      : ""
                  }
                  dateFormat="dd/MM/yyyy"
                  disabled
                />
              </Col>

              <Col sm={6}>
                <DropdownInput
                  id="employee"
                  placeholder="Select status"
                  label="Invoice Status"
                  options={availableStatus}
                  defaultValue={selectedStatus}
                  onSelectHandler={(select: any) => {
                    // handleSelect(select);
                    setSelectedStatus(select);
                    if (select?.value == "4") {
                      setByCash(true);
                    }
                    if (select == null) {
                      setShowOptions(false);
                    }
                    setDueDate("");
                  }}
                  disabled
                />
              </Col>

              <Col sm={6}>
                <DropdownInput
                  placeholder="Select"
                  label="Payment Mode"
                  asterisk="*"
                  options={paymentModeArr}
                  defaultValue={selectedPaymentMode}
                  onSelectHandler={(select: any) => {
                    // handleSelect(select);
                    setSelectedPaymentMode(select);
                    setTransactionId("");
                    setBankName("");
                    setSelectedBankTransfer("");
                    if (select?.value == "4") {
                      setByCash(true);
                    }
                    if (select == null) {
                      setShowOptions(false);
                    }
                    setDueDate("");
                  }}
                  errorMessage={validator.current.message(
                    "Payment mode",
                    selectedPaymentMode,
                    "required"
                  )}
                />
              </Col>
              {showDatePicker && (
                <Col sm={6}>
                  <Datepicker
                    className="form-control"
                    label="Due Date"
                    asterisk="*"
                    placeholder="dd/mm/yyyy"
                    dateFormat="dd/MM/yyyy"
                    minDate={new Date()}
                    selected={dueDate}
                    onChange={(e: any) => {
                      setDueDate(e);
                    }}
                    errorMessage={validator.current.message(
                      "Due_date",
                      dueDate,
                      "required"
                    )}
                  />
                </Col>
              )}
              {selectedPaymentMode &&
              selectedPaymentMode?.value != "By Cash" ? (
                <>
                  <Col sm={6}>
                    <TextInput
                      label={
                        selectedPaymentMode?.value == "By Check"
                          ? "Check ID"
                          : "Transaction ID"
                      }
                      asterisk="*"
                      value={transactionId}
                      maxlength={50}
                      placeholder="0-a"
                      onInputChange={(e: any) => {
                        const re = /^[a-zA-Z0-9]+$/;
                        if (!e.target.value || re.test(e.target.value)) {
                          setTransactionId(e.target.value);
                        }
                      }}
                      disabled={!selectedPaymentMode}
                      errorMessage={validator.current.message(
                        "transactionid",
                        transactionId,
                        "required"
                      )}
                    />
                  </Col>
                  {selectedPaymentMode?.value == "Bank Transfer" ? (
                    <Col sm={6}>
                      <DropdownInput
                        placeholder="Select"
                        label="Source"
                        asterisk="*"
                        options={bankTransferArr}
                        defaultValue={selectedBankTransfer}
                        onSelectHandler={(select: any) => {
                          setSelectedBankTransfer(select);
                        }}
                        errorMessage={validator.current.message(
                          "source",
                          selectedBankTransfer,
                          "required"
                        )}
                      />
                    </Col>
                  ) : null}
                    <Col sm={6}>
                      <TextInput
                        label={"Bank Name"}
                        asterisk="*"
                      maxlength={50}
                      value={bankName}
                        placeholder="Enter bank name"
                        onInputChange={(e: any) => {
                          setBankName(e.target.value);
                        }}
                        disabled={!selectedPaymentMode}
                        errorMessage={validator.current.message(
                          "bankname",
                          bankName,
                          "required",
                          {
                            messages: {
                              default: "The bank name field is required.",
                            },
                          }
                        )}
                      />
                    </Col>
                </>
              ) : null}
            </Row>
          </div>
          <hr />
          <Button
            variant="primary"
            onClick={(e: any) => {
              e.preventDefault();
              // onSubmit();
              allValid();
            }}
          >
            Submit
          </Button>{" "}
          &nbsp;&nbsp;
          <Button
            variant="secondary"
            onClick={() => {
              props.popupClose();
            }}
          >
            Cancel
          </Button>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default StatusModel;
