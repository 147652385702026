import React, { useState, useRef, useEffect } from "react";
import { Row, Col, Card, Button } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";
import swal from "sweetalert";
import { DropdownInput, Helmet, TextInput } from "../../components/FormInputs";
import SweetAlert from "react-bootstrap-sweetalert";
import SimpleReactValidator from "simple-react-validator";
import customFunctions from "../../helper/customFunctions";
import AuthApi from "../../helper/authApi";
import Api from "../../helper/api";
import Loader from "../../components/Loader";
import Breadcrumb from "../../components/Breadcrumb/breadcrumb.component";
import Addtask from "./AddNewtaskModel";
import TaskViewComponent from "./taskView.component";
import SelectAsyncPaginate from "../../components/ReactSelectPagination/SelectAsyncPaginate";

const AddNewTaskComponent = (props: any) => {
  const { pagetype, propsData, callBack } = props;
  const navigate: any = useNavigate();
  const { state } = useLocation();
  const [timesheetId, setTimesheetId] = useState(null);
  const validator = useRef(new SimpleReactValidator());
  const [count, forceUpdate] = useState<any>(0);
  const [roleId, setRoleId] = useState<number>(0);
  const [currentUserId, setCurrentUserId] = useState<number>(0);
  const [pageType, setPageType] = useState<string>("add");
  const [titleDate, setTitleDate] = useState<any>(
    customFunctions.getDMYDate(new Date(), "dmy")
  );
  const [title, setTitle] = useState("Create Timesheet For " + " " + titleDate);
  const [lebel, setlebel] = useState<any>("Create Timesheet");
  const [disableButton, setDisableButton] = useState<boolean>(false);
  const [isShowLoader, setShowLoader] = useState<boolean>(false);
  const [updateShow, setUpdateShow] = useState<boolean>(false);
  const [taskID, setTaskID] = useState<any>();
  const [statusToggle, setStatusToggle] = useState<any>();
  const [servicesArray, setServicesArray] = useState<any>([]);
  const [clientDataArray, setClientDataArray] = useState<any>([]);
  const [pgNo, setPgNo] = useState<Number>();

  const [createTask, setCreateTask] = useState<any>([
    {
      client_name: "",
      task_name: "",
      time: null,
      is_complete: 1,
      id: "",
      file: "",
      file_name: "",
      file_link: "",
      description: "",
      employee_name: "",
      employee_id: "",
      is_file: "",
      on_complition_doc: "",
      is_note: "",
      is_other: "",
      isChecked: false,
      serviceArray: [],
      dueDate: "",
      type: "task",
      isDisabled: false,
    },
  ]);

  useEffect(() => {
    getUserRole();
    getAssignedTasks();
  }, []);

  const getUserRole = () => {
    const role = customFunctions.getLocalStorage("role_id");
    setRoleId(Number(role));
    const currentUserId = customFunctions.getLocalStorage("id");
    setCurrentUserId(Number(currentUserId));
  };

  useEffect(() => {
    if (!roleId) return;
    if ([3, 4, 5].includes(roleId)) {
      // getClientList();
    }
  }, [roleId]);

  const items = [
    {
      to: "/",
      label: "Dashboard",
    },
    {
      to: "/timesheet",
      label: "Timesheet",
    },
    { label: lebel },
  ];

  useEffect(() => {
    if (pagetype === "add") return;

    setTimesheetId(roleId === 2 ? propsData?.due_date : propsData?.date);
    if (pagetype === "edit") {
      setPageType("edit");
      setTitle("Edit Timesheet For" + "  " + titleDate);
      setlebel("Edit Timesheet");
      getTimesheet();
    } else {
      setPageType("view");
      setTitle(
        roleId === 2 || roleId === 10
          ? `View Timesheet For ${
              propsData?.empName
            } ${customFunctions.getDMYDate(propsData?.due_date, "dmy")}`
          : `View Timesheet For ${customFunctions.getDMYDate(
              propsData?.data?.date,
              "dmy"
            )}`
      );
      setlebel("View Timesheet");
      getTimesheet();
    }
  }, [propsData, roleId]);


  //get assigned tasks
  const getAssignedTasks = async () => {
    setShowLoader(true);

    const params: any = {
      user_id: Number(customFunctions.getLocalStorage("id")),
      limit: 300,
      // task_type: 2,
      sort_direction: "descending",
    };

    const url: any = customFunctions.generateUrl(
      // Api.litigationUrl,
      Api.employeeLitigationUrl,
      params
    );

    const { data, message } = await AuthApi.getDataFromServer(url);

    if (data && data.success === true) {
      setShowLoader(false);
      const assignTaskData: any = [];
      data?.data &&
        data?.data?.length &&
        data?.data?.map((element: any) => {
          assignTaskData.push({
            client_name: {
              name: element?.litigation_client?.client_name ? element?.litigation_client?.client_name : element?.client?.client_name ,
              id: element?.litigation_client?.id ? element?.litigation_client?.id : element?.client?.id,
            },
            task_name: {
              label: element?.litigation_title ? element?.litigation_title : element?.task?.task_name,
              value: element?.litigation ? element?.litigation : element?.task?.id,
              dueDate: element?.seprated_due_date?.length
                ? element?.seprated_due_date
                : "",
            },
            time: null,
            is_complete: 1,
            id: "",
            file: "",
            file_name: "",
            file_link: "",
            description: "",
            employee_name: "",
            employee_id: "",
            is_file: "",
            on_complition_doc: "",
            is_note: "",
            is_other: "",
            isChecked: false,
            serviceArray: [],
            dueDate: element?.seprated_due_date?.length
              ? element?.seprated_due_date
              : "",
            type: element?.task_type == 1 ? "task" : "litigation",
            // type: "litigation",
            isDisabled: true,
          });
        });
      if (assignTaskData && assignTaskData?.length) {
        setCreateTask(assignTaskData);
      } else {
        setCreateTask([
          {
            client_name: "",
            task_name: "",
            time: "",
            is_complete: 1,
            id: "",
            file: "",
            file_name: "",
            file_link: "",
            description: "",
            employee_name: "",
            employee_id: "",
            is_file: "",
            on_complition_doc: "",
            is_note: "",
            is_other: "",
            isChecked: false,
            serviceArray: [],
            dueDate: "",
            type: "task",
            isDisabled: false,
          },
        ]);
      }
    } else {
      setShowLoader(false);
      swal(typeof message == "string" ? message : message[0], "", "error");
    }
  };

  const getClientList = async () => {
    setShowLoader(true);
    const { data, message } = await AuthApi.getDataFromServer(
      `${Api.clientUrl}?limit=1000`
    );

    if (data && data.success === true) {
      setShowLoader(false);
      let clientData: any = [];
      data &&
        data.data &&
        data.data.length > 0 &&
        data.data.map((item: any) => {
          if (item.client_status == 2) return;
          clientData.push({ label: item?.client_name, value: item.id });
        });
      setClientDataArray(clientData);
    } else {
      setShowLoader(false);
      swal(typeof message === "string" ? message : message[0], "", "error");
    }
  };

  const getTask = async (id: any, index: any) => {
    setShowLoader(true);
    const { data, message } = await AuthApi.getDataFromServer(
      `${Api.taskUrl}${id}/`
    );

    if (data && data.success === true) {
      setShowLoader(false);
      let taskArr: any = [data.data];
      taskArr &&
        taskArr.length > 0 &&
        taskArr.map((item: any) => {
          createTask[index].is_file = item.is_file;
          createTask[index].on_complition_doc = item.on_complition_doc;
          createTask[index].is_note = item.is_note;
          createTask[index].is_other = item.is_other;
        });
    } else {
      setShowLoader(false);
      swal(message || "Something went wrong", "", "error");
      return;
    }
  };

  const getClientServices = async (id: any, index: any) => {
    setShowLoader(true);
    const { data, message } = await AuthApi.getDataFromServer(
      `${Api.clientUrl}${id}/`
    );

    const taskData = [...createTask];
    if (data && data.success === true) {
      setShowLoader(false);
      let serviceArr: any = [];
      data &&
        data.data &&
        data.data.tasks &&
        data.data.tasks.length > 0 &&
        data.data.tasks.map((item: any) => {
          serviceArr.push({
            label: item?.task_name,
            value: item?.task_id,
            due_date: item?.due_date ? item?.due_date : "",
          });
        });
      const sortedData: any =
        serviceArr?.length &&
        serviceArr?.sort?.((acu: any, cur: any) =>
          acu?.label?.toLowerCase() > cur?.label?.toLowerCase() ? 1 : -1
        );

      taskData[index].serviceArray = sortedData?.length ? sortedData : [];
      setCreateTask(taskData);
      setServicesArray(serviceArr);
    } else {
      setShowLoader(false);
      setServicesArray("");
      swal(typeof message == "string" ? message : message[0], "", "error");
    }
  };

  const getTimesheet = async (type = "") => {
    setShowLoader(true);

    if (roleId == 2) return;
    const endPoint = `${Api.timesheetUrl}`;
    const params = {
      limit: 50,
      start_date: propsData?.date,
      end_date: propsData?.date,
      employee_id: [2].includes(Number(roleId))
        ? propsData?.data?.employee_id
        : null,
      date: customFunctions.getDate(new Date()),
    };
    const url = customFunctions.generateUrl(endPoint, params);
    const { data, message } = await AuthApi.getDataFromServer(url);
    if (data && data.success === true) {
      setShowLoader(false);
      if (type == "deleteFunction" && data?.data?.length == 0) {
        const staticTask = {
          client_name: "",
          task_name: "",
          time: "",
          is_complete: 1,
          id: "",
          file: "",
          file_name: "",
          file_link: "",
          description: "",
          employee_name: "",
          employee_id: "",
          is_file: "",
          on_complition_doc: "",
          is_note: "",
          is_other: "",
          isChecked: false,
          serviceArray: [],
          dueDate: "",
          type: "task",
          isDisabled: false,
        };
        setCreateTask([staticTask]);
        forceUpdate(count + 1);
      } else {
        if (data && data.data) {
          let task: any = data.data;

          let Arr: any = [];
          task &&
            task.map((item: any) => {
              const date: any =
                roleId == 2 ? propsData?.due_date : propsData?.date;
              const due_date: any = roleId == 2 ? item?.due_date : item?.date;
              let link: any = "https://ca-api.reviewtestlink.com/media/";
              if (due_date == date) {
                Arr.push({
                  id: item?.id,

                  employee_name: {
                    label: item?.name,
                    value: item?.employee_id,
                  },

                  client_name: {
                    name: item?.client_name,
                    id: item?.client_id,
                  },

                  is_file: item?.task?.is_file,
                  is_note: item?.task?.is_note,
                  is_other: item?.task?.is_other,
                  on_complition_doc: item?.task?.on_complition_doc,
                  task_name: item?.task?.task_name
                    ? {
                        label: item?.task?.task_name,
                        value: item?.task?.id,
                      }
                    : {
                        label: item?.litigation?.title,
                        value: item?.litigation?.id,
                      },
                  time: item?.time_log,
                  is_complete: item?.task_status_id ? item?.task_status_id : 1,
                  file: item.file?.id,
                  description: item?.description ? item?.description : "",
                  file_link:
                    roleId == 2
                      ? `${link}${item?.file__file_name}`
                      : item?.file?.file_name,
                  file_name: item?.file?.f_name,
                  serviceArray: servicesArray,
                  dueDate: item?.task_due_date
                    ? customFunctions.getDMYDate(item?.task_due_date, "dmy")
                    : "",
                  type:
                    item?.task && item?.task?.task_name ? "task" : "litigation",
                  isDisabled: false,
                });
              }
            });
          pageType === "edit" && data?.data == ""
            ? setCreateTask(createTask)
            : setCreateTask(Arr);
        }
      }
    } else {
      setShowLoader(false);
      swal(typeof message == "string" ? message : message[0], "", "error");
    }
  };

  const addTimesheet = async () => {
    const Arr: any = [];
    createTask.map((item: any) => {
      if (timesheetId) {
        if (item?.type == "task") {
          if (item?.id) {
            Arr.push({
              id: item?.id ? item?.id : null,
              client: item?.client_name?.id,
              task: item?.task_name?.value,
              time_log: Number(item?.time),
              task_status: item?.is_complete ? Number(item?.is_complete) : 1,
              file: item?.file ? item?.file : "",
              description: item?.description,
            });
          } else {
            Arr.push({
              client: item?.client_name.id,
              task: item?.task_name.value,
              time_log: Number(item?.time),
              task_status: item?.is_complete ? Number(item?.is_complete) : 1,
              file: item?.file ? item?.file : "",
              description: item?.description,
            });
          }
        } else {
          if (item?.id) {
            Arr.push({
              id: item?.id ? item?.id : null,
              client: item?.client_name.id,
              litigation: item?.task_name.value,
              time_log: Number(item?.time),
              task_status: item?.is_complete ? Number(item?.is_complete) : 1,
              file: item.file ? item?.file : "",
              description: item?.description,
            });
          } else {
            Arr.push({
              client: item?.client_name?.id,
              litigation: item?.task_name?.value,
              time_log: Number(item?.time),
              task_status: item?.is_complete ? Number(item?.is_complete) : 1,
              file: item?.file ? item?.file : "",
              description: item?.description,
            });
          }
        }
      } else {
        if (item?.type == "task") {
          Arr.push({
            client: item?.client_name?.id,
            task: item?.task_name?.value,
            time_log: Number(item?.time),
            task_status: item?.is_complete ? Number(item?.is_complete) : 1,
            file: item?.file ? item?.file : "",
            description: item?.description,
          });
        } else {
          Arr.push({
            client: item?.client_name?.id,
            litigation: item?.task_name?.value,
            time_log: Number(item?.time),
            task_status: item?.is_complete ? Number(item?.is_complete) : 1,
            file: item?.file ? item?.file : "",
            description: item?.description,
          });
        }
      }
    });

    const postData: any = {
      task: Arr,
    };

    const endPoint = Api.timesheetUrl;

    const callback: any = timesheetId
      ? AuthApi.putDataToServer
      : AuthApi.postDataToServer;

    const { data, message } = await callback(endPoint, postData);

    if (data && data.success === true) {
      setShowLoader(false);
      setDisableButton(false);
      swal(
        timesheetId
          ? "Timesheet updated successfully!"
          : "Timesheet created successfully!",
        "",
        "success"
      ).then(() => {
        onClear();
        callBack();
        // getAssignedTasks();
      });
    } else {
      setDisableButton(false);
      setShowLoader(false);
      swal(typeof message === "string" ? message : message[0], "", "error");
    }
  };

  const taskListdata = (index: any) => {
    const documentsDetailsArr: any = [...createTask];
    if (documentsDetailsArr && documentsDetailsArr.length) {
      let filterDocmentsArr: any =
        createTask?.length &&
        createTask[index].serviceArray?.length &&
        createTask[index].serviceArray.filter((sr: any) => {
          return documentsDetailsArr.findIndex(
            (serv: any, i: any) =>
              sr?.value === serv?.task_name?.value && i !== index
          ) === -1
            ? true
            : false;
        });

      documentsDetailsArr[index].serviceArray = filterDocmentsArr?.length
        ? filterDocmentsArr
        : [];
    }
    setCreateTask(documentsDetailsArr);
    forceUpdate(count + 1);
  };

  const onSubmit = (e: any) => {
    e.preventDefault();
    let isValid: any = true;
    createTask &&
      createTask.length > 0 &&
      createTask.map((item: any) => {
        for (let keys in item) {
          if (
            ["client_name", "task_name", "time"].includes(keys) &&
            ["", null, undefined].includes(item[keys])
          ) {
            isValid = false;
          }
        }
      });
    if (validator.current.allValid() && isValid) {
      addTimesheet();
    } else {
      validator.current.showMessages();
      forceUpdate(count + 1);
      setDisableButton(false);
    }
  };

  const addField = () => {
    const payload = {
      // task_id: null,
      client_name: "",
      service_name: "",
      employee_name: "",
      task_name: "",
      time: null,
      is_complete: "",
      file: "",
      file_name: "",
      file_link: "",
      description: "",
      is_file: "",
      on_complition_doc: "",
      is_note: "",
      is_other: "",
      dueDate: "",
      type: "task",
      isDisabled: false,
    };
    setCreateTask([...createTask, payload]);
  };

  const deletField = async (id: any) => {
    setShowLoader(true);
    const endpoint: any = `${Api.timesheetUrl}${id}/`;
    const { data, message } = await AuthApi.deleteDataFromServer(endpoint, "");
    if (data && data.success === true) {
      setShowLoader(false);
      setStatusToggle(false);
      swal("Timesheet deleted successfully!", "", "success", {
        closeOnClickOutside: false,
      }).then(() => {
        getTimesheet("deleteFunction");
      });
    } else {
      setShowLoader(false);
      swal(
        typeof message === "string" ? message : message[0],
        "",
        "error"
      ).then(() => {
        getTimesheet();
      });
    }
  };

  const removeField = (ind: any) => {
    const del: any = [...createTask];
    del.splice(ind, 1);
    if (del && !del?.length) {
      const newTask: any = {
        // task_id: null,
        client_name: "",
        service_name: "",
        employee_name: "",
        task_name: "",
        time: null,
        is_complete: "",
        file: "",
        file_name: "",
        file_link: "",
        description: "",
        is_file: "",
        on_complition_doc: "",
        is_note: "",
        is_other: "",
        dueDate: "",
        type: "task",
        isDisabled: false,
      };
      setCreateTask([newTask]);
    } else {
      setCreateTask([...del]);
    }
    forceUpdate(count + 1);
  };

  const documentCallback = async (value: any) => {
    let taskArray: any = [];
    createTask.map((item: any, ind: any) => {
      if (value?.index === ind) {
        taskArray.push({
          ...item,
          ...value,
        });
      } else {
        taskArray.push(item);
      }
    });
    setCreateTask(taskArray);
  };

  const onClear = () => {
    setCreateTask([
      {
        client_name: "",
        task_name: "",
        time: "",
        is_complete: 1,
        id: "",
        file: "",
        file_name: "",
        file_link: "",
        description: "",
        employee_name: "",
        employee_id: "",
        is_file: "",
        on_complition_doc: "",
        is_note: "",
        is_other: "",
        isChecked: false,
        serviceArray: [],
        dueDate: "",
        type: "task",
        isDisabled: false,
      },
    ]);
    setPageType("add");
    setTimesheetId(null);
    validator.current.hideMessages();

    forceUpdate(count + 1);
    getAssignedTasks();
  };

  return (
    <>
      <Loader showLoader={isShowLoader} needFullPage={false} />
      {statusToggle && (
        <div className="sweetalert">
          <SweetAlert
            title={"Are you sure? do you want to complete the task?"}
            warning
            showCancel
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            onConfirm={() => {
              setStatusToggle(false);
            }}
            onCancel={() => {
              setStatusToggle(false);
            }}
            reverseButtons={false}
          />
        </div>
      )}
      <section className="content-area mt-3">
        {[3, 4, 5].includes(roleId) ? (
          <Card>
            <Card.Header>
              <h6 className="section-title mt-1 mb-0">
                {pageType == "add"
                  ? "Create Timesheet"
                  : pageType == "edit"
                  ? "Edit Timesheet"
                  : pageType == "view"
                  ? "View Timesheet"
                  : "Create Timesheet"}
              </h6>
            </Card.Header>
            <Card.Body>
              {createTask && createTask.length > 0 ? (
                createTask.map(
                  (item: any, index: any) => (
                    (
                      <>
                        <Row className={index > 0 ? "mt-2" : ""}>
                          <Col sm={4}>
                            <SelectAsyncPaginate
                              asterisk={"*"}
                              label={index == 0 ? "Client" : ""}
                              id={"clientname"}
                              selectPlaceholder={"Select"}
                              apiname={"clientUrl"}
                              endpoint={Api.clientUrl}
                              value={item?.client_name}
                              onSelectChange={(select: any) => {
                                let client = [...createTask];
                                client[index].task_name = "";
                                client[index].dueDate = "";
                                client[index].time = "";
                                client[index].client_name = select;
                                client[index].is_complete = "1";
                                setCreateTask([...client]);
                                if (select?.id) {
                                  getClientServices(select?.id, index);
                                }
                              }}
                              disabled={
                                pageType == "view" ||
                                item?.id ||
                                item?.isDisabled
                              }
                              errorMessage={validator.current.message(
                                "client",
                                createTask[index].client_name,
                                "required"
                              )}
                              fromComponent={"addTask"}
                            />
                          </Col>
                          <Col sm={5}>
                            <DropdownInput
                              asterisk="*"
                              label={index == 0 ? "Task" : ""}
                              placeholder="Select"
                              options={item.serviceArray}
                              defaultValue={item.task_name}
                              disabled={
                                pageType === "view" ||
                                !item?.client_name ||
                                item?.id ||
                                item?.isDisabled
                              }
                              onSelectHandler={(select: any) => {
                                let task = [...createTask];
                                task[index].task_name = select;
                                task[index].dueDate =
                                  select && select?.due_date
                                    ? customFunctions.getDMYDate(
                                        select?.due_date,
                                        "dmy"
                                      )
                                    : "";
                                task[index].is_complete = "1";
                                setCreateTask([...task]);
                                getTask(select.value, index);
                              }}
                              errorMessage={validator.current.message(
                                "task",
                                createTask[index].task_name,
                                "required"
                              )}
                              id="document"
                              date={item?.dueDate}
                            />
                          </Col>
                          {[3, 4, 5].includes(roleId) ? (
                            <Col sm={3}>
                              <TextInput
                                onInputChange={(e: any) => {
                                  const re = /^[0-9]*$/;
                                  if (
                                    !e.target.value ||
                                    re.test(e.target.value)
                                  ) {
                                    let Arr = [...createTask];
                                    Arr[index].time = e.target.value;
                                    setCreateTask([...Arr]);
                                  }
                                }}
                                id="time"
                                asterisk="*"
                                containerClass="form-group"
                                type="text"
                                label={index == 0 ? "Time" : ""}
                                name="time"
                                inputVarible="Hrs"
                                value={item.time}
                                disabled={pageType === "view"}
                                placeholder="Time"
                                errorMessage={validator.current.message(
                                  "time",
                                  item.time,
                                  "required|integer|min:1,num|max:24,num",
                                  {
                                    messages: {
                                      min: "Please enter correct time",
                                    },
                                  }
                                )}
                                maxlength={2}
                              />
                            </Col>
                          ) : null}
                        </Row>
                        <Row
                          className={pageType === "view" ? "" : "mt-0"}
                          style={{
                            marginTop: pageType === "view" ? "-9px" : "",
                          }}
                        >
                          {[2, 3, 4, 5].includes(roleId) ? (
                            <Col sm={4}>
                              <div className="text-size">
                                <div
                                  className={
                                    pageType === "view" ? "" : "form-check"
                                  }
                                >
                                  {pageType !== "view" && item.time ? (
                                    <>
                                      <input
                                        disabled={item.task_name == ""}
                                        className="form-check-input"
                                        type={
                                          pageType !== "view" ? "checkbox" : " "
                                        }
                                        value={item.is_complete}
                                        checked={item.is_complete == "2"}
                                        id={`${index}flexCheckDefault`}
                                        onChange={(e: any) => {
                                          if (e.target.checked === false) {
                                            let complete = [...createTask];
                                            item.is_complete = 1;
                                            complete[index].file_name = "";
                                            complete[index].file = "";
                                            complete[index].description = "";
                                            setCreateTask([...complete]);
                                            setUpdateShow(false);
                                          } else {
                                            if (!item.on_complition_doc) {
                                              let complete = [...createTask];
                                              complete[index].file_name = "";
                                              complete[index].file = "";
                                              complete[index].description = "";
                                              item.is_complete = e.target
                                                .checked
                                                ? 2
                                                : 1;
                                              setCreateTask([...complete]);
                                              setUpdateShow(false);
                                            } else {
                                              if (item.on_complition_doc) {
                                                setUpdateShow(true);
                                                // setPageType("edit");
                                              }
                                              setTaskID(index);
                                            }
                                          }
                                        }}
                                      />
                                    </>
                                  ) : null}

                                  <div className="d-flex justify-content-start">
                                    {titleDate == new Date() ? (
                                      <label
                                        className="form-check-label"
                                        htmlFor={`${index}flexCheckDefault`}
                                      >
                                        {item.is_complete !== "2" &&
                                        pageType !== "view"
                                          ? "Mark as complete"
                                          : pageType === "view" &&
                                            item.is_complete == "2"
                                          ? "Mark as complete"
                                          : "Work in progress"}
                                      </label>
                                    ) : item.time ? (
                                      <label
                                        className="form-check-label"
                                        htmlFor={`${index}flexCheckDefault`}
                                      >
                                        {item.is_complete !== "2" &&
                                        pageType !== "view"
                                          ? "Mark as complete"
                                          : pageType === "view" &&
                                            item.is_complete == "2"
                                          ? "Completed"
                                          : "Work in progress"}
                                      </label>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </div>
                              </div>
                            </Col>
                          ) : null}
                          {/* <Col sm={pageType === "view" ? 5 : 6}></Col> */}
                          {pageType === "view" ? null : (
                            <Col sm={8}>
                              <div className="float-right">
                                {createTask &&
                                  createTask?.length - 1 === index &&
                                  createTask?.length - 1 !==
                                    createTask?.length && (
                                    // <img
                                    //   className="cursor-pointer float-end ml-2"
                                    //   onClick={() => {
                                    //     addField();
                                    //   }}
                                    //   style={{ height: "22px", width: "22px" }}
                                    //   src="images/plus-icon.png"
                                    //   alt="view"
                                    // />
                                    <a
                                      href="javascript:void(0)"
                                      title="Add More Task"
                                      className="cursor-pointer underline float-end ml-3"
                                      onClick={() => {
                                        addField();
                                      }}
                                    >
                                      Add More
                                    </a>
                                  )}
                                {createTask?.length > 1 && (
                                  // <img
                                  //   className="cursor-pointer float-end"
                                  //   onClick={() => {
                                  //     if (item.id) {
                                  //       swal({
                                  //         title: "Are you sure?",
                                  //         text: "you want to delete this timesheet record.",
                                  //         icon: "warning",
                                  //         buttons: ["Cancel", true],
                                  //         dangerMode: true,
                                  //       }).then((willDelete) => {
                                  //         if (willDelete) {
                                  //           deletField(item.id);
                                  //         }
                                  //       });
                                  //     } else {
                                  //       removeField(index);
                                  //     }
                                  //   }}
                                  //   style={{ height: "22px", width: "22px" }}
                                  //   src="images/minus-25.svg"
                                  //   alt="view"
                                  // />
                                  <a
                                    href="javascript:void(0)"
                                    title="Remove Task"
                                    className="cursor-pointer float-end underline"
                                    onClick={() => {
                                      if (item.id) {
                                        swal({
                                          title: "Are you sure?",
                                          text: "you want to delete this timesheet record.",
                                          icon: "warning",
                                          buttons: ["Cancel", true],
                                          dangerMode: true,
                                        }).then((willDelete) => {
                                          if (willDelete) {
                                            deletField(item.id);
                                          }
                                        });
                                      } else {
                                        removeField(index);
                                      }
                                    }}
                                  >
                                    Remove
                                  </a>
                                )}
                              </div>
                            </Col>
                          )}
                          {pageType != "add" &&
                          item?.on_complition_doc != false ? (
                            <Col
                              sm={pageType === "view" ? 8 : 5}
                              className={
                                pageType == "edit" ? "float-right d-flex" : ""
                              }
                            >
                              {item.is_complete === 2 ? (
                                <>
                                  {item?.is_file && (
                                    <a
                                      className="float-end underline ml-10"
                                      style={{ cursor: "pointer" }}
                                      title={
                                        item?.is_file
                                          ? "View Submitted File"
                                          : ""
                                      }
                                      onClick={() => {
                                        if (item) {
                                          if (pageType == "view") {
                                            setPageType("view");
                                            setPgNo(1);
                                          }
                                          if (pageType == "edit") {
                                            setPageType("edit");
                                            setPgNo(2);
                                          }
                                          // if (
                                          //   (pageType == "view" ||
                                          //     pageType == "edit") &&
                                          //   item?.is_file
                                          // ) {
                                            setUpdateShow(false);
                                            window.open(item?.file_link);
                                          // } else {
                                          //   setUpdateShow(true);
                                          //   setTaskID(index);
                                          // }
                                        }
                                      }}
                                    >
                                      {item?.is_file
                                        ? "View File"
                                        : ""}
                                    </a>
                                  )}
                                  {
                                    item?.is_note &&
                                    <a
                                      className="float-end underline ml-2"
                                      style={{ cursor: "pointer" }}
                                      title={item?.is_note
                                          ? "View Submitted Note"
                                          : ""
                                      }
                                      onClick={() => {
                                        if (item) {
                                          if (pageType == "view") {
                                            setPageType("view");
                                            setPgNo(1);
                                          }
                                          if (pageType == "edit") {
                                            setPageType("edit");
                                            setPgNo(2);
                                          }
                                          // if (
                                          //   (pageType == "view" ||
                                          //     pageType == "edit") &&
                                          //   item?.is_file
                                          // ) {
                                          //   setUpdateShow(false);
                                          //   window.open(item?.file_link);
                                          // } else {
                                            setUpdateShow(true);
                                            setTaskID(index);
                                          // }
                                        }
                                      }}
                                    >
                                      {item?.is_note
                                        ? "View Note"
                                        : ""}
                                    </a>
                                  }
                                </>
                              ) : null}
                            </Col>
                          ) : null}
                        </Row>
                        <hr />
                      </>
                    )
                  )
                )
              ) : (
                <center>
                  <h4>No record found</h4>
                </center>
              )}

              <Row>
                <Col>
                  <div style={{ float: "right" }} className="form-group mt-3">
                    {pageType !== "view" && (
                      <Button
                        type="submit"
                        variant="primary"
                        disabled={disableButton}
                        onClick={(e: any) => {
                          e.preventDefault();
                          onSubmit(e);
                        }}
                      >
                        {pageType == "add"
                          ? "Submit"
                          : pageType == "edit"
                          ? "Update"
                          : "Submit"}
                      </Button>
                    )}
                    <Button
                      onClick={(e) => {
                        e.preventDefault();
                        onClear();
                      }}
                      variant="secondary"
                      className="ms-2"
                    >
                      Cancel
                    </Button>
                  </div>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        ) : (
          <>
            {roleId == 2 || roleId == 10 ? (
              <TaskViewComponent
                employeeData={state}
                empId={state?.data?.employee_id}
                dueDate={state?.due_date}
                roleId={roleId}
                callback={(value: any) => {
                  setUpdateShow(true);
                }}
              />
            ) : null}
          </>
        )}
      </section>
      {updateShow && (
        <Addtask
          data={createTask}
          pagetype={pageType}
          updateShow={updateShow}
          pgNo={pgNo}
          handleClose={(pageType: any) => {
            setUpdateShow(!updateShow);
            setPageType(pageType);
          }}
          tId={taskID}
          callback={(value: any) => {
            documentCallback(value);
          }}
        />
      )}
    </>
  );
};

export default AddNewTaskComponent;
