import React, { useEffect, useState } from "react";
import { Tabs, Tab } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import IndividualClientComponent from "./IndividualClientList";
import NonCorporateClientComponent from "./NonCorporateClientList";
import CorporateClientComponent from "./CorporateClientList";
import ClientPanValidationComponent from "./ClientPanValidation";
import { Helmet } from "../../components/FormInputs";
import Breadcrumb from "../../components/Breadcrumb/breadcrumb.component";
import Loader from "../../components/Loader";
import customFunctions from "../../helper/customFunctions";

const ClientNavbar = () => {
  const location = useLocation();

  const activeTab = customFunctions.getLocalStorage('activeTab');
  const [key, setKey] = useState<any>(customFunctions.getLocalStorage('activeTab'));
  const [title, setTitle] = useState<any>("Client List");
  const [isShowLoader, setShowLoader] = useState<any>(false);
  
  const items = [{ to: "/", label: "Dashboard" }, { label: "Client List" }];
  
  useEffect(() => {
    setKey(activeTab);
  }, [activeTab]);

  const handleSelect = (e: any) => {
    switch (e) {
      case "individualClient":
        setKey("individualClient");
        customFunctions.setLocalStorage('activeTab', 'individualClient');
        break;
      case "corporateClient":
        setKey("corporateClient");
        customFunctions.setLocalStorage('activeTab', 'corporateClient');
        break;
      case "nonCoporateClient":
        setKey("nonCoporateClient");
        customFunctions.setLocalStorage('activeTab', 'nonCoporateClient');
        break;
    }
  }

  return (
    <>
      <Helmet title={title} />
      <div className="page-header">
        <ClientPanValidationComponent />
        <div className="title-breadcrumb-section">
          <h2 className="main-content-title tx-24 mg-b-5">{title}</h2>
          <Breadcrumb items={items} />
        </div>
      </div>

      <Loader showLoader={isShowLoader} needFullPage={false} />
      <section className="content-area mt-3">

        <div className="tabs-wrapper">
          <Tabs
            defaultActiveKey={key}
            id="justify-tab-example"
            className="mb-2"
            onSelect={(e) => handleSelect(e)}
          >
            <Tab eventKey="individualClient" title="Individual Client">
              {
                key == 'individualClient' && activeTab == "individualClient" && (
                  <IndividualClientComponent activeTab="individualClient" />
                )
              }
            </Tab>
            <Tab eventKey="corporateClient" title="Corporate Client">
              {
                key == 'corporateClient' && activeTab == "corporateClient" && (
                  <CorporateClientComponent activeTab="corporateClient" />
                )
              }
            </Tab>

            <Tab eventKey="nonCoporateClient" title="Non-Coporate Client">
              {
                key == 'nonCoporateClient' && activeTab == 'nonCoporateClient' && (
                  <NonCorporateClientComponent activeTab="nonCoporateClient" />
                )
              }
            </Tab>
          </Tabs>
        </div>

      </section>
    </>
  )
}

export default ClientNavbar;