import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";
import SweetAlert from "react-bootstrap-sweetalert";
import {
  Card,
  Form,
  Col,
  Row,
  Button,
  Accordion,
  Image,
} from "react-bootstrap";
import customFunctions from "../../helper/customFunctions";
import AuthApi from "../../helper/authApi";
import Api from "../../helper/api";
import Breadcrumb from "../../components/Breadcrumb/breadcrumb.component";
import Loader from "../../components/Loader/index";
import { Helmet, DropdownInput } from "../../components/FormInputs";
import Table from "../../components/Table/simpletable.component";
import RejectTaskComponent from "./rejectTaskComponent";
import ViewDocumentModel from "./viewDocumentModel";
import SelectAsyncPaginate from "../../components/ReactSelectPagination/SelectAsyncPaginate";

const TaskApprovalListComponent = (props: any) => {
  const { fromComponent } = props;
  const navigate = useNavigate();
  const [isShowLoader, setShowLoader] = useState<any>(false);
  const [rows, setRows] = useState<any>([]);
  const [title, setTitle] = useState<any>("Tasks Approval");
  const [statusToggle, setStatusToggle] = useState<any>(false);
  const [count, forceUpdate] = useState<any>(0);
  const [filterErr, setFilterErr] = useState<any>("");
  const [selectedClient, setSelectedClient] = useState<any>("");
  const [selectedEmployee, setSelectedEmployee] = useState<any>("");
  const [applyToggle, setApplyToggle] = useState<any>(false);
  const [roleId, setRoleId] = useState<any>();
  const [currentUserId, setCurrentUserId] = useState<number>(0);
  const [updateShow, setUpdateShow] = useState<boolean>(false);
  const [modalData, setModalData] = useState<boolean>(false);
  const [taskData, setTaskData] = useState<any>([]);
  const [clientDataArray, setClientDataArray] = useState<any>([]);
  const [employeeDataArray, setEmployeeDataArray] = useState<any>([]);
  const [disableButton, setDisableButton] = useState<boolean>(false);
  const [isShowFilter, setisShowFilter] = useState<boolean>(true);
  const [data, setData] = useState<any>();
  const items = [{ to: "/", label: "Dashboard" }, { label: "Tasks Approval" }];

  const initialPagination = {
    currentPage: 1,
    itemsPerPage: 10,
    totalItems: 1,
  };

  const [pagination, setPagination] = useState(initialPagination);

  const columns = [
    {
      label: "Sr. No",
      id: "index",
    },
    {
      label: "Client Name",
      id: "client_name",
    },
    {
      label: "Task Name",
      id: "task_name",
    },
    {
      label: "Submitted By",
      id: "submitedby",
    },
    {
      label: "Due Date",
      id: "due_date",
    },
    {
      label: "Submitted At",
      id: "submitedat",
    },
    {
      label: "Details",
      id: "documentattachment",
    },
    {
      label: "Action",
      id: "action",
    },
  ];

  useEffect(() => {
    getUserRole();
  }, []);

  const getUserRole = () => {
    const role = customFunctions.getLocalStorage("role_id");
    setRoleId(Number(role));
    const currentUserId = customFunctions.getLocalStorage("id");
    setCurrentUserId(Number(currentUserId));
  };

  useEffect(() => {
    getTaskApprovalData(1, true);
  }, [props]);

  const getEmployeeList = async () => {
    setShowLoader(true);
    const { data, message } = await AuthApi.getDataFromServer(
      `${Api.employeeUrl}?limit=1000`
    );

    if (data && data.success === true) {
      setShowLoader(false);
      let employeeData: any = [];
      data &&
        data.data &&
        data.data.length > 0 &&
        data.data.map((item: any) => {
          if (
            item.user.role == 2 ||
            Number(localStorage.getItem("id")) == item?.user?.user
          )
            return;
          employeeData.push({
            label: `${item?.user.first_name} ${item?.user.last_name}`,
            value: item?.employee?.id,
          });
        });
      setEmployeeDataArray(employeeData);
    } else {
      setShowLoader(false);
      swal(typeof message === "string" ? message : message[0], "", "error");
    }
  };

  const getClientList = async () => {
    setShowLoader(true);
    const { data, message } = await AuthApi.getDataFromServer(
      `${Api.clientUrl}?sort_direction=descending&limit=1000`
    );
    if (data && data.success === true) {
      setShowLoader(false);
      let clientData: any = [];
      data &&
        data.data &&
        data.data.length > 0 &&
        data.data.map((item: any) => {
          if (item.client_status == 2) return;
          clientData.push({ label: item?.client_name, value: item.id });
        });
      setClientDataArray(clientData);
    } else {
      setShowLoader(false);
      swal(typeof message === "string" ? message : message[0], "", "error");
    }
  };

  const getTaskApprovalData = async (pageNo?: any, clearFilter?: boolean) => {
    const page = pageNo || 1;
    setShowLoader(true);
    // setApplyToggle(false);
    const endPoint = Api.taskApprovalUrl;
    const params = {
      page: page,
      sort_direction: "descending",
      approved_status: 3,
      task_status: 2,
      employee_id:
        !clearFilter && selectedEmployee?.id ? selectedEmployee?.id : "",
      client_id: !clearFilter && selectedClient?.id ? selectedClient?.id : "",
    };
    const url = customFunctions.generateUrl(endPoint, params);
    const { data, message } = await AuthApi.getDataFromServer(url);
    if (data && data.success == true) {
      setShowLoader(false);
      if (data && data?.data && !data?.data?.length && clearFilter) {
        setisShowFilter(false);
      }

      if (data && data.data && data.data.length > 0) {
        setPagination({
          currentPage: data.paginator.current_page,
          itemsPerPage: Number(10),
          totalItems: data.paginator.total_count,
        });
        setTaskData(data.data);
        forceUpdate(count + 1);
      } else {
        setTaskData([]);
      }
    } else {
      setShowLoader(false);
      setTaskData([]);
      swal(
        typeof message == "string"
          ? message
          : message?.length
          ? message[0]
          : "Something went wrong!",
        "",
        "error"
      );
    }
  };

  useEffect(() => {
    setTableData();
  }, [taskData]);

  const setTableData = () => {
    const rowsData: any = [];
    taskData.map((dt: any, i: any) => {
      rowsData.push({
        id: dt.id,
        index: (pagination.currentPage - 1) * pagination.itemsPerPage + (i + 1),
        task_name: dt?.task?.task_name
          ? dt?.task?.task_name
          : dt?.litigation?.title,
        documentattachment: (
          <>
            {dt?.task?.on_complition_doc == true ? (
              <a
                href="javascript:void(0)"
                className="underline"
                title={
                  dt?.task?.is_file && dt?.task?.is_note ?
                  "View Details":
                  dt?.task?.is_note
                    ? "View Note"
                    : dt?.task?.is_file
                    ? "View File"
                    : ""
                }
                onClick={(e) => {
                  e.preventDefault();
                  if(dt?.task?.is_file && dt?.task?.is_note){
                    setUpdateShow(true);
                    setModalData(dt);
                  }
                  else if (dt?.task?.is_file && dt?.file?.file_name) {
                    window.open(dt?.file?.file_name);
                  }
                }}
              >
                {
                dt?.task?.is_file && dt?.task?.is_note ?
                <>View Details</>:
                dt?.task?.is_file ? (
                  <Image src="/images/notes.svg" title="View File" />
                ) : dt?.task?.is_note ? (
                  "View Note"
                ) : (
                  ""
                )}
              </a>
            ) : (
              "-"
            )}
          </>
        ),
        submitedby: dt?.name,
        submitedat: customFunctions.getDMYDate(dt?.updated_at, "dmy"),
        due_date: dt?.task_due_date
          ? customFunctions.getDMYDate(dt?.task_due_date, "dmy")
          : "-",
        client_name: dt?.client_name,
        action: (
          // (
          <>
            <a
              href="/"
              className="pl-2 ms-1"
              title={" Approve Task"}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setStatusToggle(true);
                setData(dt);
              }}
            >
              <Image src="/images/success.svg" alt="Approve task" />
            </a>
            &nbsp;&nbsp;
            <RejectTaskComponent
              data={dt}
              callback={(dt: any) => {
                taskApproval(dt);
              }}
            />
          </>
        ),
      });
      return dt;
    });
    setRows([...rowsData]);
  };

  const taskApproval = async (dt: any) => {
    const Approve: any = [];
    const postdata: any = {
      approved_status: dt.approved_status,
      note: dt.note,
    };

    const endPoint = `${Api.taskApprovalUrl}${dt.id}/`;
    const PutAPi: any = AuthApi.putDataToServer;
    const { data, message } = await PutAPi(endPoint, postdata);

    if (data && data.success === true) {
      setShowLoader(false);
      setDisableButton(false);
      swal(
        data.data.approved_status == 1
          ? "Task approved successfully"
          : "Task rejected successfully!",
        "",
        "success"
      ).then(() => {
        // window.location.reload();
        getTaskApprovalData(1, true);
      });
    } else {
      setDisableButton(false);
      setShowLoader(false);
      swal(typeof message === "string" ? message : message[0], "", "error");
    }
  };

  const filterSearch = (e: any) => {
    e.preventDefault();
    let filterSearchErr = "";
    let isValid = true;
    if (
      customFunctions.checkIfEmpty(selectedEmployee) &&
      customFunctions.checkIfEmpty(selectedClient)
    ) {
      filterSearchErr = "Please select client or employee";
      setFilterErr(filterSearchErr);
      isValid = false;
    }
    if (isValid) {
      setFilterErr("");
      getTaskApprovalData(1, false);
    }
  };

  const onClear = (e: any) => {
    setFilterErr("");
    setSelectedClient("");
    setSelectedEmployee("");
    setApplyToggle(false);

    if (selectedClient || selectedEmployee) {
      getTaskApprovalData(1, true);
    }
  };

  return (
    <>
      <>
        {fromComponent !== "dashboard" && (
          <>
            <Helmet title={title} />
            <div className="page-header">
              <div className="title-breadcrumb-section"></div>
            </div>
            <Loader showLoader={isShowLoader} needFullPage={false} />
          </>
        )}

        {statusToggle && (
          <div className="sweetalert">
            <SweetAlert
              title={"Are you sure? do you want to approve a task?"}
              warning
              showCancel
              confirmBtnBsStyle="success"
              cancelBtnBsStyle="danger"
              onConfirm={() => {
                setStatusToggle(false);
                let Approve: any = data;
                Approve.approved_status = 1;
                setData(Approve);
                taskApproval(data);
              }}
              onCancel={() => {
                setStatusToggle(false);
              }}
              reverseButtons={false}
            />
          </div>
        )}
        <section className="content-area">
          {fromComponent !== "dashboard" && isShowFilter && (
            <Accordion defaultActiveKey="1">
              <Accordion.Item eventKey="1">
                <Accordion.Header>
                  <h5 className="card-title mb-0">Filters</h5>
                </Accordion.Header>
                <Accordion.Body>
                  <Form onSubmit={(e) => filterSearch(e)}>
                    {filterErr && (
                      <div className="p-3  bg-danger text-white bg-danger-main table-red-bg mb-3">
                        <div className="img-text">
                          <img
                            style={{ cursor: "pointer" }}
                            onClick={(e) => onClear(e)}
                            src="/images/fail.svg"
                            alt="Success"
                          />{" "}
                          {filterErr}
                        </div>
                      </div>
                    )}
                    {roleId == 2 || roleId == 10 ? (
                      <Row className="mt-3">
                        <Col sm={3}>
                          {/* <DropdownInput
                          placeholder="Select client"
                          options={clientDataArray}
                          defaultValue={selectedClient}
                          onSelectHandler={(select: any) => {
                            setSelectedClient(select);
                          }}
                        /> */}

                          <SelectAsyncPaginate
                            id={"Client"}
                            selectPlaceholder={"Select client"}
                            apiname={"clientUrl"}
                            endpoint={Api.clientUrl}
                            value={selectedClient}
                            onSelectChange={(select: any) => {
                              setSelectedClient(select);
                            }}
                            label={undefined}
                            asterisk={undefined}
                            key={undefined}
                            isClearable={undefined}
                            clearSelected={undefined}
                            className={undefined}
                            disabled={undefined}
                            isSearch={undefined}
                            isMulti={undefined}
                            clearCacheOnID={undefined}
                            clearSelectedByParent={undefined}
                            errorMessage={undefined}
                            roleId={undefined}
                            currentUserId={undefined}
                            fromComponent={undefined}
                          />
                        </Col>
                        <Col sm={3}>
                          {/* <DropdownInput
                          placeholder="Select employee"
                          options={employeeDataArray}
                          defaultValue={selectedEmployee}
                          onSelectHandler={(e: any) => {
                            setSelectedEmployee(e);
                          }}
                        /> */}

                          <SelectAsyncPaginate
                            selectPlaceholder={"Select employee"}
                            value={selectedEmployee}
                            onSelectChange={(select: any) => {
                              setSelectedEmployee(select);
                            }}
                            apiname={"employeeUrl"}
                            endpoint={Api.employeeUrl}
                            key={undefined}
                            isClearable={undefined}
                            clearSelected={undefined}
                            className={undefined}
                            disabled={undefined}
                            isSearch={undefined}
                            isMulti={undefined}
                            clearCacheOnID={undefined}
                            clearSelectedByParent={undefined}
                            label={undefined}
                            asterisk={undefined}
                            errorMessage={undefined}
                            id={undefined}
                            roleId={undefined}
                            currentUserId={undefined}
                            fromComponent={undefined}
                          />
                        </Col>

                        <Col sm={3}>
                          <div className="filter-btn pull-right filter-btn-report">
                            <Button
                              variant="primary"
                              type="submit"
                              className="ms-4"
                              onClick={() => setApplyToggle(true)}
                            >
                              Apply
                            </Button>
                            <Button
                              variant="secondary"
                              type="button"
                              className="ms-4"
                              onClick={(e) => onClear(e)}
                            >
                              Clear
                            </Button>
                          </div>
                        </Col>
                      </Row>
                    ) : null}
                  </Form>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          )}
          <Card className="mt-3">
            {fromComponent == "dashboard" && (
              <Card.Header>
                <h5>Task Approval List</h5>
              </Card.Header>
            )}
            <Card.Body>
              <Table
                columns={columns}
                // showCheckbox={true}
                rows={rows}
                showPagination
                role={roleId}
                paginationProps={{
                  ...pagination,
                  currentPage: Number(pagination.currentPage),
                  onPageChange: (pageNo: any) => {
                    getTaskApprovalData(pageNo, true);
                  },
                }}
                dropdownContainerClass="mb-3"
                emptyRender={() => (
                  <p className="text-center mb-0">No record found</p>
                )}
              />
            </Card.Body>
          </Card>
        </section>
        {
          <ViewDocumentModel
            show={updateShow}
            data={modalData}
            handleClose={() => {
              setUpdateShow(false);
            }}
          />
        }
      </>
    </>
  );
};

export default TaskApprovalListComponent;
