import axios from 'axios';

export default {
  get: (url:string,config?:any) => axios.get(url,config).then((res) => res),
  post: (url:string, data:any) => axios.post(url, data).then((res) => res),
  put: (url:string, data:any) => axios.put(url, JSON.stringify(data)).then((res) => res),
  patch: (url:string, data:any) =>
    axios.patch(url, JSON.stringify(data)).then((res) => res),
  delete: (url:string) => axios.delete(url).then((res) => res),
};
