import React, { useEffect, useRef, useState } from "react";
import { Modal, Button, Card, Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { TextInput } from "../../components/FormInputs";
import SimpleReactValidator from "simple-react-validator";
import swal from "sweetalert";
import Api from "../../helper/api";
import AuthApi from "../../helper/authApi";
import Loader from "../../components/Loader";

const Addtask = (props: any) => {
  const { show, tId, callback, updateShow, data, pagetype, pgNo } = props;

  const navigate = useNavigate();
  const validator = useRef(new SimpleReactValidator());
  const file: any = useRef();

  const [isShowLoader, setShowLoader] = useState<boolean>(false);
  const [count, forceUpdate] = useState<number>(0);
  const [newDocument, setNewDocument] = useState<any>("");
  const [newDocumentTitle, setNewDocumentTitle] = useState<any>("");
  const [fileId, setFileId] = useState<any>();
  const [description, setDescription] = useState<any>("");
  const [imgURl, setImgURl] = useState<any>("");
  useEffect(() => {
    if (pagetype != "add") {
      setDescription(data[tId].description);
      setNewDocumentTitle(data[tId]?.file_name);
      setFileId(data[tId]?.file);
    }
  }, []);

  const submitDocument = (e: any) => {
    e.preventDefault();
    if (newDocumentTitle) {
      validator.current.errorMessages.UploadFile = null;
      validator.current.fields.UploadFile = true;
    }

    if (!validator.current.allValid()) {
      validator.current.showMessages();
      forceUpdate(count + 1);
      return;
    } else {
      // swal("Task completed successfully", "", "success").then(() => {
      //   let type = pagetype == "view" && pgNo == 1 ? "view" : "edit";
      //   props.handleClose(type);
      //   callback({
      //     is_complete: 2,
      //     index: tId,
      //     file: fileId,
      //     description: description,
      //     pagetype: pagetype,
      //     file_name: newDocumentTitle,
      //     file_link:imgURl
      //   });
      // });

      let type =
        pagetype == "view" && pgNo == 1
          ? "view"
          : pagetype == "edit"
          ? "edit"
          : "add";
      props.handleClose(type);
      callback({
        is_complete: 2,
        index: tId,
        file: fileId,
        description: description,
        pagetype: pagetype,
        file_name: newDocumentTitle,
        file_link: imgURl,
      });
    }
  };

  useEffect(() => {
    if (!newDocument) return;
    if (
      ["png", "jpeg", "jpg", "svg", "pdf"].includes(
        typeof newDocument[0]?.name === "string" &&
          newDocument[0]?.name.split(".").pop()
      )
    ) {
      if (newDocument[0].size >= 1000000) {
        setShowLoader(false);
        swal("File size is too large", " ", "error");
        setNewDocument("");
        return;
      } else {
        uploadDocument();
      }
    } else {
      swal("File extension is not supported", "", "error");
      return;
    }
  }, [newDocument]);

  const uploadDocument = async () => {
    setShowLoader(true);
    const formData = new FormData();
    formData.append("file_name", newDocument[0]);
    const { data, message } = await AuthApi.postDataToServer(
      Api.documentUploadUrl,
      formData
    );

    if (data && data.success === true) {
      setShowLoader(false);
      setFileId(data?.data?.id);
      setNewDocumentTitle(data?.data?.f_name);
      setImgURl(data.data.file_name);
      swal("File uploaded successfully!", "", "success");
      callback();
    } else {
      setShowLoader(false);
      swal(
        message === undefined ? "File size is too large" : message,
        "",
        "error"
      );
    }
  };

  const getFile = () => {
    const files: any = [data[tId]];
    files &&
      files.filter((item: any) => {
        if (item?.file_link) {
          return window.open(item?.file_link);
        }
      });
  };

  return (
    <>
      <Loader showLoader={isShowLoader} needFullPage={false} />
      <Modal
        show={updateShow || show}
        onHide={() => {
          let type =
            pagetype == "view" && pgNo == 1
              ? "view"
              : pagetype == "edit"
              ? "edit"
              : "add";
          props.handleClose(type);
        }}
        // size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <h5>Submitted Details</h5>
          </Modal.Title>
        </Modal.Header>

        {pagetype == "view" ? (
          <Modal.Body>
            {data?.file_name && !data?.note ? (
              <Col>
                <h5 className="d-flex justify-content-center">
                  No Record Found
                </h5>
              </Col>
            ) : null}
            {data[tId]?.description && (
              <Row className="">
                <Col sm={4}>Description :-</Col>
                <Col>{data[tId]?.description}</Col>
              </Row>
            )}

            {data[tId]?.is_file && (
              <Row className="mt-2">
                <Col sm={4}>View File :-</Col>
                <Col>
                  <img
                    style={{ cursor: "pointer" }}
                    title={"View File"}
                    onClick={() => {
                      getFile();
                    }}
                    src="images/notes.svg"
                    alt=""
                  />
                </Col>
              </Row>
            )}
          </Modal.Body>
        ) : (["add", "edit"].includes(pagetype) && data[tId]?.is_file) ||
          (["add", "edit"].includes(pagetype) && data[tId]?.is_note) ? (
          <Modal.Body>
            {["add", "edit"].includes(pagetype) && data[tId].is_note ? (
              <TextInput
                onInputChange={(
                  e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
                ) => {
                  setDescription(e.target.value);
                }}
                // label="Enter Note"
                label={
                  data[tId].is_note === true
                    ? "Enter Note"
                    : "Enter Description"
                }
                asterisk="*"
                containerClass="form-group"
                type="text"
                as="textarea"
                name=""
                // disabled={pageType == "view"}
                value={description}
                placeholder="Enter description"
                errorMessage={validator.current.message(
                  "description",
                  description,
                  "required"
                )}
                autocomplete="new-files"
              />
            ) : null}

            {/* <TextInput
            onInputChange={(
              e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
            ) => {
              setOther(e.target.value);
            }}
            // label="Enter Note"
            // label={data[tId].is_note === true ? "Enter Note" : "Enter Description"}
            asterisk="*"
            containerClass="form-group"
            type="text"
            as="textarea"
            name=""
            // disabled={pageType == "view"}
            value={description}
            placeholder="Enter description"
            errorMessage={validator.current.message(
              "description",
              description,
              "required"
            )}
            autocomplete="new-files"
          /> */}

            {["add", "edit"].includes(pagetype) && data[tId].is_file ? (
              <div>
                <TextInput
                  onInputChange={(e: any) => {
                    if (e.target.value == "") return;
                    setNewDocument(e.target.files);
                  }}
                  label="Upload File"
                  asterisk="*"
                  containerClass="form-group"
                  type="file"
                  name="file"
                  placeholder=""
                  accept="image/png, image/jpeg, image/jpg, image/svg, application/pdf"
                  title={newDocumentTitle}
                  style={{ opacity: 0, color: "transparent", width: "70px" }}
                  // errorMessage={
                  //   validator.current.message(
                  //   "document",
                  //   newDocument,
                  //   "required"
                  // )}
                  autocomplete="new-files"
                />

                <Button
                  ref={file}
                  variant="secondary"
                  style={{ marginTop: "-110px", width: "250px" }}
                >
                  {newDocumentTitle
                    ? newDocumentTitle && newDocumentTitle.length > 20
                      ? newDocumentTitle.slice(0, 20) + "..."
                      : newDocumentTitle
                    : "Choose File"}
                </Button>

                {
                  <p style={{ color: "red", marginTop: "-20px" }}>
                    {" "}
                    {!newDocumentTitle
                      ? validator.current.message(
                          "UploadFile",
                          newDocument,
                          "required"
                        )
                      : ""}{" "}
                  </p>
                }
                <div className="mb-4">
                  <h6 style={{ fontSize: "smaller" }}>
                    <b>Note:</b>
                  </h6>
                  <h6 style={{ fontSize: "smaller" }}>
                    1. Maximum upload file size limit is 1 MB.
                  </h6>
                  <h6 style={{ fontSize: "smaller" }}>
                    2. File supported extension is jpg , png , svg , jpeg , pdf
                  </h6>
                </div>
              </div>
            ) : null}

            <div className="d-flex justify-content-end">
              <Button
                className="ml-10"
                onClick={(e) => {
                  submitDocument(e);
                }}
              >
                Submit
              </Button>
              <Button
                className="ml-10"
                variant="secondary"
                onClick={() => {
                  validator.current.hideMessages();
                  // onCancel(e)
                  let type =
                    pagetype == "view" && pgNo == 1
                      ? "view"
                      : pagetype == "edit"
                      ? "edit"
                      : "add";
                  props.handleClose(type);
                }}
              >
                Cancel
              </Button>
            </div>
          </Modal.Body>
        ) : null}
      </Modal>
    </>
  );
};

export default Addtask;
