import React, { useState, useRef, useEffect } from "react";
import { Row, Col, Card, Form, Button } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";
import swal from "sweetalert";
import { DropdownInput, Helmet, TextInput, Datepicker } from "../../components/FormInputs";
import SimpleReactValidator from "simple-react-validator";
import customFunctions from "../../helper/customFunctions";
import AuthApi from "../../helper/authApi";
import Api from "../../helper/api";
import Loader from "../../components/Loader";
import Breadcrumb from "../../components/Breadcrumb/breadcrumb.component";

interface postDataInterface {
  id?: any;
  task?: string;
  client_Name?: string;
  time_Frame?: string;
  sub_Task?: string;
  billing_Type?: string;
  task_Status?: string;
  approval_Status?: string;
  due_Date?: any;
  task_Priority?: string;
  assign_To?: string;
}
const AddNewSubTask = () => {

  const navigate: any = useNavigate();
  const { state } = useLocation();
  const validator = useRef(new SimpleReactValidator());

  const [count, forceUpdate] = useState<any>(0);
  const [roleId, setRoleId] = useState<number>(0);
  const [currentUserId, setCurrentUserId] = useState<number>(0);
  const [pageType, setPageType] = useState<string>("add");
  const [title, setTitle] = useState("Add Sub-Task");
  const [lebel, setlebel] = useState<any>("Add Sub-Task");
  const [disableButton, setDisableButton] = useState<boolean>(false);
  const [isShowLoader, setShowLoader] = useState<boolean>(false);

  const [selectServices, setSelectServices] = useState<any>();
  const [subTaskName, setSubTaskName] = useState<any>();
  const [designation, setDesignation] = useState<any>();
  const [selectEmployee, setSelectEmployee] = useState<any>();
  const [description, setDescription] = useState<any>();
  const [dueDate, setDueDate] = useState<any>(new Date());

  const [servicesArray, setServicesArray] = useState<any>([]);
  const [designationArray, setDesignationArray] = useState<any>([]);
  const [employeeArray, setEmployeeArray] = useState<any>([]);

  useEffect(() => {
    getUserRole();
  }, []);

  const getUserRole = () => {
    const role = customFunctions.getLocalStorage("role_id");
    setRoleId(Number(role));
    const currentUserId = customFunctions.getLocalStorage("id");
    setCurrentUserId(Number(currentUserId));
  };

  const items = [
    {
      to: '/', label: 'Dashboard'
    },
    // { to: "/timesheet", label: "Master List" },
    { label: lebel },
  ];

  useEffect(() => {
    getServiceData();
    getDesignationList();
    getEmployeeList();
  }, []);

  const getServiceData = async () => {
    setShowLoader(true);
    const { data, message } = await AuthApi.getDataFromServer(`${Api.servicesUrl}`);

    if (data && data.success === true) {
      setShowLoader(false);
      let servicesArray: any = [];
      data && data.data && data.data.length > 0 &&
        data.data.map((item: any) => {
          servicesArray.push({ label: item.services, value: item.id })
        })
      setServicesArray(servicesArray);

    } else {
      setShowLoader(false);
      swal(typeof message === "string" ? message : message[0], "", "error")
    }
  }

  const getDesignationList = async () => {
    setShowLoader(true);
    const { data, message } = await AuthApi.getDataFromServer(`${Api.designationUrl}`);

    if (data && data.success === true) {
      setShowLoader(false);
      let designationData: any = [];
      data && data.data && data.data.length > 0 &&
        data.data.map((item: any) => {
          if ([1, 2, 3].includes(item.id)) return;
          designationData.push({ label: item.designation, value: item.id })
        })
      setDesignationArray(designationData);
    } else {
      setShowLoader(false);
      swal(typeof message === "string" ? message : message[0], "", "error")
    }
  }

  const getEmployeeList = async () => {
    setShowLoader(true);
    const { data, message } = await AuthApi.getDataFromServer(`${Api.employeeUrl}`);

    if (data && data.success === true) {
      setShowLoader(false);
      let emoployeeData: any = [];
      data && data.data && data.data.length > 0 &&
        data.data.map((item: any) => {
          emoployeeData.push({ label: item?.user?.name, value: item.id })
        })
      setEmployeeArray(emoployeeData);

    } else {
      setShowLoader(false);
      swal(typeof message === "string" ? message : message[0], "", "error")
    }
  }

  // const getTask = async () => {
  //   setShowLoader(true)
  //   const { data, message } = await AuthApi.getDataFromServer(
  //     `${Api.doctorUrl}?id=${state.taskId}`
  //   );

  //   if (data && data.success == true) {
  //     setShowLoader(false);
  //     if (
  //       data &&
  //       data.data &&
  //       data.data.response &&
  //       data.data.response.length > 0
  //     ) {
  //       let task = data.data.response[0];

  //       setTask(task.task);
  //       setClientName(task.client_Name);
  //       setTimeFrame(task.time_Frame);
  //       setSubTask(task.sub_Task);
  //     }
  //   } else {
  //     setShowLoader(false);
  //     swal(message, "", "error");
  //   }
  // };
  // useEffect(() => {
  //   if (state && state.type == "edit") {
  //     setTitle("Edit Doctor");
  //     getTask();
  //   }
  // }, [state])

  // useEffect(() => {
  //   if (state && state.pageType == "add") return;

  //   if (state && state) {

  //     const taskN: any = {
  //       label: state.data.Task_name,
  //       value: state.data.Task_name,
  //     };

  //     const taskP: any = {
  //       label: state.data.Task_priority,
  //       value: state.data.Task_priority,
  //     };

  //     const clientN: any = {
  //       label: state.data.Client_name,
  //       value: state.data.Client_name,
  //     };

  //     // const Arr1: any = {
  //     //   label: state.data.Task_status,
  //     //   value: state.data.Task_status,
  //     // };
  //     // const Arr2: any = {
  //     //   label: state.data.Assign_to,
  //     //   value: state.data.Assign_to,
  //     // };
  //     // const Arr5: any = {
  //     //   label: state.data.Financial_year,
  //     //   value: state.data.Financial_year,
  //     // };
  //     // const Arr6: any = {
  //     //   label: state.data.Services,
  //     //   value: state.data.Services,
  //     // };

  //     if (state.pageType == "edit") {
  //       setPageType("edit");
  //       setTitle("Edit Task");
  //       setlebel("Edit Task");
  //     } else {
  //       setPageType("view");
  //       setTitle("View Task");
  //       setlebel("View Task");
  //     }
  //     setSelectTask(taskN);
  //     setTaskPriority(taskP);
  //     setSelectedClient(clientN);
  //   }
  // }, [state]);

  const addSubTask = async () => {
    // setShowLoader(true);
    const postData: any = {
      service: selectServices.label,
      sub_task_name: subTaskName,
      designation: designation.label,
      employee_name: selectEmployee.label,
      due_Date: dueDate,
      description: description
    };

    // swal(
    //   selectTaskId ? "Task updated successfully!" : "Task created successfully!",
    //   "",
    //   "success"
    // ).then(() => {
    //   navigate('/task-list');
    //   navigate('/timesheet');
    // });
    swal("Sub task created successfully!", '', "success")
      .then(() => {
        navigate("/timesheet");
        // navigate("/task-list");
      })
    //   if (state) {
    //     postData.id = state.taskId;
    //   }
    //   const payload = customFunctions.cleanObject(postData);
    //   const endPoint = `${Api.doctorUrl}`;
    //   const callback =
    //   state && state.Id
    //     ? AuthApi.putDataToServer
    //     : AuthApi.postDataToServer;
    // const { data, message } = await callback(endPoint, payload);
    // if (data && data.success === true) {
    //   setShowLoader(false);
    //   setDisableButton(false);
    //   swal(
    //     state && state.taskId
    //       ? "Task" updated successfully!"
    //       : "Task created successfully!",
    //     "",
    //     "success"
    //   ).then(() => {
    //     navigate(-1);
    //   });
    // } else {
    //   setDisableButton(false);
    //   setShowLoader(false);
    //   swal(message, "", "error");
    // }
  };

  const onSubmit = (e: any) => {
    e.preventDefault();
    if (disableButton) return;
    setDisableButton(true);
    if (validator.current.allValid()) {
      addSubTask();
    } else {
      validator.current.showMessages();
      forceUpdate(count + 1);
      setDisableButton(false);
    }
  };

  return (
    <>
      <Helmet title={title} />
      {pageType === "view" && (
        <Button
          style={{ float: "right" }}
          className="form-group mt-3"
          type="submit"
          variant="primary"
          onClick={() => {
            navigate(-1);
          }}
        >
          Back
        </Button>
      )}
      <div className="page-header">
        <div className="title-breadcrumb-section">
          <h2 className="main-content-title tx-24 mg-b-5">{title}</h2>
          <Breadcrumb items={items} />
        </div>
      </div>
      <Loader showLoader={isShowLoader} needFullPage={false} />
      <section className="content-area">
        <Card>
          <Card.Body>
            <Form
              autoComplete="off"
              onSubmit={(e) => {
                onSubmit(e);
              }}
            >
              <Row>
                <Col sm={6}>
                  <DropdownInput
                    label="Select Service"
                    asterisk="*"
                    placeholder="Select service"
                    disabled={pageType === "view"}
                    options={servicesArray}
                    defaultValue={selectServices}
                    onSelectHandler={(select: any) => {
                      setSelectServices(select);
                    }}
                    isClearable={true}
                    errorMessage={validator.current.message(
                      "select services",
                      selectServices,
                      "required"
                    )}
                    id="tasklist"
                  />
                </Col>

                <Col sm={6}>
                  <TextInput
                    onInputChange={(
                      e: React.ChangeEvent<
                        HTMLTextAreaElement | HTMLInputElement
                      >
                    ) => {
                      const re = /^[a-z A-Z : 0-9]*$/;
                      if (!e.target.value || re.test(e.target.value)) {
                        setSubTaskName(e.target.value);
                      }
                    }}
                    label="Sub Task Name"
                    asterisk="*"
                    containerClass="form-group"
                    type="text"
                    name="sub task name"
                    disabled={pageType === 'view'}
                    value={subTaskName}
                    maxlength={12}
                    placeholder="Enter sub task name"
                    errorMessage={validator.current.message(
                      "sub task name",
                      subTaskName,
                      "required"
                    )}
                    autoComplete="new-timeFrame"
                  />
                </Col>

                <Col sm={6}>
                  <DropdownInput
                    label="Designation"
                    asterisk="*"
                    placeholder="Select designation"
                    disabled={pageType === "view"}
                    options={designationArray}
                    defaultValue={designation}
                    onSelectHandler={(select: any) => {
                      setDesignation(select);
                    }}
                    isClearable={true}
                    errorMessage={validator.current.message(
                      "designation",
                      designation,
                      "required"
                    )}
                    id="designation"
                  />
                </Col>

                <Col sm={6}>
                  <DropdownInput
                    label="Select Employee"
                    asterisk="*"
                    placeholder="Select Employee"
                    disabled={pageType === "view"}
                    options={employeeArray}
                    defaultValue={selectEmployee}
                    onSelectHandler={(select: any) => {
                      setSelectEmployee(select);
                    }}
                    isClearable={true}
                    errorMessage={validator.current.message(
                      "selectEmployee",
                      selectEmployee,
                      "required"
                    )}
                    id="selectEmployee"
                  />
                </Col>

                <Col sm={6}>
                  <Datepicker
                    peekNextMonth={true}
                    showMonthDropdown={true}
                    showYearDropdown={true}
                    dropdownMode="select"
                    label="Due Date"
                    disabled={pageType == "view"}
                    className="form-control"
                    selected={dueDate}
                    value={dueDate}
                    asterisk="*"
                    placeholder="Enter due date"
                    dateFormat="dd/MM/yyyy"
                    onChange={(e: any) => {
                      setDueDate(e);
                    }}
                    maxDate={new Date()}
                    // onKeyDown={true}
                    // showYearDropdown={true}
                    // isClearable={dateOfBirth && true}
                    errorMessage={validator.current.message(
                      "Due Date",
                      dueDate,
                      "required"
                    )}
                  />
                </Col>

                <Col sm={6}>
                  <TextInput
                    onInputChange={(
                      e: React.ChangeEvent<
                        HTMLTextAreaElement | HTMLInputElement
                      >
                    ) => {
                      const re = /^[a-z A-Z : 0-9]*$/;
                      if (!e.target.value || re.test(e.target.value)) {
                        setDescription(e.target.value);
                      }
                    }}
                    label="Description"
                    asterisk="*"
                    containerClass="form-group"
                    type="text"
                    as="textarea"
                    name="description"
                    disabled={pageType == 'view'}
                    value={description}
                    maxlength={12}
                    placeholder="Enter description"
                    errorMessage={validator.current.message(
                      "description",
                      description,
                      "required"
                    )}
                    autoComplete="new-timeFrame"
                  />
                </Col>
              </Row>
              {pageType !== "view" && (
                <Row>
                  <Col>
                    <div style={{ float: "right" }} className="form-group mt-3">
                      <Button
                        type="submit"
                        variant="primary"
                        disabled={disableButton}
                      >
                        Submit
                      </Button>
                      <Button
                        onClick={(e) => {
                          e.preventDefault();
                          navigate(-1);
                        }}
                        variant="secondary"
                        className="ms-2"
                      >
                        Cancel
                      </Button>
                    </div>
                  </Col>
                </Row>
              )}
            </Form>
          </Card.Body>
        </Card>
      </section>
    </>
  );
};

export default AddNewSubTask;



{/* <Col sm={6}>
      <DropdownInput
        label="Financial Year"
        asterisk="*"
        placeholder="Select financial year"
        disabled={pageType == "view"}
        options={financialYearArray}
        defaultValue={selectedFinancialYear}
        onSelectHandler={(select: any) => {
        setSelectedFinancialYear(select);
        }}
        isClearable={true}
        errorMessage={validator.current.message(
        "Financial year",
        selectedFinancialYear,
        "required"
        )}
        id="financialYear"
      />
    </Col> 
*/}

{/* <Col sm={6}>
    <DropdownInput
      label="Services"
      placeholder="Select services"
      options={servicesValues}
      asterisk="*"
      disabled={pageType == "view"}
      defaultValue={services}
      onSelectHandler={(select: any) => {
      setServices(select);
      }}
      isClearable={true}
      errorMessage={validator.current.message(
      "Services",
      services,
      "required"
      )}
      id="Services"
    />                            
</Col> */}
{/* {
                  ![4, 5].includes(roleId) && (
                    <Col sm={6}>
                      <DropdownInput
                        label="Assign to"
                        asterisk="*"
                        placeholder="Select assign to"
                        options={employee}
                        disabled={pageType == "view"}
                        defaultValue={assignTo}
                        onSelectHandler={(select: any) => {
                          setAssignTo(select);
                        }}
                        isClearable={true}
                        errorMessage={validator.current.message(
                          "assign to",
                          assignTo,
                          `${roleId == 2 ? 'required' : ''}`
                        )}
                        id="assignTo"
                      />
                    </Col>
                  )
                }
                {
                  ![4, 5].includes(roleId) && (
                    <Col sm={6}>
                      <DropdownInput
                        label="Task Status"
                        asterisk="*"
                        placeholder="Select task status"
                        disabled={pageType == "view"}
                        options={Status}
                        defaultValue={taskStatus}
                        onSelectHandler={(select: any) => {
                          setTaskStatus(select);
                        }}
                        isClearable={true}
                        errorMessage={validator.current.message(
                          "Task status",
                          taskStatus,
                          `${roleId == 2 ? 'required' : ''}`
                        )}
                        id="gender"
                      />
                    </Col>
                  )
                } */}

{/* <Col sm={4}>
                <TextInput
                    onInputChange={(
                      e: React.ChangeEvent<
                        HTMLTextAreaElement | HTMLInputElement
                      >
                    ) => {
                      const re = /^[a-zA-Z ]*$/;
                      if (!e.target.value || re.test(e.target.value)) {
                        setApprovalStatus(e.target.value);
                      }
                    }}
                    label="Approval status"
                    asterisk="*"
                    containerClass="form-group"
                    type="text"
                    name="Approval status"
                    value={approvalStatus}
                    maxlength={12}
                    placeholder="Enter approval status"
                    errorMessage={validator.current.message(
                      "sub Task",
                      approvalStatus,
                      "required|alpha_space"
                    )}
                    autoComplete="new-first_name"
                  />
                </Col> */}