import React, { useEffect, useRef, useState } from "react";
import SimpleReactValidator from "simple-react-validator";
import { Button, Col, Row, Image, Accordion, Card } from "react-bootstrap";
import {
  TextInput,
  Datepicker,
  Helmet,
  DropdownInput,
} from "../../components/FormInputs";
import Breadcrumb from "../../components/Breadcrumb/breadcrumb.component";
import swal from "sweetalert";
import Api from "../../helper/api";
import AuthApi from "../../helper/authApi";
import customFunctions from "../../helper/customFunctions";
import Loader from "../../components/Loader";
import Table from "../../components/Table/simpletable.component";
import StatusModel from "./statusModel";
import PreviewPdfModel from "./previewPdfModel";
import UpdateInvoiceModel from "./updateInvoiceModel";
import SweetAlert from "react-bootstrap-sweetalert";
import AddInvoice from "./generateInvoiceForm";
import { useNavigate } from "react-router-dom";
import InvoicePreviewDetailsModel from "./invoicePreviewModel";
import PaymentDetailsModel from "./paymentDetailsModel";
import SelectAsyncPaginate from "../../components/ReactSelectPagination/SelectAsyncPaginate";

const InvoiceList = () => {
  const validator = useRef(new SimpleReactValidator());
  const navigate = useNavigate();

  const [title, setTitle] = useState<string>("Invoices List");
  const [label, setLabel] = useState<string>("Invoices List");
  const [invoiceData, setInvoiceData] = useState<any>();
  const [rows, setRows] = useState<any>([]);
  const [isShowLoader, setShowLoader] = useState<boolean>(false);
  const [updateStatus, setUpdateStatus] = useState<any>(false);
  const [updateInvoice, setUpdateInvoice] = useState<any>(false);
  const [previewStatus, setPreviewStatus] = useState<any>(false);
  const [invoiceId, setInvoiceId] = useState<any>();
  const [selectedInvoice, setSelectedInvoice] = useState<any>("");
  const [count, forceUpdate] = useState(0);
  const [applyToggle, setApplyToggle] = useState<any>(false);
  const [invoiceNumber, setSearchInvoiceNo] = useState<any>();
  const [invoiceDate, setInvoiceDate] = useState<any>();
  const [invoiceDueDate, setInvoiceDueDate] = useState<any>();
  const [date, setDate] = useState<any>();
  const [selectedClient, setSelectedClient] = useState<any>("");
  const [clientList, setClientList] = useState<any>("");
  const [invoiceStatus, setInvoiceStatus] = useState<any>("");
  const [searchKeyword, setSearchKeyword] = useState<any>("");
  const [selectedStatus, setSelectedStatus] = useState<any>("");
  const [selectedClientName, setSelectedClientName] = useState<any>("");
  const [filterErr, setFilterErr] = useState<any>("");
  const [typeClient, setClientType] = useState<any>("");
  const [selectedService, setSelectedService] = useState<any>();
  const [createPDF, setCreatePDF] = useState<boolean>(true);
  const [showPDF, setShowPDF] = useState<boolean>(false);
  const [sentEmail, setSentEmail] = useState<boolean>(false);
  const [editInvoice, setEditInvoice] = useState<boolean>(true);
  const [viewPdf, setViewPdf] = useState<any>();
  const [pdfInvoiceId, setPdfInvoiceId] = useState<any>();
  const [isDelete, setIsDelete] = useState<boolean>(false);
  const [childToggle, setChildToggle] = useState<boolean>(false);
  const [isSentInvoice, setIsSentInvoice] = useState<boolean>(false);
  const [isOpenPreviewModel, setIsOpenPreviewModel] = useState<boolean>(false);
  const [isPaymentDetails, setIsPaymentDetails] = useState<boolean>(false);
  const [selectedOldStatus, setOldStatus] = useState<any>("");
  const [adminAddress, setAdminAddress] = useState<any>();
  const [adminGSTState, setAdminGSTState] = useState<any>();
  const [isShowFilter, setisShowFilter] = useState<boolean>(true);

  const initialPagination = {
    currentPage: 1,
    itemsPerPage: 10,
    totalItems: 1,
  };
  const [pagination, setPagination] = useState(initialPagination);

  const items: any = [{ to: "/", label: "Dashboard" }, { label: label }];

  const columns: any = [
    {
      label: "Invoice No",
      id: "invoice_id",
    },
    {
      label: "Client Name",
      id: "client_name",
    },

    {
      label: "Invoice Date",
      id: "invoice_date",
    },
    {
      label: "Due Date",
      id: "due_date",
    },
    {
      label: "Total Amount",
      id: "total_amount",
    },
    {
      label: "Invoice Sent",
      id: "invoice_sent",
    },
    // {
    //   label: "Service",
    //   id: "services",
    // },
    // {
    //   label: "Total hours",
    //   id: "total_hours",
    // },
    {
      label: "Invoice Status",
      id: "invoice_status_name",
    },
    {
      label: "Payment Mode",
      id: "payment_mode",
    },

    {
      label: "Action",
      id: "action",
    },
  ];

  useEffect(() => {
    getInvoiceData(1, true);
    getAdminData();
  }, []);

  useEffect(() => {
    if (!showPDF) return;
  }, [showPDF]);
  const clientTypeArray: any = [
    {
      label: "Proprietor",
      value: 1,
    },
    {
      label: "Corporate",
      value: 2,
    },
    {
      label: "Non-Corporate",
      value: 3,
    },
  ];

  const getInvoiceData = async (page: any, clearFilter?: boolean) => {
    const pageNo: any = page || 1;
    setShowLoader(true);
    setApplyToggle(false);
    const endPoint: any = Api.getInvoiceList;

    setShowLoader(true);
    const params: any = {
      page: pageNo,
      limit: pagination.itemsPerPage,
      keyword:
        !clearFilter && String(selectedClientName)
          ? String(selectedClientName)
          : "",
      invoice_no: !clearFilter && invoiceNumber ? invoiceNumber : null,
      invoice_status: !clearFilter && invoiceStatus ? invoiceStatus.value : "",
      invoice_date:
        !clearFilter && invoiceDate ? customFunctions.getDate(invoiceDate) : "",
      due_date:
        !clearFilter && invoiceDueDate
          ? customFunctions.getDate(invoiceDueDate)
          : "",
      client_id: !clearFilter && selectedClient?.id ? selectedClient?.id : "",
      services_id: !clearFilter && selectedService ? selectedService.id : "",
      client_type: !clearFilter && typeClient ? typeClient.value : "",
      sort_direction: "descending",
    };

    // if (!params.invoice_status) {
    //   params.invoice_status = 2;
    // }

    const url: any = customFunctions.generateUrl(endPoint, params);
    const { data, message } = await AuthApi.getDataFromServer(url);

    if (data && data.success === true) {
      setShowLoader(false);
      if (data && data?.data && !data?.data?.length && clearFilter) {
        setisShowFilter(false);
      }

      setPagination({
        itemsPerPage: Number(10),
        currentPage: data.paginator.current_page,
        totalItems: data.paginator.total_count,
      });
      setInvoiceData(data.data);
    } else {
      setShowLoader(false);
      swal(
        typeof message == "string"
          ? message
          : message?.length
          ? message[0]
          : "Something went wrong!",
        "",
        "error"
      );
    }
  };

  const getAdminData = async () => {
    const { data, message } = await AuthApi.getDataFromServer(
      `${Api.profileUrl}`
    );

    if (data && data.success == true) {
      if (data && data?.data && data?.data?.company?.length) {
        data?.data?.company?.map((item: any) => {
          setAdminAddress(item?.address?.state_id);
          if (item?.gst_no) {
            const stateValue = item?.gst_no.split("").slice(0, 2).join("");
            setAdminGSTState(stateValue);
          }
        });
      }
    } else {
      swal(typeof message == "string" ? message : message[0], "", "error");
      return;
    }
  };

  const filterSearch = (e: any) => {
    e.preventDefault();
    let filterSearchErr: string = "";
    let isValid: boolean = true;
    if (
      customFunctions.checkIfEmpty(invoiceNumber) &&
      customFunctions.checkIfEmpty(invoiceStatus) &&
      customFunctions.checkIfEmpty(selectedClient)
    ) {
      filterSearchErr = "Please select any filter";
      setFilterErr(filterSearchErr);
      isValid = false;
    }
    if (isValid) {
      setFilterErr("");
      getInvoiceData(1, false);
    }
  };

  const onClear = (e: any) => {
    setClientType("");
    setDate("");
    setFilterErr("");
    setSearchKeyword("");
    setSelectedStatus("");
    setSelectedClient("");
    setSearchInvoiceNo("");
    setSelectedClientName("");
    setInvoiceStatus("");
    setIsDelete(false);

    if (invoiceNumber || selectedClient || invoiceStatus) {
      getInvoiceData(1, true);
    }
  };

  const getPdf = async () => {
    setShowLoader(true);

    const payload = {
      invoice_id: pdfInvoiceId,
    };

    const callback: any = AuthApi.postDataToServer;
    const { data, message } = await callback(Api.getPdfUrl, payload);

    if (data && data.success === true) {
      swal("PDF generated successfully!", "", "success").then(() => {
        setViewPdf(data.data.attachement);
        // setShowPDF(true);
        setShowLoader(false);
        window.location.reload();
        return;
      });
    } else {
      setShowLoader(false);
      swal(message, "", "error");
      window.location.reload();
    }
  };

  useEffect(() => {
    if (!pdfInvoiceId) return;
    getPdf();
  }, [pdfInvoiceId]);

  useEffect(() => {
    setTableData();
  }, [invoiceData]);

  useEffect(() => {
    if (!invoiceId && !isDelete) return;
    // deleteInvoice();
  }, [isDelete]);

  const deleteInvoice = async (id: any) => {
    setShowLoader(true);

    const callback: any = AuthApi.deleteDataFromServer;
    let url = `${Api.generatePdfUrl}${id}/`;
    const { data, message } = await callback(url);

    if (data && data.success === true) {
      swal("Invoice deleted successfully!", "", "success").then(() => {
        setShowLoader(false);
        window.location.reload();
        return;
      });
    } else {
      setShowLoader(false);
      swal(typeof message == "string" ? message : message[0], "", "error");
    }
  };

  const setTableData = async () => {
    let toggle: boolean = false;
    const rowData: any = [];
    invoiceData &&
      invoiceData.map((item: any, index: any) => {
        rowData.push({
          index:
            (pagination.currentPage - 1) * pagination.itemsPerPage +
            (index + 1),
          invoice_id: <>{item?.invoice_no}</>,
          client_name:
            localStorage.getItem("admin_access") == "true" ? (
              <a
                href="javascript:void(0)"
                title={"View Dashboard"}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  navigate("/client-dashboard", {
                    state: {
                      fromComponent: "clientList",
                      clientId: item?.client?.id,
                      data: item,
                    },
                  });
                }}
              >
                {item?.client?.client_name}
              </a>
            ) : (
              item?.client?.client_name
            ),
          email: item?.client?.email,
          invoice_sent: item?.is_sent ? (
            <center>Yes</center>
          ) : (
            <center>No</center>
          ),
          invoice_date: item?.invoice_date ? (
            customFunctions.getFormattedDate(new Date(item.invoice_date))
          ) : (
            <center>-</center>
          ),
          due_date: item?.due_date ? (
            customFunctions.getFormattedDate(new Date(item.due_date))
          ) : (
            <center>-</center>
          ),
          total_amount: <center>{item?.total_amount}</center>,
          editInvoice: true,
          createPDF: true,
          showPDF: item?.file_name ? true : false,
          sentEmail: false,
          invoice_status_name:
            item.invoice_status_name === "Closed" ? (
              <span>
                <center>{item.invoice_status_name}</center>
              </span>
            ) : (
              <>
                <a
                  href="javascript:void(0)"
                  className=""
                  title="Click here to update invoice status."
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setUpdateStatus(true);
                    setInvoiceId(item.id);
                    setSelectedInvoice(item);
                    setOldStatus({
                      label: item?.invoice_status_name,
                      value: item?.invoice_status,
                    });
                  }}
                >
                  <center>{item.invoice_status_name}</center>
                </a>
              </>
            ),
          payment_mode: item.is_online ? (
            <center title={item.is_online ? "View Details" : ""}>
              <a
                href="javascript:void(0)"
                onClick={() => {
                  setIsPaymentDetails(true);
                  setSelectedInvoice(item);
                }}
              >
                Online
              </a>
            </center>
          ) : item?.is_check ? (
            <center title={item.is_check ? "View Details" : ""}>
              <a
                href="javascript:void(0)"
                onClick={() => {
                  setIsPaymentDetails(true);
                  setSelectedInvoice(item);
                }}
              >
                Check
              </a>
            </center>
          ) : (
            <>
              <span>
                {item.is_cash ? <center>Cash</center> : <center>NA</center>}
              </span>
            </>
          ),
          action: (
            <>
              {item.invoice_status == 2 || item.invoice_status == 3 ? (
                <>
                  <a
                    href="javascript:void(0)"
                    style={{ color: "#009D9A", cursor: "pointer" }}
                    title={"Edit Invoice"}
                    className="underline"
                    onClick={(e: any) => {
                      e.preventDefault();
                      e.stopPropagation();
                      // setUpdateInvoice(true);
                      setInvoiceId(item.id);
                      navigate("/edit-invoice", {
                        state: {
                          updateInvoiceId: item.id,
                          data: item,
                          adminAddress: adminAddress,
                          adminGSTState: adminGSTState,
                        },
                      });
                    }}
                  >
                    {/* <Image src="images/edit-button.svg" alt="view" /> */}
                    Edit
                  </a>
                  <br />
                </>
              ) : (
                ""
              )}
              {
                // <i
                //   className="fa-solid fa-trash"
                //   title="Delete Invoice"
                //   style={{ color: "maroon", cursor: "pointer", height: "25px" }}
                //   onClick={(e: any) => {
                //     e.preventDefault();
                //     e.stopPropagation();
                //     setInvoiceId(item.id);
                //     // setIsDelete(true);
                //     setChildToggle(true);
                //     // setPreviewStatus(true);
                //   }}
                // ></i>

                <>
                  <a
                    href="javascript:void(0)"
                    style={{ color: "#009D9A", cursor: "pointer" }}
                    title={"Delete Invoice"}
                    className="underline"
                    onClick={(e: any) => {
                      e.preventDefault();
                      e.stopPropagation();
                      setInvoiceId(item.id);
                      // setIsDelete(true);
                      setChildToggle(true);
                      // setPreviewStatus(true);
                    }}
                  >
                    {/* <Image src="images/INVOICE.svg" alt="view" /> */}
                    Delete
                  </a>
                  <br />
                </>
              }
              {item && item.file_name && (
                <>
                  <a
                    href="javascript:void(0)"
                    className="underline"
                    style={{ color: "#009D9A", cursor: "pointer" }}
                    title={"Preview"}
                    onClick={(e: any) => {
                      e.preventDefault();
                      e.stopPropagation();
                      // setPreviewStatus(true);
                      setInvoiceId(item.id);
                      if (item?.file_name) {
                        window.open(item?.file_name);
                      }
                    }}
                  >
                    {/* <Image src="images/eye.svg" alt="view" /> */}
                    Preview
                  </a>
                  <br />
                </>
              )}
              {!createPDF && (
                <i
                  className=" fa-solid fa-file-pdf"
                  style={{ color: "#009D9A", cursor: "pointer" }}
                  onClick={(e: any) => {
                    e.preventDefault();
                    e.stopPropagation();
                    // setInvoiceId(item.id);
                    setPdfInvoiceId(item.id);
                    setShowPDF(item.showPDF);
                    // setShowStatus(index)
                    // setPreviewStatus(true);
                  }}
                ></i>
              )}
              {item && item?.invoice_status != 4 ? (
                <>
                  <a
                    href="javascript:void(0)"
                    style={{ color: "#009D9A", cursor: "pointer" }}
                    title={"Send Invoice To Client"}
                    className="underline"
                    onClick={(e: any) => {
                      e.preventDefault();
                      e.stopPropagation();
                      setIsSentInvoice(true);
                      setInvoiceId(item.id);
                      setSelectedInvoice(item);
                    }}
                  >
                    {/* <Image src="images/INVOICE.svg" alt="view" /> */}
                    Send To Client
                  </a>
                  <br />
                </>
              ) : null}
              &nbsp;
            </>
          ),
        });
      });
    setRows(rowData);
    forceUpdate(count + 1);
  };

  const sentInvoice = async (id: any) => {
    setShowLoader(true);
    const postData: any = {
      invoice_id: id,
    };

    const endPoint = `${Api.sentInvoiceEmail}`;
    const callback: any = AuthApi.postDataToServer;

    const { data, message } = await callback(endPoint, postData);

    if (data && data.success === true) {
      setShowLoader(false);
      swal("Invoice sent on email successfully!", "", "success").then(() => {
        getInvoiceData(1, true);
      });
    } else {
      setShowLoader(false);
      swal(typeof message == "string" ? message : message[0], "", "error");
    }
  };

  const invoiceStatusArray: any = [
    // {
    //   label: "Draft",
    //   value: "1",
    // },
    {
      label: "Open",
      value: "2",
    },
    // {
    //   label: "Publish",
    //   value: "3",
    // },
    {
      label: "Closed",
      value: "4",
    },
    {
      label: "Overdue",
      value: "3",
    },
  ];

  useEffect(() => {
    // getClientName();
  }, []);

  const getClientName = async () => {
    setShowLoader(true);

    let url = `${Api.clientUrl}?sort_direction=descending&limit=1000`;
    const { data, message } = await AuthApi.getDataFromServer(url);
    if (data && data.success) {
      setShowLoader(false);

      const empData: any = [];
      data &&
        data.data &&
        data.data.map((dt: any) => {
          empData.push({
            label: dt.client_name,
            value: dt.id,
          });
        });
      setClientList(empData);
    } else {
      setClientList([]);
      setShowLoader(false);
      swal(typeof message == "string" ? message : message[0], "", "error");
    }
  };

  return (
    <>
      <Helmet title={title} />
      <div className="page-header">
        <div className="title-breadcrumb-section">
          <h2 className="main-content-title tx-24 mg-b-5">{title}</h2>
          <Breadcrumb items={items} />
        </div>
      </div>
      <Loader showLoader={isShowLoader} needFullPage={false} />

      <section className="content-area">
        {isShowFilter && (
          <Accordion defaultActiveKey="1">
            <Accordion.Item eventKey="1">
              <Accordion.Header>
                <h5 className="card-title mb-0">Filters</h5>
              </Accordion.Header>
              <Accordion.Body>
                {filterErr && (
                  <div className="p-3  bg-danger text-white bg-danger-main table-red-bg mb-3">
                    <div className="img-text">
                      <img
                        style={{ cursor: "pointer" }}
                        onClick={(e) => onClear(e)}
                        src="/images/fail.svg"
                        alt="Success"
                      />{" "}
                      {filterErr}
                    </div>
                  </div>
                )}

                <Row>
                  <Col sm="3">
                    <TextInput
                      className="form-control"
                      value={invoiceNumber}
                      placeholder="Search by Invoice No"
                      onInputChange={(e: any) => {
                        setSearchInvoiceNo(e.target.value);
                      }}
                    />
                  </Col>
                  <Col sm="3">
                    <SelectAsyncPaginate
                      id={"Client"}
                      selectPlaceholder={"Select client"}
                      apiname={"clientUrl"}
                      endpoint={Api.clientUrl}
                      value={selectedClient}
                      onSelectChange={(select: any) => {
                        setSelectedClient(select);
                      }}
                    />
                  </Col>
                  <Col sm="3">
                    <DropdownInput
                      placeholder="Select invoice status"
                      defaultValue={invoiceStatus}
                      onSelectHandler={(select: any) => {
                        setInvoiceStatus(select);
                      }}
                      options={invoiceStatusArray}
                    />
                  </Col>
                  <Col sm={3}>
                    <div className="filter-btn pull-right filter-btn-report">
                      <Button
                        id="submit-btn"
                        variant="primary"
                        type="submit"
                        className="ms-4"
                        onClick={(e) => {
                          e.preventDefault();
                          filterSearch(e);
                          setApplyToggle(true);
                        }}
                      >
                        Apply
                      </Button>
                      <Button
                        id="clear-btn"
                        variant="secondary"
                        type="button"
                        className="ms-4"
                        onClick={(e) => onClear(e)}
                      >
                        Clear
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        )}
        <Card className="mt-3">
          <Card.Header>
            <div className="d-flex align-items-center justify-content-end">
              <a
                id="add-client-btn-link"
                href="#"
                className="underline"
                onClick={(e) => {
                  e.preventDefault();
                  navigate("/create-invoice", {
                    state: { pageType: "add" },
                  });
                }}
              >
                New Invoice
              </a>
            </div>
          </Card.Header>
          <Card.Body>
            <Table
              columns={columns}
              showCheckbox={false}
              rows={rows}
              showPagination
              paginationProps={{
                ...pagination,
                currentPage: Number(pagination.currentPage),
                onPageChange: (pageNo: any) => {
                  getInvoiceData(pageNo, true);
                },
              }}
              dropdownContainerClass="mb-3"
              emptyRender={() => (
                <p className="text-center mb-0">No record found</p>
              )}
            />

            {updateStatus && (
              <StatusModel
                show={updateStatus}
                invoiceId={invoiceId}
                selectedOldStatus={selectedOldStatus}
                selectedInvoice={selectedInvoice}
                popupClose={() => setUpdateStatus(!updateStatus)}
                callBack={() => {
                  setTableData();
                  getInvoiceData(1, true);
                }}
              />
            )}
            {childToggle && (
              <div className="sweetalert">
                <SweetAlert
                  title={"Are you sure? do you want to delete invoice?"}
                  warning
                  showCancel
                  confirmBtnBsStyle="success"
                  cancelBtnBsStyle="danger"
                  onConfirm={() => {
                    // setInvoiceId(item.id);
                    setIsDelete(true);
                    setChildToggle(false);
                    // setIsOkay(true)
                    deleteInvoice(invoiceId);
                  }}
                  onCancel={() => {
                    // setCancelBtn(true);
                    setIsDelete(false);
                    setChildToggle(false);
                  }}
                />
              </div>
            )}
            {isSentInvoice && (
              <div className="sweetalert">
                <SweetAlert
                  title={"Are you sure? do you want to send invoice to client?"}
                  warning
                  showCancel
                  confirmBtnBsStyle="success"
                  cancelBtnBsStyle="danger"
                  onConfirm={() => {
                    setIsSentInvoice(false);
                    // setIsOpenPreviewModel(true);
                    sentInvoice(invoiceId);
                  }}
                  onCancel={() => {
                    setIsSentInvoice(false);
                    // setIsOpenPreviewModel(false);
                  }}
                />
              </div>
            )}
            {updateInvoice && (
              <AddInvoice
                show={updateInvoice}
                updateInvoiceId={invoiceId}
                updatePopupClose={() => setUpdateInvoice(!updateInvoice)}
                callBack={() => {
                  setTableData();
                  getInvoiceData(1, true);
                }}
              />
            )}

            {previewStatus && (
              <PreviewPdfModel
                invoiceId={invoiceId}
                previewPopUpClose={() => {
                  setPreviewStatus(!previewStatus);
                  window.location.reload();
                }}
              />
            )}

            {isOpenPreviewModel && (
              <InvoicePreviewDetailsModel
                invoiceId={invoiceId}
                selectedInvoice={selectedInvoice}
                popupClose={() => {
                  setIsOpenPreviewModel(!isOpenPreviewModel);
                }}
                callBack={() => {
                  getInvoiceData(1, true);
                }}
              />
            )}

            {isPaymentDetails && (
              <PaymentDetailsModel
                show={isPaymentDetails}
                details={selectedInvoice}
                popupClose={() => {
                  setIsPaymentDetails(!isPaymentDetails);
                }}
              />
            )}
          </Card.Body>
        </Card>
      </section>
    </>
  );
};

export default InvoiceList;
