import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";
import SimpleReactValidator from "simple-react-validator";
import { TextInput, Helmet } from "../../components/FormInputs";
import { Row, Col, Button, Form, Card, Image } from "react-bootstrap";
// import { uploadFile } from "react-s3";
import AuthApi from "../../helper/authApi";
import Api from "../../helper/api";
import customFunctions from "../../helper/customFunctions";
import Loader from "../../components/Loader/index";
import Breadcrumb from "../../components/Breadcrumb/breadcrumb.component";
// window.Buffer = window.Buffer || require("buffer").Buffer;
import SharedService from "../../services/SharedService/sharedServices";

const validator = new SimpleReactValidator();

const Profile = () => {
  const navigate = useNavigate();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [userMobile, setUserMobile] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [isShowLoader, setShowLoader] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const [count, forceUpdate] = useState(0);
  const [title, setTitle] = useState("");
  const [currentUserId, setCurrentUserId] = useState<Number>();
  const [selectRole, setSelectRole] = useState(null);
  const [userRole, setUserRole] = useState("");
  const [roleId, setRoleId] = useState<number>(0);
  const [imageFile, setImageFile] = useState(null);
  const [imageUrl, setImageUrl] = useState("");
  const [fileName, setFileName] = useState("");
  const [staffRole, setStaffRole] = useState("");
  const [location, setLocation] = useState("");
  const [staffLocation, setStaffLocation] = useState("");
  const [profiletToggle, setProfiletToggle] = useState(false);

  const items = [{ to: "/", label: "Dashboard" }, { label: "Profile" }];

  useEffect(() => {
    setTitle("Profile");
  }, []);

  useEffect(() => {
    const role = customFunctions.getLocalStorage("role_id");
    setRoleId(Number(role));
    const currentUserId = customFunctions.getLocalStorage("id");
    setCurrentUserId(Number(currentUserId));
  }, []);

  useEffect(() => {
    getUser();
  }, [currentUserId]);

  const getUser = async () => {
    if (!currentUserId) return;
    setShowLoader(true);
    const { data, message } = await AuthApi.getDataFromServer(
      `${Api.profileUrl}?id=${currentUserId}`
    );
    if (data && data.success === true) {
      setStaffLocation(data?.data?.parent_city?.name || "");
      if (data && data.data && data.data.response && data.data.response) {
        let profileData = data.data.response[0];
        await customFunctions.setLocalStorage(
          "userName",
          `${customFunctions.capitalizeFirstLetter(
            profileData.first_name
          )} ${customFunctions.capitalizeFirstLetter(profileData.last_name)}`
        );

        setFirstName(profileData.first_name);
        setLastName(profileData.last_name);
        // setSelectRole( profileData.role.name);
        setUserRole(profileData?.role?.label || "");
        setLocation(profileData?.citie?.name || "");
        setUserEmail(profileData.email);
        setUserMobile(profileData.mobile);
        setStaffRole(profileData?.staff_role?.name || "");
        setImageUrl(profileData?.profile_picture);
        setShowLoader(false);
      }
    } else {
      setShowLoader(false);
      swal(message || "Something went wrong", "", "error");
    }
  };

  interface postData {
    id: Number,
    first_name: string,
    last_name: string,
    mobile: Number,
    email: string,
    designation?: string
  }

  const addUser = async () => {
    setShowLoader(true);
    const postData: postData = {
      id: Number(currentUserId),
      first_name: firstName,
      last_name: lastName,
      mobile: Number(userMobile),
      email: userEmail.trim(),
    };

    // if (imageUrl) {
    //   const imgUrl = await uploadImage();
    //   if (imgUrl) postData.profile_picture = imgUrl;
    // }

    if (currentUserId == 1) {
      delete postData.designation;
    }
    const api = `${Api.usersUrl}`;
    const url = customFunctions.generateUrl(api);
    const callback = AuthApi.putDataToServer;
    const { data, message } = await callback(url, postData);
    if (data && data.success === true) {
      await customFunctions.setLocalStorage(
        "userName",
        `${customFunctions.capitalizeFirstLetter(
          firstName
        )} ${customFunctions.capitalizeFirstLetter(lastName)}`
      );

      setShowLoader(false);
      swal("Profile updated successfully!", "", "success").then(() => {
        SharedService.profileImageChange.next(true)
        setDisableButton(false);
        validator.hideMessages();
        navigate("/");
      });
    } else {
      setDisableButton(false);
      setShowLoader(false);
      swal(message, "", "error");
    }
  };

  const onSubmit = (e: any) => {
    e.preventDefault();

    if (disableButton) return;

    setDisableButton(true);
    if (validator.allValid()) {
      addUser();
    } else {
      validator.showMessages();
      forceUpdate(count + 1);
      setDisableButton(false);
    }
  };

  const handelImageChange = (e: any) => {
    let fileName = e.target.files[0].name;
    setFileName(fileName);
    if (!e.target.files || e.target.files.length < 1) return;
    const fileUrl = URL.createObjectURL(e.target.files[0]);
    setImageFile(e.target.files[0]);
    setImageUrl(fileUrl);
  };

  validator.purgeFields();

  return (
    <>
      <Helmet title={title} />
      <div className="page-header">
        <div className="title-breadcrumb-section">
          <h2 className="main-content-title tx-24 mg-b-5">{title}</h2>
          <Breadcrumb items={items} />
        </div>
      </div>
      <Loader showLoader={isShowLoader} needFullPage={false} />
      <section className="content-area">
        <Card>
          <Card.Body>
            <Form
              autoComplete="off"
              onSubmit={(e) => {
                onSubmit(e);
              }}
            >
              <div className="d-flex justify-content-between">
                <div>
                  <h5>My Profile</h5>
                </div>
                <div className="float-end" >
                  {
                    !profiletToggle && (
                      <Button onClick={() => { setProfiletToggle(true) }}>Edit Profile</Button>
                    )
                  }
                </div>
              </div>

              <Row className="mt-5">
                <Col sm={2}>
                  <Row>
                    <div className="text-center m-b-10">
                      <div className="position-relative">
                        <input
                          onChange={handelImageChange}
                          title={profiletToggle ? `Edit Profile Picture` : ''}
                          className="profile-file"
                          type="file"
                          accept="image/png, image/jpeg,image/jpg, "
                          disabled={!profiletToggle}
                        />
                        <img
                          className={profiletToggle ? "profilepic" : 'static-profilepic'}
                          src={
                            imageUrl
                              ? imageUrl
                              : '/images/user.png'
                          }
                          alt=""
                          width={100}
                          height={100}
                        />
                        {
                          profiletToggle && (
                            <span
                              className={"profile-pen"}
                              style={{ left: '80%' }}
                            >
                              <Image src="images/edit-pen.svg" height={'15px'} width={'14px'} />
                            </span>

                          )
                        }
                      </div>
                    </div>
                  </Row>
                </Col>
                <Col>
                  <>
                    <Row>
                      <Col sm={6} className="">
                        <TextInput
                          onInputChange={(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                            const re = /^[a-zA-Z ]*$/;
                            if (!e.target.value || re.test(e.target.value)) {
                              setFirstName(e.target.value);
                            }
                          }}
                          disabled={!profiletToggle}
                          label="First Name"
                          asterisk="*"
                          containerClass="form-group"
                          type="text"
                          name="firstName"
                          value={firstName}
                          placeholder="Enter first name"
                          errorMessage={validator.message(
                            "First Name",
                            firstName,
                            "required"
                          )}
                          autocomplete="new-firstName"
                        />
                      </Col>
                      <Col sm={6} className="">
                        <TextInput
                          onInputChange={(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                            setLastName(e.target.value);
                          }}
                          label="Last Name"
                          asterisk="*"
                          disabled={!profiletToggle}
                          containerClass="form-group"
                          type="text"
                          name="lastName"
                          value={lastName}
                          placeholder="Enter last name"
                          errorMessage={validator.message(
                            "Last Name",
                            lastName,
                            "required"
                          )}
                          autocomplete="new-firstName"
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={6} className="">
                        <TextInput
                          onInputChange={(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                            setUserEmail(e.target.value);
                          }}
                          label="Email"
                          asterisk="*"
                          containerClass="form-group"
                          type="text"
                          name="userEmail"
                          disabled
                          value={userEmail}
                          placeholder="Enter user email"
                          errorMessage={validator.message(
                            "User email",
                            userEmail,
                            "required|email"
                          )}
                        />
                      </Col>
                      <Col sm={6}>
                        <TextInput
                          onInputChange={(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                            const newValue = customFunctions.numberMasking(
                              e.target.value
                            );
                            setUserMobile(newValue);
                          }}
                          disabled={!profiletToggle}
                          label="Mobile Number"
                          asterisk="*"
                          containerClass="form-group"
                          type="text"
                          name="userMobile"
                          value={
                            userMobile &&
                            customFunctions.formatPhoneNumber(userMobile)
                          }
                          maxlength={14}
                          placeholder="Enter mobile number"
                          errorMessage={validator.message(
                            "Mobile number",
                            userMobile,
                            "required|regex:^\\(?(\\d{3})\\)?[- ]?(\\d{3})[- ]?(\\d{4})$",
                            {
                              messages: {
                                required: "The mobile number field is required",
                                regex: "Invalid mobile number number",
                              },
                            }
                          )}
                        />
                      </Col>
                      <Col sm={6}>
                        <TextInput
                          // onInputChange={(e) => {
                          //   const re = /^[a-zA-Z ]*$/;
                          //   if (!e.target.value || re.test(e.target.value)) {
                          //     setFirstName(e.target.value);
                          //   }
                          // }}
                          // asterisk="*"
                          disabled
                          label="Role"
                          containerClass="form-group"
                          autoComplete="firstname"
                          value={[1, 3, 5, 7].includes(roleId) ? userRole : staffRole}
                        />
                      </Col>
                      {[3, 4, 5, 6].includes(roleId) ? (
                        <Col sm={6}>
                          <TextInput
                            disabled={[3, 4, 5, 6].includes(roleId)}
                            label="Location"
                            containerClass="form-group"
                            value={[3, 5].includes(roleId) ? location : staffLocation}
                          />
                        </Col>
                      ) : null}
                    </Row>
                    <Row>
                      {
                        profiletToggle && (
                          <Col>
                            <div style={{ float: "right" }} className="form-group mt-3">
                              <Button
                                type="submit"
                                variant="primary"
                                disabled={disableButton}
                              >
                                Submit
                              </Button>
                              <Button
                                onClick={(e) => {
                                  e.preventDefault();
                                  setProfiletToggle(false);
                                }}
                                variant="secondary"
                                className="ms-2"
                              >
                                Cancel
                              </Button>
                            </div>
                          </Col>
                        )
                      }
                    </Row>
                  </>
                </Col>
              </Row>
            </Form>
          </Card.Body>
        </Card>
      </section>
    </>
  );
};

export default Profile;
