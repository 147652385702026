export const serviceStatus = [
  {
    label: "Every month",
    value: 1,
  },
  {
    label: "Once in year",
    value: 2,
  },
  // {
  //   label: "Non-repeated",
  //   value: 3,
  // },
];

export const dateOptions = [
  {
    label: "1",
    value: "01",
  },
  {
    label: "2",
    value: "02",
  },
  {
    label: "3",
    value: "03",
  },
  {
    label: "4",
    value: "04",
  },
  {
    label: "5",
    value: "05",
  },
  {
    label: "6",
    value: "06",
  },
  {
    label: "7",
    value: "07",
  },
  {
    label: "8",
    value: "08",
  },
  {
    label: "9",
    value: "09",
  },
  {
    label: "10",
    value: "10",
  },
  {
    label: "11",
    value: "11",
  },
  {
    label: "12",
    value: "12",
  },
  {
    label: "13",
    value: "13",
  },
  {
    label: "14",
    value: "14",
  },
  {
    label: "15",
    value: "15",
  },
  {
    label: "16",
    value: "16",
  },
  {
    label: "17",
    value: "17",
  },
  {
    label: "18",
    value: "18",
  },
  {
    label: "19",
    value: "19",
  },
  {
    label: "20",
    value: "20",
  },
  {
    label: "21",
    value: "21",
  },
  {
    label: "22",
    value: "22",
  },
  {
    label: "23",
    value: "23",
  },
  {
    label: "24",
    value: "24",
  },
  {
    label: "25",
    value: "25",
  },
  {
    label: "26",
    value: "26",
  },
  {
    label: "27",
    value: "27",
  },
  {
    label: "28",
    value: "28",
  },
  // {
  //   label: "29",
  //   value: "29",
  // },
  // {
  //   label: "30",
  //   value: "30",
  // },
  // {
  //   label: "31",
  //   value: "31",
  // },
];

export const monthsOptions = [
  {
    label: "January",
    value: "January",
  },
  {
    label: "February",
    value: "February",
  },
  {
    label: "March",
    value: "March",
  },
  {
    label: "April",
    value: "April",
  },
  {
    label: "May",
    value: "May",
  },
  {
    label: "June",
    value: "June",
  },
  {
    label: "July",
    value: "July",
  },
  {
    label: "August",
    value: "August",
  },
  {
    label: "September",
    value: "September",
  },
  {
    label: "October",
    value: "October",
  },
  {
    label: "November",
    value: "November",
  },
  {
    label: "December",
    value: "December",
  },
];
