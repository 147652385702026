import React, { useEffect, useRef, useState } from "react";
import swal from "sweetalert";
import Api from "../../helper/api";
import AuthApi from "../../helper/authApi";



const PreviewPdfModel = (props:any) => {

    const {invoice, invoiceId} = props;
    const [isShowLoader, setShowLoader] = useState<boolean>(false);
    const [invoiceData, setInvoiceData] = useState<any>();
    
    useEffect(()=>{
        getInvoiceData();
    },[invoiceId])

    const getInvoiceData = async () => {
        setShowLoader(true);
        const { data, message } = await AuthApi.getDataFromServer(
          `${Api.getInvoiceList}${invoiceId}/`
        );
        if (data && data.success === true) {
          setShowLoader(false);
          
          setInvoiceData(data.data)
          imageNewTab(data.data)
        } else {
          setShowLoader(false);
          swal(typeof message == "string" ? message : message[0], "", "error");
        }
      };
    
    const imageNewTab = (item: any) => {
      setShowLoader(true);
      let newWindow = null;
      
      if(item.file_name != null){
        setShowLoader(false);
        newWindow = window.open(item.file_name);
        
        if (newWindow) {
            props.previewPopUpClose();
          };
      }
      else {
        setShowLoader(false);
        swal("File is not present, please contact admin", "", "error");
      }
    };

    return (
     <>
     </>
    )
}

export default PreviewPdfModel;

