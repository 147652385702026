import React, { useEffect, useState } from "react";
import RejectTaskList from "./rejectTaskList";
import ApproveTaskList from "./approveTaskList";
import Breadcrumb from "../../components/Breadcrumb/breadcrumb.component";

import {
  Card,
  Form,
  Col,
  Row,
  Button,
  Accordion,
  Tab,
  Tabs,
} from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
// import AllTaskList from "./AllTaskList";

const EmployeeTaskReport = (props: any) => {
  const state = useLocation();
  const [title, setTitle] = useState<any>("Task List");
  const [defaultActiveTab, setDefaultActiveTab] = useState<any>("reject-task");
  const comp = props?.fromComponent;

  const items = [
    { to: "/", label: "Dashboard" },
    // { to: "/employee-profile", label: "Employee" },
    { label: "Task List" },
  ];

useEffect(()=>{
if(state?.state?.fromComponent == "dashboard"){
  setDefaultActiveTab("reject-task")
}
},[state?.state?.fromComponent]);

  return (
    <>
      {!comp ? (
        <>
          <div className="page-header">
            <div className="title-breadcrumb-section">
              <h2 className="main-content-title tx-24 mg-b-5">{title}</h2>
              <Breadcrumb items={items} />
            </div>
          </div>
          <div className="tabs-wrapper">
            <Tabs
              activeKey={defaultActiveTab}
              id="uncontrolled-tab-example"
              className="mb-3"
              onClick={(e:any)=>{
                if(e.target.textContent == "All Task"){
                  setDefaultActiveTab("all-task")
                }
                else if(e.target.textContent == "Rejected Task"){
                  setDefaultActiveTab("reject-task")
                }
                else if(e.target.textContent == "Approved Task"){
                  setDefaultActiveTab("approved-task")
                }
              }}
            >
              <Tab eventKey="reject-task" title="Rejected Task">
                <RejectTaskList isRender={true}/>
              </Tab>
              <Tab eventKey="approved-task" title="Approved Task">
                <ApproveTaskList isRender={true}/>
              </Tab>
            </Tabs>
          </div>
        </>
      ) : (
        <RejectTaskList />
      )}
    </>
  );
};

export default EmployeeTaskReport;
