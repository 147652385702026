import { Modal, Button, Card, Col, Row } from "react-bootstrap";

import { useEffect, useState, useRef } from "react";
import Api from "../../helper/api";
import AuthApi from "../../helper/authApi";
import Loader from "../../components/Loader";
import {
  Datepicker,
  DropdownInput,
  TextInput,
  Helmet
} from "../../components/FormInputs";
import swal from "sweetalert";
import SimpleReactValidator from "simple-react-validator";
import customFunctions from "../../helper/customFunctions";
import Breadcrumb from "../../components/Breadcrumb/breadcrumb.component";
import { async } from "rxjs";


const LineItemsModel = (props: any) => {
  const validator = useRef(new SimpleReactValidator());
  const [isShowLoader, setShowLoader] = useState<boolean>(false);
  const { show, invoiceId } = props;
  
  const [amount, setAmount] = useState<any>();
  const [selectedLineItems,setSelectedLineItems] = useState<any>();
  const [count, forceUpdate] = useState<number>(0);
  const [alineItemsArray,setLineItemsArray] = useState<any>();

  //Titles
  const [title, setTitle] = useState<string>("Add in line items");
  const [label, setLabel] = useState<string>("In line items");
  const items: any = [{ to: "/", label: "Dashboard" }, { label: label }];
  const [lineItemsUpdateArray, setLineItemsUpdateArray] = useState<any>(
    []
  );
  

  const lineItemsArray: any = [
    {
      label: "Checking all bills",
      value: 1,
    },
    {
      label: "Testing with AI",
      value: 2,
    },
  ]
  const statusArray: any = [
    {
      label: "Draft",
      value: 1,
    },
    {
      label: "Pending",
      value: 2,
    },
    {
      label: "Published",
      value: 3,
    },
    {
      label: "Completed",
      value: 4,
    },
  ];

  useEffect(()=>{
    if (!show)
    return
    getLineItems();
  },[show])

  const getLineItems = async () => {
    setShowLoader(true);

    const callback: any = AuthApi.getDataFromServer;
    let url = `${Api.lineItems}`;
    
    const { data, message } = await callback(url);
    if (data && data.success === true) {
      setShowLoader(false);
      let lineItemArr: any = [];

      data &&
        data.data &&
        data.data.map((item: any) => {
          lineItemArr.push({
            label: item?.name,
            value: item?.id,
          });
        });
        setLineItemsUpdateArray(lineItemArr);
    } else {
      setShowLoader(false);
      swal(typeof message == "string" ? message : message[0], "", "error");
    }
  };
  
  const handleSubmit = async(event: React.FormEvent) => {
      let status = true
      if (status && status === true) {
        props.updatePopupClose(amount,selectedLineItems);
        swal("Line items save successfully!", "", "success").then(() => {
          props.callBack();
          return;
        });
      } else {
        swal("Please referesh the page.", "", "error");
      }
  };

  const isClear = () => {
    setAmount("");
    setSelectedLineItems("");
  };

  // for validations
  const allValid = (e: any) => {
    e.preventDefault();
    if (validator.current.allValid()) {
      handleSubmit(e);
    } else {
      validator.current.showMessages();
      forceUpdate(count + 1);
    }
  };

  return (
    <>
      <Loader showLoader={isShowLoader} needFullPage={false} />

      <Modal
        className={""}
        centered
        onHide={props.updatePopupClose}
        show={show}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <h5>{"Status"}</h5>
        </Modal.Header>
        <Modal.Body>
          <Row>
              <Col sm={6}>
                  <DropdownInput
                    asterisk="*"
                    className="d-flex justify-content-around"
                    label="Line items"
                    defaultValue={selectedLineItems}
                    onSelectHandler={(select: any) => {
                        setSelectedLineItems(select);
                        // handleDropdownChange(select, index, 1)  
                    }}
                    options={lineItemsUpdateArray}
                    errorMessage={validator.current.message(
                      "Line items",
                      selectedLineItems,
                      "required"
                    )}
                  />
                </Col>
                <Col sm={6}>
                  <TextInput
                    asterisk="*"
                    label="Amount"
                    value={amount}
                    placeholder="0"
                    onInputChange={(e: any) => {
                      const re = /^[0-9]+(\\.[0-9]+)?[%]?/;
                      if (!e.target.value || re.test(e.target.value)) {
                        e.preventDefault();
                        setAmount(e.target.value);
                        // formData.amount = Number(e.target.value);
                      }
                    }}
                    errorMessage={validator.current.message(
                      "Amount value",
                      amount,
                      "required"
                    )}
                  />
                </Col>
              </Row>{" "}
                &nbsp;&nbsp;
                <hr />
              <div className="d-flex flex-row">
                <Button
                  variant="primary"
                  onClick={(e:any) => {
                    // handleSubmit(e);
                    allValid(e)
                  }}
                >
                  Save
                </Button>{" "}
                &nbsp;&nbsp;
                <Button
                  variant="secondary"
                  onClick={() => {
                    isClear();
                  }}
                  >
                  Clear
                </Button>
              </div>
          
        </Modal.Body>
      </Modal>
    </>
  );
};

export default LineItemsModel;

