import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";
import {
  Card,
  Form,
  Col,
  Row,
  Button,
  Accordion,
  Image,
} from "react-bootstrap";
import customFunctions from "../../helper/customFunctions";
import AuthApi from "../../helper/authApi";
import Api from "../../helper/api";
import Breadcrumb from "../../components/Breadcrumb/breadcrumb.component";
import Loader from "../../components/Loader/index";
import { Helmet, DropdownInput } from "../../components/FormInputs";
import Table from "../../components/Table/simpletable.component";
import SweetAlert from "react-bootstrap-sweetalert";

const ServicesList = () => {
  const navigate = useNavigate();

  const [isShowLoader, setShowLoader] = useState<any>(false);
  const [rows, setRows] = useState<any>([]);
  const [title, setTitle] = useState<any>("Services");
  const [filterErr, setFilterErr] = useState<any>("");
  const [searchKeyword, setSearchKeyword] = useState<any>("");
  const [roleId, setRoleId] = useState<number>(0);
  const [currentUserId, setCurrentUserId] = useState<number>(0);
  const [serviceData, setServiceData] = useState<any>([]);
  const [serviceId, setServiceId] = useState<any>("");
  const [serviceArray, setServiceArray] = useState<any>();
  const [serviceName, selectServiceName] = useState<String>("");
  const [repeatationFilter, setRepeatationFilter] = useState<any>({
    serviceName: "",
    status: "",
  });
  const [deleteToggle, setDeleteToggle] = useState<boolean>(false);

  const items = [{ to: "/", label: "Dashboard" }, { label: "Services" }];
  const initialPagination = {
    currentPage: 1,
    itemsPerPage: 10,
    totalItems: 1,
  };
  const [pagination, setPagination] = useState(initialPagination);

  const columns: any = [
    {
      label: "Sr. No.",
      id: "index",
    },
    {
      label: "Service Name",
      id: "services_name",
    },
    // {
    //   label: "Repetition Status",
    //   id: "repeatation_status",
    // },
    {
      label: "SAC Number",
      id: "sac_code",
    },
    {
      label: "Action",
      id: "action",
    },
  ];

  useEffect(() => {
    getUserRole();
    serviceListOptions();
    serviceList(1, false);
  }, []);

  const getUserRole = () => {
    const role = customFunctions.getLocalStorage("role_id");
    setRoleId(Number(role));
    const currentUserId = customFunctions.getLocalStorage("id");
    setCurrentUserId(Number(currentUserId));
  };

  const serviceListOptions = async () => {
    setShowLoader(true);

    const params = {
      limit: 100,
      sort_by: "name",
    };

    const url = customFunctions.generateUrl(`${Api.servicesUrl}`, params);
    const { data, message } = await AuthApi.getDataFromServer(url);

    if (data && data.success === true) {
      setShowLoader(false);
      let serviceData: any = [];
      data &&
        data.data.length > 0 &&
        data.data.map((item: any) => {
          serviceData.push({
            label: item.services,
            value: item.id,
            sac_code: item?.services_sac_code,
          });
        });
      setServiceArray(serviceData);
    } else {
      setShowLoader(false);
      swal(typeof message === "string" ? message : message[0], "", "error");
    }
  };

  const serviceList = async (page: any, filter: any) => {
    setShowLoader(true);

    const params = {
      page: page,
      limit: 10,
      keyword: filter && searchKeyword?.label ? searchKeyword?.label : "",
      sort_direction: "descending",
    };

    const payload = customFunctions.cleanObject(params);
    const url = customFunctions.generateUrl(Api.servicesUrl, payload);
    const { data, message } = await AuthApi.getDataFromServer(url);

    if (data && data.success === true) {
      setShowLoader(false);
      let serviceData: any = [];

      setPagination({
        currentPage: data?.paginator?.current_page,
        itemsPerPage: 10,
        totalItems: data?.paginator?.total_count,
      });
      data &&
        data.data.length > 0 &&
        data.data.map((item: any) => {
          serviceData.push({
            label: item.services,
            value: item.id,
            sac_code: item?.services_sac_code,
            repeatative_status: item?.repetative_status_name,
            repeatative_status_id: item?.repetative_status,
          });
        });
      setServiceData(serviceData);
    } else {
      setShowLoader(false);
      swal(typeof message === "string" ? message : message[0], "", "error");
    }
  };

  useEffect(() => {
    setTableData();
  }, [serviceData]);

  const setTableData = () => {
    const rowsData: any = [];

    serviceData?.length &&
      serviceData.map((dt: any, i: any) => {
        rowsData.push({
          index:
            (pagination.currentPage - 1) * pagination.itemsPerPage + (i + 1),
          services_name: <>{dt?.label ? dt?.label : "-"}</>,
          repeatation_status: dt?.repeatative_status,
          sac_code: dt?.sac_code ? dt?.sac_code : "-",
          action: (
            <>
              <a
                href="/"
                className="pl-2 underline"
                title={"Edit Service"}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  navigate("/edit-service", {
                    state: {
                      data: dt,
                      id: dt?.value,
                      pageType: "edit",
                    },
                  });
                }}
              >
                {/* <Image
                  src="images/edit-button.svg"
                  alt="Edit task"
                  height={"12px"}
                /> */}
                Edit
              </a>
              <br />
              <a
                href="javascript:void(0)"
                className="pl-2 underline"
                title={"Delete Service"}
                onClick={() => {
                  setDeleteToggle(true);
                  setServiceId(dt?.value);
                }}
              >
                {/* <i
                  title="Delete Service"
                  className="fa-regular fa-trash-can fa-ml ms-2"
                  style={{ color: "#e65b5b", cursor: "pointer" }}
                ></i> */}
                Delete
              </a>
            </>
          ),
        });
        return dt;
      });
    setRows([...rowsData]);
  };

  const deleteService = async () => {
    if (!serviceId) return;
    setShowLoader(true);

    const postData: any = {};

    const payload = customFunctions.cleanObject(postData);

    const { data, message } = await AuthApi.deleteDataFromServer(
      `${Api.servicesUrl}${Number(serviceId)}/`,
      payload
    );

    if (data && data.success == true) {
      setDeleteToggle(false);
      swal("Service deleted successfully!", "", "success", {
        closeOnClickOutside: false,
      }).then(() => {
        setServiceId("");
        serviceList(1, false);
      });
    } else {
      setShowLoader(false);
      swal(message || "Something went wrong", "", "error");
    }
  };

  const filterSearch = (e: any) => {
    e.preventDefault();
    let filterSearchErr = "";
    let isValid = true;
    if (customFunctions.checkIfEmpty(searchKeyword)) {
      filterSearchErr = "Please select service name ";
      setFilterErr(filterSearchErr);
      isValid = false;
    }
    if (isValid) {
      setFilterErr("");
      serviceList(1, true);
    }
  };

  const onClear = (e: any) => {
    setFilterErr("");
    setSearchKeyword("");
    serviceList(1, false);
  };

  return (
    <>
      <Helmet title={title} />
      <div className="page-header">
        <div className="title-breadcrumb-section">
          <h2 className="main-content-title tx-24 mg-b-5">{title}</h2>
          {/* <Breadcrumb items={items} /> */}
        </div>
      </div>
      <Loader showLoader={isShowLoader} needFullPage={false} />

      {deleteToggle && (
        <div className="sweetalert">
          <SweetAlert
            title={"Are you sure? do you want to delete the service?"}
            warning
            showCancel
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            onConfirm={() => {
              setDeleteToggle(false);
              deleteService();
            }}
            onCancel={() => {
              setDeleteToggle(false);
            }}
            reverseButtons={false}
          />
        </div>
      )}
      <section className="content-area">
        <Accordion defaultActiveKey="1">
          <Accordion.Item eventKey="1">
            <Accordion.Header>
              <h5 className="card-title mb-0">Filters</h5>
            </Accordion.Header>
            <Accordion.Body>
              <Form onSubmit={(e) => filterSearch(e)}>
                {filterErr && (
                  <div className="p-3  bg-danger text-white bg-danger-main table-red-bg mb-3">
                    <div className="img-text">
                      <img
                        style={{ cursor: "pointer" }}
                        onClick={(e) => onClear(e)}
                        src="/images/fail.svg"
                        alt="Success"
                      />{" "}
                      {filterErr}
                    </div>
                  </div>
                )}
                <Row className="mt-3">
                  <Col sm={3}>
                    <DropdownInput
                      placeholder="Select service name"
                      options={serviceArray}
                      defaultValue={searchKeyword}
                      onSelectHandler={(select: any) => {
                        setSearchKeyword(select);
                      }}
                    />
                  </Col>

                  <Col sm={3}>
                    <div className="filter-btn pull-right filter-btn-report">
                      <Button variant="primary" type="submit" className="ms-4">
                        Apply
                      </Button>
                      <Button
                        variant="secondary"
                        type="button"
                        className="ms-4"
                        onClick={(e) => onClear(e)}
                      >
                        Clear
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Form>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>

        <Card className="mt-3">
          <Card.Header>
            <div className="d-flex align-items-center justify-content-end">
              <a
                href="#"
                className="underline"
                onClick={(e) => {
                  e.preventDefault();
                  navigate("/create-service", {
                    state: { pageType: "add" },
                  });
                }}
              >
                Create Service
              </a>
            </div>
          </Card.Header>
          <Card.Body>
            <Table
              columns={columns}
              showCheckbox={false}
              rows={rows}
              showPagination
              role={roleId}
              paginationProps={{
                ...pagination,
                currentPage: Number(pagination.currentPage),
                onPageChange: (pageNo: any) => {
                  serviceList(pageNo, false);
                },
              }}
              dropdownContainerClass="mb-3"
              emptyRender={() => (
                <p className="text-center mb-0">No record found</p>
              )}
            />
          </Card.Body>
        </Card>
      </section>
    </>
  );
};

export default ServicesList;
