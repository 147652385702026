import { Modal, Col, Row } from "react-bootstrap";

const AdminViewDocumentComponent = (props: any) => {
  const { show, data } = props;
  
  const getFile = () => {
    return window.open(data?.file_link);
  };

  return (
    <>
      <Modal
        show={show}
        onHide={() => {
          props.handleClose();
        }}
        // size={"md"}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <h5>Submitted Details</h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {data?.on_complition_doc == false ? (
            <Col>
              <h5 className="d-flex justify-content-center">No Record Found</h5>
            </Col>
          ) : null}

          {data?.is_note && (
            <Row className="">
              <Col sm={4}>Description :-</Col>
              <Col>{data?.description}</Col>
            </Row>
          )}
          {data?.is_file && (
            <Row className="mt-2">
              <Col sm={4}>View File :-</Col>
              <Col>
                <img
                  title={"View File"}
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    getFile();
                  }}
                  src="images/notes.svg"
                  alt=""
                />
              </Col>
            </Row>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AdminViewDocumentComponent;
