import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";
import SweetAlert from "react-bootstrap-sweetalert";
import {
  Card,
  Form,
  Col,
  Row,
  Button,
  Accordion,
  Image,
} from "react-bootstrap";
import customFunctions from "../../helper/customFunctions";
import AuthApi from "../../helper/authApi";
import Api from "../../helper/api";
import Breadcrumb from "../../components/Breadcrumb/breadcrumb.component";
import Loader from "../../components/Loader/index";
import { Helmet, TextInput, DropdownInput } from "../../components/FormInputs";
import Table from "../../components/Table/simpletable.component";

const EmployeeComponent = () => {
  const navigate = useNavigate();

  const [isShowLoader, setShowLoader] = useState<any>(false);
  const [rows, setRows] = useState<any>([]);
  const [title, setTitle] = useState<any>("Employees");
  const [statusToggle, setStatusToggle] = useState<any>(false);
  const [statusId, setStatusId] = useState<any>("");
  const [count, forceUpdate] = useState<any>(0);
  const [filterErr, setFilterErr] = useState<any>("");
  const [searchKeyword, setSearchKeyword] = useState<any>("");
  const [selectedStatus, setSelectedStatus] = useState<any>("");
  const [applyToggle, setApplyToggle] = useState<any>(false);
  const [roleId, setRoleId] = useState<number>(0);
  const [employeeId, setEmployeeId] = useState<any>(null);
  const [currentUserId, setCurrentUserId] = useState<number>(0);
  const [employeeData, setEmployeeData] = useState<any>([]);
  const [designationValues, setDesignationValues] = useState<any>([]);
  const [designation, setDesignation] = useState<any>();
  const [isShowFilter, setisShowFilter] = useState<boolean>(true);

  const items: any = [
    { to: "/", label: "Dashboard" },
    { label: "Employees" },
  ];

  const initialPagination: any = {
    currentPage: 1,
    itemsPerPage: 10,
    totalItems: 1,
  };

  const [pagination, setPagination] = useState<any>(initialPagination);

  const columns: any = [
    {
      label: "Sr. No.",
      id: "index",
    },
    {
      label: "Employee Name",
      id: "name",
    },
    {
      label: "Email",
      id: "email",
    },
    {
      label: "Contact Number",
      id: "contact_number",
    },
    // {
    //   label: "Pan Card",
    //   id: "pan_card_number",
    //   render: (rowData: any) => {
    //     return <span>{rowData.pan_card_number.toUpperCase()}</span>;
    //   },
    // },
    // {
    //   label: "Aadhar Card",
    //   id: "aadhar_card_number",
    // },
    {
      label: "Designation",
      id: "designation",
    },

    {
      label: "Status",
      id: "status",
    },
    {
      label: "Action",
      id: "action",
    },
    {
      label: "Admin",
      id: "admin_access",
    },
  ];

  const statusArray: any = [
    {
      label: "Active",
      value: "1",
    },
    {
      label: "Inactive",
      value: "2",
    },
  ];

  useEffect(() => {
    getUserRole();
  }, []);

  const getUserRole = () => {
    const role: any = customFunctions.getLocalStorage("role_id");
    setRoleId(Number(role));
    const currentUserId: any = customFunctions.getLocalStorage("id");
    setCurrentUserId(Number(currentUserId));
  };

  useEffect(() => {
    getEmployeeList(1, true);
  }, [currentUserId]);

  const getEmployeeList = async (pageNo?: any, clearFilter?: boolean) => {
    setShowLoader(true);
    const page: any = pageNo || 1;

    const params: any = {
      page: page,
      limit: pagination.itemsPerPage,
      sort_direction: "descending",
      keyword: !clearFilter && searchKeyword ? searchKeyword.trim() : "",
      status: !clearFilter && selectedStatus ? selectedStatus.value : "",
      role_name:
        !clearFilter && designationValues ? designationValues.label : "",
    };

    const url: any = customFunctions.generateUrl(Api.employeeUrl, params);

    const { data, message } = await AuthApi.getDataFromServer(url);

    if (data && data.success == true) {
      setShowLoader(false);
      
      if (data && data.data && data.data.length > 0) {
        setShowLoader(false);
        setPagination({
          currentPage: data.paginator.current_page,
          itemsPerPage: Number(10),
          totalItems: data.paginator.total_count - 1,
        });
        let getEmp: any =
          data.data &&
          data.data.filter((item: any) => {
            if (item.user.role !== 2 && Number(localStorage.getItem("id")) != item?.user?.user) {
              return item;
            }
          });

          if(getEmp && !getEmp?.length && clearFilter){
            setisShowFilter(false);
          }
        setEmployeeData(getEmp);
        // forceUpdate(count + 1);
      } else {
        if (data?.data && !data?.data?.length && clearFilter) {
          setisShowFilter(false);
        }
        setEmployeeData([]);
      }
    } else {
      setShowLoader(false);
      setEmployeeData([]);
      swal(message, "", "error");
    }
  };

  useEffect(() => {
    setTableData();
  }, [employeeData]);

  const setTableData = () => {
    const rowsData: any = [];
    employeeData.map((dt: any, i: any) => {
      rowsData.push({
        id: dt.id,
        index: (pagination.currentPage - 1) * pagination.itemsPerPage + (i + 1),
        employee_id: dt?.emp_id,
        address: dt?.address,
        pan_card_number: dt?.pan_number,
        aadhar_card_number: dt?.aadhar_card,
        designation: dt?.user?.role_name,
        email: dt?.user?.email,
        admin_access:
          dt?.user?.admin_access === true ? (
            <Image
              title="Admin Access"
              src="/images/success.svg"
              alt="Admin Access"
            />
          ) : (
            <Image
              title="No Access"
              src="/images/fail.svg"
              alt="Admin Access"
            />
          ),
        name: (
          <>
            <a
              href="/"
              className="pl-2 ms-1"
              title="Edit Employee"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                navigate("/edit-employee", {
                  state: {
                    id: dt.employee.id,
                    pageType: "edit",
                  },
                });
              }}
            >
              {`${
                dt && dt.user?.first_name
                  ? customFunctions.capitalizeFirstLetter(dt.user?.first_name)
                  : null
              }
               ${
                 dt && dt.user?.last_name
                   ? customFunctions.capitalizeFirstLetter(dt.user?.last_name)
                   : null
               }`}
            </a>
          </>
        ),

        contact_number: dt?.user.mobile,
        status: (
          <>
            <div className="switch-check">
              <div
                className="form-check form-switch"
                style={{ marginBottom: "0" }}
              >
                <input
                  className="form-check-input"
                  type="checkbox"
                  role="switch"
                  id="flexSwitchCheckDefault"
                  checked={dt?.user?.status_id == "1" ? true : false}
                  onClick={() => {
                    setStatusToggle(true);
                    setStatusId(dt?.user?.status_id == "2" ? "1" : "2");
                    setEmployeeId(dt?.employee?.id);
                  }}
                  title={dt?.user?.status_id == "1" ? "Active" : "Inactive"}
                />
              </div>
            </div>
          </>
        ),
        action: (
          <>
            <a
              href="/"
              className="pl-2 ms-1 underline"
              title={"View Details"}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                navigate("/view-employee", {
                  state: {
                    id: dt.employee.id,
                    pageType: "view",
                  },
                });
              }}
            >
              {/* <Image src="images/eye.svg" alt="view" /> */}
              View
            </a>
            &nbsp;&nbsp;
          </>
        ),
      });
      return dt;
    });
    setRows([...rowsData]);
  };

  const changeStatus = async () => {
    const postData: any = {
      id: Number(employeeId),
      user: { status: statusId },
    };

    if (!employeeId) return;
    setShowLoader(true);
    const endpoint: any = `${Api.employeeUrl}${employeeId}/`;
    const { data, message } = await AuthApi.putDataToServer(endpoint, postData);

    if (data && data.success == true) {
      setStatusToggle(false);
      swal("Employee status changed successfully!", "", "success", {
        closeOnClickOutside: false,
      }).then(() => {
        setEmployeeId(null);
        setStatusId("");
        getEmployeeList(1, true);
      });
    } else {
      setShowLoader(false);
      swal(message || "Something went wrong", "", "error").then(() => {
        setEmployeeId(null);
        setStatusId("");
        getEmployeeList(1, true);
      });
    }
  };

  const filterSearch = (e: any) => {
    e.preventDefault();
    let filterSearchErr: string = "";
    let isValid: boolean = true;
    if (
      customFunctions.checkIfEmpty(searchKeyword) &&
      customFunctions.checkIfEmpty(selectedStatus)
      // customFunctions.checkIfEmpty(designationValues)
    ) {
      filterSearchErr = "Please enter name or select status";
      setFilterErr(filterSearchErr);
      isValid = false;
    }
    if (isValid) {
      setFilterErr("");
      getEmployeeList(1, false);
    }
  };

  useEffect(() => {
    getDesignation();
  }, []);

  const getDesignation = async () => {
    setShowLoader(true);
    const { data, message } = await AuthApi.getDataFromServer(
      `${Api.designationUrl}`
    );

    if (data && data.success == true) {
      setShowLoader(false);
      if (data && data.data && data.data.length > 0) {
        let designationArr: any = [];
        data &&
          data.data.length > 0 &&
          data.data.map((item: any) => {
            if ([1, 2].includes(item.id)) return;
            designationArr.push({ label: item.designation, value: item.id });
          });

        setDesignationValues(designationArr);
      } else {
        setDesignationValues([]);
      }
    } else {
      setShowLoader(false);
      swal(message, "", "error");
      return;
    }
  };

  const onClear = (e: any) => {
    setFilterErr("");
    setSearchKeyword("");
    setSelectedStatus("");
    setApplyToggle(false);

    if (searchKeyword || selectedStatus) {
      getEmployeeList(1, true);
    }
  };

  return (
    <>
      <>
        <Helmet title={title} />
        <div className="page-header">
          <div className="title-breadcrumb-section">
            <h2 className="main-content-title tx-24 mg-b-5">{title}</h2>
            <Breadcrumb items={items} />
          </div>
        </div>
        <Loader showLoader={isShowLoader} needFullPage={false} />

        {statusToggle && (
          <div className="sweetalert">
            <SweetAlert
              title={"Are you sure? do you want to change the status?"}
              warning
              showCancel
              confirmBtnBsStyle="success"
              cancelBtnBsStyle="danger"
              onConfirm={() => {
                setStatusToggle(false);
                changeStatus();
              }}
              onCancel={() => {
                setStatusToggle(false);
              }}
              reverseButtons={false}
            />
          </div>
        )}
        <section className="content-area">
          {isShowFilter &&
          <Accordion defaultActiveKey="1">
            <Accordion.Item eventKey="1">
              <Accordion.Header>
                <h5 className="card-title mb-0">Filters</h5>
              </Accordion.Header>
              <Accordion.Body>
                <Form onSubmit={(e) => filterSearch(e)}>
                  {filterErr && (
                    <div className="p-3  bg-danger text-white bg-danger-main table-red-bg mb-3">
                      <div className="img-text">
                        <img
                          style={{ cursor: "pointer" }}
                          onClick={(e) => onClear(e)}
                          src="/images/fail.svg"
                          alt="Success"
                        />{" "}
                        {filterErr}
                      </div>
                    </div>
                  )}
                  <Row className="mt-3">
                    <Col sm={3}>
                      <TextInput
                        value={searchKeyword}
                        placeholder="Search by first name "
                        onInputChange={(e: any) => {
                          setSearchKeyword(e.target.value);
                        }}
                        designationValues
                      />
                    </Col>

                    <Col sm={3}>
                      <DropdownInput
                        placeholder="Search by status"
                        options={statusArray}
                        defaultValue={selectedStatus}
                        onSelectHandler={(e: any) => {
                          setSelectedStatus(e);
                        }}
                      />
                    </Col>

                    {/* <Col sm={3}>
                      <DropdownInput
                        placeholder="Choose designation"
                        options={designationValues}
                        isClearable={true}
                        defaultValue={designation}
                        onSelectHandler={(e: any) => {
                          setDesignationValues(e);
                        }}
                      />
                    </Col> */}

                    <Col sm={3}>
                      <div className="filter-btn pull-right filter-btn-report">
                        <Button
                          id="apply-btn"
                          variant="primary"
                          type="submit"
                          className="ms-4"
                          onClick={() => setApplyToggle(true)}
                        >
                          Apply
                        </Button>
                        <Button
                        id="clear-btn"
                          variant="secondary"
                          type="button"
                          className="ms-4"
                          onClick={(e) => onClear(e)}
                        >
                          Clear
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>}
          <Card className="mt-3">
            <Card.Header>
              <div className="d-flex align-items-center justify-content-end">
                <a
                id="add-employee-btn-link"
                  href="#"
                  className="underline"
                  onClick={(e) => {
                    e.preventDefault();
                    navigate("/create-employee", {
                      state: { pageType: "add" },
                    });
                  }}
                >
                  Create Employee
                </a>
              </div>
            </Card.Header>
            <Card.Body>
              <Table
                columns={columns}
                showCheckbox={false}
                rows={rows}
                showPagination
                role={roleId}
                paginationProps={{
                  ...pagination,
                  currentPage: Number(pagination.currentPage),
                  onPageChange: (pageNo: any) => {
                    getEmployeeList(pageNo, false);
                  },
                }}
                dropdownContainerClass="mb-3"
                emptyRender={() => (
                  <p className="text-center mb-0">No record found</p>
                )}
              />
            </Card.Body>
          </Card>
        </section>
      </>
    </>
  );
};

export default EmployeeComponent;
