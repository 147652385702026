/* eslint-disable-line */
import React, { useState, useRef, useEffect } from "react";
import { Row, Col, Card, Form, Button } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";
import swal from "sweetalert";
import {
  Datepicker,
  DropdownInput,
  Helmet,
  TextInput,
} from "../../components/FormInputs";
import SimpleReactValidator from "simple-react-validator";
import customFunctions from "../../helper/customFunctions";
import AuthApi from "../../helper/authApi";
import Api from "../../helper/api";
import Loader from "../../components/Loader";
import Breadcrumb from "../../components/Breadcrumb/breadcrumb.component";
import SelectAsyncPaginate from "../../components/ReactSelectPagination/SelectAsyncPaginate";

const AssignLitigationComponent = () => {
  const navigate: any = useNavigate();
  const { state } = useLocation();

  const validator = useRef(new SimpleReactValidator());

  const [count, forceUpdate] = useState<any>(0);
  const [roleId, setRoleId] = useState<number>(0);
  const [pageType, setPageType] = useState<string>("add");
  const [title, setTitle] = useState("Assign Litigation");
  const [isShowLoader, setShowLoader] = useState<boolean>(false);
  const [litigationId, setLitigationId] = useState<boolean>(false);
  const [selectedEmployee, setSelectedEmployee] = useState<any>("");
  const [selectedLitigation, setSelectedLitigation] = useState<any>("");

  useEffect(() => {
    getUserRole();
  }, []);

  const getUserRole = () => {
    const role = customFunctions.getLocalStorage("role_id");
    setRoleId(Number(role));
  };

  useEffect(() => {
    if (state && state?.id) {
      setTitle("Assign Litigation");
      setLitigationId(state?.id);
      const employees: any = [];
      state?.data?.employee?.length &&
        state?.data?.employee?.map((element: any) => {
          employees.push({
            name: element?.employee_name,
            id: element?.id,
          });
        });
      setSelectedEmployee(employees?.length ? employees : "");

      setSelectedLitigation(
        state?.data?.litigation
          ? {
              name: state?.data?.litigation_title,
              id: state?.data?.litigation,
            }
          : ""
      );
    }
  }, [state]);

  const items = [
    {
      to: "/",
      label: "Dashboard",
    },
    {
      to: "/assign-litigations-list",
      label: "Assign Litigations List",
    },
    { label: title },
  ];

  useEffect(() => {
    if (litigationId) {
      getLitgation(litigationId);
    }
  }, [litigationId]);

  const getLitgation = async (id: any) => {
    setShowLoader(true);
    const { data, message } = await AuthApi.getDataFromServer(
      `${Api.employeeLitigationUrl}${id}/`
    );

    if (data && data.success === true) {
      setShowLoader(false);
      if (data && data.data) {
      }
    } else {
      setShowLoader(false);
      swal(typeof message == "string" ? message : message[0], "", "error");
    }
  };

  const assignLitigation = async () => {
    setShowLoader(true);
    const postData: any = {
      employee:
        selectedEmployee?.length &&
        selectedEmployee?.map((element: any) => element?.id),
      litigation: selectedLitigation?.id,
      task_type: 2,
    };

    const payload = customFunctions.cleanObject(postData);
    const endPoint = litigationId
      ? `${Api.employeeLitigationUrl}${litigationId}/`
      : `${Api.employeeLitigationUrl}`;

    const callback = litigationId
      ? AuthApi.putDataToServer
      : AuthApi.postDataToServer;
    const { data, message } = await callback(endPoint, payload);
    if (data && data.success === true) {
      setShowLoader(false);
      swal(
        litigationId
          ? "Litigation updated successfully!"
          : "Litigation assigned successfully!",
        "",
        "success"
      ).then(() => {
        navigate(-1);
      });
    } else {
      setShowLoader(false);
      swal(typeof message == "string" ? message : message[0], "", "error");
    }
  };

  const onSubmit = (e: any) => {
    e.preventDefault();

    if (validator.current.allValid()) {
      assignLitigation();
    } else {
      validator.current.showMessages();
      forceUpdate(count + 1);
    }
  };

  return (
    <>
      <Helmet title={title} />
      <Button
        style={{ float: "right" }}
        className="form-group mt-4"
        type="submit"
        variant="primary"
        onClick={() => {
          navigate(-1);
        }}
      >
        Back
      </Button>
      <div className="page-header">
        <div className="title-breadcrumb-section">
          <h2 className="main-content-title tx-24 mg-b-5">{title}</h2>
          <Breadcrumb items={items} />
        </div>
      </div>
      <Loader showLoader={isShowLoader} needFullPage={false} />
      <section className="content-area">
        <Card>
          <Card.Body>
            <Form
              autoComplete="off"
              onSubmit={(e) => {
                onSubmit(e);
              }}
            >
              <Row>
                <Col sm={4}>
                  <SelectAsyncPaginate
                    label={"Litigation"}
                    asterisk={"*"}
                    selectPlaceholder={"Select litigation"}
                    value={selectedLitigation}
                    onSelectChange={(select: any) => {
                      setSelectedLitigation(select);
                    }}
                    apiname={"litigationUrl"}
                    endpoint={Api.litigationUrl}
                    errorMessage={validator.current.message(
                      "litigation",
                      selectedLitigation,
                      "required"
                    )}
                  />
                </Col>
                <Col sm={4}>
                  <SelectAsyncPaginate
                    label={"Employee"}
                    asterisk={"*"}
                    selectPlaceholder={"Select employee"}
                    value={selectedEmployee}
                    onSelectChange={(select: any) => {
                      setSelectedEmployee(select);
                    }}
                    apiname={"employeeUrl"}
                    endpoint={Api.employeeUrl}
                    errorMessage={validator.current.message(
                      "employee",
                      selectedEmployee,
                      "required"
                    )}
                    isClearable={true}
                    isMulti={true}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <div style={{ float: "left" }} className="form-group mt-4">
                    <Button type="submit" variant="primary">
                      Submit
                    </Button>
                    <Button
                      onClick={(e) => {
                        e.preventDefault();
                        navigate(-1);
                      }}
                      variant="secondary"
                      className="ms-2"
                    >
                      Cancel
                    </Button>
                  </div>
                </Col>
              </Row>
            </Form>
          </Card.Body>
        </Card>
      </section>
    </>
  );
};

export default AssignLitigationComponent;
