import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import SimpleReactValidator from "simple-react-validator";
import swal from "sweetalert";
import { Button } from "react-bootstrap";
import CustomFunctions from "../../../helper/customFunctions";
import AuthApi from "../../../helper/authApi";
import Api from "../../../helper/api";
import { Helmet } from "../../../components/FormInputs";
import AdminDashboardComponent from "../../dashboard/Dashboard/DashboardComponent";

function Login() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const validator = useRef(new SimpleReactValidator());
  const [count, forceUpdate] = useState<number>(0);
  const [username, setUsername] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [disableButton, setDisableButton] = useState<boolean>(false);
  const [switchItem, setSwitchItem] = useState<boolean>(false);

  // While use in project comment this code and use above Useeffect
  useEffect(() => {
    const token = CustomFunctions.getLocalStorage("access_token");
    if (token) {
      dispatch({ type: "LOGIN", value: true });
      navigate("/");
    } else {
      dispatch({ type: "LOGIN", value: false });
      navigate("/login");
    }
  }, []);

  const loginUser = async () => {
    const postData: LoginData = {
      email: username.trim(),
      password,
    };
    const { data, message } = await AuthApi.postDataToServer(
      Api.loginUrl,
      postData
    );
    if (data && data.data && data.data.token) {
      
      if ([3, 4, 5, 6].includes(data.data.role_id) && data.data.admin_access) {
        CustomFunctions.setLocalStorage("role_id", Number(10));
      } else {
        CustomFunctions.setLocalStorage("role_id", data.data.role_id);
      }
      CustomFunctions.setLocalStorage(
        "access_token",
        data?.data?.token?.access_token
      );
      CustomFunctions.setLocalStorage("email", data.data.email);
      CustomFunctions.setLocalStorage("id", data.data.id);
      CustomFunctions.setLocalStorage("admin_access", data.data.admin_access);

      CustomFunctions.setLocalStorage(
        "userName",
        `${data.data.first_name
          ? CustomFunctions.capitalizeFirstLetter(data.data.first_name)
          : ""
        } ${data.data.last_name
          ? CustomFunctions.capitalizeFirstLetter(data.data.last_name)
          : ""
        }`
      );
      let mymsg = CustomFunctions.checkIfEmpty(message, "O");

      setDisableButton(false);
      const timer = setInterval(async () => {
        const roleId = CustomFunctions.getLocalStorage("role_id");
        const token = CustomFunctions.getLocalStorage("access_token");
        if (token) {
          clearInterval(timer);
          // if (
          //   [3, 4, 5].includes(data.data.role_id) &&
          //   !data.data.admin_access
          // ) {
          //   window.location.href = "/";
          // } else {
            if(Number(roleId) == 1){
              window.location.href = "/services";

            }else{
              window.location.href = "/";
            }

          // }
        }
      }, 100);
    } else {
      setDisableButton(false);
      swal(
        !CustomFunctions.checkIfEmpty(message, "O")
          ? typeof message === "string"
            ? message
            : message[0]
          : "Something went wrong",
        "",
        "error"
      );
    }
  };

  // const loginUser = async () => {
  //   // dummy login
  //   let roleId: any = ''
  //   if (username == 'akshay@gmail.com') {
  //     roleId = Number(2);
  //     CustomFunctions.setLocalStorage("access_token", 'jasdyajsdjadjakd');
  //     CustomFunctions.setLocalStorage('activeTab', 'individualClient');
  //     CustomFunctions.setLocalStorage("role_id", Number(2));
  //     CustomFunctions.setLocalStorage(
  //       "userName", 'Akshay Deshpande'
  //     );

  //     setDisableButton(false);
  //     const timer = setInterval(async () => {
  //       const token = CustomFunctions.getLocalStorage("access_token");
  //       if (token) {
  //         clearInterval(timer);
  //         window.location.href = "/";
  //       }
  //     }, 100);
  //   }
  //   else if (username == 'rohit@gmail.com') {
  //     roleId = Number(4);
  //     CustomFunctions.setLocalStorage("access_token", 'jasdyajsdjadjakd');
  //     CustomFunctions.setLocalStorage('activeTab', 'individualClient');
  //     CustomFunctions.setLocalStorage("role_id", Number(4));
  //     CustomFunctions.setLocalStorage(
  //       "userName", 'Rohit Sharma'
  //     );

  //     setDisableButton(false);
  //     const timer = setInterval(async () => {
  //       const token = CustomFunctions.getLocalStorage("access_token");
  //       if (token) {
  //         clearInterval(timer);
  //         if (roleId == 2) {
  //           window.location.href = "/";
  //         }
  //         else {
  //           window.location.href = "/dashboard-emp";
  //         }
  //       }
  //     }, 100);
  //   }
  //   else {
  //     setDisableButton(false);
  //     swal('User does not exists', '', 'error');
  //   }
  // };

  const validateForm = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    // if (disableButton) return;

    // setDisableButton(true);
    if (validator.current.allValid()) {
      loginUser();
    } else {
      validator.current.showMessages();
      forceUpdate(count + 1);
      setDisableButton(false);
    }
  };

  const handlePasswordView = (event: React.MouseEvent<HTMLImageElement>) => {
    event.preventDefault();
    setSwitchItem(!switchItem);
  };

  return (
    <>
      <Helmet title={"Login"} />
      <div className="login-page d-flex align-items-center justify-content-center flex-column">
        <div className="logo  text-center">
          <img
            src="/images/CA-logo_in_black"
            className="header-brand-img desktop-logo svg"
            alt="img"
          />
        </div>
        <form className="login-form" autoComplete="off">
          <div className="title text-center">
            <h1>Welcome!</h1>
          </div>
          <div className="form-group">
            <img src="/images/user.svg" alt="Username" />
            <input
              id="email"
              className="form-control form-control-lg"
              type="email"
              name="email"
              value={username || ""}
              onChange={(
                e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
              ) => setUsername(e.target.value)}
              placeholder="Enter email address"
              maxLength={150}
            />
            {validator.current.message(
              "Email address",
              username,
              "required|email",
              {
                className: "text-danger",
              }
            )}
          </div>

          <div className="form-group m-0">
            <img src="/images/password.svg" alt="Username" />
            <input
              id="password"
              className="form-control form-control-lg"
              type={switchItem ? "text" : "password"}
              name="password"
              value={password || ""}
              onChange={(
                e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
              ) => setPassword(e.target.value)}
              placeholder="Password"
              minLength={1}
            />
            {password && (
              <img
                style={{ cursor: "pointer" }}
                src={
                  !switchItem ? "/images/visibility.svg" : "/images/eyePass.svg"
                }
                alt="Username"
                className="showpass"
                onClick={handlePasswordView}
              />
            )}{" "}
            {validator.current.message("Password", password, "required", {
              className: "text-danger",
            })}
          </div>

          <div className="form-row d-flex align-items-center justify-content-between rememberme-password">
            <label className="login__rememberme">
              {/* <input
                onChange={(e) => setRemember(e.target.checked)}
                name="remember"
                type="checkbox"
                checked={remember}
                id="remember"
                value={remember}
              /> */}
              {/* <span className="ms-2">Remember me</span> */}
            </label>
            <p className="lost_password underline">
              <a
                href="/forget-password"
                onClick={(e) => {
                  e.preventDefault();
                  navigate("/forget-password");
                }}
              >
                Forgot password ?
              </a>
            </p>
          </div>
          <div className="text-center mt-3">
            <>
              <Button
                id="submitBtn"
                variant="btn btn-lg btn-primary"
                type="submit"
                className="ms-4"
                disabled={disableButton}
                onClick={validateForm}
              >
                Login
              </Button>
              <Button
                variant="btn btn-lg btn-secondary"
                type="button"
                className="ms-4"
                // disabled={disableButton}
                onClick={() => {
                  navigate("/company-registration");
                }}
              >
                New Firm
              </Button>
            </>
          </div>
        </form>
      </div>
    </>
  );
}

export default Login;
