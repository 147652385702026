import React, { useState, useRef, useEffect } from "react";
import Breadcrumb from "../../components/Breadcrumb/breadcrumb.component";
import { TextInput, Helmet, Datepicker } from "../../components/FormInputs";
import { Row, Col, Button, Form, Card, Image } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";
import swal from "sweetalert";
import customFunctions from "../../helper/customFunctions";
import Api from "../../helper/api";
import AuthApi from "../../helper/authApi";
import SimpleReactValidator from "simple-react-validator";

interface updateProfileInterface {
  id?: any;
  first_Name?: string;
  last_Name?: string;
  dateOf_Birth?: any;
  contact?: any;
}

const EmployeeProfileComponent = () => {
  const validator = useRef(new SimpleReactValidator());
  const currentDate = new Date();
  const minDate = new Date(currentDate);
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [dateOfBirth, setDateOfBirth] = useState<any>();
  const [contact, setContact] = useState<any>("");
  const [address, setAddress] = useState<any>("");
  const [addressId, setAddressId] = useState<any>("");
  // const [department, setDepartment] = useState<string>("Accounting Department");
  const [designation, setDesignation] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [panCardNumber, setPanCardNumber] = useState<string>("");
  const [aadharCardNumber, setAadharCardNumber] = useState<string>("");
  // const [confirmationDate, setConfirmationDate] = useState<any>(new Date());
  const [joiningDate, setJoiningDate] = useState<any>();
  const [title, setTitle] = useState<string>("Edit Profile");
  const [profiletToggle, setProfiletToggle] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const [imageId, setImageId] = useState<any>();
  const [newDocument, setNewDocument] = useState<any>([]);
  const [fileId, setFileId] = useState<any>();
  const [newDocumentTitle, setNewDocumentTitle] = useState<any>("");
  const [disableButton, setDisableButton] = useState(false);
  const [isShowLoader, setShowLoader] = useState(false);
  const [count, forceUpdate] = useState<any>(0);
  const [employeeId, setEmployeeId] = useState<any>("");

  const navigate = useNavigate();
  const location = useLocation();

  const items = [{ to: "/", label: "Dashboard" }, { label: title }];

  useEffect(() => {
    getEmployeeProfile();
  }, []);

  const getEmployeeProfile = async () => {
    setShowLoader(true);
    const { data, message } = await AuthApi.getDataFromServer(
      `${Api.profileUrl}`
    );

    if (data && data.success === true) {
      setShowLoader(false);
      if (data && data.data) {
        let { employee, user } = data.data;
        employee &&
          employee.length > 0 &&
          employee.map((item: any) => {
            setEmployeeId(item?.id);
            setFirstName(user.first_name);
            setLastName(user.last_name);
            setAddress(item?.area);
            setAddressId(item?.address_id);
            setContact(user.mobile);
            setEmail(user.email);
            setPanCardNumber(item.pan_number);
            setAadharCardNumber(item.aadhar_card);
            setDateOfBirth(new Date(item.date_of_birth));
            setJoiningDate(new Date(item.joining_date));
            setDesignation(user.role_name);
            setImageUrl(user?.file?.file_name);
            setNewDocument(user?.file?.id);
          });
      }
    }
  };

  // useEffect(() => {
  //   if (!newDocument) return;
  //   uploadDocument();
  // }, [newDocument]);

  const uploadDocument = async (file: any) => {
    setShowLoader(true);
    const formData = new FormData();

    formData.append("file_name", file[0]);

    const { data, message } = await AuthApi.postDataToServer(
      Api.documentUploadUrl,
      formData
    );

    if (data && data.success === true) {
      setShowLoader(false);
      setFileId(data?.data?.id);
      setNewDocumentTitle(data?.data?.f_name);
      setImageUrl(data?.data?.file_name);
      swal("File uploaded successfully!", "", "success");
      // updateProfile(data?.data?.id);
      // getEmployeeProfile();
    } else {
      setShowLoader(false);
      swal(typeof message == "string" ? message : message[0], "", "error");
    }
  };

  const updateProfile = async (file_id?: any) => {
    setShowLoader(true);
    const postData: any = {
      first_name: firstName,
      last_name: lastName,
      mobile: contact,
      
      profile_image: file_id ? file_id : fileId,
      address: {
        address_id: addressId,
        area: address,
      },
      employee:{
        employee_id:employeeId,
        date_of_birth: dateOfBirth ? customFunctions.getDate(dateOfBirth) :null,
        pan_number: panCardNumber,
      aadhar_card: aadharCardNumber,
      }
    };
    const payload = customFunctions.cleanObject(postData);
    const endPoint = `${Api.profileUrl}`;
    const callback: any = AuthApi.putDataToServer;

    const { data, message } = await callback(endPoint, payload);
    
    
    if (data && data.success === true) {
      setShowLoader(false);
      setDisableButton(false);
      
      if(data?.data?.profile_image_file_name){
        customFunctions.setLocalStorage(
          "profileImage",
          data?.data?.profile_image_file_name
        );
      }
      customFunctions.setLocalStorage(
        "userName",
        `${
          data.data.first_name
            ? customFunctions.capitalizeFirstLetter(data.data.first_name)
            : ""
        } ${
          data.data.last_name
            ? customFunctions.capitalizeFirstLetter(data.data.last_name)
            : ""
        }`
      );
      swal("Profile Updated successfully!", "", "success").then(() => {
        navigate(-1);

      });
    } else {
      setDisableButton(false);
      setShowLoader(false);
      swal(typeof message == "string" ? message : message[0], "", "error");
    }
  };

  const onUpdate = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    // if (disableButton) return;
    // setDisableButton(true);
    if (validator.current.allValid()) {
      updateProfile();
      
    } else {
      validator.current.showMessages();
      forceUpdate(count + 1);
      setDisableButton(false);
    }
  };

  return (
    <>
      <Helmet title={title} />
      <div className="page-header">
        <div className="title-breadcrumb-section">
          <h2 className="main-content-title tx-24 mg-b-5">{title}</h2>
          <Breadcrumb items={items} />
        </div>
      </div>
      <section className="content-area"></section>
      <Card>
        <Card.Body>
          <Form
            autoComplete="off"
            onSubmit={(e) => {
              // onSubmit(e);
            }}
          >
            <div className="d-flex justify-content-between">
              <div>
                <h5>My Profile</h5>
              </div>
              <div className="float-end">
                {!profiletToggle && (
                  <Button
                    onClick={() => {
                      setProfiletToggle(true);
                    }}
                  >
                    Edit Profile
                  </Button>
                )}
              </div>
            </div>
            <Row className="mt-5">
              <Col sm={2}>
                <Row>
                  <div className="text-center m-b-10">
                    <div className="position-relative">
                      <input
                        onChange={(e: any) => {
                          e.preventDefault();
                          if (e.target.value == "") return;
                        if (
                          e.target.value !== "" &&
                          ["png", "jpeg", "jpg"].includes(
                            typeof e.target.files[0].name === "string" &&
                              e.target.files[0].name.split(".").pop()
                          )
                        ) {
                          if (e.target.files[0].size >= 3000000) {
                            setShowLoader(false);
                            swal("File size is too large", " ", "error");
                            return;
                          } else {
                            setNewDocument(e.target.files);
                            uploadDocument(e.target.files);
                          }
                        } else {
                          swal("File extension is not supported", "", "error");
                          return;
                        }
                        }}
                        title={
                          profiletToggle
                            ? `Edit Profile Picture`
                            : newDocumentTitle
                        }
                        className="profile-file"
                        type="file"
                        accept="image/png, image/jpeg,image/jpg, "
                        disabled={!profiletToggle}
                      />
                      {/* <img
                        className={
                          profiletToggle ? "progilepic" : "static-profilepic"
                        }
                        src={imageUrl ? imageUrl : "/images/user.svg"}
                        alt=""
                        width={100}
                        height={100}
                      /> */}
                      <Image
                      width={100}
                      height={100}
                      src={imageUrl ? imageUrl : "/images/user.svg"}
                      roundedCircle
                    />
                      {profiletToggle && (
                        <span className={"profile-pen"} style={{ left: "80%" }}>
                          <Image
                            src="images/edit-pen.svg"
                            height={"15px"}
                            width={"14px"}
                          />
                        </span>
                      )}
                    </div>
                  </div>
                </Row>
              </Col>
              <Col>
                <>
                  <Row>
                    <Col sm="6">
                      <TextInput
                        onInputChange={(
                          e: React.ChangeEvent<
                            HTMLTextAreaElement | HTMLInputElement
                          >
                        ) => {
                          const re = /^[a-zA-Z ]*$/;
                          if (!e.target.value || re.test(e.target.value)) {
                            setFirstName(e.target.value);
                          }
                        }}
                        label="First Name"
                        asterisk="*"
                        containerClass="form-group"
                        type="text"
                        name="firstName"
                        value={firstName}
                        disabled={!profiletToggle}
                        placeholder="Enter first name"
                        errorMessage={validator.current.message(
                          "firstName",
                          firstName,
                          "required|alpha_space"
                        )}
                        autoComplete="new-firstName"
                      />
                    </Col>
                    <Col sm="6">
                      <TextInput
                        onInputChange={(
                          e: React.ChangeEvent<
                            HTMLTextAreaElement | HTMLInputElement
                          >
                        ) => {
                          const re = /^[a-zA-Z ]*$/;
                          if (!e.target.value || re.test(e.target.value)) {
                            setLastName(e.target.value);
                          }
                        }}
                        label="Last Name"
                        asterisk="*"
                        containerClass="form-group"
                        type="text"
                        name="lastName"
                        value={lastName}
                        disabled={!profiletToggle}
                        placeholder="Enter last name"
                        errorMessage={validator.current.message(
                          "lastName",
                          lastName,
                          "required|alpha_space"
                        )}
                        autoComplete="new-lastName"
                      />
                    </Col>
                    <Col sm="6">
                      <TextInput
                        onInputChange={(
                          e: React.ChangeEvent<
                            HTMLTextAreaElement | HTMLInputElement
                          >
                        ) => {
                          // const re = /^[a-zA-Z ]*$/;
                          // if (!e.target.value || re.test(e.target.value)) {
                            setAddress(e.target.value);
                          // }
                        }}
                        asterisk="*"
                        label="Address"
                        containerClass="form-group"
                        type="text"
                        name="address"
                        value={address}
                        disabled={!profiletToggle}
                        placeholder="Enter address"
                        errorMessage={validator.current.message(
                          "address",
                          address,
                          "required"
                        )}
                        autoComplete="new-address"
                      />
                    </Col>

                    <Col sm="6">
                      <TextInput
                        onInputChange={(
                          e: React.ChangeEvent<
                            HTMLTextAreaElement | HTMLInputElement
                          >
                        ) => {
                          const re = /^[0-9]*$/;
                          if (!e.target.value || re.test(e.target.value)) {
                            setContact(e.target.value);
                          }
                        }}
                        label="Contact Number"
                        asterisk="*"
                        containerClass="form-group"
                        type="text"
                        name="contact"
                        value={contact}
                        maxlength={10}
                        minlength={10}
                        disabled={!profiletToggle}
                        placeholder="Enter contact number"
                        errorMessage={validator.current.message(
                          "contact number",
                          contact,
                          "required|numeric|phone"
                        )}
                        autoComplete="new-contact"
                      />
                    </Col>
                    <Col sm="6">
                      <TextInput
                        onInputChange={(
                          e: React.ChangeEvent<
                            HTMLTextAreaElement | HTMLInputElement
                          >
                        ) => {
                          const re = /^[a-z 0-9 ]*$/;
                          if (!e.target.value || re.test(e.target.value)) {
                            setEmail(e.target.value);
                          }
                        }}
                        label="Email Address"
                        asterisk="*"
                        containerClass="form-group"
                        type="text"
                        name="email"
                        value={email}
                        disabled
                        placeholder="Enter email address"
                        errorMessage={validator.current.message(
                          "email",
                          email,
                          "required|email"
                        )}
                        autoComplete="new-email"
                      />
                    </Col>

                    <Col sm="6">
                      <TextInput
                        onInputChange={(e: any) => {
                          const re = /^[ 0-9 a-z A-Z ]*$/;
                          if (!e.target.value || re.test(e.target.value)) {
                            setPanCardNumber(e.target.value.toUpperCase());
                          }
                        }}
                        label="Pan Card Number"
                        asterisk="*"
                        containerClass="form-group"
                        type="text"
                        name="panCardNumber"
                        value={panCardNumber}
                        placeholder="Enter pan card number"
                        maxlength={10}
                        minlength={10}
                        disabled={!profiletToggle}d
                        errorMessage={validator.current.message(
                          "panCardNumber",
                          panCardNumber,
                          "required|regex:^([A-Z]){5}([0-9]){4}([A-Z]){1}$",
                          {
                            messages: {
                              required: "The pan card number field is required",
                              regex: "Invalid pan card number",
                            },
                          }
                        )}
                        autoComplete="new-panCardNumber"
                      />
                    </Col>
                    <Col sm={6}>
                      <TextInput
                        onInputChange={(e: any) => {
                          const re = /^[0-9]*$/;
                          if (!e.target.value || re.test(e.target.value)) {
                            setAadharCardNumber(e.target.value);
                          }
                        }}
                        label="Aadhar Card Number"
                        asterisk="*"
                        containerClass="form-group"
                        type="text"
                        name="aadharCardNumber"
                        value={aadharCardNumber}
                        disabled={!profiletToggle}
                        placeholder="Enter aadhar card number"
                        errorMessage={validator.current.message(
                          "Aadhar Card Number",
                          aadharCardNumber,
                          "required|regex:^([0-9]){12}$",
                          {
                            messages: {
                              required:
                                "The aadhar card number field is required",
                              regex: "Invalid aadhar card number",
                            },
                          }
                        )}
                        autoComplete="new-aadharCardNumber"
                        maxlength={12}
                        minlength={12}
                      />
                    </Col>
                    <Col sm="6">
                      <Datepicker
                        peekNextMonth={true}
                        showMonthDropdown={true}
                        showYearDropdown={true}
                        dropdownMode="select"
                        label="DOB"
                        className="form-control"
                        selected={dateOfBirth}
                        asterisk="*"
                        disabled={!profiletToggle}
                        placeholder="Select date of birth"
                        dateFormat="dd/MM/yyyy"
                        onChange={(e: any) => {
                          setDateOfBirth(e);
                        }}
                        class
                        maxDate={new Date()}
                        // maxDate={minDate.setFullYear(minDate.getFullYear() - 18)}
                        errorMessage={validator.current.message(
                          "date Of Birth",
                          dateOfBirth,
                          "required"
                        )}
                      />
                    </Col>

                    {/* <Col sm="6">
                                            <TextInput
                                                onInputChange={(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                                                    const re = /^[a-zA-Z ]*$/;
                                                    if (!e.target.value || re.test(e.target.value)) {
                                                        setDepartment(e.target.value);
                                                    }
                                                }}
                                                label="Department"
                                                // asterisk="*"
                                                containerClass="form-group"
                                                type="text"
                                                name="department"
                                                value={department}
                                                disabled
                                                placeholder="Enter department"
                                                // errorMessage={validator.current.message(
                                                //   "department",
                                                //   department,
                                                //   "required|alpha_space"
                                                // )}
                                                autoComplete="new-department"
                                            />
                                        </Col> */}

                    <Col sm="6">
                      <TextInput
                        onInputChange={(
                          e: React.ChangeEvent<
                            HTMLTextAreaElement | HTMLInputElement
                          >
                        ) => {
                          // const re = /^[a-zA-Z ]*$/;
                          // if (!e.target.value || re.test(e.target.value)) {
                            setDesignation(e.target.value);
                          // }
                        }}
                        label="Designation"
                        // asterisk="*"
                        containerClass="form-group"
                        type="text"
                        name="designation"
                        value={designation}
                        disabled
                        placeholder="Enter designation"
                        // errorMessage={validator.current.message(
                        //   "designation",
                        //   designation,
                        //   "required|alpha_space"
                        // )}
                        autoComplete="new-designation"
                      />
                    </Col>
                    {/* <Col sm="6">
                      <TextInput
                        onInputChange={(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                          const re = /^[a-z A-Z ]*$/;
                          if (!e.target.value || re.test(e.target.value)) {
                            setManager(e.target.value);
                          }
                        }}
                        label="Manager"
                        // asterisk="*"
                        containerClass="form-group"
                        type="text"
                        name="manager"
                        value={manager}
                        disabled
                        placeholder="Enter manager"
                        // errorMessage={validator.current.message(
                        //   "manager",
                        //   manager,
                        //   "required|alpha_space"
                        // )}
                        autoComplete="new-manager"
                      />
                    </Col> */}

                    {/* <Col sm="6">
                      <Datepicker
                        peekNextMonth={true}
                        showMonthDropdown={true}
                        showYearDropdown={true}
                        dropdownMode="select"
                        label="Confirmation Date"
                        className="form-control"
                        selected={confirmationDate}
                        // asterisk="*"
                        // disabled={[5, 6].includes(roleId) || referralStatus == "1"}
                        placeholder="Select confirmation date"
                        dateFormat="dd/MM/yyyy"
                        onChange={(e: any) => {
                          setConfirmationDate(e);
                          // setRelievingDate
                        }}
                        class
                        maxDate={new Date()}
                        disabled
                      // onKeyDown={true}
                      // showYearDropdown={true}
                      // isClearable={dateOfBirth && true}
                      // errorMessage={validator.current.message(
                      //     "confirmationDate",
                      //     confirmationDate,
                      //     "required"
                      // )}
                      />
                    </Col> */}
                    <Col sm="6">
                      <Datepicker
                        peekNextMonth={true}
                        showMonthDropdown={true}
                        showYearDropdown={true}
                        dropdownMode="select"
                        label="Joining Date"
                        className="form-control"
                        selected={joiningDate}
                        // asterisk="*"
                        // disabled={[5, 6].includes(roleId) || referralStatus == "1"}
                        placeholder="Select joining date"
                        dateFormat="dd/MM/yyyy"
                        onChange={(e: any) => {
                          setJoiningDate(e);
                        }}
                        class
                        maxDate={new Date()}
                        disabled
                        // onKeyDown={true}
                        // showYearDropdown={true}
                        // isClearable={dateOfBirth && true}
                        // errorMessage={validator.current.message(
                        //     "joining Date",
                        //     joiningDate,
                        //     "required"
                        // )}
                      />
                    </Col>
                  </Row>
                </>
                <Row>
                  {profiletToggle && (
                    <Col>
                      <div
                        style={{ float: "right" }}
                        className="form-group mt-3"
                      >
                        <Button
                          type="submit"
                          variant="primary"
                          disabled={disableButton}
                          onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                            e.preventDefault();
                            onUpdate(e);
                            // navigate(-1);
                            
                          }}
                        >
                          Submit
                        </Button>
                        <Button
                          onClick={(e) => {
                            e.preventDefault();
                            setProfiletToggle(false);
                            // validator.current.messagesShown=false;
                            navigate("/");
                          }}
                          variant="secondary"
                          className="ms-2"
                        >
                          Cancel
                        </Button>
                      </div>
                    </Col>
                  )}
                </Row>
              </Col>
            </Row>
          </Form>
        </Card.Body>
      </Card>
    </>
  );
};
export default EmployeeProfileComponent;
