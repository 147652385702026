import React, { useState, useRef, useEffect } from "react";
import { Card, Row, Col, Button, Form } from "react-bootstrap";
import Breadcrumb from "../../components/Breadcrumb/breadcrumb.component";
import { Helmet, TextInput, Datepicker, DropdownInput } from "../../components/FormInputs";
import { useNavigate, useLocation } from "react-router-dom";
import SimpleReactValidator from "simple-react-validator";
import swal from "sweetalert";
import customFunctions from "../../helper/customFunctions";
import Api from "../../helper/api";
import AuthApi from "../../helper/authApi";

interface acceptTaskInterface {
  id?: any,
  title?: string,
  clientName?: string,
  submittedBy?: string,
  dueDate?: any,
  submittedAt?: any,
  taskName?: string,
  subTaskName?: string,
  documentAttachment?: any,
  totalTime?: any,
  discription?: string,
  attachFile?: any,
  approvalStatus?: any
}

const TaskApprovalDetails = (props: any) => {
  const { fromComponent } = props;
  const [title, setTitle] = useState<string>("");

  const location = useLocation();
  const [count, forceUpdate] = useState<number>(0);
  const [disableButton, setDisableButton] = useState<boolean>(false);
  const navigate = useNavigate();
  const validator = useRef(new SimpleReactValidator());
  const [isShowLoader, setShowLoader] = useState(false);

  const [clientName, setClientName] = useState<string>("");
  const [submittedBy, setSubmittedBy] = useState<string>("");
  const [dueDate, setDueDate] = useState<any>(new Date());
  const [submittedAt, setSubmittedAt] = useState<any>(new Date());
  const [taskName, setTaskName] = useState<string>("");
  const [subTaskName, setSubTaskName] = useState<string>("");
  const [documentAttachment, setDocumentAttachment] = useState<string>("")
  const [totalTime, setTotalTime] = useState<string>("");
  const [discription, setDiscription] = useState<string>("");
  const [attachFile, setAttachFile] = useState<any>("")
  const [approvalStatus, setApprovalStatus] = useState<any>("");
  const [label, setLabel] = useState<string>("");


  const [status, setStatus] = useState<any>("")
  // const [status, setStatus] = useState<any>()
  // const status = location.state.data.status;

  useEffect(() => {
    if (location && location?.state?.pageType === "task approval") {
      setStatus(location.state.data.status)
      setClientName(location.state.data.clientname);
      setSubmittedBy(location.state.data.submitedby || "");
      // setDueDate(location.state.data.duedate || "");
      // setSubmittedAt(location.state.data.submitedat || "");
      setTaskName(location.state.data.taskname || "");
      setSubTaskName(location.state.data.subTask_name || "");
      setDocumentAttachment(location.state.data.documentattachment || "");
      setTotalTime(location.state.data.totaltime || "");
      setLabel("Task Details")
      setTitle("Task Details")
      // setDiscription(location.state.data.discription || "");
    }
  }, [])

  let items: any = [
    { to: "/", label: "Dashboard" },
    { to: "/approval-list", label: "Task Approval List" },
    { label: label },
  ];
  // const [items, setItems] = useState<any>(item)


  // useEffect(() => {
  //   if (fromComponent == 'dashboard') {
  //     setItems([
  //       { to: "/", label: "Dashboard" },
  //       { label: label },
  //     ])
  //   }
  // }, [fromComponent])

  const approvalStatusArray = [
    {
      label: 'Accept',
      value: 'Accept'
    },
    {
      label: 'Reject',
      value: 'Reject'
    }
  ]

  const acceptTaskApproval = async () => {
    // setShowLoader(true);
    const postTask: acceptTaskInterface = {
      title: title,
      clientName: clientName,
      submittedBy: submittedBy,
      dueDate: dueDate,
      submittedAt: submittedAt,
      taskName: taskName,
      subTaskName: subTaskName,
      documentAttachment: documentAttachment,
      totalTime: totalTime,
      discription: discription,
      attachFile: attachFile,
      approvalStatus: approvalStatus.value
    }
    // if (location.state) {
    //     postTask.id = location.state.taskId;
    // }
    // const payload = customFunctions.cleanObject(postTask);
    // const endPoint = `${Api.doctorUrl}`;
    // const callback =
    // location.state && location.state.taskId
    //         ? AuthApi.putDataToServer
    //         : AuthApi.postDataToServer;
    // const { data, message } = await callback(endPoint, payload);
    // if (data && data.success === true) {
    //     setShowLoader(false);
    //     setDisableButton(false);
    //     swal(
    //         location.state && location.state.taskId
    //         ? "Task Approved Successfully!"
    //         : "Task successfully!",
    //       "",
    //       "success"
    //     ).then(() => {
    //       navigate(-1);
    //     });
    //   } else {
    //     setDisableButton(false);
    //     setShowLoader(false);
    //     swal(message, "", "error");
    //   }
    if (approvalStatus.value == "Accept") {
      swal("Task Approved Successfully!", '', 'success')
    } else {
      swal("Task Rejected", '', 'success')
    }
    navigate(-1);
  }

  const acceptApproval = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (disableButton) return;
    setDisableButton(true);
    // if (approvalStatus.value == "Accept") {
    //   validator.current.fields.discription = true;
    //   validator.current.errorMessages.discription = null;
    //   validator.current.fields.attachFile = true;
    //   validator.current.errorMessages.attachFile = null;
    // }
    if (validator.current.allValid()) {
      acceptTaskApproval()
    } else {
      setDisableButton(false);
      validator.current.showMessages()
      forceUpdate(count + 1)
    }
  }

  // const rejectTask = () => {
  //   swal({
  //     title: "Are you sure?",
  //     text: "Do you want to reject a task?",
  //     icon: "warning",
  //     // buttons: true,
  //     dangerMode: true,
  //   }).then((rejectTaskForNow) => {
  //     if (rejectTaskForNow) {
  //       swal("Task Reject For Now!", '', 'success')
  //     }
  //   }).then(() => {
  //     navigate(-1)
  //   })
  // }

  return (
    <>
      <Helmet title={title} />
      <div className="page-header">
        <div className="title-breadcrumb-section">
          <h2 className="main-content-title tx-24 mg-b-5">{title}</h2>
          <Breadcrumb items={items} />
        </div>
      </div>
      <section className="content-area"></section>
      <Card>
        <Card.Header>
          <div className="d-flex align-items-center justify-content-end">
            <h6>Status &nbsp;&nbsp;&nbsp;
              <span style={{ color: "red" }} >
                {status}
              </span> &nbsp;&nbsp;&nbsp;
              <img src="images/Bell_16.jpeg" width="30px" height="30px" />
            </h6>
          </div>
        </Card.Header>
        <Card.Body>
          <Form >
            <Row>
              <Col sm="6">
                <TextInput
                  onInputChange={(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                    const re = /^[A-Z a-z]*$/;
                    if (!e.target.value || re.test(e.target.value)) {
                      setClientName(e.target.value);
                    }
                  }}
                  label="Client Name"
                  asterisk="*"
                  containerClass="form-group"
                  type="text"
                  disabled
                  name="clientName"
                  value={clientName}
                  // disabled={state && state.status == 4}
                  placeholder="Enter client name"
                  errorMessage={validator.current.message(
                    "clientName",
                    clientName,
                    "required|alpha_space"
                  )}
                  autoComplete="new-clientName"
                />
              </Col>
              <Col sm="6">
                <TextInput
                  onInputChange={(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                    const re = /^[A-Z a-z 0-9]*$/;
                    if (!e.target.value || re.test(e.target.value)) {
                      setTaskName(e.target.value);
                    }
                  }}
                  label="Task Name"
                  asterisk="*"
                  containerClass="form-group"
                  type="text"
                  name="taskName"
                  value={taskName}
                  disabled
                  placeholder="Enter task name"
                  errorMessage={validator.current.message(
                    "taskName",
                    taskName,
                    "required|alpha_space"
                  )}
                  autoComplete="new-taskName"
                />
              </Col>
              <Col sm="6">
                <TextInput
                  onInputChange={(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                    const re = /^[A-Z a-z 0-9]*$/;
                    if (!e.target.value || re.test(e.target.value)) {
                      setSubTaskName(e.target.value);
                    }
                  }}
                  label="Sub Task Name"
                  // asterisk="*"
                  containerClass="form-group"
                  type="text"
                  name="subTaskName"
                  value={subTaskName}
                  disabled
                  placeholder="Enter sub task name"
                  // errorMessage={validator.current.message(
                  //     "subTaskName",
                  //     subTaskName,
                  //     "required|alpha_space"
                  // )}
                  autoComplete="new-subTaskName"
                />
              </Col>
              <Col sm="6">
                <Datepicker
                  peekNextMonth={true}
                  showMonthDropdown={true}
                  showYearDropdown={true}
                  dropdownMode="select"
                  label="Due Date"
                  className="form-control"
                  selected={dueDate}
                  asterisk="*"
                  disabled
                  placeholder="Enter due date"
                  dateFormat="dd/MM/yyyy"
                  onChange={(e: any) => {
                    setDueDate(e);
                  }}
                  class
                  maxDate={new Date()}
                  // onKeyDown={true}
                  // showYearDropdown={true}
                  // isClearable={dateOfBirth && true}
                  errorMessage={validator.current.message(
                    "dueDate",
                    dueDate,
                    "required"
                  )}
                />
              </Col>
              <Col sm="6">
                <TextInput
                  onInputChange={(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                    const re = /^[A-Z a-z]*$/;
                    if (!e.target.value || re.test(e.target.value)) {
                      setSubmittedBy(e.target.value);
                    }
                  }}
                  label="Submitted By"
                  asterisk="*"
                  containerClass="form-group"
                  type="text"
                  name="submittedBy"
                  value={submittedBy}
                  disabled
                  placeholder="Enter submitted by"
                  errorMessage={validator.current.message(
                    "submittedBy",
                    submittedBy,
                    "required|alpha_space"
                  )}
                  autoComplete="new-submittedBy"
                />
              </Col>

              <Col sm="6">
                <Datepicker
                  peekNextMonth={true}
                  showMonthDropdown={true}
                  showYearDropdown={true}
                  dropdownMode="select"
                  label="Submitted At"
                  className="form-control"
                  selected={submittedAt}
                  asterisk="*"
                  disabled
                  placeholder="Enter submitted date"
                  dateFormat="dd/MM/yyyy"
                  onChange={(e: any) => {
                    setSubmittedAt(e);
                  }}
                  class
                  maxDate={new Date()}
                  // onKeyDown={true}
                  // showYearDropdown={true}
                  // isClearable={dateOfBirth && true}
                  errorMessage={validator.current.message(
                    "submittedAt",
                    submittedAt,
                    "required"
                  )}
                />
              </Col>

              <Col sm="6">
                <TextInput
                  onInputChange={(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                    const re = /^[0-9 : ]*$/;
                    if (!e.target.value || re.test(e.target.value)) {
                      setTotalTime(e.target.value);
                    }
                  }}
                  label="Total Time"
                  asterisk="*"
                  containerClass="form-group"
                  type="text"
                  name="totalTime"
                  value={totalTime}
                  disabled
                  placeholder="Enter total time"
                  errorMessage={validator.current.message(
                    "totalTime",
                    totalTime,
                    "required|numeric"
                  )}
                  autoComplete="new-totalTime"
                />
              </Col>
              <Col sm={6}>
                <DropdownInput
                  asterisk="*"
                  label="Approval Status"
                  placeholder="Select approval status"
                  options={approvalStatusArray}
                  defaultValue={approvalStatus}
                  onSelectHandler={(e: any) => {
                    setApprovalStatus(e);
                  }}
                  errorMessage={validator.current.message(
                    "Approval status",
                    approvalStatus,
                    "required"
                  )}
                />
              </Col>
              <Col sm="6">
                <TextInput
                  onInputChange={(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                    setDocumentAttachment(e.target.value);
                  }}
                  label="Document Attachment"
                  asterisk="*"
                  containerClass="form-group"
                  type="text"
                  name="documentAttachment"
                  value={documentAttachment}
                  // disabled={state && state.status == 4}
                  placeholder="Document attachment"
                  errorMessage={validator.current.message(
                    "Document attachment",
                    documentAttachment,
                    "required"
                  )}
                  autoComplete="new-documentAttachment"
                />
              </Col>
            </Row>

            <Row>
              <Col sm="6">
                <TextInput
                  onInputChange={(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                    const re = /^[0-9 A-Z a-z]*$/;
                    if (!e.target.value || re.test(e.target.value)) {
                      setDiscription(e.target.value);
                    }
                  }}
                  label="Discription"
                  // asterisk={approvalStatus.value == "Reject" ? "*" : ""}
                  asterisk={"*"}
                  containerClass="form-group"
                  type="text"
                  as="textarea"
                  name="discription"
                  value={discription}
                  // disabled={state && state.status == 4}
                  placeholder="Enter discription"
                  errorMessage={validator.current.message(
                    "discription",
                    discription,
                    "required"
                  )}
                  autoComplete="new-discription"
                />
              </Col>
              <Col sm="6">
                <TextInput
                  onInputChange={(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                    setAttachFile(e.target.value);
                  }}
                  label="Attach File"
                  // asterisk={approvalStatus.value == "Reject" ? "*" : ""}
                  // asterisk="*"
                  containerClass="form-group"
                  type="file"
                  name="attachFile"
                  value={attachFile}
                  // disabled={state && state.status == 4}
                  placeholder="Attach a file"
                  // errorMessage={approvalStatus.value == 'Reject' && validator.current.message(
                  //   "attachFile",
                  //   attachFile,
                  //   "required"
                  // )}
                  autoComplete="new-attachFile"
                />
              </Col>
            </Row>

            <Row>
              <Col>
                <div
                  style={{ float: "right" }}
                  className="form-group mt-3"
                >
                  <Button
                    type="submit"
                    variant="primary"
                    onClick={(e: any) => {
                      e.preventDefault();
                      acceptApproval(e)
                    }}
                  >Submit
                  </Button>
                  <Button
                    variant="secondary"
                    className="ms-2"
                    onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                      e.preventDefault();
                      navigate(-1);
                      // rejectTask()
                    }}
                  >Cancel
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>
        </Card.Body>
      </Card>
    </>
  )
}
export default TaskApprovalDetails